export const MSG = {
    FNAMEREQ: "First name is required",
    LNAMEREQ: "Last name is required",
    VALIDNAMREQ: "Name should contain only Alphabets",
    INVEMAILREQ: "Please enter a valid email address",
    INVMOBNUMREQ: "Invalid Mobile number",
    VLDPINCODE_REQ:"Enter valid Pincode",
    MOBNUMREQ: "Phone number is required",
    NAMEREQ: " Name is required",
    TRNTYPEREQ: " Transaction Type  is required",
    TYPOFCLIENTREQ: " Type Of Client is required",
    VERREQ: " Vertical is required",
    VERTYPEREQ: " Vertical Type is required",
    SUBVERTYPEREQ: " Sub Vertical Type is required",
    STATUSREQ : "Status is required",
    STAGEREQ : "Stage is required",
    OWNERREQ : "Assigned Staff is required",
    FIELDPERSON : "Field person is required",
    SITEREQ : "Site is required",
    TITLEREQ : "Title is required",
    PRIORITYREQ : "Priority is required",
    SELALLVALUES : "Please enter all values",
    NEGETIVE : "Enter Valid Value",
    LEASEEXPIERYDATE : "Lease expirationdate is required",
    AREATYPEREQ : "Area type is required",
    UNITREQ:"Unit is required",
    VALUEREQ: "Value is required",
    FLOORREQ: "Floor is required",
    TASKREQ: "Task is required",
    PASSREQ: "Password is required",
    ROLEREQ: "Role is required",
    LEAVEDATE: "Leaving Date must be grater than Joining Date",
    JOINDATE: "Joining Date must be less than Leaving Date",
};