import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import moment from "moment"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import PubSub from 'pubsub-js';

const FilesEdit = (props) => {
    const [files, setFiles] = useState(props.file.row);
    useEffect(() => {
        if (props.table === "lead" && props.parent !== null && props.file !== null) {
            //.log('//.log(files);', files);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
  
            console.log("handleSubmit",files)
            const result = await inventoryApi.saveFiles(files);
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', {title: 'Record Saved', message: 'Record saved successfully'});
                submitFiles();
            }

    }
    const submitFiles = () => {
        props.submitFiles();
    };

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Group controlId="formBasicTitle">
              <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                Title
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title"
                value={files.title}
                onChange={(e) =>
                  setFiles({ ...files, [e.target.name]: e.target.value })
                }
              />
            </Form.Group>
          </Form.Group>
          
          {/* ============================================================= */}


          {console.log("props.table ",props)}

          {(props.table === "property")  && (
            <Form.Group controlId="formBasicDescription">
            <Form.Label
              className="form-view-label"
              htmlFor="formBasicDescription"
            >
              Document Type
            </Form.Label>
            <Form.Select
              as="select"
              name="documenttype"
              value={files.documenttype}
              onChange={(e) =>
                setFiles({ ...files, [e.target.name]: e.target.value })
              }
            >
                <option value="">Select Type</option>
              {!(props.table === "property" && ( props.parent.vertical === "Warehouse" || props.parent.vertical === "Investment" || props.parent.vertical === "Cold storage" || props.parent.vertical === "Factory"))  ? (
                <>
                  {/* <option value="Proposed Option">Proposed Option</option> */}
                  {/* <option value="City Overview">City Overview</option> */}
                  {/* <option value="Modern Facilities"> Modern Facilities</option> */}
                  <option value="Actual View">Actual View</option>
                  <option value="Brand Mapping">Brand Mapping</option>
                  <option value="Floor Plan Basement Floor">Floor Plan Basement Floor</option>
                  <option value="Floor Plan Ground Floor">Floor Plan Ground Floor</option>
                  <option value="Floor Plan 1st Floor">Floor Plan 1st Floor</option>
                  <option value="Floor Plan 2nd Floor">{props.parent.vertical  !== 'Retail' ?'Floor Plan 2nd Floor' :'Section Height' }</option>
                  <option value="Typical Floor plan">Typical Floor plan</option>
                  <option value="Floor Plan Terrace"> Terrace Floor Plan</option>
                  <option value="Inner Pics">Inner Pics</option>
                  <option value="Elevation">Elevation</option>
                  <option value="Site Plan">Site Plan</option>
                  <option value="Vicinity Images">Vicinity Images</option>

                </>
              ):
              <>
              {/* <option value="aboutUsPage">About Us Page</option> */}
              <option value="Inner View">Inner View </option>
              <option value="Outer View">Outer View </option>
              <option value="layout Plan">Layout Plan</option>
              </>
              }
              <option value="other">Other</option>
             
            </Form.Select>
          </Form.Group>
        )}

         
          <br></br>
          {/* --------------------------------------------------------- */}
          <Form.Group controlId="formBasicDescription">
            <Form.Label
              className="form-view-label"
              htmlFor="formBasicDescription"
            >
              Description
            </Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              placeholder="Enter description"
              value={files.description}
              onChange={(e) =>
                setFiles({ ...files, [e.target.name]: e.target.value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="submit">
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          <Button onClick={props.onHide} variant="light">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
};
export default FilesEdit;