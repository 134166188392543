import React, { useState, useEffect,  forwardRef, useImperativeHandle, useRef  } from 'react'
import { Badge, Button, Col, Row, Table,Tooltip,OverlayTrigger } from 'react-bootstrap';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import inventoryApi from '../api/inventoryApi';

  const RelatedPricingList = ({parent, relatedListPrice}) => {

  const [body, setBody] = useState([]);


//Remodified by saideep-15 May 2024
  useEffect(() => {
    const filteredHeightList = parent?.pricingorrental?.filter(area => area?.type === "pricing");
    console.log('parent',parent,filteredHeightList);  
    if (filteredHeightList && filteredHeightList.length > 0) {
      setBody(filteredHeightList);
    } else {
      setBody([]);
    }
  }, [relatedListPrice]);
  


  const labels = {
    beforeSelect: " "
  }

  const header = [
    { title: 'Floor', prop: 'floorno' },
    { title: 'Unit', prop: 'unit' },
    // { title: 'Area', prop: 'area'},
    { title: 'Per Unit Cost', prop: 'perunitcost'},
    { title: 'Saleable Area', prop: 'saleablearea'},
    { title: 'Quoted Cost', prop: 'quotedcost'},
  ];


  return (
    <>
      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody rowKey="id" />
          </Table>
          <Pagination />
        </DatatableWrapper> : ''}
    </>
  )
};

export default RelatedPricingList;