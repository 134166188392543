import React, { useState, useEffect,  forwardRef, useImperativeHandle, useRef  } from 'react'
import { Badge, Button, Col, Row, Table,Tooltip,OverlayTrigger } from 'react-bootstrap';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import inventoryApi from '../api/inventoryApi';

const RelatedRentList = ({parent, refreshHeightList}) => {

  
  const [body, setBody] = useState([]);

  useEffect(() => {
    const filteredHeightList = parent?.pricingorrental?.filter(area => area?.type === "rent");
    console.log('parent',parent,filteredHeightList);  

    setBody(filteredHeightList)

  }, [refreshHeightList]);
  
 

  const labels = {
    beforeSelect: " "
  }

  const header = [
    { title: 'Floor', prop: 'floorno' },
    { title: 'Unit', prop: 'unit' },
    { title: 'Area', prop: 'area'},
    { title: 'Rental Value', prop: 'rentalvalue'},
    { title: 'Chargeble Area', prop: 'chargeblearea'},
    { title: 'Quoted Rent Per Month', prop: 'quotedrentpermonth'},
  ];


  return (
    <>
      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody rowKey="id" />
          </Table>
          <Pagination />
        </DatatableWrapper> : ''}
    </>
  )
};

export default RelatedRentList;