const propertyData1 =[
  {label : 'Floor Plate' , value  : 'areadetail_summary',defaultvalues:""},
  {label : 'Possession Timeline', value  : 'possessiondate',defaultvalues:""},
  {label : 'Rental' , value  : 'pricingorrentaldetail_summary',defaultvalues:""},
  {label : 'Location', value  : 'googlelocation',defaultvalues:""},
  {label : 'Frontage', value  : 'frontagearea',defaultvalues:""},
  {label : 'Tenure', value  : 'tenure',defaultvalues:""},
]
const propertyDatahotel1 =[
  {label : 'Floors' , value  : 'areadetail_summary',defaultvalues:""},
  {label : 'Total Keys', value  : 'noofkeys',defaultvalues:""},
  {label : 'Banquet Capacity' , value  : 'banquetcapacity',defaultvalues:""},

]
const propertyDatahotel2 =[
  {label : 'Location' , value  : 'googlelocation',defaultvalues:""},
  {label : 'Lifts', value  : 'noofservicelifts',defaultvalues:""},
  {label : 'Tenure' , value  : 'tenure',defaultvalues:""},

]
const propertyDatahotel3 =[
  {label : 'Rent Escalation', value  : 'rentescalations',defaultvalues:"15% after every three years of last paid rent"},
  {label : 'Maintenance Charges', value  : 'maintainancecharges',defaultvalues:""},
  {label : 'Electric Connection' , value  : 'electricharges',defaultvalues:"Available as per requirement"},
  {label : 'Taxes' , value  : 'floorplate',defaultvalues:"Any Taxes on the property are to be borne by the landlord. Any taxes on rental like GST. are to be paid by the company."},
  {label : 'Stamp Duty and Registration Cost', value  : 'stampduty',defaultvalues:"50 : 50"},
  {label : 'Status', value  : 'statustable',defaultvalues:"Commercial Approved"},
  {label : 'Neighbouring Hotels', value  : 'marketbrand',defaultvalues:""},
]
const propertyDataland1 =[
  {label : 'Land Area' , value  : 'areadetail_summary',defaultvalues:""},
  {label : 'Status', value  : 'subverticaltype',defaultvalues:""},
  {label : 'Address' , value  : 'address',defaultvalues:""},
  {label : 'Location', value  : 'googlelocation',defaultvalues:""},
  {label : 'Proposed model for development', value  : 'verticaltype',defaultvalues:""},
]

const propertyDataland2 =[
  {label : 'Land Price' , value  : 'pricingorrentaldetail_summary',defaultvalues:""},
  {label : 'Frontage', value  : 'frontagearea',defaultvalues:""},
  {label : 'Road Width' , value  : 'roadwidth',defaultvalues:""},
  {label : 'Near by Railway Station', value  : 'nearrailwasytation',defaultvalues:""},
  {label : 'Near by International Airport', value  : 'nearinternationalairport',defaultvalues:""},
]

const propertyDataretail2 =[
  {label : 'Rent Escalation', value  : 'rentescalations',defaultvalues:"15% after every three years of last paid rent"},
  {label : 'Maintenance Charges', value  : 'maintainancecharges',defaultvalues:""},
  {label : 'Electric Connection' , value  : 'electricharges',defaultvalues:"Available as per requirement"},
  {label : 'Taxes' , value  : 'taxes',defaultvalues:"Any Taxes on the property are to be borne by the landlord. Any taxes on rental like GST. are to be paid by the company."},
  {label : 'Stamp Duty and Registration Cost', value  : 'stampduty',defaultvalues:"50 : 50"},
  {label : 'Status', value  : 'statustable',defaultvalues:"Commercial Approved"},
  {label : 'Neighbouring Brands', value  : 'marketbrand',defaultvalues:""},
]
const propertyDataoffice2 =[
  {label : 'Rent Escalation', value  : 'rentescalations',defaultvalues:"15% after every three years of last paid rent"},
  {label : 'Maintenance Charges', value  : 'maintainancecharges',defaultvalues:""},
  {label : 'Electric Connection' , value  : 'electricharges',defaultvalues:"Available as per requirement"},
  {label : 'Taxes' , value  : 'taxes',defaultvalues:"Any Taxes on the property are to be borne by the landlord. Any taxes on rental like GST. are to be paid by the company."},
  {label : 'Stamp Duty and Registration Cost', value  : 'stampduty',defaultvalues:"50 : 50"},
  {label : 'Status', value  : 'statustable',defaultvalues:"Commercial Approved"},

]
const cityOverviewData = [
  {label : 'State', value  : 'state',defaultvalues:""},
  {label : 'City', value  : 'city',defaultvalues:""},
  {label : 'Area', value  : 'areas',defaultvalues:"567 km²"},
  {label : 'Population', value  : 'population',defaultvalues:"9,210,388"},
  {label : 'Population Growth', value  : 'growth',defaultvalues:"2.43%"},
  {label : 'Population Density', value  : 'density',defaultvalues:"27,613 / KM²"},
  {label : 'Average Literacy Rate', value  : 'literacyrate',defaultvalues:"83.33%"},
  {label : 'Annual Per Capita Income(District)', value  : 'capita',defaultvalues:"Rs 1,56,150"}
]

const modernFacilities = {
  vaastu: "Vaastu Compliant",
  ROWater: "RO Water System",
  HighSpeed: "High-Speed Internet",
  wheelChair: "Wheelchair Accessibility",
  maintenanceStaff: "Maintenance Staff",
  powerBackup: "Power Backup",
  DedicatedParking: "Dedicated Parking"}

  const specificationData = [
    {label : 'Area', value  : 'state',defaultvalues:""},
    {label : 'Total land Area', value  : 'openareavalue',defaultvalues:""},
    {label : 'Title of Land', value  : 'verticaltype',defaultvalues:""},
    {label : 'Approach/Access Road Width', value  : 'roadwidth',defaultvalues:""},
    {label : 'Location', value  : 'address',defaultvalues:""},
    {label : 'Clear Height at the Center', value  : 'heightcenter',defaultvalues:""},
    {label : 'Clear Height (Side)', value  : 'heightside',defaultvalues:""},
    {label : 'Fire Noc', value  : 'firenoc',defaultvalues:""},
    {label : 'No. of Docks', value  : 'noofdocksvalue',defaultvalues:""}
  ]
  const commerticalData = [
    {label : 'Lease agreement', value  : 'state',defaultvalues:""},
    {label : 'Possession', value  : 'possessiondate',defaultvalues:""},
    {label : 'Area ( carpet)', value  : 'offeredcarpetarea',defaultvalues:""},
    {label : 'Rent sq.ft', value  : 'population',defaultvalues:""},
    {label : 'Suggested by', value  : 'growth',defaultvalues:"Ritesh Agarwal"},
    {label : 'Contact', value  : 'density',defaultvalues:"+91 9829610077"},
    {label : 'Company', value  : 'company',defaultvalues:"Sthapatya Leasing & Consultant"},
  ]
 
  const warekeyDetails1 = [
    {label : 'Investment Type', value  : 'typeofwarehouse',defaultvalues:""},
    {label : 'Type', value  : 'subverticaltype',defaultvalues:""},
    {label : 'Covered Area', value  : 'offeredcarpetarea',defaultvalues:""},
    {label : 'Land Area', value  : 'openareavalue',defaultvalues:""},
    {label : 'Land Title', value  : 'verticaltype',defaultvalues:"Ritesh Agarwal"},
  ]
  const warekeyDetails2 = [
    {label : 'Cold Storage Type', value  : 'typeofwarehouse',defaultvalues:""},
    {label : 'Type', value  : 'subverticaltype',defaultvalues:""},
    {label : 'Covered Area', value  : 'offeredcarpetarea',defaultvalues:""},
    {label : 'Land Area', value  : 'openareavalue',defaultvalues:""},
    {label : 'Land Title', value  : 'verticaltype',defaultvalues:"Ritesh Agarwal"},
  ]
  const warekeyDetails3 = [
    {label : 'Factory Type', value  : 'typeofwarehouse',defaultvalues:""},
    {label : 'Type', value  : 'subverticaltype',defaultvalues:""},
    {label : 'Covered Area', value  : 'offeredcarpetarea',defaultvalues:""},
    {label : 'Land Area', value  : 'openareavalue',defaultvalues:""},
    {label : 'Land Title', value  : 'verticaltype',defaultvalues:"Ritesh Agarwal"},
  ]
  const warekeyDetails4 = [
    {label : 'Warehouse Type', value  : 'typeofwarehouse',defaultvalues:""},
    {label : 'Type', value  : 'subverticaltype',defaultvalues:""},
    {label : 'Covered Area', value  : 'offeredcarpetarea',defaultvalues:""},
    {label : 'Land Area', value  : 'openareavalue',defaultvalues:""},
    {label : 'Land Title', value  : 'verticaltype',defaultvalues:"Ritesh Agarwal"},
  ]

export const initialData = {
  "Office" : [
    {page : 'Home Page', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"", isVisible : true},
    {page : 'Over View' ,section : "City Overview" , image1:"/pdf_template_1/secondpageimage.imagelink.jpg", tableData:cityOverviewData,values:"",  isVisible : true},
    {page : 'Google View', section : 'Google View',image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"", isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyData1,values:"", isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDataoffice2,values:"", isVisible : true},
    {page : 'Modern Facilites',  section:'Modern Facilities',image1:"/pdf_template_1/fifthpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Images Pages',  section:'Modern Facilities',image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Contact Page',  section:'Modern Facilities',  isVisible : true},
    {page : 'Thanks Page',  section:'Contact Details', isVisible : true},
  ],
  "Land" : [
    {page : 'Home Page', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    // {page : 'Over View' ,section : "City Overview" , image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:cityOverviewData,defaultvalues:""},
    {page : 'Google View', section : 'Google View',image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"", isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDataland1,values:"", isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDataland2,values:"",  isVisible : true},
    // {page : 'Modern Facilites',  section:'Modern Facilities',image1:"/pdf_template_1/fifthpage.imagelink.jpg", tableData:modernFacilities,defaultvalues:""},
    {page : 'Images Pages',  section:'Modern Facilities',image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Contact Page',  section:'Modern Facilities', isVisible : true},
    {page : 'Thanks Page',  section:'Contact Details', isVisible : true},

  ],
  "Retail" : [
    {page : 'Home Page', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'Over View' ,section : "City Overview" , image1:"/pdf_template_1/secondpageimage.imagelink.jpg", tableData:cityOverviewData,values:"", isVisible : true},
    {page : 'Google View', section : 'Google View',image1:"/pdf_template_1/firstpage.imagelink.jpg",defaultvalues:"", isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyData1,values:"",  isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDataretail2,values:"",  isVisible : true},
    {page : 'Modern Facilites',  section:'Modern Facilities',image1:"/pdf_template_1/fifthpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Images Pages',  section:'Modern Facilities',image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Contact Page',  section:'Modern Facilities',  isVisible : true},
    {page : 'Thanks Page',  section:'Contact Details',  isVisible : true},
    {page : 'About us',  section:'Contact Details',  isVisible : true},
  ],
  "Warehouse" : [
    {page : 'Warehouse Home', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'About Us', image1:"/warehouse-pdf/AboutUs.jpeg", image2 : "/warehouse-pdf/AboutUs2.jpeg" ,values:"",  isVisible : true},
    {page : 'Key Details',  section:'Contact Details',tableData:warekeyDetails4,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:specificationData,columns:false,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:commerticalData,columns:true,  isVisible : true},   
    {page : 'Images Pages',  isVisible : true},
    {page : 'Warehouse Contact',  section:'Modern Facilities',  isVisible : true},
  ],
  "Investment" : [
    {page : 'Warehouse Home', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'About Us', image1:"/warehouse-pdf/AboutUs.jpeg", image2 : "/warehouse-pdf/AboutUs2.jpeg" ,values:"",  isVisible : true},
    {page : 'Key Details',  section:'Contact Details',tableData:warekeyDetails1,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:specificationData,columns:false,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:commerticalData,columns:true,  isVisible : true},   
    {page : 'Images Pages',  isVisible : true},
    {page : 'Warehouse Contact',  section:'Modern Facilities',  isVisible : true},
  ],
  "Cold storage" : [
    {page : 'Warehouse Home', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'About Us', image1:"/warehouse-pdf/AboutUs.jpeg", image2 : "/warehouse-pdf/AboutUs2.jpeg" ,values:"",  isVisible : true},
    {page : 'Key Details',  section:'Contact Details',tableData:warekeyDetails2},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:specificationData,columns:false},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:commerticalData,columns:true},   
    {page : 'Images Pages',  isVisible : true},
    {page : 'Warehouse Contact',  section:'Modern Facilities',  isVisible : true},
  ],
  "Factory" : [
    {page : 'Warehouse Home', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'About Us', image1:"/warehouse-pdf/AboutUs.jpeg", image2 : "/warehouse-pdf/AboutUs2.jpeg" ,values:"",  isVisible : true},
    {page : 'Key Details',  section:'Contact Details',tableData:warekeyDetails3,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:specificationData,columns:false,  isVisible : true},
    {page : 'Warehouse Table',  section:'Contact Details',tableData:commerticalData,columns:true},   
    {page : 'Images Pages',  isVisible : true},
    {page : 'Warehouse Contact',  section:'Modern Facilities',  isVisible : true},
  ],
  "Hotels" : [
    {page : 'Home Page', image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    // {page : 'Over View' ,section : "City Overview" , image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:cityOverviewData,defaultvalues:""},
    {page : 'Google View', section : 'Google View',image1:"/pdf_template_1/firstpage.imagelink.jpg",values:"",  isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDatahotel1,values:"",  isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDatahotel2,values:"",  isVisible : true},
    {page : 'Property Details', section:'Property Details',tableData : propertyDatahotel3,values:"",  isVisible : true},
    {page : 'Modern Facilites',  section:'Modern Facilities',image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Images Pages',  section:'Modern Facilities',image1:"/pdf_template_1/firstpage.imagelink.jpg", tableData:modernFacilities,values:"",  isVisible : true},
    {page : 'Contact Page',  section:'Modern Facilities',  isVisible : true},
    {page : 'Thanks Page',  section:'Contact Details',  isVisible : true},
    {page : 'About us',  section:'Contact Details',  isVisible : true},
  ]
}