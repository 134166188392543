
import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Card, Col, Container, Row,Tooltip,OverlayTrigger  } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import * as constants from "../../constants/CONSTANT";
import moment from "moment";

function PropertyDetail() {
    
    const location = useLocation();
  const [property, setProperty] = useState(location.state ? location.state : {});
  useEffect(() => {

    fetchProperty();

  }, []);
  const VerticalColors = {
    Office: "#82eedd",
    Land: "#c9ee82",
    Retail: "#ff80ed",
    Investment: "#e3c6ff",
    Logistic: "#00d5ff",
    Warehouse: "#d0ae8b",
    Others: "#7f72a2",
  };
  const fetchProperty = () => {
    // Coming from Email


    async function initProperty() {
        let response = await fetch(
            constants.API_BASE_URL + "/api/properties/public/ibs_sthapatya",
            {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          const result = await response.json();
          console.log('result',result)
      //.log('resultresult',result,property);
      if (result) {
        setProperty(result.find((e) => e.id === location.pathname.split("/")[2]));
        console.log('result',result.filter((e) => e.id === location.pathname.split("/")[2]));
      } else {
        setProperty({});
      }
    }
    initProperty();
  };
  return (
    <Row className="view-form gx-5 px-4">
              <Col lg={12} >
                <Row className="view-form-header align-items-center">
                  <Col lg={6}>
                    Inventory
                    <h4>{property.name}</h4>
                  </Col>
                </Row>
                <Row
                className="py-3 ibs-edit-form"
                style={{ backgroundColor: "#fff" ,
              }}>
                  <Col lg={4}>
                    <label>Property Name</label>
                    <span>{property.name}</span>
                  </Col>
                { property.contactid && <Col lg={4}>
                    <label>Contact Person</label>
                    <span>
                      <Link to={`/contacts/${property.contactid}`}>
                        {property.contactname || <br />}
                      </Link>
                    </span>
                  </Col>}
                  {/* {property.transactiontype === "Lease" && <Col lg={4}>
                    <label>Lease Expiration Date</label>
                    <span>{property.leaseexpirationdate} </span>
                  </Col> } */}
                   {property.transactiontype && 
                  <Col lg={4}>
                    <label>Transaction</label>
                    <span>{property.transactiontype}</span>
                  </Col>
                  }
                  {property.typeofclient && 
                  <Col lg={4}>
                    <label>Type Of client</label>
                    <span>{property.typeofclient} </span>
                  </Col>
                  }
                  {property.vertical && 
                  <Col lg={4}>
                    <label>Vertical</label>       
                      <Badge
                        bg={property.vertical !== null ?  property.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[property.vertical] : ""}
                        style={{
                          display: "block",
                          paddingBottom: "5px",
                          backgroundColor: property.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[property.vertical],
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                        }}
                      >
                        {property.vertical}
                      </Badge>
              
                  </Col>
                  }
                  {property.verticaltype && 
                  <Col lg={4}>
                    <label>Vertical Type</label>
                    <span>{property.verticaltype} </span>
                  </Col>
                  }
                  {property.subverticaltype && 
                  <Col lg={4}>
                    <label>Sub Vertical Type</label>
                    <span>{property.subverticaltype}</span>
                  </Col>
                  }
                  {property.retailsubvertical && 
                  <Col lg={4}>
                    <label>Retail Sub Vertical</label>
                    <span>{property.retailsubvertical}</span>
                  </Col>
                  }
                {/* {property.possessionstatus && <Col lg={4}>
                  <label>Possession Status</label>
                  <span>{property.possessionstatus}</span>
                </Col>} */}
                 {property.Possessiondate && <Col lg={4}>
                    <label>Possession Date</label>
                    <span>{moment(property.Possessiondate).format("DD-MM-YYYY")}</span>
                  </Col>}
                 {property?.propertytype &&  <Col>
                  <label>Property Type</label>
                  <span>{property?.propertytype}</span>
                  </Col>}
                 {property.furnishedstatus &&  <Col lg={4}>
                    <label>Furnished</label>
                    <span>{property.furnishedstatus}</span>
                  </Col>
                  }
                  {property.leaseexpirationdate && <Col lg={4}>
                    <label>Lease Expiration Date</label>
                    <span>{property.leaseexpirationdate} </span>
                  </Col> 
                  }

                  {property.locatedin && <Col lg={4}>
                    <label>Located In</label>
                    <span>{property.locatedin} </span>
                  </Col> 
                  }
                  {property.availability && <Col lg={4}>
                    <label>Availability</label>
                    <span>{property.availability} </span>
                  </Col> 
                  }

                 {property.noofdocksvalue && property.noofdocksvalue !== "0" && <Col lg={4}>
                    <label>No. of docks </label>
                    <span>{property.noofdocksvalue}</span>
                  </Col>}
                  {property.dockheight && <Col lg={4}>
                  <label>Dock height in ft.</label>
                    <span>{property.dockheight}</span>
                  </Col>}
                  {property.docklevel && <Col lg={4}>
                  <label>Dock level drop down</label>
                    <span>{property.docklevel}</span>
                  </Col>}
                  {property.noofwashroomsvalue && property.noofwashroomsvalue !== "0" && <Col lg={4}>
                    <label>No.of Washrooms</label>
                    <span>{property.noofwashroomsvalue}</span>
                  </Col>}
                  {property.verticaltype === "Warehousing" && property?.openareaunit  && property?.openareavalue  ? 
                    <Col lg={4}>
                      <label>Open area</label>
                      <span>
                        {property?.openareaunit + " " + property?.openareavalue} 
                      </span>
                    </Col>
                   :''}
                  {property.verticaltype === "Warehousing" && property.closeareaunit && property.closeareavalue ?
                    <Col lg={4}>
                      <label>Close area</label>
                      <span>
                        {property.closeareaunit + " "}
                        {property.closeareavalue}
                      </span>
                    </Col>
                  :''}
                  {property.verticaltype === "Warehousing" && property.rentalunit  && property.rentalvalue ?
                    <Col lg={4}>
                      <label>Rental</label>
                      <span>
                        {property.rentalunit + " "}
                        {property.rentalvalue}
                      </span>
                    </Col>
                  :''}
                {property.floor &&  <Col lg={4}>
                    <label>Floor</label>
                    <span>{property.floor}</span>
                  </Col>}
                  {property.chargeablearea &&  <Col lg={4}>
                  <label>Approx. Chargeable Area (Sq.ft)</label>
                    <span>{property.chargeablearea}</span>
                  </Col>}
                  {property.offeredcarpetarea &&  <Col lg={4}>
                  <label>Carpet Area Offered in Sq.ft</label>                   
                   <span>{property.offeredcarpetarea}</span>
                  </Col>}
                  {property.heightside &&  <Col lg={4}>
                  <label>Height (ft.) Side</label>
                    <span>{property.heightside}</span>
                  </Col>}
                  {property.heightcenter &&  <Col lg={4}>
                  <label>Height (ft.) Center</label>
                    <span>{property.heightcenter}</span>
                    </Col>}

                    {property.typeofflooring &&  <Col lg={4}>
                    <label>Type of Flooring</label>
                    <span>{property.typeofflooring}</span>
                  </Col>}

                  {property.firehydrants &&  <Col lg={4}>
                  <label>Fire Hydrants</label>
                    <span>{property.firehydrants}</span>
                  </Col>}

                  {property.firesprinkelers &&  <Col lg={4}>
                  <label>Fire Sprinkelers</label>
                    <span>{property.firesprinkelers}</span>
                  </Col>}
                  {property.firenoc &&  <Col lg={4}>
                  <label>Fire NOC</label>
                    <span>{property.firenoc}</span>
                  </Col>}
                  {property.quotedrentonchargeablearea &&  <Col lg={4}>
                  <label>Quoted Rent On Chargeable Area</label>
                    <span>{property.quotedrentonchargeablearea}</span>
                  </Col>}
                  {property.securitydeposit &&  <Col lg={4}>
                  <label>Security Deposit</label>
                    <span>{property.securitydeposit}</span>
                  </Col>}
                  {property.advance && <Col lg={4}>
                    <label>Advance</label>
                    <span>{property.advance}</span>
                  </Col>}

                  {property.commonareamaintaince &&  <Col lg={4}>
                  <label>Common Area Maintenance (CAM)</label>
                    <span>{property.commonareamaintaince}</span>
                  </Col>}
                  {property.possessionstatus && <Col lg={4}>
                  <label>Possession Status</label>
                  <span>{property.possessionstatus}</span>
                </Col>}
                
                {property.addtionalinformation && <Col lg={4}>
                <label>Additional Information</label>
                  <span>{property.addtionalinformation}</span>
                </Col>}
                {property.roadwidth && <Col lg={4}>
                    <label>Road width in ft.</label>
                    <span>{property.roadwidth}</span>
                  </Col>}
                  {property.labourroom && <Col lg={4}>
                    <label>Labour Room</label>
                    <span>{property.labourroom}</span>
                  </Col>}
                  {property.guardroom && <Col lg={4}>
                    <label>Guard Room</label>
                    <span>{property.guardroom}</span>
                  </Col>}
                  {property.powerconnection && <Col lg={4}>
                  <label>Power Connection (KV)</label>
                    <span>{property.powerconnection}</span>
                  </Col>}
                  {property.waterconnection && <Col lg={4}>
                  <label>Water connection (Drop Down)</label>
                    <span>{property.waterconnection}</span>
                  </Col>}
                  {property.flooringloadingcapacity && <Col lg={4}>
                  <label>Flooring Loading Capacity ( in tons)</label>
                    <span>{property.flooringloadingcapacity}</span>
                  </Col>}

                {property.project && <Col lg={4}>
                <label>Project (Building Name)</label>
                  <span>{property.project}</span>
                </Col>}
                {property.location && <Col lg={4}>
                <label>Location</label>
                  <span>{property.location}</span>
                </Col>}
                {property.totalfloors && <Col lg={4}>
                <label>Total No. of Floors</label>
                  <span>{property.totalfloors}</span>
                </Col>}
                {property.totalbuilduparea && <Col lg={4}>
                <label>Total Built-up of Building in Sq.ft(approx.)</label>
                  <span>{property.totalbuilduparea}</span>
                </Col>}
                {property.offeredspacedetails && <Col lg={4}>
                <label>Offered space details</label>
                  <span>{property.offeredspacedetails}</span>
                </Col>}
                {property.flooroffered && <Col lg={4}>
                <label>Floor Number/s offered</label>
                  <span>{property.flooroffered}</span>
                </Col>}
                {property.Quotedrent && <Col lg={4}>
                <label>Quoted Rent (in Rs) per Sft. per month (excluding applicable taxes)</label>
                  <span>{property.Quotedrent}</span>
                </Col>}
                {property.maintainancecharges && <Col lg={4}>
                <label>Maintenance Charges per sft per month</label>
                  <span>{property.maintainancecharges}</span>
                </Col>}
                {property.powerbackup && <Col lg={4}>
                <label>Power and Power back up</label>
                  <span>{property.powerbackup}</span>
                </Col>}   
                {property.powerallocation && <Col lg={4}>
                <label>Total Power allocation for the unit</label>
                  <span>{property.powerallocation}</span>
                </Col>
                }
                {property.powerbackupsystem && <Col lg={4}>
                <label>Power Back - up System</label>
                  <span>{property.powerbackupsystem}</span>
                </Col>}

                {property.powerbackupcharges && <Col lg={4}>
                <label>Power Back - up Charges</label>
                  <span>{property.powerbackupcharges}</span>
                </Col>}
                {property.othergeneralterms && <Col lg={4}>
                <label>Other General terms</label>
                  <span>{property.othergeneralterms}</span>
                </Col>}
                {property.proposedleaseterm && <Col lg={4}>
                <label>Proposed Lease Term</label>
                  <span>{property.proposedleaseterm}</span>
                </Col>}
                {property.proposedlockperiod && <Col lg={4}>
                <label>Proposed Lock in period</label>
                  <span>{property.proposedlockperiod}</span>
                </Col>}
                {property.tenure && <Col lg={4}>
                <label>Tenure</label>
                  <span>{property.tenure}</span>
                </Col>}
                {property.intrestfreesecuritydeposit && <Col lg={4}>
                <label>Interest Free Security Deposit (Refundable)*</label>
                  <span>{property.intrestfreesecuritydeposit}</span>
                </Col>}
                {property.propertytax && <Col lg={4}>
                <label>Property Tax</label>
                  <span>{property.propertytax}</span>
                </Col>}
                {property.stampdutyregistrationcharges && <Col lg={4}>
                <label>Stamp Duty & Registration Charges on Lease Deed</label>
                  <span>{property.stampdutyregistrationcharges}</span>
                </Col>}
                {property.parkingcharges && <Col lg={4}>
                <label> Additional Parking Charges (INR)</label>
                  <span>{property.parkingcharges}</span>
                </Col>}
                {/* {property.availability && <Col lg={4}>
                <label> Parking Availability</label>
                  <span>{property.availability}</span>
                </Col>} */}
                {property.marketbrand && <Col lg={4}>
                <label> Nevarby Market / Brands Info & Immediate Neighbourhood Details</label>
                  <span>{property.marketbrand}</span>
                </Col>}
                {property.googlecoordinates && <Col lg={4}>
                <label> Google Coordinates</label>
                  <span>{property.googlecoordinates}</span>
                </Col>}
                {property.offeredarea && <Col lg={4}>
                <label> Offered Area in sq. ft. in SBA</label>
                  <span>{property.offeredarea}</span>
                </Col>}
                {property.frontagearea && <Col lg={4}>
                <label>Frontage Area (ft)</label>
                  <span>{property.frontagearea}</span>
                </Col>}
                {property.commercialterms && <Col lg={4}>
                <label>Commercial Terms</label>
                  <span>{property.commercialterms}</span>
                </Col>}
                {property.heightfloor && <Col lg={4}>
                <label>Height Floor to Floor (ft)</label>
                  <span>{property.heightfloor}</span>
                </Col>}
                {property.clientcalloptionremark && <Col lg={4}>
                <label>Remarks</label>
                  <span>{property.clientcalloptionremark}</span>
                </Col>}
               
                {property.plotunit || property.plotlength || property.plotwidth ?  <Col lg={4}>
                  <label>Plot Size</label>
                  {property.plotunit !== null ? property.plotunit  : " "}{" "}
                  {property.plotlength !== null ? property.plotlength+'L' : " "}{" "}
                  {property.plotwidth !== null ? property.plotwidth +'W' : " "}
                </Col> : ''}


                {property.verticalname && <Col lg={4}>
                <label>Vertical Name</label>
                  <span>{property.verticalname}</span>
                </Col>}
                {property.floorplatesize && <Col lg={4}>
                <label>Floor Plate Size in Sq. ft. (approx.)</label>
                  <span>{property.floorplatesize}</span>
                </Col>}
                {property.chargeableareaoffered && <Col lg={4}>
                <label>Super / Chargeable area Offered in sq. ft. Approx.</label>
                  <span>{property.chargeableareaoffered}</span>
                </Col>}
                {property.workinghours && <Col lg={4}>
                <label>Normal Working Hours</label>
                  <span>{property.workinghours}</span>
                </Col>}
                {property.status && <Col lg={4}>
                <label>Status</label>
                  <span>{property.status}</span>
                </Col>}
                {property.fitoutrental && <Col lg={4}>
                <label>Fit Out Rental</label>
                  <span>{property.fitoutrental}</span>
                </Col>}
                {property.propertystatus && <Col lg={4}>
                <label>Status of Property </label>
                  <span>{property.propertystatus}</span>
                </Col>}
                {property.electriccharges && <Col lg={4}>
                <label>Per Unit Electricity charges</label>
                  <span>{property.electriccharges}</span>
                </Col>}
                {property.rentescalation && <Col lg={4}>
                <label>Rent Escalation in Percentage</label>
                  <span>{property.rentescalation}</span>
                </Col>}               
                {property.numberofcarortruckparking && <Col lg={4}>
                <label>Car Parking</label>
                  <span>{property.numberofcarortruckparking}</span>
                </Col>}
                {property.carparkcharges && <Col lg={4}>
                <label>Car Park charges (Rs. / car park / month)</label>
                  <span>{property.carparkcharges}</span>
                </Col>}
                {property.othertenants && <Col lg={4}>
                <label>Other Tenants</label>
                  <span>{property.othertenants}</span>
                </Col>}
                {property.designation && <Col lg={4}>
                <label>Designation</label>
                  <span>{property.designation}</span>
                </Col>} 

                 {property.state &&  <Col lg={4}>
                    <label>State</label>
                    <span>{property.state}</span>
                  </Col>}
                 {property.city &&  <Col lg={4}>
                    <label>City</label>
                    <span>{property.city}</span>
                  </Col>}
                {property.street &&   <Col lg={4}>
                    <label>Street</label>
                    <span>{property.street}</span>
                  </Col>}
                {property.pincode &&   <Col lg={4}>
                    <label>Pincode</label>
                    <span>{property.pincode}</span>
                  </Col>}
                  {property.country &&
                  <Col lg={4}>
                    <label>Country</label>
                    <span>{property.country}</span>
                  </Col> 
                  }
                  {property.googlelocation &&  <Col lg={4}>
                  <label>Google Location</label>
                    <a href={property.googlelocation} target="_blank">{property.googlelocation.split('/').join('\n')}</a>
                    {/* <span>{property.googlelocation.split('/').join('\n')}</span> */}
                  </Col>}
                 {property.officestreet &&  <Col lg={4}>
                    <label>Office street</label>
                    <span>{property.officestreet}</span>
                  </Col>}
                 {property.officestate &&  <Col lg={4}>
                    <label>Office state</label>
                    <span>{property.officestate}</span>
                  </Col>}
                 {property.officecity &&  <Col lg={4}>
                    <label>Office city</label>
                    <span>{property.officecity}</span>
                  </Col>}
                 {property.officepincode &&  <Col lg={4}>
                    <label>Office pincode</label>
                    <span>{property.officepincode}</span>
                  </Col>}
                  {property.description &&  <Col lg={4}>
                    <label>Description</label>
                    <span>{property.description}</span>
                  </Col>}
                   {property.noofkeys && <Col lg={4}>
                   <label>No. of Keys</label>
                    <span>{property.noofkeys}</span>
                  </Col>}
                  {property.architectname && <Col lg={4}>
                   <label>Architect Name</label>
                    <span>{property.architectname}</span>
                  </Col>}
                  {property.banquetcapacity && <Col lg={4}>
                  <label>Banquet Capacity</label>
                    <span>{property.banquetcapacity}</span>
                  </Col>}
                  {property.noofservicelifts && <Col lg={4}>
                  <label>No. of service Lifts</label>
                    <span>{property.noofservicelifts}</span>
                  </Col>}
                  {property.noofcarparking && <Col lg={4}>
                  <label>No. Car parking</label>
                    <span>{property.noofcarparking}</span>
                  </Col>}
                 {/* {property.createdbyname &&  <Col lg={4}>
                  <label>Created By</label>
                  <span>{property.createdbyname}</span>
                </Col>} */}
               {property.createddate &&  <Col lg={4}>
                  <label>Created date </label>
                  <span>{moment(property.createddate).format("DD-MM-YYYY hh:mm A")}</span>
                </Col>}
               {property.lastmodifieddate &&  <Col lg={4}>
                  <label>Last Modified Date</label>
                  <span>{moment(property.lastmodifieddate).format("DD-MM-YYYY hh:mm A")}</span>
                </Col>}
                {property.lastmodifiedbyname !== " " && <Col lg={4}>
                  <label>Last Modified By</label>
                  <span>{property.lastmodifiedbyname}</span>
                </Col>}
                  <Col></Col>
                </Row>
              </Col>

            </Row>
  )
}

export default PropertyDetail