import React, { useState, useEffect } from "react";

import { Badge, Button, Card, Col, Container, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import Confirm from "../common/Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedListTask from "../task/RelatedListTask";
import inventoryApi from "../../api/inventoryApi";
import FilesCreate from "../FilesCreate";
import RelatedListFiles from "../RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "../task/TaskEdit";
import Path from "../common/Path";
import Chat from "../common/Chat";
import EmailComposer from "../common/EmailComposer";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import moment from "moment";
import RelatedListEmails from "../RelatedListEmails";
import RelatedListArea from "../RelatedListArea";
import RelatedLisHeight from "../RelatedLisHeight";
import PubSub from "pubsub-js";
import RelatedRentList from "../RelatedRentList";
import RelatedPricingList from "../RelatedPricingList";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  //console.log('leadStatusArray1', JSON.parse(localStorage.getItem("lead_status")));
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowArea, setModalShowArea] = useState(false);
  const [modalShowHeight, setModalShowHeight] = useState(false);

  const [relatedListTasks, setRelatedListTasks] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [relatedListEmails, setRelatedListEmails] = useState(false);
  const [relatedListArea, setRelatedListArea] = useState(false);
  const [relatedListHeight, setRelatedListHeight] = useState(false);

  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [refreshTaskList, setRefreshTaskList] = useState(Date.now());
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isOverlayDeleteVisible, setIsDeleteOverlayVisible] = useState(false);
  const [relatedListPrice, setRelatedListPrice] = useState(false);
  const [relatedListRent, setRelatedListRent] = useState(false);
  const [modalShowPrice, setModalShowPrice] = useState(false);
  const [modalShowRent, setModalShowRent] = useState(false);
  const VerticalColors = {
    'Active': "#8E969C",
    'Close': "#C5E7E2",
    'Hold': "#EDF0A7",
    'Pending': "#A9D1D7",
    'Option Shared': "#A1DAD7",
    'Site Visit Done': "#8BD0D0",
    'Negotiation Stage': "#6BAAAE",
    'Due diligence Stage': "#B4D3C4",
  };
  useEffect(() => {
    fetchLead();
  }, []);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
      setRefreshFileList(Date.now());
      setRefreshTaskList(Date.now());
    }

    async function initLead() {
      let result = await inventoryApi.fetchLead(lead.id);
      if (result) {
        setLead(result);
      } else {
        setLead({});
      }
    }
    initLead();
  };
  const handleMouseEnter = () => {
    setIsOverlayVisible(true);
  };

  const handleMouseLeave = () => {
    setIsOverlayVisible(false);
  };
  const handleDeleteMouseEnter = () => {
    setIsDeleteOverlayVisible(true);
  };

  const handleDeleteMouseLeave = () => {
    setIsDeleteOverlayVisible(false);
  };
  const deleteLead = async () => {
    const result = await inventoryApi.deleteLead(lead.id);
    if (result.success)
    PubSub.publish("RECORD_SAVED_TOAST", {
      title: "Record Deleted",
      message: "Record deleted successfully",
    }); navigate(`/leads`);
  };

  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);

    setRefreshTaskList(Date.now());
  };
  const submitfiles = () => {
    setModalShowFile(false);

    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedListEmails(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)

    } else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedListEmails(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)

    } else if (key === "emails") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(true);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
    }
    else if (key === "area") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(false);
      setRelatedListArea(true);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)

    }
    else if (key === "height") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(false);
      setRelatedListArea(false);
      setRelatedListHeight(true);
      setRelatedListPrice(false)
      setRelatedListRent(false)

    }
    else if (key === "price") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(true)
      setRelatedListRent(false)

    }
    else if (key === "rent") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(true)

    }
  };

  return (
    <div>
      {lead && lead.success !== false ? (
        <>
          <Container>
            <CustomSeparator
              cmpListName="Leads"
              currentCmpName={lead.firstname + " " + lead.lastname}
              indexLength="2"
              url="/leads"
            ></CustomSeparator>
          </Container>

          {lead && (
            <Container className="mt-4">
              {modalShow && (
                <Confirm
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  deleteLead={deleteLead}
                  title="Confirm delete?"
                  message="You are going to delete the record. Are you sure?"
                  table="lead"
                />
              )}

              <Row className="view-form gx-5 mx-auto">
                <Col lg={8}>
                  <Row className="view-form-header align-items-center">
                    <Col lg={6}>
                      Lead
                      <h4>{lead.firstname + " " + lead.lastname}</h4>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="my-tooltip">Call</Tooltip>}
                      >
                        <Button
                          className="btn-sm"
                          href={`tel:${lead.phone}`}
                          variant="success"
                        >
                          <i class="fa fa-phone"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        show={isOverlayVisible}
                        overlay={
                          <Tooltip className="my-tooltip">Send Email</Tooltip>
                        }
                      >
                        <Button
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          className="btn-sm mx-2"
                          onClick={() => setModalShowEmail(true)}
                          variant="success"
                        >
                          <i class="fa fa-envelope"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="my-tooltip">Edit</Tooltip>}
                      >
                        <Button
                          className="btn-sm mx-2"
                          onClick={() => editLead(true)}
                        >
                          <i class="fa fa-pen-to-square"></i>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        show={isOverlayDeleteVisible}
                        overlay={
                          <Tooltip className="my-tooltip">Delete</Tooltip>
                        }
                      >
                        <Button
                          onMouseEnter={handleDeleteMouseEnter}
                          onMouseLeave={handleDeleteMouseLeave}
                          className="btn-sm"
                          variant="danger"
                          onClick={() => setModalShow(true)}
                        >
                          <i class="fa fa-trash"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row
                    className="py-3 ibs-edit-form"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Col lg={12}>
                      <Path
                        values={leadStatusArray}
                        selectedValue={lead.leadstage}
                      />
                    </Col>

                    <Row>
                      <Col lg={4}>
                        <label>Name</label>
                        <span>
                          {lead.salutation} {lead.firstname} {lead.lastname}
                        </span>
                      </Col>
                      {lead.phone && (
                        <Col lg={4}>
                          <label>Phone</label>
                          <a href={`tel:${lead.phone}`}>{lead.phone}</a>
                        </Col>
                      )}
                      {lead.email && (
                        <Col lg={4}>
                          <label>Email</label>
                          <span>{lead.email}</span>
                        </Col>
                      )}
                    </Row>

                    {lead.leadsource && (
                      <Col lg={4}>
                        <label>Lead Source</label>
                        <span>{lead.leadsource}</span>
                      </Col>
                    )}
                    {lead.leadstage && (
                      <Col lg={4}>
                        <label>Stage</label>
                        <Badge
            bg={VerticalColors[lead.leadstage]}
            style={{
              display: "block",
              borderRadius: "15px",
              padding: "5px 5px",
              backgroundColor: VerticalColors[lead.leadstage],
              color: "black",
              fontWeight: 'bold',
              fontSize: '0.9rem',
              width: '150px'
            }}
          >
            {lead.leadstage}
          </Badge>
                      </Col>
                    )}
                    {lead.alternatephone && (
                      <Col lg={4}>
                        <label>Alternate Phone</label>
                        <span>{lead.alternatephone}</span>
                      </Col>
                    )}
                    {lead.clienttype && (
                      <Col lg={4}>
                        <label>Client Type</label>
                        <span>{lead.clienttype}</span>
                      </Col>
                    )}
                    {lead.company && (
                      <Col lg={4}>
                        <label>Company</label>
                        <span>{lead.company}</span>
                      </Col>
                    )}
                    <Col lg={4}>
                      <label>Assigned Staff</label>
                      <span>
                        <Link to={`/users/${lead.ownerid}`}>
                          {lead.ownername}
                        </Link>
                      </span>
                    </Col>
                    {lead.clientstate && (
                      <Col lg={4}>
                        <label>State</label>
                        <span>{lead.clientstate}</span>
                      </Col>
                    )}
                    {lead.clientstate !== "" && lead.clientcity && (
                      <Col lg={4}>
                        <label>City</label>
                        <span>{lead.clientcity}</span>
                      </Col>
                    )}
                    {lead.clientstreet && (
                      <Col lg={4}>
                        <label>Street</label>
                        <span>{lead.clientstreet}</span>
                      </Col>
                    )}
                    {lead.clientpincode && (
                      <Col lg={4}>
                        <label>Pincode</label>
                        <span>{lead.clientpincode}</span>
                      </Col>
                    )}
                    {lead.clientcountry && (
                      <Col lg={4}>
                        <label>Country</label>
                        <span>{lead.clientcountry}</span>
                      </Col>
                    )}
                    {lead.zone && (
                      <Col lg={4}>
                        <label>Zone</label>
                        <span>{lead.zone}</span>
                      </Col>
                    )}

                    {lead.clientcalloption && (
                      <Col lg={4}>
                        <label>Third Party</label>
                          <span >
                            <input style={{height:"15px",width:"15px"}} type="checkbox" disabled="true" checked={lead.clientcalloption}/>
                          </span>
                        {/* <span>{lead.clientcalloption}</span> */}
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionname && (
                      <Col lg={4}>
                        <label>Third Party Name</label>
                        <span>{lead.clientcalloptionname}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionmobile && (
                      <Col lg={4}>
                        <label>Third Party Mobile</label>
                        <span>{lead.clientcalloptionmobile}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionemail && (
                      <Col lg={4}>
                        <label>Third Party Email</label>
                        <span>{lead.clientcalloptionemail}</span>
                      </Col>
                    )}

                    {lead.clientcalloption && lead.clientcalloptiondate && (
                      <Col lg={4}>
                        <label>Third Party Date</label>
                        <span>{lead.clientcalloptiondate}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionremark && (
                      <Col lg={4}>
                        <label>Third Party Remarks</label>
                        <span>{lead.clientcalloptionremark}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionratepersqfeet && (
                      <Col lg={4}>
                        <label>Third Party Rate/Sq feet</label>
                        <span>{lead.clientcalloptionratepersqfeet}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionbrokerage && (
                      <Col lg={4}>
                        <label>Third Party Brokarage</label>
                        <span>{lead.clientcalloptionbrokerage}</span>
                      </Col>
                    )}
                    {lead.transactiontype && (
                      <Col lg={4}>
                        <label>Transaction Type</label>
                        <span>{lead.transactiontype}</span>
                      </Col>
                    )}
                    {lead.typeofclient && (
                      <Col lg={4}>
                        <label>Type Of Client</label>
                        <span>{lead.typeofclient}</span>
                      </Col>
                    )}
                    {lead.vertical && (
                      <Col lg={4}>
                        <label>Vertical </label>
                        <span>{lead.vertical}</span>
                      </Col>
                    )}
                    {lead.verticaltype && (
                      <Col lg={4}>
                        <label>Vertical Type</label>
                        <span>{lead.verticaltype}</span>
                      </Col>
                    )}
                    {lead.subverticaltype && (
                      <Col lg={4}>
                        <label>Sub Vertical Type</label>
                        <span>{lead.subverticaltype}</span>
                      </Col>
                    )}
                    {lead.furnishedstatus && lead.furnishedstatus !== null ? (
                      <Col lg={4}>
                        <label>Furnished Status</label>
                        <span>{lead.furnishedstatus}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.locatedin && lead.locatedin !== null ? (
                      <Col lg={4}>
                        <label>Located In</label>
                        <span>{lead.locatedin}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.availability && lead.availability !== null ? (
                      <Col lg={4}>
                        <label>Availablity</label>
                        <span>{lead.availability}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.retailsubvertical && (
                      <Col lg={4}>
                        <label>Retail Sub Vertical</label>
                        <span>{lead.retailsubvertical}</span>
                      </Col>
                    )}
                    {lead.type && (
                      <Col lg={4}>
                        <label>Type</label>
                        <span>{lead.type}</span>
                      </Col>
                    )}
                    {lead.otherdetails && (
                      <Col lg={4}>
                        <label>Other Details</label>
                        <span>{lead.otherdetails}</span>
                      </Col>
                    )}

                    {lead.otherlocations && (
                      <Col lg={4}>
                        <label>Other Locations</label>
                        <span>{lead.otherlocations}</span>
                      </Col>
                    )}

                    {lead.completiondate && (
                      <Col lg={4}>
                        <label>Completion Date</label>
                        <span>{lead.completiondate}</span>
                      </Col>
                    )}
                    {lead.frontage && (
                      <Col lg={4}>
                        <label>Frontage</label>
                        <span>{lead.frontage}</span>
                      </Col>
                    )}
                    {lead.areaorlocationbrief && (
                      <Col lg={4}>
                        <label>Area/Location Brief</label>
                        <span>{lead.areaorlocationbrief}</span>
                      </Col>
                    )}
                    {lead.noofdocksvalue ? (
                      <Col lg={4}>
                        <label>No. of docks </label>
                        <span>{lead.noofdocksvalue}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.dockheight && lead.dockheight !== null ? (
                      <Col lg={4}>
                        <label>Dock Height</label>
                        <span>{lead.dockheight}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.docklevel && lead.docklevel !== null ? (
                      <Col lg={4}>
                        <label>Dock Level</label>
                        <span>{lead.docklevel}</span>
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.noofwashroomsvalue ? (
                      <Col>
                        <label>No.of Washrooms</label>
                        <span>{lead.noofwashroomsvalue}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.verticaltype === "Warehousing" && (
                      <Col lg={4}>
                        <label>Open area</label>
                        <span>
                          {lead.openareaunit !== null ? lead.openareaunit : " "}{" "}
                          {lead.openareavalue !== null
                            ? lead.openareavalue
                            : ""}
                        </span>
                      </Col>
                    )}
                    {lead.verticaltype === "Warehousing" && (
                      <Col lg={4}>
                        <label>Close area</label>
                        <span>
                          {lead.closeareaunit !== null
                            ? lead.closeareaunit
                            : " "}{" "}
                          {lead.closeareavalue !== null
                            ? lead.closeareavalue
                            : " "}
                        </span>
                      </Col>
                    )}
                    {lead.verticaltype === "Warehousing" && (
                      <Col lg={4}>
                        <label>Rental</label>
                        <span>
                          {lead.rentalunit !== null ? lead.rentalunit : " "}
                          {lead.rentalvalue !== null ? lead.rentalvalue : " "}
                        </span>
                      </Col>
                    )}
                    {lead.typeofwarehouse && (
                      <Col lg={4}>
                        <label>Type of Warehouse</label>
                        <span>{lead.typeofwarehouse}</span>
                      </Col>
                    )}
                    {lead.floor && (
                      <Col lg={4}>
                        <label>Floor</label>
                        <span>{lead.floor}</span>
                      </Col>
                    )}

                    {lead.chargeablearea && (
                      <Col lg={4}>
                        <label>Approx. Chargeable Area (Sq.ft)</label>
                        <span>{lead.chargeablearea}</span>
                      </Col>
                    )}

                    {lead.offeredcarpetarea && (
                      <Col lg={4}>
                        <label>Carpet Area Offered in Sq.ft</label>
                        <span>{lead.offeredcarpetarea}</span>
                      </Col>
                    )}

                    {lead.heightside && (
                      <Col lg={4}>
                        <label>Height (ft.) Side</label>
                        <span>{lead.heightside}</span>
                      </Col>
                    )}
                    {lead.heightcenter && (
                      <Col lg={4}>
                        <label>Height (ft.) Center</label>
                        <span>{lead.heightcenter}</span>
                      </Col>
                    )}
                    {lead.typeofflooring && (
                      <Col lg={4}>
                        <label>Type of Flooring</label>
                        <span>{lead.typeofflooring}</span>
                      </Col>
                    )}
                    {lead.firehydrants && (
                      <Col lg={4}>
                        <label>Fire Hydrants</label>
                        <span>{lead.firehydrants}</span>
                      </Col>
                    )}
                    {lead.firesprinkelers && (
                      <Col lg={4}>
                        <label>Fire Sprinkelers</label>
                        <span>{lead.firesprinkelers}</span>
                      </Col>
                    )}
                    {lead.firenoc && (
                      <Col lg={4}>
                        <label>Fire NOC</label>
                        <span>{lead.firenoc}</span>
                      </Col>
                    )}
                    {lead.quotedrentonchargeablearea && (
                      <Col lg={4}>
                        <label>Quoted Rent On Chargeable Area</label>
                        <span>{lead.quotedrentonchargeablearea}</span>
                      </Col>
                    )}
                    {lead.securitydeposit && (
                      <Col lg={4}>
                        <label>Security Deposit</label>
                        <span>{lead.securitydeposit}</span>
                      </Col>
                    )}
                    {lead.advance && lead.advance !== null ? (
                      <Col lg={4}>
                        <label>Advance</label>
                        <span>{lead.advance}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.commonareamaintaince && (
                      <Col lg={4}>
                        <label>Common Area Maintenance (CAM)</label>
                        <span>{lead.commonareamaintaince}</span>
                      </Col>
                    )}
                    {lead.possessionstatus && (
                      <Col lg={4}>
                        <label>Possession Status</label>
                        <span>{lead.possessionstatus}</span>
                      </Col>
                    )}
                    {lead.addtionalinformation && (
                      <Col lg={4}>
                        <label>Additional Information</label>
                        <span>{lead.addtionalinformation}</span>
                      </Col>
                    )}
                    {lead.roadwidth && lead.roadwidth !== null ? (
                      <Col lg={4}>
                        <label>Road Width</label>
                        <span>{lead.roadwidth}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.labourroom && lead.labourroom !== null ? (
                      <Col lg={4}>
                        <label>Labour Room</label>
                        <span>{lead.labourroom}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.guardroom && lead.guardroom !== null ? (
                      <Col lg={4}>
                        <label>Guard Room</label>
                        <span>{lead.guardroom}</span>
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.powerconnection && lead.powerconnection !== null ? (
                      <Col lg={4}>
                        <label>Power Connection </label>
                        <span>{lead.powerconnection}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.waterconnection && lead.waterconnection !== null ? (
                      <Col lg={4}>
                        <label>Water Connection</label>
                        <span>{lead.waterconnection}</span>
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.flooringloadingcapacity &&
                    lead.flooringloadingcapacity !== null ? (
                      <Col lg={4}>
                        <label>Flooring Loading Capacity</label>
                        <span>{lead.flooringloadingcapacity}</span>
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.project && (
                      <Col lg={4}>
                        <label>Project (Building Name)</label>
                        <span>{lead.project}</span>
                      </Col>
                    )}
                    {lead.location && (
                      <Col lg={4}>
                        <label>Location</label>
                        <span>{lead.location}</span>
                      </Col>
                    )}
                    {lead.totalfloors && (
                      <Col lg={4}>
                        <label>Total No. of Floors</label>
                        <span>{lead.totalfloors}</span>
                      </Col>
                    )}
                    {lead.totalbuilduparea && (
                      <Col lg={4}>
                        <label>
                          Total Built-up of Building in Sq.ft(approx.)
                        </label>
                        <span>{lead.totalbuilduparea}</span>
                      </Col>
                    )}
                    {lead.offeredspacedetails && (
                      <Col lg={4}>
                        <label>Offered space details</label>
                        <span>{lead.offeredspacedetails}</span>
                      </Col>
                    )}
                    {lead.flooroffered && (
                      <Col lg={4}>
                        <label>Floor Number/s offered</label>
                        <span>{lead.flooroffered}</span>
                      </Col>
                    )}
                    {lead.quotedrent && (
                      <Col lg={4}>
                        <label>
                          Quoted Rent (in Rs) per Sft. per month (excluding
                          applicable taxes)
                        </label>
                        <span>{lead.quotedrent}</span>
                      </Col>
                    )}
                    {lead.maintainancecharges && (
                      <Col lg={4}>
                        <label>Maintenance Charges per sft per month</label>
                        <span>{lead.maintainancecharges}</span>
                      </Col>
                    )}
                    {lead.securitydeposit && (
                      <Col lg={4}>
                        <label>Security Deposit</label>
                        <span>{lead.securitydeposit}</span>
                      </Col>
                    )}
                    {lead.powerbackup && (
                      <Col lg={4}>
                        <label>Power and Power back up</label>
                        <span>{lead.powerbackup}</span>
                      </Col>
                    )}
                    {lead.powerallocation && (
                      <Col lg={4}>
                        <label>Total Power allocation for the unit</label>
                        <span>{lead.powerallocation}</span>
                      </Col>
                    )}
                    {lead.powerbackupsystem && (
                      <Col lg={4}>
                        <label>Power Back - up System</label>
                        <span>{lead.powerbackupsystem}</span>
                      </Col>
                    )}
                    {lead.powerbackupcharges && (
                      <Col lg={4}>
                        <label>Power Back - up Charges</label>
                        <span>{lead.powerbackupcharges}</span>
                      </Col>
                    )}
                    {lead.othergeneralterms && (
                      <Col lg={4}>
                        <label>Other General terms</label>
                        <span>{lead.othergeneralterms}</span>
                      </Col>
                    )}
                    {lead.proposedleaseterm && (
                      <Col lg={4}>
                        <label>Proposed Lease Term</label>
                        <span>{lead.proposedleaseterm}</span>
                      </Col>
                    )}
                    {lead.proposedlockperiod && (
                      <Col lg={4}>
                        <label>Proposed Lock in period</label>
                        <span>{lead.proposedlockperiod}</span>
                      </Col>
                    )}
                    {lead.tenure && (
                      <Col lg={4}>
                        <label>Tenure</label>
                        <span>{lead.tenure}</span>
                      </Col>
                    )}

                    {lead.rentescalation && (
                      <Col lg={4}>
                        <label>Rent Escalation in Percentage</label>
                        <span>{lead.rentescalation}</span>
                      </Col>
                    )}
                    {lead.intrestfreesecuritydeposit && (
                      <Col lg={4}>
                        <label>
                          Interest Free Security Deposit (Refundable)*
                        </label>
                        <span>{lead.intrestfreesecuritydeposit}</span>
                      </Col>
                    )}
                    {lead.propertytax && (
                      <Col lg={4}>
                        <label>Property Tax</label>
                        <span>{lead.propertytax}</span>
                      </Col>
                    )}
                    {lead.stampdutyregistrationcharges && (
                      <Col lg={4}>
                        <label>
                          Stamp Duty & Registration Charges on Lease Deed
                        </label>
                        <span>{lead.stampdutyregistrationcharges}</span>
                      </Col>
                    )}
                    {lead.parkingcharges && (
                      <Col lg={4}>
                        <label> Additional Parking Charges (INR)</label>
                        <span>{lead.parkingcharges}</span>
                      </Col>
                    )}
                    {lead.marketbrand && (
                      <Col lg={4}>
                        <label>
                          {" "}
                          Nearby Market / Brands Info & Immediate Neighbourhood
                          Details
                        </label>
                        <span>{lead.marketbrand}</span>
                      </Col>
                    )}
                    {lead.googlecoordinates && (
                      <Col lg={4}>
                        <label> Google Coordinates</label>
                        <span>{lead.googlecoordinates}</span>
                      </Col>
                    )}
                    {lead.offeredarea && (
                      <Col lg={4}>
                        <label> Offered Area in sq. ft. in SBA</label>
                        <span>{lead.offeredarea}</span>
                      </Col>
                    )}
                    {lead.offeredcarpetarea && (
                      <Col lg={4}>
                        <label>Carpet Area Offered in Sq.ft</label>
                        <span>{lead.offeredcarpetarea}</span>
                      </Col>
                    )}
                    {lead.frontagearea && (
                      <Col lg={4}>
                        <label>Frontage Area (ft)</label>
                        <span>{lead.frontagearea}</span>
                      </Col>
                    )}
                    {lead.commercialterms && (
                      <Col lg={4}>
                        <label>Commercial Terms</label>
                        <span>{lead.commercialterms}</span>
                      </Col>
                    )}
                    {lead.heightfloor && (
                      <Col lg={4}>
                        <label>Height Floor to Floor (ft)</label>
                        <span>{lead.heightfloor}</span>
                      </Col>
                    )}
                    {lead.remark && (
                      <Col lg={4}>
                        <label>Remarks</label>
                        <span>{lead.remark}</span>
                      </Col>
                    )}
                    {/* --------Added by saideep------------ */}

                    {lead.noofkeys && lead.noofkeys !== null ? (
                      <Col lg={4}>
                        <label>No of keys</label>
                        <span>{lead.noofkeys}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.architectname && lead.architectname !== null ? (
                      <Col lg={4}>
                        <label>Architect Name</label>
                        <span>{lead.architectname}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.banquetcapacity && lead.banquetcapacity !== null ? (
                      <Col lg={4}>
                        <label>Banquet Capacity</label>
                        <span>{lead.banquetcapacity}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.noofservicelifts && lead.noofservicelifts !== null ? (
                      <Col lg={4}>
                        <label>No of Servicelifts</label>
                        <span>{lead.noofservicelifts}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.noofcarparking && lead.noofcarparking !== null ? (
                      <Col lg={4}>
                        <label>No of Carparking </label>
                        <span>{lead.noofcarparking}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {lead.unit && lead.length && lead.width ? (
                      <Col lg={4}>
                        <label>Room size</label>
                        {lead.unit !== null ? lead.unit : " "}{" "}
                        {lead.length !== null ? lead.length : " "}{" "}
                        {lead.width !== null ? lead.width : " "}
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.plotunit || lead.plotlength || lead.plotwidth ? (
                      <Col lg={4}>
                        <label>Plot Size</label>
                        {lead.plotunit !== null ? lead.plotunit : " "}{" "}
                        {lead.plotlength !== null ? lead.plotlength : " "}{" "}
                        {lead.plotwidth !== null ? lead.plotwidth : " "}
                      </Col>
                    ) : (
                      ""
                    )}

                    {lead.area ||
                    lead.floorno ||
                    lead.perunitcost ||
                    lead.saleablearea ||
                    lead.quotedcost ? (
                      <Col lg={4}>
                        <label>Pricing Details</label>
                        {lead.area !== null ? lead.area : " "}{" "}
                        {lead.floorno !== null ? lead.floorno : " "}{" "}
                        {lead.perunitcost !== null ? lead.perunitcost : " "}{" "}
                        {lead.saleablearea !== null ? lead.saleablearea : " "}{" "}
                        {lead.quotedcost !== null ? lead.quotedcost : " "}
                      </Col>
                    ) : (
                      ""
                    )}
                    {/* {lead.perunitcost  &&  <Col lg={4}>
                 <label>Per Unit Cost </label>
                  <span>{lead.perunitcost }</span>
                </Col>
                }
                {lead.saleablearea &&  <Col lg={4}>
                 <label>Saleable Area</label>
                  <span>{lead.saleablearea}</span>
                </Col>
                }
                {lead.quotedcost &&  <Col lg={4}>
                 <label>Quoted Cost</label>
                  <span>{lead.quotedcost}</span>
                </Col>
                } */}

                    {lead.rentalvalue && (
                      <Col lg={4}>
                        <label> Rental Value </label>
                        <span>
                          {lead.rentalvalue !== null ? lead.rentalvalue : " "}
                        </span>
                      </Col>
                    )}
                    {lead.chargeblearea && (
                      <Col lg={4}>
                        <label>Chargeble Area </label>
                        <span>
                          {lead.chargeblearea !== null
                            ? lead.chargeblearea
                            : " "}
                        </span>
                      </Col>
                    )}
                    {lead.quotedrentpermonth && (
                      <Col lg={4}>
                        <label>Quoted Rent Per Month</label>
                        <span>
                          {lead.quotedrentpermonth !== null
                            ? lead.quotedrentpermonth
                            : " "}
                        </span>
                      </Col>
                    )}

                    {/* --------------------------------------------- */}

                    {lead.verticalname && (
                      <Col lg={4}>
                        <label>Vertical Name</label>
                        <span>{lead.verticalname}</span>
                      </Col>
                    )}
                    {lead.project && (
                      <Col lg={4}>
                        <label>Project</label>
                        <span>{lead.project}</span>
                      </Col>
                    )}
                    {lead.location && (
                      <Col lg={4}>
                        <label>Location</label>
                        <span>{lead.location}</span>
                      </Col>
                    )}
                    {lead.totalfloors && (
                      <Col lg={4}>
                        <label>Total No. of Floors</label>
                        <span>{lead.totalfloors}</span>
                      </Col>
                    )}
                    {lead.floorplatesize && (
                      <Col lg={4}>
                        <label>Floor Plate Size in Sq. ft. (approx.)</label>
                        <span>{lead.floorplatesize}</span>
                      </Col>
                    )}
                    {lead.totalbuilduparea && (
                      <Col lg={4}>
                        <label>
                          Total Built up Area of Building in Sq.ft(approx.)
                        </label>
                        <span>{lead.totalbuilduparea}</span>
                      </Col>
                    )}
                    {lead.offeredspacedetails && (
                      <Col lg={4}>
                        <label>Offered space details</label>
                        <span>{lead.offeredspacedetails}</span>
                      </Col>
                    )}
                    {lead.flooroffered && (
                      <Col lg={4}>
                        <label>Floor Number/s offered</label>
                        <span>{lead.flooroffered}</span>
                      </Col>
                    )}

                    {lead.chargeableareaoffered && (
                      <Col lg={4}>
                        <label>
                          Super / Chargeable area Offered in sq. ft. Approx.
                        </label>
                        <span>{lead.chargeableareaoffered}</span>
                      </Col>
                    )}
                    {lead.workinghours && (
                      <Col lg={4}>
                        <label>Normal Working Hours</label>
                        <span>{lead.workinghours}</span>
                      </Col>
                    )}
                    {lead.status && (
                      <Col lg={4}>
                        <label>Status</label>
                        <span>{lead.status}</span>
                      </Col>
                    )}

                    {lead.quotedrent && (
                      <Col lg={4}>
                        <label>
                          Quoted Rent (in Rs) per Sft. per month (excluding
                          applicable taxes)
                        </label>
                        <span>{lead.quotedrent}</span>
                      </Col>
                    )}
                    {lead.fitoutrental && (
                      <Col lg={4}>
                        <label>Fit Out Rental</label>
                        <span>{lead.fitoutrental}</span>
                      </Col>
                    )}
                    {lead.maintainancecharges && (
                      <Col lg={4}>
                        <label>Maintenance Charges per sft per month</label>
                        <span>{lead.maintainancecharges}</span>
                      </Col>
                    )}
                    {lead.propertystatus && (
                      <Col lg={4}>
                        <label>Status of Property </label>
                        <span>{lead.propertystatus}</span>
                      </Col>
                    )}
                    {lead.electriccharges && (
                      <Col lg={4}>
                        <label>Per Unit Electricity charges</label>
                        <span>{lead.electriccharges}</span>
                      </Col>
                    )}
                    {lead.numberofcarortruckparking && (
                      <Col lg={4}>
                        <label># of Car/ Truck Parking</label>
                        <span>{lead.numberofcarortruckparking}</span>
                      </Col>
                    )}
                    {lead.carparkcharges && (
                      <Col lg={4}>
                        <label>Car Park charges (Rs. / car park / month)</label>
                        <span>{lead.carparkcharges}</span>
                      </Col>
                    )}
                    {lead.availability && (
                      <Col lg={4}>
                        <label>Availability</label>
                        <span>{lead.availability}</span>
                      </Col>
                    )}
                    {lead.contactname && (
                      <Col lg={4}>
                        <label>Contact Name</label>
                        <span>{lead.contactname}</span>
                      </Col>
                    )}
                    {lead.contactnumber && (
                      <Col lg={4}>
                        <label>Contact Number</label>
                        <span>{lead.contactnumber}</span>
                      </Col>
                    )}

                    {lead.othertenants && (
                      <Col lg={4}>
                        <label>Other Tenants</label>
                        <span>{lead.othertenants}</span>
                      </Col>
                    )}
                    {lead.clientcalloption && lead.clientcalloptionremark && (
                      <Col lg={4}>
                        <label>Client Call Option Remark</label>
                        <span>{lead.clientcalloptionremark}</span>
                      </Col>
                    )}
                    {lead.designation && (
                      <Col lg={4}>
                        <label>Designation</label>
                        <span>{lead.designation}</span>
                      </Col>
                    )}
                    {lead.officestate && (
                      <Col lg={4}>
                        <label>State</label>
                        <span>{lead.officestate}</span>
                      </Col>
                    )}
                    {lead.officecity && (
                      <Col lg={4}>
                        <label>City</label>
                        <span>{lead.officecity}</span>
                      </Col>
                    )}
                    {lead.officestreet && (
                      <Col lg={4}>
                        <label>Street</label>
                        <span>{lead.officestreet}</span>
                      </Col>
                    )}
                    {lead.officepincode && (
                      <Col lg={4}>
                        <label> Pincode </label>
                        <span>{lead.officepincode}</span>
                      </Col>
                    )}
                    {lead.officecountry && (
                      <Col lg={4}>
                        <label>Country </label>
                        <span>{lead.officecountry}</span>
                      </Col>
                    )}
                    <Col lg={4}>
                      <label>Created By</label>
                      <span>{lead.createdbyname}</span>
                    </Col>
                    <Col lg={4}>
                      <label>Created date </label>
                      <span>
                        {moment(lead.createddate).format("DD-MM-YYYY hh:mm A")}
                      </span>
                    </Col>
                    <Col lg={4}>
                      <label>Last Modified Date</label>
                      <span>
                        {moment(lead.lastmodifieddate).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </span>
                    </Col>
                    <Col lg={4}>
                      <label>Last Modified By</label>
                      <span>{lead.lastmodifiedbyname}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Chat parentid={lead.id} />
                </Col>
              </Row>
              <Card bg="light" text="light" className="mb-2 mt-4">
                <Card.Header className="d-flex justify-content-between">
                  <Tabs
                    defaultActiveKey="tasks"
                    id="uncontrolled-tab-example"
                    onSelect={(key) => handleSelect(key)}
                  >
                    <Tab eventKey="tasks" title="Tasks"></Tab>
                    <Tab eventKey="files" title="Files"></Tab>
                    <Tab eventKey="emails" title="Emails"></Tab>
                    {!(
                          lead.vertical === "Warehouse" ||
                          lead.vertical === "Cold storage" ||
                          lead.vertical === "Factory"
                        ) && lead.areadetails &&
                        lead.areadetails &&
                        <Tab eventKey="area" title="Area"></Tab>}
                    {!(
                          lead.vertical === "Warehouse" ||
                          lead.vertical === "Cold storage" ||
                          lead.vertical === "Factory" ||
                          lead.vertical === "Land")
                           && lead.heightdetails &&
                            lead.heightdetails &&<Tab eventKey="height" title="Height"></Tab>}

                    { lead.pricingorrental &&
                            lead.pricingorrental && lead.transactiontype  === "Lease" ? (
                      <Tab eventKey="rent" title="Rental"></Tab>
                    ) : (
                      ""
                    )}
                    {  lead.pricingorrental &&
                            lead.pricingorrental &&lead.transactiontype === "Sell" ? (
                      <Tab eventKey="price" title="Pricing"></Tab>
                    ) : (
                      ""
                    )}
                  </Tabs>
                  {relatedListTasks && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowTask(true)}
                    >
                      New Task
                    </Button>
                  )}
                  {modalShowTask && (
                    <TaskEdit
                      show={modalShowTask}
                      onHide={() => setModalShowTask(false)}
                      parentid={lead.id}
                      table="lead"
                      submitTasks={submitTasks}
                    />
                  )}
                  {relatedListFiles && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowFile(true)}
                    >
                      Upload File
                    </Button>
                  )}
                  {modalShowTaskfile && (
                    <FilesCreate
                      show={modalShowTaskfile}
                      onHide={() => setModalShowFile(false)}
                      parent={lead}
                      table="contact"
                      submitfiles={submitfiles}
                    />
                  )}
                </Card.Header>
                <Card.Body>
                  {relatedListTasks ? (
                    <RelatedListTask
                      parent={lead}
                      refreshTaskList={refreshTaskList}
                    />
                  ) : (
                    ""
                  )}
                  {relatedListFiles ? (
                    <RelatedListFiles
                      parent={lead}
                      refreshFileList={refreshFileList}
                    />
                  ) : (
                    ""
                  )}
                  {relatedListEmails ? (
                    <RelatedListEmails
                      refreshEmailList={modalShowEmail}
                      parent={lead}
                      table="property"
                    />
                  ) : (
                    ""
                  )}
                  {relatedListArea ? (
                    <RelatedListArea
                      refreshAreaList={modalShowArea}
                      parent={lead}
                      table="lead"
                    />
                  ) : (
                    ""
                  )}
                  {relatedListHeight ? (
                    <RelatedLisHeight
                      refreshAreaList={modalShowHeight}
                      parent={lead}
                      table="lead"
                    />
                  ) : (
                    ""
                  )}
                  {relatedListPrice ? (
                    <RelatedPricingList
                      refreshAreaList={modalShowRent}
                      parent={lead}
                      table="lead"
                    />
                  ) : (
                    ""
                  )}
                  {relatedListRent ? (
                    <RelatedRentList
                      refreshAreaList={modalShowPrice}
                      parent={lead}
                      table="lead"
                    />
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
              <EmailComposer
                size="lg"
                show={modalShowEmail}
                onHide={() => setModalShowEmail(false)}
                parentid={lead?.id}
                toEmail={lead?.email}
                table="lead"
                submitTasks={submitTasks}
              />
            </Container>
          )}
        </>
      ) : (
        <h3 style={{ textAlign: "center" }}>No Record Found</h3>
      )}
    </div>
  );
};

export default LeadView;

