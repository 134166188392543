import React from "react";
import {
  Document,
  Text,
  Link,
  View,
  Page,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
//import Data from "./Data";

const styles = StyleSheet.create({
  body: {
    // backgroundColor: '#07575B',
    backgroundColor: "#132925",
    display: "none",
  },
  size: {
    height: 320,
    width: 620,
  },


  layoutPage1: {
    marginTop: '0px',
    height: '380px',
    width: '260px',
  },
  layoutPage2: {
    marginTop: '0px',
    height: '380px',
    width: '438px',
    position: 'relative',
    marginLeft: '50px'
  },
  layoutPage: {
    fontWeight: '900',
    marginTop: '130px',
    color: 'white',
    marginLeft: '-400px'
  },
  innerViewPage1: {
    marginTop: '0px',
    height: '100px',
    width: '700px',
    position: 'relative',
  },
  innerViewPage2: {
    top: '100px',
    height: '280px',
    position: 'relative',
    width: '700px',
    marginLeft: '-340px'

  },
  innerViewPage: {
    position: "relative",
    textAlign: "center",
    color: "white",
    top: "45px",
    alignItems: "center",
    marginLeft: '230px'

  },
  outerView: {
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    //color:'red',
    top: '7px'
  },
  image: {
    // marginVertical:15,
    // marginHorizontal:100,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    textAlign: "center",
    color: "grey",
  },
  centeredText: {
    color: "white",
    fontSize: 28,
    lineHeight: 1.5,
    display: "flex",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    // paddingHorizontal: 1,
    // paddingVertical: 1,
  },

  column: {
    // backgroundColor:'#FFF59D',
    backgroundColor: "#B7A292",
    // backgroundColor:'white',
    //marginVertical: 5,
  },

  columnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#A27C4B",
    borderBottomWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    border: '1px solid #dddddd',
    padding: '10px',
   
  },
  
  columnRowText: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "black",
    fontSize: 10,
    textAlign: 'left',
    marginLeft:'20px'
  },
  columnRowTexts: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "black",
    fontSize: 10,
    textAlign: 'left',
    marginRight:'70px'
  },

  details: {
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
    marginLeft: 90,
  },
  keyDetailspage2: {
    marginTop: '0px',
    height: '320px',
    width: '432px',
    position: 'relative',
    marginLeft:'66px',
  },

});

////.log('Background color:', styles.body.backgroundColor);
const WarehousePdfDownload = ({ reportBuilder, table, selectedPages }) => {
  //.log("selectedPages*==>", selectedPages);
  //   //.log("data WarehousePdfDownload*==>", reportBuilder,table);

  console.log("reportBuilder *==>", reportBuilder,selectedPages);
  // //.log('reportBuilder.homePage *==>',reportBuilder.homePage)
  // //.log('reportBuilder.homePage.PropertyType *==>',reportBuilder.homePage.PropertyType)
  // //.log('reportBuilder.homePage.imagelink *==>',reportBuilder.homePage.imagelink)

  return (
    <Document>
      {/*--------------------------- Home Page----------------------------- */}
      {selectedPages.homePage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

            <Image
              style={{ ...styles.image }}
              src={reportBuilder.homePage.imagelink}
            />
        </Page>
      )}
      {/*--------------------------- About Us Page----------------------------- */}
      {selectedPages.aboutUsPage && (
       <Page
       size={styles.size}
       style={{ ...styles.body, }}
     >

        <View style={{ display: 'flex', flexDirection: 'row' , alignItems: 'center', }}>
          <View style={{ flex: 1, marginLeft: 20 }}>
            <Image style={{ height: 200, width: 170, position: 'relative' }} src={reportBuilder.aboutUsPage.imagelink} />
          </View>
          <View style={{ flex: 1, marginLeft: 20, textAlign: 'center' }}>
            <Text style={{ color: 'white', fontWeight: 'bold', marginTop:'10px' }}>ABOUT US</Text>
            <Text style={{ color: 'white', fontWeight: 'bold', marginTop:'20px' ,fontSize:'14px'}}>
              Decade isn’t just a number it is a journey of Sthapatya that has gained trust of its client’s. We, at Sthapatya
              have always believed into a customized, client-specific & need-based approach. We understand the existing gaps
              & requirements in the current situations from our clients and aspire to provide a more efficient & best suited
              warehousing or real estate solution to cater to our clients’ growing needs from time to time.
            </Text>
          </View>
          <View style={{ flex: 1, marginLeft: 20 }}>
            <Image style={{ position: 'relative',marginTop:'30px',marginLeft: '0px',width: 150 }} src={reportBuilder.aboutUsPage.imagelink2} />
            <View style={{ textAlign: 'center' }}>
              <Text style={{ color: 'white', fontWeight: 'bold', marginLeft: 10 }}>Owner</Text>
              <Text style={{ color: 'white', fontWeight: 'bold', marginLeft: 10 }}>RITESH AGARWAL</Text>
            </View>
          </View>
        </View>
     </Page>


      )}

      {/*--------------------------- Specifications Page----------------------------- */}
      {selectedPages.keyDetailspage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ flex: 4 }}>
              <Image
                style={{
                  marginTop: '0px',
                  height: '320px',
                  width: '190px',
                  position: 'relative',
                  //marginLeft:'40px'
                }}
                src={reportBuilder.keyDetailsfirstpage.imagelink}
              />
            </View>

            <View
              style={{
                position: "relative",
                top: "100px",
                left: "20px"
              }}>
              <Text
                style={{
                  position: "relative",
                  //textAlign: "center",
                  fontSize: 23,
                  color: "white",
                  top: "15px" }}>Warehouse </Text>
              <Text
                style={{
                  position: "relative",
                  //textAlign: "center",
                  fontSize: 23,
                  color: "white",
                  top: "15px" }}> Key area </Text>
              <Text
                style={{
                  position: "relative",
                  //textAlign: "center",
                  fontSize: 23,
                  color: "white",
                  top: "15px" }}>  details</Text>
            </View>

            <View style={{ flex: 4 }}>
              <Image
                style={{
                  ...styles.keyDetailspage2
                }}
                src={reportBuilder.keyDetailsSecondbg.imagelink}
              />
            </View>
            
            <View class="d-flex flex-column bd-highlight"
              style={{
                position: "relative",
                top: "44px",
                left: "150px"

              }}>
              <View
                style={{
                  height: "28px",
                  width: "210px",
                  backgroundColor: "#4c89ff",
                  border: "none",
                  borderRadius: "6px",
                  
                }}>
                <Text
                  style={{
                    position: "absolute",
                    right: "70px",
                    top: "6px",
                    fontSize: 13,
                    color: "white",
                  }}>Warehouse&nbsp;type : PEB</Text>
              </View>
              <Text>{'\n'}</Text>

              <View
                style={{
                  height: "28px",
                  width: "210px",
                  backgroundColor: "#37D5D6",
                  border: "none",
                  borderRadius: "6px"
                }}>
                <Text
                  style={{
                    position: "absolute",
                    right: "48px",
                    top: "6px",
                    fontSize: 13,
                    color: "white"
                  }}>Type : Under Construction
                  </Text>
              </View>
              <Text>{'\n'}</Text>
              <View
                style={{
                  height: "28px",
                  width: "210px",
                  backgroundColor: "#04AF70",
                  border: "none",
                  borderRadius: "6px"
                }}>
                <Text
                  style={{
                    position: "absolute",
                    right: "48px",
                    top: "6px",
                    fontSize: 13,
                    color: "white"
                  }}>Covered&nbsp;Area : 40,000Sqft</Text>
              </View>
              <Text>{'\n'}</Text>
              <View
                style={{
                  height: "28px",
                  width: "210px",
                  backgroundColor: "#04AF70",
                  border: "none",
                  borderRadius: "6px"
                }}>
                <Text
                  style={{
                    position: "absolute",
                    right: "68px",
                    top: "6px",
                    fontSize: 13,
                    color: "white"
                  }}>Land&nbsp;Area : 65,000Sqft 

                  </Text>
              </View>
              <Text>{'\n'}</Text>
              <View
                style={{
                  height: "28px",
                  width: "210px",
                  backgroundColor: "#027148",
                  border: "none",
                  borderRadius: "6px"
                }}>
                <Text
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "6px",
                    fontSize: 13,
                    color: "white"
                  }}>Land&nbsp;Title : Under Construction
                  </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
      {/*--------------------------- Specifications Page----------------------------- */}
      {selectedPages.innerViewPage && (
       <Page
       size={styles.size}
       style={{ ...styles.body, }}
     >

        <View style={{
       marginTop: '0px',
        height: '100px',
        width: '700px',
        position: 'relative',
      }}>
        <Image
          src={reportBuilder.innerViewPage.imagelink2 }
          resizeMode="cover"
        />
      </View>
      <View className="pagecontainer"
       style={{
        backgroundColor: 'white',
        height: '220px',
        width: '400px',
        marginLeft: '120px',
        position: 'absolute',
        top: '65px',
      }}>
         
      
                 <View style={{...styles.columnRow, backgroundColor:'rgb(21, 96, 189)',border:'none',}}>
                    <Text style={{...styles.columnRowText,color:'white'}}>VALIDATION PARAMETERS :	</Text>
                    <Text style={{...styles.columnRowTexts,color:'white'}}>
                    SPECIFICATIONS
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Area	</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.specificationsPage.area}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Total land Area</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.specificationsPage.totalarealand}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Title of Land	</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.specificationsPage.titleofland}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Approach/Access Road Width	</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.specificationsPage.approch}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Location</Text>
                    <Text style={styles.columnRowTexts}>{reportBuilder.specificationsPage.location}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Clear Height at the Center	</Text>
                    <Text style={styles.columnRowTexts}>{reportBuilder.specificationsPage.clearHeightattheCenter}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Clear Height (Side)	</Text>
                    <Text style={styles.columnRowTexts}>{reportBuilder.specificationsPage.clearHeight}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Fire Noc	</Text>
                    <Text style={styles.columnRowTexts}> {reportBuilder.specificationsPage.fireNoc}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>No. of Docks	</Text>
                    <Text style={styles.columnRowTexts}>{reportBuilder.specificationsPage.noofducks}
                    </Text>
                  </View>
      </View>
     </Page>


      )}
      {/*--------------------------- Commercial Page----------------------------- */}
      {selectedPages.innerViewPage && (
       <Page
       size={styles.size}
       style={{ ...styles.body, }}
     >

        <View style={{
       marginTop: '0px',
        height: '100px',
        width: '700px',
        position: 'relative',
      }}>
        <Image
          src={reportBuilder.innerViewPage.imagelink2 }
          resizeMode="cover"
        />
      </View>
      <View className="pagecontainer"
       style={{
        backgroundColor: 'white',
        height: '220px',
        width: '400px',
        marginLeft: '120px',
        position: 'absolute',
        top: '65px',
      }}> 
      
                 <View style={{...styles.columnRow, backgroundColor:'rgb(21, 96, 189)',border:'none'}}>
                    <Text style={{...styles.columnRowText,color:'white'}}>COMMERCIAL :	</Text>
                    <Text style={{...styles.columnRowTexts,color:'white'}}>
                    SPECIFICATIONS
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Lease agreement	</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.leaseagreement}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Possession	</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.possession}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Area ( carpet)</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.area}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Rent sq.ft</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.rent}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Suggested by</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.suggestedby}
                    </Text>
                  </View>
                 <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Contact</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.contact}
                    </Text>
                  </View>
                  <View style={{...styles.columnRow, backgroundColor: '#dddddd'}}>
                    <Text style={styles.columnRowText}>Company</Text>
                    <Text style={styles.columnRowTexts}>
                    {reportBuilder.commercialPage.company}
                    </Text>
                  </View>

      </View>
     </Page>


      )}

      {/*--------------------------- Inner View Page----------------------------- */}
      {selectedPages.innerViewPage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage1 }}
                src={reportBuilder.innerViewPage.imagelink2}
              />
            </View>
            <Text style={{ ...styles.innerViewPage }}>INNER VIEW</Text>
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage2, }}
                src={reportBuilder.innerViewPage.imagelink}
              />
            </View>
          </View>
        </Page>
      )}
      {/*--------------------------- Inner View2 Page----------------------------- */}
      {selectedPages.innerViewPage2 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

           <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage1 }}
                src={reportBuilder.innerViewPage.imagelink2}
              />
            </View>
            <Text style={{ ...styles.innerViewPage }}>INNER VIEW</Text>
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage2, }}
                src={reportBuilder.innerViewPage2.imagelink}
              />
            </View>
          </View>
        </Page>


      )}
      
      {/*--------------------------- Inner View3 Page----------------------------- */}
      {selectedPages.innerViewPage3 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

<View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage1 }}
                src={reportBuilder.innerViewPage.imagelink2}
              />
            </View>
            <Text style={{ ...styles.innerViewPage }}>INNER VIEW</Text>
            <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.innerViewPage2, }}
                src={reportBuilder.innerViewPage3.imagelink}
              />
            </View>
          </View>
        </Page>


      )}
      {/* {selectedPages.innerViewPage3 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

            <Image
              style={{ ...styles.image }}
              src={reportBuilder.innerViewPage3.imagelink}
            />
        </Page>


      )} */}
      {/*--------------------------- Outer View Page----------------------------- */}
      {selectedPages.outerViewPage && (
       
          <View style={{ flex: 3 }}>
            <View style={{
              backgroundColor: 'white',
              height: '37px',
              position: "relative",
              textAlign: "center",
              // marginTop:'200px', 
              //top: "180px",
              width: '700px'
            }}>
              <Text style={{ ...styles.outerView }}>OUTER VIEW</Text>
              <Image
                style={{
                  // marginBottom: '44px',
                  height: '45px',
                  width: '45px',
                  marginLeft: '420px',
                }}
                src={reportBuilder.logo.imagelink}
              />
            </View>

            <View>

              <Image
                style={{
                  marginTop: '0px',
                  height: '380px',
                  width: '700px',
                  position: 'relative',
                }}
                src={reportBuilder.outerViewPage.imagelink}
              />
            </View>
          </View>


      )}
      {/*--------------------------- Outer View2 Page----------------------------- */}
      {selectedPages.outerViewPage2 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >

          <View style={{ flex: 3 }}>
            <View style={{
              backgroundColor: 'white',
              height: '37px',
              position: "relative",
              textAlign: "center",
              // marginTop:'200px', 
              //top: "180px",
              width: '700px'
            }}>
              <Text style={{ ...styles.outerView }}>OUTER VIEW</Text>
              <Image
                style={{
                  // marginBottom: '44px',
                  height: '45px',
                  width: '45px',
                  marginLeft: '420px',
                }}
                src={reportBuilder.logo.imagelink}
              />
            </View>
            <Image
              style={{ ...styles.image }}
              src={reportBuilder.outerViewPage2.imagelink}
            />
          </View>
        </Page>


      )}
      {/*--------------------------- Outer View2 Page----------------------------- */}
      {selectedPages.layoutPage && (
        

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ flex: 3}}>
              <Image
                style={{ ...styles.layoutPage1 }}
                src={reportBuilder.layoutPage.imagelink2}
              />
            </View>
            <Text style={{ ...styles.layoutPage }}>LayoutPlan</Text>
            <View style={{ flex: 3 }}>
              <Image
                style={{ ...styles.layoutPage2, }}
                src={reportBuilder.layoutPage.imagelink}
              />
            </View>
          </View>
      )}
      {/*--------------------------- Contact Page----------------------------- */}

      {selectedPages.contactUsPage && (
        <Page
          size={styles.size}
        //style={{ ...styles.body, flexDirection: "column" }}
        >

          <Image
            style={{ ...styles.image }}
            src={reportBuilder.contactUsPage.imagelink}
          />

        </Page>
      )}

    </Document>
  );
};

export default WarehousePdfDownload;
