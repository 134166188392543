import React, { useState, useEffect } from "react";
import TableTemplateDownload from "./TableTemplateDownload";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
function DynamicpdfDownloadPages({
  imageFile1,
  imageFile2,
  section,
  subSection,
  changeImage,
  tableData,
  page,
  dataRec,
  mapimage,
  columns
}) {
  const [image1, setImage1] = useState(imageFile1);
  const [image2, setImage2] = useState(imageFile2);
  const [data, setData] = useState(dataRec);
  const colourCodes = ["#4c89ff", "#37D5D6", "#22E4AC", "#04AF70", "#027148"];
  useEffect(() => {
    setImage1(imageFile1);
  }, [imageFile1]);
  useEffect(() => {
    setImage2(imageFile2);
  }, [imageFile2]);
  useEffect(() => {
    console.log('dataRec',dataRec);
    setData(dataRec);
  }, [dataRec]);
  const myStyleColor = (index) => {
    return { backgroundColor: colourCodes[index] };
  };

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "black",
      color: "white",
      //padding: 10,
    },
    warehousepage: {
      padding:10,
      backgroundColor: "black",
      color: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    mordernpage: {
      backgroundColor: "black",
      color: "white",
      //padding:10
    },
    size: {
      height: 320,
      width: 600,
    },
    pageHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "rgb(183, 162, 146)",
      paddingLeft: 20,
      paddingRight: 20,
      paddingVertical: 5,
    },
    headText: {
      fontSize: 20,
      fontWeight: "bold",
    },
    subheadText: {
      fontSize: 17,
      marginTop: 4,
      marginLeft: 10,
    },
    appLogo: {
      width: 80,
      height: 50,
    },
    pageBody: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    twoLayout: {
      width: "50%",
      height: "100%",
    },
    image: {
      width: 450,
      height: 320,
    },
    twosideimage: {
      width: 180,
      height: 250,
      marginTop:10
    },
    bodyImage: {
      width: "100%",
      height: 260,
    },
    sidebarImage: {
      width: 300,
      height: 280,
    },
    firstpage: {
      flexDirection: "row",
      //padding: 20,
      backgroundColor: "black",
    },
    leftColumn: {
      width: "50%",
      paddingRight: 10,
      color: "white",
    },
    rightColumn: {
      width: "50%",
      paddingLeft: 10,
      color: "white",
    },
    header: {
      marginBottom: 20,
      color:  '#A27C4B',
    },
    title: {
      fontSize: 24,
      marginBottom: 10,
      textAlign: "center",
      color: "white",
    },
    firsttitle: {
      fontSize: 24,
      marginBottom: 10,
      textAlign: "center",
      color:  '#A27C4B',
    },
    subtitle: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
      color:  '#A27C4B',
    },
    footer: {
      fontSize: 10,
      marginTop: '170px',
      textAlign: "left",
      color:  '#A27C4B',
    },
    firstpageimage: {
      width: "330px",
      height: 350,
      //marginLeft: '-50px',
      color: "white",
    },
    cityimage: {
      width: "320px",
      height: 400,
      color: "white",
      marginLeft: "-20px",
    },
    section: {
      marginBottom: 20,
      textAlign: "center",
      width: "33%",
    },
    sectionside: {
      marginBottom: 20,
      textAlign: "center",
      width: "33%",
    },
    sectionsideimage: {
      position: "absolute",
      width: 180,
      height: 600,
      zIndex: -1,
    },
    text: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    textfont: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    tableContainer: {
      width: "90%",
      height: "260",
      marginTop: -30,
      marginLeft: 30,
    },
    detailsContainer: {
      width: "67%",
      paddingLeft: 50,
    },
    warehouseheaderimage: {
      position: "absolute",
      width: 600,
      height: 70,
      zIndex: -1,
    },
    warehouseheader: {
      width: 600,
      height: 70,
    },
    details: {
      marginTop: 70,
    },
    keyDetailBorder: {
      padding: 10,
      border: "1px solid blue",
      width: 280,
      height: 30,
      marginTop: 30,
    },
    keyDetail: {
      padding: 10,
      paddingBottom: 20,
      width: 200,
      height: 30,
      borderRadius: 5,
      marginLeft: 15,
      justifyContent: "center",
      marginTop:-25

      // color: "#000",
    },
    homeheader: {
      fontSize: 24,
      marginBottom: 10,
    },
    subheader: {
      fontSize: 18,
      marginBottom: 5,
    },
    boldText: {
      fontWeight: 'bold',
    },

    sectionhome: {
      marginBottom: 20,
      textAlign: "center",
      width: "33%",
    },
    homeimg:{
      position: "absolute",
      height: 320,
      width: 600,
      zIndex: -1,
    },
    hometext:{
      color:"black",
      marginLeft:300,
      marginTop:15,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight:"bold"
    },
    hometexthead:{
      color:"black",
      marginLeft:430,
      marginTop:100,
      fontSize: 24,
      fontWeight:"1000"
    },
    
    moderndetails:{
    color: "white",
    marginLeft: 45,
    marginTop: 2,
    fontSize: 12,
    },
    moderndetailstext:{
      color:  '#A27C4B',

    fontSize: 14,
    },
    logoContactDetailsmages: {
      height: 50,
      width: 85,
      marginTop: -72,
      marginRight: 10,
      //  marginBottom:5,
      marginLeft: 510,
    },
    homepagelogo: {
      height: 50,
      width: 85,
      marginTop:300,
     // marginRight: 10,
      //  marginBottom:5,
      //marginLeft: 510,
    },

  });

  return (
    <>
      {page === "Home Page" && (
        <Page size={{ width: 600, height: 350 }} style={styles.firstpage}>
          <View style={styles.leftColumn}>
            <View style={styles.header}>
              <Text style={styles.firsttitle}>
                Proposed Option {"\n"} For {"\n"} {data.vertical} Space
              </Text>
              <Text style={styles.subtitle}>
                {data.street}, {data.city} {"\n"} {data.name}
              </Text>
              <View style={styles.footer}>
                <Text>By Sthapatya Leasing & Consultant</Text>
                <Text style={{ marginTop: '-10px',textAlign: "right",}}> {moment(data.lastmodifieddate).format("DD-MM-YYYY")} </Text>
              </View>
            </View>
          </View>
          <View style={styles.rightColumn}>
            <Image src={image1} style={styles.firstpageimage} />
          </View>
          <Image
              style={{ ...styles.homepagelogo }}
              src='/pdf_template_1/logo.jpg'
            />
        </Page>
      )}
      {page === "Over View" && (
        <Page size={{ width: 600, height: 400 }} style={styles.firstpage}>
          <View style={styles.leftColumn}>
            <View style={styles.header}>
              <View style={styles.pageHeader}>
                <Text style={styles.title}>City Over View</Text>
              </View>
              {tableData && (
                <TableTemplateDownload table={tableData} data={data} />
              )}
            </View>
          </View>
          <View style={styles.rightColumn}>
            <Image src={image1} style={styles.cityimage} />
          </View>
        </Page>
      )}
      {(page === "Property Details" || page === "Google View") && (
        <>
          <Page size={{ height: 365, width: 600 }} style={styles.page}>
            <View style={styles.pageHeader}>
              <View  style={{marginLeft:"200"}}>
                <Text>{section}</Text>
                <Text>{subSection}</Text>
              </View>
              <Image
                style={styles.appLogo}
                src="/pdf_template_1/logo.jpg"
                alt="Property"
              />
            </View>
            <View style={[styles.pageBody,{padding:10}]}>
              {tableData && (
                <TableTemplateDownload table={tableData} data={data} />
              )}
              {image1 && (
                <Image style={styles.bodyImage} src={mapimage} alt="Property" />
              )}
            </View>
          </Page>
        </>
      )}
      {page === "Contact Page" && (
        <>
          <Page size={{ height: 350, width: 600 }} style={styles.page}>
            <View>
              <Text
                style={{
                  color: "#D0CCBE",
                  fontSize: 40,
                  lineHeight: 1.5,
                  display: "flex",
                  marginLeft: 5,
                  marginTop: 15,
                }}
              >
                Contact Details
              </Text>
              <Image
              style={{ ...styles.logoContactDetailsmages }}
              src='/pdf_template_1/logo.jpg'
            />
              <View style={{ marginTop: 35 }}>
                <Text
                  style={{ marginLeft: 15, fontSize: 14, color: "#D0CCBE" }}
                >
                  Test Name
                </Text>
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 12,
                    color: "#D0CCBE",
                    marginTop: 4,
                  }}
                >
                  Mobile : 9876543210
                </Text>
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 14,
                    color: "#D0CCBE",
                    marginTop: 14,
                  }}
                >
                  Test
                </Text>
                <Text
                  style={{
                    marginLeft: 15,
                    fontSize: 12,
                    color: "#D0CCBE",
                    marginTop: 4,
                  }}
                >
                  Mobile : 8976543211
                </Text>
                <Text
                  style={{
                    marginLeft: 12,
                    fontSize: 10,
                    color: "#D0CCBE",
                    marginTop: 14,
                  }}
                >
                  Email : abc@gmail.com
                </Text>
                Website :
                {/* <Link src={'https://www.google.com/'}>
                <Text
                  style={{
                    marginLeft: 12,
                    fontSize: 10,
                    color: "#D0CCBE",
                    marginTop: 4,
                  }}
                >
                  Website :www.Google.com
                </Text>
              </Link> */}
                <Text
                  style={{
                    marginLeft: 2,
                    color: "#D0CCBE",
                    fontSize: 10,
                    marginTop: 40,
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 2,
                      color: "#D0CCBE",
                      fontSize: 12,
                      marginTop: 30,
                    }}
                  >
                    Our Address -
                  </Text>{" "}
                  Sthapatya Leasing & Consultant E-143, 1st Floor, Nirman Nagar,
                  Opp. Hotel Kiran Palace DCM, Ajmer Road, Jaipur
                </Text>
              </View>

              <View style={{ marginTop: 2, backgroundColor: "#FFFDE7" }}>
                <Text
                  style={{
                    marginLeft: 2,
                    color: "#1E2224",
                    fontSize: 8,
                    marginTop: 5,
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 2,
                      color: "#9A6237",
                      fontSize: 12,
                      marginTop: 30,
                    }}
                  >
                    Disclaimer:
                  </Text>{" "}
                  Details & Pictures mentioned in this presentation is for
                  illustration purpose only & cannot be in any way treated as a
                  legal document. All information, specifications, plans,
                  materials, dates, & visual representations contained are
                  subject to change from time to time by the Land Owner /
                  Realtor / Company / Competent Authorities and shall not form
                  part of any contract, offer or deal. Sizes, Rates,
                  Specifications, and Availability of the project/property may
                  change without any prior information and Land Owner / Realtor
                  / Company shall not be held responsible in any manner
                  whatsoever.
                </Text>
              </View>
            </View>
          </Page>
        </>
      )}
      {page === "Modern Facilites" && (
        <>
          <Page style={styles.mordernpage} size={styles.size}>
            <View style={styles.pageBody}>
              <View style={styles.twoLayout}>
                <View style={styles.pageHeader}>
                  <Text style={styles.headText}>{section}</Text>
                </View>
                <Image style={styles.sidebarImage} src={image1} />
              </View>
              <View style={styles.twoLayout}>
                <View style={{marginTop:40}}>
                  {tableData &&
                    Object.entries(tableData).map(([key, value]) => (
                      <View key={key} style={styles.moderndetails}>
                          <Text  style={styles.moderndetailstext}> {'\n'}  •   {value} </Text> 
                      </View>
                    ))}
                </View>
              </View>
            </View>
          </Page>
        </>
      )}
      {page === "Thanks Page" && (
        <>
          <Page size={styles.size} style={styles.page}>
            <Text
              style={{
                textAlign: "center",
                display: "flex",
                marginTop: "120px",
                alignItems: "center",
                color: "white",
                fontSize: 54,
                justifyContent: "center",
              }}
            >
              Thank you
            </Text>
          </Page>
        </>
      )}
      {page === "About Us" && (
        <>
        
          <Page size={{height:370, width: 600,}} style={styles.warehousepage}>
          
            <View style={styles.section}>
              <Image src={image1} style={[styles.twosideimage,{marginTop:50}]} />
            </View>
            <View style={[styles.section,{marginTop:50}]}>
            <Text style={[styles.title, { textDecoration: 'underline' }]}>ABOUT US</Text>
              <Text style={styles.text}>
                Decade isn’t just a number it is a journey of Sthapatya that has
                gained trust of its client’s. {'\n'}
              </Text>
              <Text style={styles.text}>
              We, at Sthapatya have always
                believed into a customized, client-specific & need-based
                approach. We understands the existing gaps & requirements in the
                current situations from our clients and aspire to provide a more
                efficient & best suited warehousing or real estate solution to
                cater our clients’ growing needs from time to time. 

              </Text>
            </View>
            <View style={styles.section}>
              <Image
                src="/pdf_template_1/logo.jpg"
                style={{width:'100px',height:'50px',marginLeft:'100px'}}
                alt="Property"
              />
              <Image src={image2} style={styles.twosideimage} />
              <Text style={[styles.title, {fontSize: 12}]}>OWNER {'\n'} RITESH AGARWAL</Text>
            </View>
          </Page>
        </>
      )}
      {page === "Key Details" && (
        <>
          <Page size={styles.size} style={[styles.warehousepage,{backgroundColor:'white',padding:0}]}>
            <View style={styles.sectionside}>
              <Image
                src="/warehouse-pdf/keyAreaDetailspagebg.png"
                style={styles.sectionsideimage}
              />
              <View style={styles.details}>
                <Text style={styles.title}>{data.vertical}</Text>
                <Text style={styles.title}>Key Area</Text>
                <Text style={styles.title}>Details</Text>
              </View>
            </View>
            <View style={styles.detailsContainer}>
              {tableData.map((e, index) => (
                <View key={index} style={styles.keyDetailBorder}>
                  <View style={{ ...styles.keyDetail, ...myStyleColor(index) }}>
                    <Text style={styles.textfont}>
                      {e.label} : {data[e.value]}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        </>
      )}
      {page === "Warehouse Table" && (
        <>
          <Page size={styles.size} style={styles.page}>
            <View style={styles.warehouseheader}>
              <Image
                src="/warehouse-pdf/HeaderInnerView.png"
                style={styles.warehouseheaderimage}
                alt="Property"
              />
            </View>
            <View style={styles.tableContainer}>
              <TableTemplateDownload
                table={tableData}
                data={data}
                isWareHouse={true}
                columns={columns}
              />
            </View>
          </Page>
        </>
      )}
      {page === "Warehouse Home" && (
        <>
          <Page size={styles.size} style={styles.page}>

              <Text style={styles.hometexthead}>{data.vertical} </Text>
              <Text style={styles.hometext}>- {data.name} , {data.street} </Text>
              <Image   style={styles.homeimg} src="/Image20240529154115.png" />
          </Page>
        </>
      )}
      {page === "Warehouse Contact" && (
        <>
          <Page size={styles.size} style={styles.page}>
              <Image   style={styles.homeimg} src="/warehouse-pdf/ContactUs.jpg" />
          </Page>
        </>
      )}
    </>
  );
}

export default DynamicpdfDownloadPages;
