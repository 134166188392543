import React, { useState, useEffect } from "react";
import { Document} from "@react-pdf/renderer";
import DynamicpdfDownloadPages from "./DynamicpdfDownloadPages";
import inventoryApi from "../../api/inventoryApi";
import ImageTemplateDownload from "./ImageTemplateDownload";
import { initialData } from "./PdfData";

function DynamicPdfDownload({propertyData,files}) {
  const [documentFile, setDocumentFile] = useState(files);
  const [displayFile, setDisplayFile] = useState();
  const [dataRec, setDataRec] = useState(propertyData);
  const [pageData, setPageData] = useState(initialData[dataRec.vertical]);

  const generateMapImage = (address) => {
    const mapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(address)}&size=600x300&maptype=roadmap&markers=color:red%7C${encodeURIComponent(address)}&key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI`;
    return mapImageURL;
  };
  const address = `${dataRec.street}, ${dataRec.city}, ${dataRec.state}, ${dataRec.pincode}, ${dataRec.country}`;
  const mapImageLink = generateMapImage(address);
  useEffect(() => {
    console.log('dataRec',propertyData);
    setDataRec(propertyData);
  }, [propertyData]);
  useEffect(() => {
    console.log('documentFile',documentFile);
    setDocumentFile(files);
    const getFIles = async () => {
      if (files && files?.length > 0) {
        let documentMap = [...files];
        documentMap = documentMap.sort(compare)
        let mapFile = {};
        documentMap.forEach(element => {
          if (!mapFile[element.section]) {
            mapFile[element.section] = [];
        }
          mapFile[element.section].push(element);
        });
        setDisplayFile(mapFile);
        // setDocumentFile(documentMap);
       
    
      // setDocumentFile(documentMap);
      }
    }
    getFIles();
  }, [files]);

  function compare( a, b ) {
    if ( a.section < b.section ){
      return -1;
    }
    if ( a.section > b.section ){
      return 1;
    }
    return 0;
  }
    return (

      <Document>
        {pageData.map((value, index, elements) => (
          <React.Fragment>
           {value.isVisible &&  (value.page === "Images Pages" ? (
              <>
                {displayFile &&
                  Object.entries(displayFile).map(([key, value]) => (
                    <React.Fragment key={value.id}>
                     <ImageTemplateDownload imageset={value} isTwoLayout={key === 'Actual View'}  isWareouse={dataRec.vertical === 'Warehouse' ||dataRec.vertical === 'Cold storage'||dataRec.vertical === 'Investment'||dataRec.vertical === 'Factory'}/>
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>
               <DynamicpdfDownloadPages
                key={value.id}
                imageFile1={value.image1}
                imageFile2={value.image2}
                section={value.section}
                page={value.page}
                mapimage={mapImageLink}
                tableData={value.tableData}
                dataRec={dataRec}
                columns={value.columns}
              />
              </>
            ))}
          </React.Fragment>
        ))}
         
      </Document>
    );
  }


export default DynamicPdfDownload;
