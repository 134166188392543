import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import {
DatatableWrapper,
Filter,
Pagination,
PaginationOptions,
TableBody,
TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import CustomSeparator from "./Breadcrumbs/CustomSeparator";

import { Link } from "react-router-dom";
import { Field } from "formik";
import { Input } from "react-bootstrap-typeahead";



const ReportList = () => {


const [body, setBody] = useState([]);
const [reports, setReports] = useState([]);

const [searchTerm, setSearchTerm] = useState('');

const handleSearchChange = (e) => {
  console.log("e.target.value",e.target.value)
  setSearchTerm(e.target.value);
}

const filteredReports = reports.filter(report => {
  return report.name.toLowerCase().includes(searchTerm.toLowerCase());
});


useEffect(() => {
  async function init() {
    const reports = await inventoryApi.fetchReports();
    console.log(' =======reports',reports)
    if (reports) 
    //.log(reports);
    //setBody(reports);
    setReports(reports);
  }
  init();
}, []);

const header = [
    { title: 'Report Name', prop: 'name', isFilterable: true ,
    cell: (row) => (
      <Link
        to={"/reports/" + row.id}
        state={row}
      >
        {row.name}
      </Link>
    )
},
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')),isFilterable: true },
    { title: 'LastModified Date', prop: 'lastmodifieddate',cell: (row) => (moment(row.lastmodifieddate).format('DD-MM-YYYY')), isFilterable: true },
]

const labels = {
    beforeSelect: " "
  }
  return (
    <>
      <Container>
        <CustomSeparator
          // cmpListName="Report List"
          currentCmpName="Reports"
          indexLength="0"
          url="/reports"
        ></CustomSeparator>
      </Container>

      <Row className="py-3 m-4 ibs-edit-form"
                style={{ backgroundColor: "#fff" ,
              }}>
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={filteredReports}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4" >
            <Col lg={2}  className="mx-3"   >
                <Form.Group
                  controlId="formBasicTextInput"

                >
                  <Form.Control
                    type="text"
                    placeholder="Search report" 
                    onChange={handleSearchChange}
                  />
          
                </Form.Group>
              </Col>
              <Col
                xs={10}
                sm={4}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                {/* <PaginationOptions labels={labels} /> */}
              </Col>
            </Row>
            <Row className="pl-2">
              <Col lg={12} className="px-2">
              {console.log("filteredReports-->",filteredReports)}
                {filteredReports.length > 0 ? (
                  <div>
                    {filteredReports.map(report => (
                      <Badge
                        key={report.id}
                        bg="RGB(227, 30, 36)"
                        style={{
                          backgroundColor: "RGB(227, 30, 36)",
                          padding: "8px",
                          margin: "20px",
                        }}
                      >
                        <Link
                          style={{ textDecoration: 'none', color: "white" }}
                          to={"/reports/" + report.id}
                          state={report}
                        >
                          {report.name}
                        </Link>
                      </Badge>
                    ))}
                  </div>
                ) : (
                  <p>No reports found</p>
                )}
              </Col>
            </Row>
            {/* <Pagination /> */}
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
}

export default ReportList
