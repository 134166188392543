import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Container,
  Modal,
  InputGroup,
} from "react-bootstrap";
import inventoryApi from "../../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import Form from "react-bootstrap/Form";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import jwt_decode from "jwt-decode";
import moment from "moment/moment";
import PubSub from "pubsub-js";


const AttendanceForAdmin = (props) => {
  const [body, setBody] = useState([]);
  const [allData, setAlldata] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [month, setMonts] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const [leavetype, setLeavetype] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [date, setdate] = useState();

  const [userInfo, setUserInfo] = useState(
    jwt_decode(localStorage.getItem("token"))
  );
  const [validated, setValidated] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [searchQuery, setSearchQuery] = useState("");

  const [updatedItem, setUpdatedItem] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [remark, setRemark] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${amPm}`;
  };


  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchAttendance();
    
      setAlldata(result);

      if (result && result.length) {
        onFilterData(
          result,
          new Date().getFullYear(),
          new Date().getMonth() + 1
        );
      } 
    }
    const getAllYears = () => {
      const currentDate = new Date();
      const curyear = currentDate.getFullYear();

      const last10Years = Array.from({ length: 10 }, (_, index) =>
        (curyear - index - 1).toString()
      );
      const next10Years = Array.from({ length: 10 }, (_, index) =>
        (curyear + index).toString()
      );
      const allYears = [...last10Years, curyear.toString(), ...next10Years];
      const sortedYears = allYears.sort((a, b) => parseInt(a) - parseInt(b));
      setYearOptions(sortedYears);
    };

    init();
    getAllYears();
  }, [showModal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    const selectedDate = document.querySelector('[name="date"]').value;
    const allData = await inventoryApi.fetchAttendance();
     console.log('allData',allData);
   // const fetcheddate = allData?.filter(e=>e.id === props.user.id).map((item) => moment(item.date).format("DD-MM-YYYY"));
    const fetcheddate = allData.filter(e => e.user_id === props.user.id).map(item => moment(item.date).format("DD-MM-YYYY"))
    
    const isDateFetched = fetcheddate?.includes(moment(selectedDate).format("DD-MM-YYYY"));
    const isExistingAttendance = body?.some((item) => moment(item.date).format("DD-MM-YYYY") === moment(selectedDate).format("DD-MM-YYYY"));
    console.log('props',fetcheddate,selectedDate);

    if (isExistingAttendance || isDateFetched) {
      PubSub.publish("RECORD_ERROR_TOAST", {
        title: "Record Not Created",
        message: "Attendance for this date already exists.",
      });
      return;
    }

    const saveAttendance = {
      attendance_status: attendanceStatus,
      date: selectedDate,
      leavetype,
      reason,
      status: attendanceStatus === "Leave" ? "Pending..." : "",
      user_id: userInfo.id,
    };

    if (saveAttendance.attendance_status === "Present") {
      saveAttendance.reason = null;
    }

    if (attendanceStatus && attendanceStatus !== "" && selectedDate !== "") {
      let result;

      //.log("attendanceStatus  :-  ", attendanceStatus);
      //.log("leavetype  :-  ", leavetype);
      //.log("reason  :-  ", reason);

      if (attendanceStatus === "Leave" && (leavetype === "" || reason === "")) {
        return;
      }

      result = await inventoryApi.createAttendance(saveAttendance);

      if (result) {
        setBody(prevBody => [...prevBody, result]);
        setAttendanceStatus("");
        setShowModal(false);
      }
    }
  };

  const header = [
    {
      title: "Date",
      prop: "date",
      isSortable: true,
      isFilterable: true,
      cell: (row) =>
        row.date ? moment(row.date).format("DD-MM-YYYY") : "",
    },
    {
      title: "Time",
      prop: "time",
      isSortable: true,
      isFilterable: true,
      cell: (row) =>
      moment(row.time, 'HH:mm:ss.SSSSSSZ').format('HH:mm:A')
        // row.time ? moment(row.time).format("hh:mm:ss") : "",
    },

    {
      title: "Attendance Status",
      prop: "attendance_status",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "140px",
            height: "15px",
            padding: "10px 10px",
            fontSize: "12px",
            backgroundColor:
              row.attendance_status === "Present" ? "#85d884" : "#EF9F9F",
            color: "black",
            borderRadius: "15px",
          }}
        >
          {row.attendance_status}
        </span>
      ),
    },
    {
      title: "Leave Type",
      prop: "leavetype",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Leave Reason",
      prop: "reason",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          className="attendance_Reason"
          data-toggle="tooltip"
          data-placement="bottom"
          title={row.reason}
        >
          {row.reason}
        </span>
      ),
    },
    {
      title: "Status",
      prop: "status",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <>
       {row.username !== userInfo.username?
        <span>
          {row.status}
        </span> :''}
        </>
      ),
    },
    {
      title: "Remark",
      prop: "remark",
      isSortable: true,
      isFilterable: true,
    },
  ];
  const clearSearch = () => {
    setSearchQuery("");
  };
  const filteredData = () => {
    if (searchQuery === "") {
      return body;
    }
    //.log('searchQuery3',searchQuery);

    // const formattedSearchDate = moment(searchQuery, "DD-MM-YYYY", true).format(
    //   "YYYY-MM-DD"
    // );
    return body.filter(
      (row) =>
        (row.username &&
          row.username.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.date &&
          moment(row.date).format("YYYY-MM-DD").includes(searchQuery)) ||
        (row.attendance_status &&
          row.attendance_status
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (row.leavetype &&
          row.leavetype.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.reason &&
          row.reason.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.status && row.status.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const handleMonth = (event) => {
    setSelectedMonth(parseInt(event.target.value));
    onFilterData(allData, selectedYear, parseInt(event.target.value));
  };

  const handleYear = (event) => {
    setSelectedYear(parseInt(event.target.value));
    onFilterData(allData, parseInt(event.target.value), selectedMonth);
  };

  const onFilterData = (res, yearValue, monthValue) => {
    if (isNaN(monthValue) && isNaN(yearValue)) {
      setBody(res);
    } else {
      const filteredData = res.filter((item) => {
        if (
          monthValue === item.attendancemonth &&
          yearValue === item.attendanceyear
        ) {
          return item;
        } else if (
          (isNaN(monthValue) && yearValue === item.attendanceyear) ||
          (monthValue === item.attendancemonth && isNaN(yearValue))
        ) {
          return item;
        }
      });
      //.log("filteredData:-", filteredData);
      setBody(filteredData);
    }
  };

  const labels = {
    beforeSelect: "  ",
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      setReason("");
      setLeavetype("");
      setAttendanceStatus(null);
      setValidated(false);
    }
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setReason("");
    setLeavetype("");
    setAttendanceStatus(null);
    setValidated(false);
  };

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOrder("asc");
      setSortedColumn(column);
    }
  };
  
  const renderSortIcon = (column) => (
    <span className="px-1">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sort-up"
        className={`svg-inline--fa fa-solid fa-sort-up fa-fwicon ${
          sortedColumn === column && sortOrder === "asc" ? "active" : ""
        }`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path fill="currentColor" d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"></path>
      </svg>
    </span>
  );
  const paginateData = () => {
    const sortedData = filteredData().sort((a, b) => {
      if (sortedColumn) {
        if (a[sortedColumn] < b[sortedColumn]) {
          return sortOrder === "asc" ? -1 : 1;
        } else if (a[sortedColumn] > b[sortedColumn]) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    return sortedData.slice(startIndex, endIndex);
  };

  const handleApprove = async (row) => {
    const updatedItem = { ...row, status: "Approved" };
    saveAttendanceAndUpdateState(updatedItem);
  };

  const handleReject = (row) => {
    setUpdatedItem(row);
    setShowPopup(true);
  };
  const saveAttendanceAndUpdateState = async (updatedItem) => {
    const data = await inventoryApi.saveAttendance(updatedItem);
    //.log("data", data);

    const updatedData = body.map((item) => {
      return item.id === updatedItem.id ? updatedItem : item;
    });
    //.log('searchQuery',updatedData);

    setBody(updatedData);
    setUpdatedItem(null);
  };

  const handleRejectWithRemark = async () => {
    const dataById = await inventoryApi.fetchAttendanceById(updatedItem.id);
    //.log("dataById", dataById);

    const updatedItemWithRemark = {
      ...updatedItem,
      status: "Rejected",
      remark: remark,
    };
    saveAttendanceAndUpdateState(updatedItemWithRemark);
    setShowPopup(false);
    setRemark("");
  };

  const handleFilter = (filterText) => {
    const lowercasedFilterText = filterText.toLowerCase();
  
    const filteredData = allData.filter((row) => {
      const date = new Date(row.date);
      const formattedDate = date.toISOString().split('T')[0]; 
      const [year, month, day] = formattedDate.split('-');
      const formattedDateDMY = `${day}-${month}-${year}`;
  
      return (
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(lowercasedFilterText)
        ) || formattedDateDMY.includes(lowercasedFilterText)
      );
    });
  
    setBody(filteredData);
  }
  return (
    <Container>
      <CustomSeparator
        currentCmpName="Attendance"
        indexLength="0"
        url="/data"
      ></CustomSeparator>

      <Row className="g-0">
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
            sortProps={{
              initialState: {
                prop: "createddate",
                order: "desc",
              },
            }}
          >
            <Row className="mb-4">
              {/* <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col> */}
              <Col
                  //xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      className="inputbox"
                      placeholder="Enter text"
                      onChange={(e) => {
                        handleFilter(e.target.value);
                      }}
                    />
                    <Button
                      variant="primary"
                      onClick={() => {
                        //.log('insdode', document.querySelector(".inputbox").value);
                        document.querySelector(".inputbox").value = "";
                        handleFilter("");
                      }}
                    >
                      <b>×</b>
                    </Button>
                  </InputGroup>
          </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-center"
              >
              <Form.Group className="mx-3 mt-4" controlId="formGroupPagination">
                <Form.Select
                  aria-label="Select Items Per Page"
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Select>
              </Form.Group>
                <Form.Group className="mx-3 mt-4" controlId="formBasicMonth">
                  <Form.Select
                    aria-label="Enter Month"
                    name="month"
                    onChange={handleMonth}
                  >
                    <option value="">--All Month--</option>
                    {month.map((item, index) => {
                      return item ===
                        new Date().toLocaleString("default", {
                          month: "long",
                        }) ? (
                        <option value={index + 1} key={index} selected>
                          {item}
                        </option>
                      ) : (
                        <option value={index + 1} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mx-3 mt-4" controlId="formBasicYear">
                  <Form.Select
                    aria-label="Enter Year"
                    name="year"
                    onChange={handleYear}
                  >
                    <option value="">--All Year--</option>
                    {yearOptions.map((item, index) => {
                      return item === new Date().getFullYear().toString() ? (
                        <option value={item} key={index} selected>
                          {item}
                        </option>
                      ) : (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={openModal}
                >
                  Add Attendance
                </Button>
              </Col>
            </Row>
            {body ? (
            <Table striped className="data-table">
              <thead style={{ cursor: "pointer" }}>
                <tr
                  className="g-0"
                  style={{
                    backgroundColor: "#1a293b",
                    color: "#fff",
                    border: "1px solid #1a293b",
                    padding: "0.5rem 0.5rem",
                  }}
                >
                    <th className="px-4" onClick={() => handleSort("username")}>
                        Name
                        {renderSortIcon("username")}
                    </th>
                    <th className="px-4" onClick={() => handleSort("date")}>
                        Date
                        {renderSortIcon("date")}
                    </th>   
                    <th className="px-4" onClick={() => handleSort("date")}>
                        Time
                        {renderSortIcon("date")}
                    </th>   
                    <th className="px-4" onClick={() => handleSort("attendance_status")}>
                    Attendance Status
                        {renderSortIcon("attendance_status")}
                    </th>
                    <th className="px-4" onClick={() => handleSort("leavetype")}>
                    Leave Type
                        {renderSortIcon("leavetype")}
                    </th> 
                    <th className="px-4" onClick={() => handleSort("reason")}>
                    Leave Reason
                        {renderSortIcon("reason")}
                    </th>
                    <th className="px-4" onClick={() => handleSort("status")}>
                    Status
                        {renderSortIcon("status")}
                    </th>  
                    <th className="px-4" onClick={() => handleSort("remark")}>
                    Remark
                        {renderSortIcon("remark")}
                    </th> 
                </tr>
              </thead>
              <tbody>
                {paginateData().map((row, index) => (
                  <tr key={index}>
                    <td>{row.username}</td>
                    <td>
                      {row.date ? moment(row.date).format("DD-MM-YYYY") : ""}
                    </td>
                    <td>
                   {moment(row.time, 'HH:mm:ss.SSSSSSZ').format('HH:mm:A')} 
                    </td>
                    <td>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "140px",
                          height: "15px",
                          padding: "10px 10px",
                          fontSize: "12px",
                          backgroundColor:
                          row.attendance_status === "Present" ? "#85d884" : "#EF9F9F",
                          color: "black",
                          borderRadius: "15px"
                        }}
                      >
                        {row.attendance_status}
                      </span>
                    </td>
                    <td>{row.leavetype}</td>

                    <td
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title={row.reason}
                    >
                      <span className="attendance_Reason tooltiptext">
                        {row.reason}
                      </span>
                    </td>
                    <td>
                      {row.attendance_status === "Leave" &&  row.status === "Pending..." && row.user_id !== userInfo.id ? (
                        <>
                          <Button
                          className="me-2"
                            variant="success"
                            onClick={() => handleApprove(row)}
                            style={{ backgroundColor: '#85d884', color:"black" }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleReject(row)}
                            style={{ backgroundColor: '#EF9F9F', color:"black" }}
                          >
                            Reject
                          </Button>
                        </>
                      ) : (
                        <>{row.status}</>
                      )}
                    </td>
                    <td>{row.remark}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Modal show={showPopup} onHide={() => setShowPopup(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Remark</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter remark..."
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowPopup(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => handleRejectWithRemark(updatedItem)}
                disabled={!remark.trim()} 
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
              
                <Col lg={7}>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <InputGroup>
                      <Form.Control
                        type="date"
                        name="date"
                        required
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={(event)=>setdate(event.target.value)}

                      />
                      <Form.Control
                        disabled
                        required
                        type="text"
                        name="time"
                        value={getCurrentTime()}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={5}>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustom02"
                    style={{ width: "145px" }}
                  >
                    <Form.Select
                      required
                      aria-label="Select Attendance Status"
                      name="attendanceStatus"
                      value={attendanceStatus}
                      onChange={(e) => setAttendanceStatus(e.target.value)}
                    >
                      <option value="">--Select Status--</option>
                      {date === moment(new Date()).format("YYYY-MM-DD") ? <option value="Present">Present</option> : ''}
                      <option value="Leave">Leave</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {attendanceStatus === "Leave" ? (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <Form.Select
                        required
                        aria-label="Select Attendance Status"
                        name="leavetype"
                        value={leavetype}
                        onChange={(e) => setLeavetype(e.target.value)}
                      >
                        <option value="">--Select Leave Type--</option>
                        <option value="First Half Day">First Half Day</option>
                        <option value="Second Half Day">Second Half Day</option>
                        <option value="Full Day">Full Day</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                      <Form.Control
                        required
                        as="textarea"
                        name="reason"
                        placeholder="Enter Leave Reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                </>
              ) : null}

              <Button onClick={handleSubmit}>Submit form</Button>
              <Button className="ms-2" variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AttendanceForAdmin;
