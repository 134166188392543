import React, { useState, useEffect } from "react";
import "./DynamicPdf.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "react-bootstrap";
import DynamicPdfDownload from "./DynamicPdfDownload";
import DynamicPdfGeneratePage from "./DynamicPdfGeneratePage";
import inventoryApi from "../../api/inventoryApi";
import ImageTemplate from "./ImageTemplate";
import { initialData } from "./PdfData";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import PubSub from "pubsub-js";
import * as constants from "../../constants/CONSTANT"
function DynamicPdfGenrate() {
  const location = useLocation();

  const [documentFile, setDocumentFile] = useState(location.state ? location.state.documentFile : {});
  const [displayFile, setDisplayFile] = useState();
  const [dataRec, setDataRec] = useState(location.state ? location.state.propertydata : {});
  const [pageData, setPageData] = useState(initialData[dataRec.vertical]);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  function handlePDfSave(blob) {

    var myFile = new File([blob], `${dataRec.name}.pdf`, { lastModified: 1534584790000 });
    //.log('myFile',myFile);
    const token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("image", myFile);
    formData.append(`description`, 'pdf files');
    // formData.append(`fileType`, myFile);
    formData.append(`ispdf`, true);
    //.log("formdata",JSON.stringify(formData));

    axios
      .post(
        `${constants.API_BASE_URL}/api/files/${dataRec.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
          onUploadProgress: (data) => {
            // Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },

        }
      )
      .then((response) => {
        //.log("response",response)
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  function handleCheckbox(key) {
    let temp = pageData.map(e=> { 
      if(e.page === key){  
        e.isVisible = !e.isVisible
        return e;
      }
      else{
         return e;
      }
    })
    console.log(temp);
    setPageData(temp);
  }
  function removedImages(temp, ischeck) {

    setDocumentFile(documentFile.map(e=> {
      if(temp.includes(e.id)){
        e.isVisible  = ischeck ? true : false;
        return e;
      }else{
        return e;
      }
    }))

  }
  const handleGoBack = () => {
    navigate(`/properties/${dataRec.id}`, { state: dataRec });
  };
  const handleAdd = () => {
    // const newId = fileData.length ? fileData[fileData.length - 1].id + 1 : 1;
    // const newFile = { image: "/pdf_template_1/twentythPage.imagelink3.jpg", section: "Actual View", subSection: '', id: newId };
    // setFileData([...fileData, newFile]);
  };
  const generateMapImage = (address) => {
    const mapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(address)}&size=600x300&maptype=roadmap&markers=color:red%7C${encodeURIComponent(address)}&key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI`;
    return mapImageURL;
  };
  const address = `${dataRec.street}, ${dataRec.city}, ${dataRec.state}, ${dataRec.pincode}, ${dataRec.country}`;
  const mapImageLink = generateMapImage(address);

  useEffect(() => {
    const getFIles = async () => {

        let mapFile = {};
        documentFile?.forEach((element) => {
          if (!mapFile[element.section]) {
            mapFile[element.section] = [];
          }
          mapFile[element.section].push(element);
        });
        setDisplayFile(mapFile);
    };
    getFIles();

  }, [documentFile]);

  const handleUpdateImage = (updatedImage, isImage1) => {
    setDocumentFile((prevfileData) =>
      prevfileData.map((file) => {
        if (file.id === updatedImage.id) {
          return { ...file, image: updatedImage.image };
        }
        return file;
      })
    );
  };

  const handleChange = (data) => {
    console.log("data", data);
    setDataRec(data);
  };
  return (
    <>
      {/* <PDFDownloadLink
        document={}
  
        fileName={`testpdf.pdf`}
      >
        {({ loading, blob }) =>
          loading ? (
            <Button disabled>Loading Document...</Button>
          ) : (
            <Button onClick={() => handlePDfSave(blob)}>Save</Button>
          )
        }
      </PDFDownloadLink> */}
      <center>
        <div
          className="d-flex justify-content-between"
          style={{ width: "95%" }}
        >
          <div className="m-2">
            <Button onClick={handleGoBack}> Back </Button>
          </div>

          <div className="m-2">
            {/* <OverlayTrigger
              placement="top"
              overlay={<Tooltip className="my-tooltip">PDF</Tooltip>}
            >*/}
            <PDFDownloadLink
              document={
                <DynamicPdfDownload propertyData={dataRec} pageDataRec={pageData} files={documentFile}/>
              }
              fileName={`${dataRec.name} - pdf - ${moment().format('DD-MM-YYYY:HH:MM:SS')}.pdf`}
            >
              {({ loading, blob }) =>
                loading ? (
                  <Button disabled>Loading Document...</Button>
                ) : (
                  <Button onClick={() => handlePDfSave(blob)}> Save</Button>
                )
              }
            </PDFDownloadLink>
            {/* </OverlayTrigger> */}
          </div>
        </div>
      </center>

      <div style={{ marginLeft: "25%" }}>
        {pageData.map((value, index, elements) => (
          <React.Fragment>
            {value.page === "Images Pages" ? (
              <>
                {displayFile &&
                  Object.entries(displayFile).map(([key, value]) => (
                    <React.Fragment key={value.id}>
                      {console.log('values-->',value)}
                      <ImageTemplate
                        images={value}
                        isTwoLayout={key === "Actual View"}
                        isWarehouse={dataRec.vertical === 'Warehouse' ||dataRec.vertical === 'Cold storage'||dataRec.vertical === 'Investment'||dataRec.vertical === 'Factory'}
                        changeImage={handleUpdateImage}
                        removedImages={(data,ischeck) => removedImages(data,ischeck)}
                      />
                      {/* <div>
                        <button onClick={handleAdd} className="plus-button">
                          +
                        </button>
                      </div> */}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>
                <input type="checkbox" checked={value.isVisible} onChange={() => handleCheckbox(value.page)}/> 
                <DynamicPdfGeneratePage
                  key={value.id}
                  imageFile1={value.image1}
                  imageFile2={value.image2}
                  section={value.section}
                  changeImage={handleUpdateImage}
                  mapimage={mapImageLink}
                  page={value.page}
                  tableData={value.tableData}
                  data={dataRec}
                  columns={value.columns}
                  handlechange={(data) => handleChange(data)}
                
                />
              </>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* <DynamicPdfDownload/> */}
    </>
  );
}

export default DynamicPdfGenrate;
