import React, { useState, useEffect } from "react";

const ImageTemplate = ({ images, isTwoLayout = false, isWarehouse = false,removedImages }) => {
  const [isUpload, setIsUpload] = useState({});
  const [imageData, setImageData] = useState(images);

  // Function to handle image upload
  const handleChange = (event, index) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedImages = [...imageData];
      updatedImages[index] = { image: reader.result };
      setImageData(updatedImages);
      setIsUpload({ ...isUpload, [index]: false });
    };
    reader.readAsDataURL(file);
  };

  const handleCheckbox = (chunks, isCheck) => {
     let temp = isTwoLayout ? chunks.map(e=> e.id) : [chunks[0].id]
     removedImages(temp,isCheck);
  }
  useEffect(() => {
    setImageData(images);
  }, [images]);

  // Function to split images into chunks of 2
  const chunkImages = (images) => {
    let chunks = [];
    for (let i = 0; i < images.length; i += isTwoLayout ? 2 : 1) {
      chunks.push(images.slice(i, i + (isTwoLayout ? 2 : 1)));
    }
    return chunks;
  };

  const imageChunks = chunkImages(imageData);
  const myStyle = {
    backgroundImage: 'url("/warehouse-pdf/HeaderInnerView.png")',
    backgroundSize:"cover"
  };
  const noneStyle = {
  };

  return (
    <div>
      
      {imageChunks.map((chunk, pageIndex) => (
        <>
         <input type="checkbox" checked={chunk[0].isVisible} onChange={(e) => handleCheckbox(chunk,e.target.checked )}/>
        <div className="page" key={pageIndex} >
          <div className={isWarehouse? "page-header-ware" : "page-header" }  style={isWarehouse? myStyle : noneStyle}>
            <div>
              <div className="head-text">{chunk[0]?.section}</div>
              <div className="subhead-text">{chunk[0]?.subSection}</div>
            </div>
            {!isWarehouse && <div>
              <img
                className="app-logo"
                src={"/pdf_template_1/logo.jpg"}
                alt="Property"
              />
            </div>}
          </div>
          <div
            className={`page-body ${
              chunk[0]?.image && chunk[1]?.image ? "has-two-images" : ""
            }`}
          >
            {chunk.map((img, index) => (
              <div key={index}>
                {img?.image && !isUpload[pageIndex * 2 + index] && (
                  <img
                    src={img.image}
                    alt="Property"
                    onDoubleClick={() =>
                      setIsUpload({
                        ...isUpload,
                        [pageIndex * 2 + index]: true,
                      })
                    }
                  />
                )}
                {isUpload[pageIndex * 2 + index] && (
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={(event) =>
                      handleChange(event, pageIndex * 2 + index)
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        </>
      ))}

    </div>
  );
};

export default ImageTemplate;
