import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import PubSub from "pubsub-js";
import CityState from "../../constants/CityState.json";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import { Field, Formik, ErrorMessage, FieldArray } from "formik";
import data from "../NewJson";
import { floornumber } from "../NewJson";
import {
  schemaLeadAddress,
  schemaLeadInfo,
  schemaLeadPersonalInfo,
} from "../common/ValidateSchemaHelper";
import { leadAddressInitialValues } from "../common/InitialValuesHelper";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import moment from "moment";


const LeadEdit = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [stateList, setStateList] = useState(false);
  const [cityList, setCityList] = useState();
  const [currentSection, setCurrentSection] = useState(1);
  const [ownerList, setownerList] = useState([]);
  const [removeIndex, setRemoveIndex] = useState([]);
  const [defaultOwner, setDefaultOwner] = useState([]);
  const [typeOfClient, setTypeOfClient] = useState([lead.typeofclient]);
  const [transcationType, setTranscationType] = useState([
    lead.transactiontype,
  ]);
  const [verticals, setVertical] = useState([]);
  const [verticalType, setVerticalType] = useState([]);
  const [subVerticalType, setSubVerticalType] = useState([]);
  const [stepperStep, setStepperStep] = useState("");
  const [removePricingOrRental, setRemovePricingOrRental] = useState([]);

  useEffect(() => {
    var temp = [];
    CityState.map((ele) => {
      if (!temp.includes(ele.state)) {
        temp.push(ele.state);
      }
    });
    setStateList(temp.sort());

    if (!lead?.clientstate) {
      var tempList = CityState.filter((ele) => ele.state === 'Rajasthan');
      setCityList(tempList.sort());
    } else {
      var tempList = CityState.filter((ele) => ele.state === lead?.clientstate);
      setCityList(tempList.sort());
    }

    async function init() {
      const result = await inventoryApi.fetchUsers();
      if (result) {
        setownerList(result);
        if (lead.id) {
          setDefaultOwner([{ id: lead.ownerid, username: lead.ownername }]);
        } else {
          setDefaultOwner([{ id: "", username: "" }]);
        }
      }
    }
    init();
  }, []);

  useEffect(() => {
    if(!(lead.id && lead.id !=='')){
      setTranscationType(data.Value["Transaction type"]);
    }else{
      setTranscationType(data.Value["Transaction type"]);
      if (lead.transactiontype && Object.entries(data.Dependency[lead?.transactiontype])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(
          data.Dependency[lead?.transactiontype]
        )) {
          temp.push(key);
        }
        setTypeOfClient(temp);
      }
      if (lead.typeofclient && Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient]
        )) {
          temp.push(key);
        }
        setVertical(temp);
      }
      if (lead.vertical && Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient][lead?.vertical])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient][lead?.vertical]
        )) {
          temp.push(key);
        }
        setVerticalType(temp);
      }
      if (lead.verticaltype && Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient][lead?.vertical][lead?.verticaltype])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[lead?.transactiontype][lead?.typeofclient][lead?.vertical][lead?.verticaltype]
          )) {
            temp.push(value);
          }
          setSubVerticalType(temp[0]);
      }
    }
  },[]);

  useEffect(() => {
    fetchLead();
  }, []);

  const fetchLead = () => {

    async function initLead() {
      let result = await inventoryApi.fetchLead(lead.id);
      // console.log('lead',result);
      if (result) {
        //if (result?.id) {
        //   let details = [];
        //   if (lead.transactiontype === 'Sell') {
        //     details = result?.pricingorrental?.filter(e => e.type === 'pricing');
        //     console.log('details', details);
        //   } else {
        //     details = result?.pricingorrental?.filter(e => e.type === 'rent');
        //     console.log('details123', details);
        //   }
        //   result.pricingorrental = details;

        // if (result.transactiontype === 'Lease') {
        //   result.pricingorrental = result.pricingorrental.filter(item => item.type === 'rent');
        //   setLead({ ...result }); 
        // }else if(result.transactiontype === 'Sell'){
        //   result.pricingorrental = result.pricingorrental.filter(item => item.type === 'pricing');
        //   setLead({ ...result }); 
        // }
        //  }
        setLead(result);



        // setLead(result)

      } else {
        setLead({});
      }
    }
    initLead();
  };


  console.log('lead', lead);


  const handleBack = () => {
    setCurrentSection(currentSection - 1);
  };

  const handleSubmitSave = async (values) => {

    // for (const key in values) {
      //   if (values[key] === undefined || values[key] === null) {
        //     values[key] = "";
        //   }
        // }
        if (stepperStep !== "" && currentSection === 1) {
          setCurrentSection(stepperStep);
        } else if (stepperStep !== "" && currentSection === 2) {
          setCurrentSection(stepperStep);
        } else {
          values.heightdetails = values.heightdetails.filter((item) => (item.floor !== "" || item.unit !== "" || item.value !== ""));
          values.areadetails = values.areadetails.filter((item) => item.floor !== "" || item.unit !== "" || item.value !== "" || item.area !== "");
          values.pricingorrental = values.pricingorrental.filter((item) => (item.floorno !== "" || item.unit !== "" || item.area !== "" ));
          console.log('values', values);
          
      if (!lead.id) {
        let result = await inventoryApi.createLead(values);

        if (result) {
          //setLead(result);
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          navigate(`/leads/${result.id}`, { state: result });
        }
      } else if (lead.id) {
        if (removeIndex.length > 0  || removePricingOrRental.length > 0) {
          let deletAarea = await inventoryApi.deletePropertyAreadetails(
            removeIndex
          );
          let deletPricingOrRental = await inventoryApi.deletePropertyPricingorRentaldetails(removePricingOrRental)
        }
        values.id = lead.id;
        let result = await inventoryApi.saveLead(values);
        console.log('result edit', result);
        if (result) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          navigate(`/leads/${values.id}`, { state: values });
        }
      }
    }
  };

  const handleCancel = () => {
    if (lead.id) {
      navigate("/leads/" + lead.id, { state: lead });
    } else {
      navigate("/leads/");
    }
  };

  const steps = [
    { title: <span style={{ fontWeight: "bold" }}>Client Information</span> },
    { title: <span style={{ fontWeight: "bold" }}>Lead Information</span> },
    { title: <span style={{ fontWeight: "bold" }}>Review And Confirm</span> },
  ];

  return (
    <>
      <Container className="view-form inputbox">
        {location?.state?.id ? (
          <CustomSeparator
            cmpListName="Leads"
            cmpViewName={lead.firstname + " " + lead.lastname}
            currentCmpName="Edit"
            indexLength="2"
            indexViewLength="3"
            cmpViewUrl={"/leads/" + lead.id}
            url="/leads"
          ></CustomSeparator>
        ) : (
          <CustomSeparator
            cmpListName="Leads"
            currentCmpName="Create"
            indexLength="2"
            url="/leads"
          ></CustomSeparator>
        )}

        <Row className="mt-4 mx-2">
          <Col lg={12} className="ibs-form-section">
            <Col lg={12} className="ibs-form-section">
              <Formik
                validationSchema={
                  currentSection === 1
                    ? schemaLeadPersonalInfo()
                    : currentSection === 2
                    ? schemaLeadInfo()
                    : schemaLeadAddress()
                }
                initialValues={leadAddressInitialValues(lead)}
                onSubmit={handleSubmitSave}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  setFieldValue,
                  touched,
                  errors,
                }) => (
                  <>
                    {currentSection === 1 && (
                      <Form onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                          <Col lg={10}>
                            {lead.id ? (
                              <>
                                <b>Edit Lead</b>
                                <h4>{lead.firstname + " " + lead.lastname}</h4>
                              </>
                            ) : (
                              <h6 style={{ marginTop: "5px" }}>New Lead</h6>
                            )}
                          </Col>
                          <Col
                            lg={2}
                            className="d-flex flex-col justify-content-end align-items-end"
                          >
                            <Button
                              className="btn-sm mx-2"
                              variant="danger"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn-sm mx-2"
                              onClick={() => {
                                setStepperStep(currentSection + 1);
                                handleSubmit();
                              }}
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col lg={12}>
                                <Stepper activeStep={currentSection - 1}>
                                  {steps.map((step, index) => (
                                    <Step key={index}>
                                      <StepLabel
                                        onClick={() => {
                                          setStepperStep(index + 1);
                                          handleSubmit();
                                        }}
                                      >
                                        {step.title}
                                      </StepLabel>
                                    </Step>
                                  ))}
                                </Stepper>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {currentSection === 1 && (
                          <Row lg={12} className="section-header  mx-auto">
                            <Col lg={10}>
                              <h5 style={{ marginTop: "5px" }}>
                                Client Information
                              </h5>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col lg={3}>
                            <label>
                              Salutation<b class="red-star"></b>
                            </label>
                            <Field
                              className="custom-input"
                              as="select"
                              name="salutation"
                              value={values.salutation}
                              onChange={handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Mr">Mr.</option>
                              <option value="Mrs">Mrs.</option>
                              <option value="Ms">Ms.</option>
                              <option value="Dr">Dr.</option>
                              <option value="Prof">Prof.</option>
                            </Field>
                          </Col>
                          <Col lg={3}>
                            <label>
                              First Name <b class="red-star">*</b>
                            </label>
                            <Field
                              className="custom-input"
                              type="text"
                              name="firstname"
                              placeholder="Enter firstname"
                              style={{ textTransform: "capitalize" }}
                              value={values.firstname}
                              onChange={(e) => {
                                const formattedValue =
                                  e.target.value.charAt(0).toUpperCase() +
                                  e.target.value.slice(1).toLowerCase();
                                setFieldValue("firstname", formattedValue);
                              }}
                              required
                            />
                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>
                              Last Name<b class="red-star">*</b>
                            </label>
                            <Field
                              className="custom-input"
                              type="text"
                              name="lastname"
                              style={{ textTransform: "capitalize" }}
                              value={values.lastname}
                              placeholder="Enter lastname"
                              required
                              onChange={(e) => {
                                const formattedValue =
                                  e.target.value.charAt(0).toUpperCase() +
                                  e.target.value.slice(1).toLowerCase();
                                setFieldValue("lastname", formattedValue);
                              }}
                            />
                            <ErrorMessage
                              name="lastname"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>
                              Phone
                              <b class="red-star"></b>
                            </label>
                            <Field
                              className="custom-input"
                              type="number"
                              name="phone"
                              placeholder="Enter phone number"
                              value={values.phone}
                              onChange={handleChange}
                              required
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>Email </label>
                            <Field
                              className="custom-input"
                              type="email"
                              name="email"
                              placeholder="Enter Email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Lead Source</label>
                            <Field
                              type="text"
                              name="leadsource"
                              placeholder="Enter Lead Source"
                              value={values.leadsource}
                            ></Field>
                          </Col>

                          <Col lg={3}>
                            <label>
                              Stage<b class="red-star"></b>
                            </label>
                            <Field
                              as="select"
                              name="leadstage"
                              onChange={handleChange}
                              value={values.leadstage}
                              required
                            >
                              <option value="Active">Active</option>
                              <option value="Close">Close</option>
                              <option value="Hold">Hold</option>
                              <option value="Pending">Pending</option>
                              <option value="Option Shared">
                                Option Shared
                              </option>
                              <option value="Site Visit Done">
                                Site Visit Done
                              </option>
                              <option value="Negotiation Stage">
                                {" "}
                                Negotiation Stage
                              </option>
                              <option value="Due diligence Stage">
                                Due Diligence Stage{" "}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="leadstage"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Alternate Phone</label>
                            <Field
                              className="custom-input"
                              type="number"
                              name="alternatephone"
                              placeholder="Enter alternate phone number"
                              value={values.alternatephone}
                              onChange={handleChange}
                              required
                            />
                            <ErrorMessage
                              name="alternatephone"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Client Type </label>
                            <Field
                              as="select"
                              value={values.clienttype}
                              name="clienttype"
                              onChange={handleChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Owner">Owner</option>
                              <option value="Company">Company</option>
                              <option value="Individual">Individual</option>
                            </Field>
                          </Col>

                          <Col lg={3}>
                            <label>Company</label>
                            <Field
                              type="text"
                              name="company"
                              placeholder="Enter company"
                              value={values.company}
                              onChange={handleChange}
                            ></Field>
                          </Col>
                          {/* <Col lg={3}>
                            <label>Office Address </label>
                            <Field
                              type="text"
                              name="office"
                              placeholder="Enter Office Address"
                              value={values.office}
                              onChange={handleChange}
                            ></Field>
                          </Col> */}
                          <Col lg={3}>
                            <label>
                              Assign Staff<b class="red-star"> *</b>
                            </label>
                            <Field
                              as="select"
                              name="ownerid"
                              value={values.ownerid}
                              defaultValue={defaultOwner}
                              onChange={handleChange}
                              required
                            >
                              <option value="">--Select Assign Staff--</option>
                              {ownerList &&
                                ownerList.map((ele) => (
                                  <option value={ele.id}>{ele.username}</option>
                                ))}
                            </Field>

                            <ErrorMessage
                              name="ownerid"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                        </Row>

                        <Row lg={12} className="section-header  mx-auto">
                          <Col lg={7}>
                            <h5 style={{ marginTop: "5px" }}>
                              Address Information
                            </h5>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={3}>
                            <label>State</label>
                            <Field
                              as="select"
                              name="clientstate"
                              value={values.clientstate}
                              onChange={(event) => {
                                var temp = CityState.filter(
                                  (ele) => ele.state === event.target.value
                                );
                                setCityList(temp.sort());
                                handleChange(event);
                              }}
                            >
                              <option value="">--Select State--</option>
                              {stateList &&
                                stateList.map((state) => (
                                  <option value={state}>{state}</option>
                                ))}
                            </Field>
                          </Col>
                          <Col lg={3}>
                            <label>City</label>
                            <Field
                              as="select"
                              name="clientcity"
                              value={values.clientcity}
                              onChange={handleChange}
                            >
                              <option value="">--Select City--</option>
                              {cityList &&
                                cityList.map((ele) => (
                                  <option value={ele.name}>{ele.name}</option>
                                ))}
                            </Field>
                          </Col>
                          <Col lg={3}>
                            <label>Street</label>
                            <Field
                              className=""
                              type="text"
                              name="clientstreet"
                              placeholder="Enter street"
                              value={values.clientstreet}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={3}>
                            <label> Pincode </label>
                            <Field
                              className=" no-arrows"
                              type="number"
                              name="clientpincode"
                              placeholder="Enter pincode"
                              value={values.clientpincode}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="clientpincode"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Country </label>
                            <Field
                              className=""
                              type="text"
                              name="clientcountry"
                              value="India"
                              disabled
                              // onChange={handleChange}
                            />
                          </Col>

                          <Col lg={3}>
                            <label> Zone </label>
                            <Field
                              as="select"
                              value={values.zone}
                              name="zone"
                              onChange={handleChange}
                            >
                              <option value="">--Select Zone--</option>
                              <option value="North">North</option>
                              <option value="East">East</option>
                              <option value="South">South</option>
                              <option value="West">West</option>
                            </Field>
                          </Col>

                          <Col lg={3}>
                            <label>
                              Third Party<b class="red-star"></b>
                            </label>
                            <Field
                              type="checkbox"
                              name="clientcalloption"
                              defaultChecked={false}
                              checked={values.clientcalloption}
                              onChange={(e) => {
                                setFieldValue(
                                  "clientcalloption",
                                  e.target.checked
                                );
                                handleChange(e);
                              }}
                            />
                          </Col>
                        </Row>

                        {values.clientcalloption &&
                        values.clientcalloption === true ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={7}>
                                <h5 style={{ marginTop: "5px" }}>
                                  Third Party Information
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={3}>
                                <label>
                                  {" "}
                                  Name <b class="red-star">*</b>
                                </label>
                                <Field
                                  type="text"
                                  name="clientcalloptionname"
                                  placeholder="Enter Name"
                                  value={values.clientcalloptionname}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="clientcalloptionname"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>

                              <Col lg={3}>
                                <label>
                                  Mobile
                                  <b class="red-star"></b>
                                </label>
                                <Field
                                  className="custom-input"
                                  type="number"
                                  name="clientcalloptionmobile"
                                  placeholder="Enter mobile number"
                                  value={values.clientcalloptionmobile}
                                  onChange={handleChange}
                                  required
                                />
                                <ErrorMessage
                                  name="clientcalloptionmobile"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>
                              <Col lg={3}>
                                <label>Email </label>
                                <Field
                                  // style={{ textTransform: "lowercase" }}
                                  className="custom-input"
                                  type="email"
                                  name="clientcalloptionemail"
                                  placeholder="Enter Email"
                                  value={values.clientcalloptionemail}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="clientcalloptionemail"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>

                              <Col lg={3}>
                                <label>Date</label>
                                <Field
                                  className="custom-input"
                                  type="date"
                                  name="clientcalloptiondate"
                                  placeholder="Enter date"
                                  value={values.clientcalloptiondate}
                                  onChange={handleChange}
                                  required
                                />
                                <ErrorMessage
                                  name="clientcalloptiondate"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>
                              <Col lg={3}>
                                <label>Remarks</label>
                                <Field
                                  type="text"
                                  name="clientcalloptionremark"
                                  placeholder="Enter Remarks"
                                  value={values.clientcalloptionremark}
                                ></Field>
                              </Col>
                              <Col lg={3}>
                                <label> Rate/Sq.Ft.</label>
                                <Field
                                  type="number"
                                  name="clientcalloptionratepersqfeet"
                                  placeholder="Enter Rate/Sq.Ft"
                                  value={values.clientcalloptionratepersqfeet}
                                />
                                <ErrorMessage
                                  name="clientcalloptionratepersqfeet"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>
                              <Col lg={3}>
                                <label>Brokerage</label>
                                <Field
                                  type="text"
                                  name="clientcalloptionbrokerage"
                                  placeholder="Enter Brokerage"
                                  value={values.clientcalloptionbrokerage}
                                ></Field>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      </Form>
                    )}
                    {currentSection === 2 && (
                      <Form onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                          <Col lg={8}>
                            {lead.id ? (
                              <>
                                <b>Edit Lead</b>
                                <h4>{lead.firstname + " " + lead.lastname}</h4>
                              </>
                            ) : (
                              "New Lead"
                            )}
                          </Col>
                          <Col
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                          >
                            <Button
                              className="btn-sm mx-2"
                              onClick={handleBack}
                            >
                              Back
                            </Button>{" "}
                            &nbsp;
                            <Button
                              className="btn-sm mx-2"
                              // onClick={handleSubmit}
                              onClick={() => {
                                setStepperStep(currentSection + 1);
                                handleSubmit();
                              }}
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Stepper activeStep={currentSection - 1}>
                              {steps.map((step, index) => (
                                <Step key={index}>
                                  <StepLabel
                                    onClick={() => {
                                      setStepperStep(index + 1);
                                      handleSubmit();
                                    }}
                                  >
                                    {step.title}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </Col>
                        </Row>
                        <Row lg={12} className="section-header  mx-auto">
                          <Col lg={8}>
                            <h5 style={{ marginTop: "5px" }}>
                              Lead Information
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <label>Transaction Type</label>
                            <Field
                              className="Field"
                              as="select"
                              name="transactiontype "
                              value={values.transactiontype}
                              onChange={(e) => {
                                setFieldValue(
                                  "transactiontype",
                                  e.target.value
                                );
                                setFieldValue("typeofclient", "");
                                setFieldValue("vertical", "");
                                setFieldValue("verticaltype", "");
                                setFieldValue("subverticaltype", "");
                                const temp = [];
                                if (e.target.value !== "") {
                                  if (
                                    Object.entries(
                                      data.Dependency[e.target.value]
                                    ).length > 0
                                  ) {
                                    for (const [key, value] of Object.entries(
                                      data.Dependency[e.target.value]
                                    )) {
                                      temp.push(key);
                                    }
                                    setTypeOfClient(temp);
                                  } else {
                                    setTypeOfClient(
                                      data.Value["Type of client"]
                                    );
                                  }
                                } else {
                                  setTypeOfClient([]);
                                }
                                if (e.target.value === "Lease") {
                                  setRemovePricingOrRental(
                                    values?.pricingorrental
                                      ?.filter(
                                        (price) => price?.type === "pricing"
                                      )
                                      .map((item) => item.id)
                                  );
                                } else if (e.target.value === "Sell") {
                                  setRemovePricingOrRental(
                                    values?.pricingorrental
                                      ?.filter(
                                        (price) => price?.type === "rent"
                                      )
                                      .map((item) => item.id)
                                  );
                                }
                                if (lead.id) {
                                  if (e.target.value === "Sell") {
                                    let details = lead?.pricingorrental?.filter(
                                      (e) => e.type === "pricing"
                                    );
                                    setFieldValue("pricingorrental", details);
                                    if (
                                      lead?.pricingorrental === null ||
                                      details?.length === 0
                                    ) {
                                      setFieldValue("pricingorrental", [
                                        {
                                          id: undefined,
                                          area: "",
                                          unit: "",
                                          floorno: "",
                                          rentalvalue: "",
                                          perunitcost: "",
                                          saleablearea: "",
                                          chargeblearea: "",
                                          quotedrentpermonth: "",
                                          type: "pricing",
                                        },
                                      ]);
                                    }
                                  } else {
                                    let details = lead?.pricingorrental?.filter(
                                      (e) => e.type === "rent"
                                    );
                                    setFieldValue("pricingorrental", details);
                                    if (
                                      lead?.pricingorrental === null ||
                                      details?.length === 0
                                    ) {
                                      setFieldValue("pricingorrental", [
                                        {
                                          id: undefined,
                                          area: "",
                                          unit: "",
                                          floorno: "",
                                          rentalvalue: "",
                                          perunitcost: "",
                                          saleablearea: "",
                                          chargeblearea: "",
                                          quotedrentpermonth: "",
                                          type: "rent",
                                        },
                                      ]);
                                    }
                                  }
                                } else {
                                  if (e.target.value === "Sell") {
                                    setFieldValue("pricingorrental", [
                                      {
                                        id: undefined,
                                        area: "",
                                        unit: "",
                                        floorno: "",
                                        rentalvalue: "",
                                        perunitcost: "",
                                        saleablearea: "",
                                        chargeblearea: "",
                                        quotedrentpermonth: "",
                                        type: "pricing",
                                      },
                                    ]);
                                  } else {
                                    setFieldValue("pricingorrental", [
                                      {
                                        id: undefined,
                                        area: "",
                                        unit: "",
                                        floorno: "",
                                        rentalvalue: "",
                                        perunitcost: "",
                                        saleablearea: "",
                                        chargeblearea: "",
                                        quotedrentpermonth: "",
                                        type: "rent",
                                      },
                                    ]);
                                  }
                                }
                              }}
                            >
                              <option value="">--Select--</option>
                              {transcationType &&
                                transcationType.map((value) => (
                                  <option value={value}>{value}</option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="transactiontype"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>Type Of Client</label>
                            <Field
                              className="Field"
                              disabled={!values.transactiontype}
                              as="select"
                              name="typeofclient"
                              value={values.typeofclient}
                              onChange={(e) => {
                                setFieldValue("typeofclient", e.target.value);
                                setFieldValue("vertical", "");
                                setFieldValue("verticaltype", "");
                                setFieldValue("subverticaltype", "");
                                const temp = [];
                                if (e.target.value !== "") {
                                  if (
                                    Object.entries(
                                      data.Dependency[values.transactiontype]
                                    ).length > 0
                                  ) {
                                    for (const [key, value] of Object.entries(
                                      data.Dependency[values.transactiontype][
                                        e.target.value
                                      ]
                                    )) {
                                      temp.push(key);
                                    }
                                    setVertical(temp);
                                  } else {
                                    setVertical(data.Value["Vertical"]);
                                  }
                                } else {
                                  setVertical([]);
                                }
                              }}
                            >
                              <option value="">--Select--</option>
                              {typeOfClient.length > 0 &&
                                typeOfClient.map((value) => (
                                  <option value={value}>{value}</option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="typeofclient"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>Vertical In Property</label>
                            <Field
                              className="Field"
                              disabled={!values.typeofclient}
                              as="select"
                              name="vertical"
                              value={values.vertical}
                              onChange={(e) => {
                                setFieldValue("vertical", e.target.value);
                                setFieldValue("verticaltype", "");
                                setFieldValue("subverticaltype", "");

                                if (e.target.value !== "") {
                                  if (
                                    Object.entries(
                                      data.Dependency[values.transactiontype]
                                    ).length > 0
                                  ) {
                                    const temp = [];
                                    for (const [key, value] of Object.entries(
                                      data.Dependency[values.transactiontype][
                                        values.typeofclient
                                      ][e.target.value]
                                    )) {
                                      temp.push(key);
                                    }
                                    setVerticalType(temp);
                                  } else {
                                    setVerticalType(
                                      data.Value["Vertical-type"]
                                    );
                                  }
                                } else {
                                  setVerticalType([]);
                                }
                              }}
                            >
                              <option value="">--Select--</option>
                              {verticals &&
                                verticals.map((value) => (
                                  <option value={value}>{value}</option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="vertical"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>Vertical Type</label>
                            <Field
                              disabled={!values.vertical}
                              className="Field"
                              as="select"
                              name="verticaltype"
                              value={values.verticaltype}
                              onChange={(e) => {
                                setFieldValue("verticaltype", e.target.value);
                                setFieldValue("subverticaltype", "");

                                if (e.target.value !== "") {
                                  if (
                                    Object.entries(
                                      data.Dependency[values.transactiontype]
                                    ).length > 0
                                  ) {
                                    const temp = [];
                                    for (const [key, value] of Object.entries(
                                      data.Dependency[values.transactiontype][
                                        values.typeofclient
                                      ][values.vertical][e.target.value]
                                    )) {
                                      temp.push(...value);
                                    }
                                    setSubVerticalType(temp);
                                  } else {
                                    setSubVerticalType(
                                      data.Value["Sub vertical type"]
                                    );
                                  }
                                } else {
                                  setSubVerticalType([]);
                                }
                              }}
                            >
                              <option value="">--Select--</option>
                              {verticalType &&
                                verticalType.map((value) => (
                                  <option value={value}>{value}</option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="verticaltype"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label>
                              Sub Vertical Type<b class="red-star"></b>
                            </label>
                            <Field
                              title=" Please Select Dependent"
                              disabled={!values.verticaltype}
                              className="Field"
                              as="select"
                              name="subverticaltype"
                              value={values.subverticaltype}
                              onChange={(e) =>
                                setFieldValue("subverticaltype", e.target.value)
                              }
                            >
                              <option value="">--Select--</option>
                              {values.vertical === "Cold storage" ||
                              values.vertical === "Warehouse" ||
                              values.vertical === "Factory" ? (
                                <>
                                  <option value="Ready to Move">
                                    Ready to Move
                                  </option>
                                  <option value="BTS">BTS</option>
                                  <option value="Under Construction">
                                    Under Construction
                                  </option>
                                </>
                              ) : (
                                ""
                              )}
                              {subVerticalType &&
                                subVerticalType.map((value) => (
                                  <>
                                    <option value={value}>{value}</option>
                                  </>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="subverticaltype"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          {values.vertical === "Retail" ? (
                            <Col lg={3}>
                              <label>Retail Category</label>
                              <Field
                                as="select"
                                name="retailsubvertical"
                                placeholder="Enter Retail  Sub Vertical"
                                value={values.retailsubvertical}
                                onChange={handleChange}
                              >
                                <option value="">--Select--</option>
                                <option value="Footware,Bags , Luggage & Accessories">
                                  Footware,Bags , Luggage & Accessories
                                </option>
                                <option value="Luggage & Accessories">
                                  Luggage & Accessories
                                </option>
                                <option value="Watches">Watches</option>
                                <option value="Fashion Accessories">
                                  Fashion Accessories
                                </option>
                                <option value="Kid's Fashion">
                                  Kid's Fashion
                                </option>
                                <option value="Jewellery">Jewellery</option>
                                <option value="Women's Fashion">
                                  Women's Fashion
                                </option>
                                <option value="Cafes,Restaurants & Desserts">
                                  Cafes,Restaurants & Desserts
                                </option>
                                <option value="Sportwear & Sportsgear">
                                  Sportwear & Sportsgear
                                </option>
                                <option value="Books & Toys">
                                  Books & Toys
                                </option>
                                <option value="Hypermarkets, Supermarket & Gourmet">
                                  Hypermarkets, Supermarket & Gourmet
                                </option>
                                <option value="Electronics, Gizmos & Gadgets">
                                  Electronics, Gizmos & Gadgets
                                </option>
                                <option value="Departmental stores">
                                  Departmental stores
                                </option>
                                <option value="General Fashion">
                                  General Fashion
                                </option>
                                <option value="Multiplex, Fec & Entertainment">
                                  Multiplex, Fec & Entertainment{" "}
                                </option>
                                <option value="Cosmetics, Salon, Spa & Optics">
                                  Cosmetics, Salon, Spa & Optics
                                </option>
                              </Field>
                            </Col>
                          ) : (
                            ""
                          )}

                        
                            <Col lg={3}>
                              <label>Located In</label>
                              <Field
                                type="text"
                                placeholder="Enter Location"
                                name="locatedin"
                                value={values.locatedin}
                                onChange={handleChange}
                              />
                            </Col>
                        

                          
                            <Col lg={3}>
                              <label>Availability</label>
                              <Field
                                as="select"
                                name="availability"
                                value={values.availability}
                                onChange={handleChange}
                              >
                                <option value="">--Select--</option>
                                <option value="Available">Available</option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                              </Field>
                            </Col>
                          
                          {values.availability === "Available" ? (
                            <Col lg={3}>
                              <label> Possession Date</label>
                              <Field
                                type="date"
                                name="possessiondate"
                                value={values.possessiondate}
                                onChange={handleChange}
                              />
                            </Col>
                          ) : (
                            ""
                          )}
                          {values.availability === "Not Available" ? (
                            <Col lg={3}>
                              <label>
                                Lease Expiration Date
                                {/* <b class="red-star"></b> */}
                              </label>
                              <Field
                                min={moment(new Date()).format("DD/MM/YYYY")}
                                type="date"
                                name="leaseexpirationdate"
                                value={values.leaseexpirationdate}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="leaseexpirationdate"
                                component="div"
                                className="error-message"
                              />
                            </Col>
                          ) : (
                            ""
                          )}
                          {!(
                            values.vertical === "Retail" ||
                            values.vertical === "Land" ||
                            values.vertical === "Cold storage" ||
                            values.vertical === "Warehouse" ||
                            values.vertical === "Factory"
                          ) ? (
                            <Col lg={3}>
                              <label>Furnished Status</label>
                              <Field
                                as="select"
                                name="furnishedstatus"
                                value={values.furnishedstatus}
                                onChange={handleChange}
                              >
                                {values.vertical === "Cold storage" ||
                                values.vertical === "Warehouse" ||
                                values.vertical === "Factory" ? (
                                  <>
                                    <option value="">--Select--</option>
                                    <option value="Semi Furnished">
                                      Available
                                    </option>
                                    <option value="Furnished">
                                      Not Available
                                    </option>
                                  </>
                                ) : (
                                  <>
                                    <option value="">--Select--</option>
                                    <option value="Semi Furnished">
                                      Semi Furnished
                                    </option>
                                    <option value="Furnished">Furnished</option>
                                    <option value="Unfurnished">
                                      Unfurnished
                                    </option>
                                  </>
                                )}
                              </Field>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col lg={3}>
                            <label>Other Details</label>
                            <Field
                              className="Field"
                              type="text"
                              placeholder="Enter other details"
                              name="otherdetails"
                              value={values.otherdetails}
                            ></Field>
                          </Col>
                          <Col lg={3}>
                            <label>Other Locations</label>
                            <Field
                              className="Field"
                              type="text"
                              placeholder="Enter other locations"
                              name="otherlocations"
                              value={values.otherlocations}
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Completion Date</label>
                            <Field
                              type="date"
                              name="completiondate"
                              placeholder="Enter Completion Date"
                              value={values.completiondate}
                            ></Field>
                          </Col>

                          <Col lg={3}>
                            <label>Area/Location breif</label>
                            <Field
                              as="textarea"
                              className="textarea"
                              name="areaorlocationbrief"
                              placeholder="Enter Area/Location"
                              value={values.areaorlocationbrief}
                            ></Field>
                          </Col>
                        </Row>

                        {values.vertical === "Hotels" ? (
                          <>
                            {
                              <Row lg={12} className="section-header mx-auto">
                                <Col lg={6}>
                                  <h5 style={{ marginTop: "5px" }}>
                                    Hotel Details
                                  </h5>
                                </Col>
                              </Row>
                            }
                            <Row>
                              <Col lg={3}>
                                <label>No. of Keys</label>
                                <div className="d-flex w-80">
                                  <Field
                                    type="number"
                                    name="noofkeys"
                                    placeholder="Enter No. of Keys"
                                    value={values.noofkeys}
                                    onChange={handleChange}
                                  />
                                </div>
                                {touched.noofkeys && (
                                  <div className="error-message">
                                    {errors.noofkeys}
                                  </div>
                                )}
                              </Col>
                              <Col lg={6}>
                                <label> Room size</label>
                                <div className="d-flex w-80">
                                  <Field
                                    as="select"
                                    name="unit"
                                    placeholder="Enter unit"
                                    value={values.unit}
                                    onChange={handleChange}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Ft.">Ft.</option>
                                    <option value="m">m</option>
                                  </Field>
                                  <Field
                                    className="mx-1"
                                    type="number"
                                    name="length"
                                    placeholder="Enter Length"
                                    value={values.length}
                                  />
                                  <Field
                                    className="mx-1"
                                    type="number"
                                    name="width"
                                    placeholder="Enter Width"
                                    value={values.width}
                                  />
                                </div>
                                {(touched.length || touched.length) && (
                                  <div className="error-message">
                                    {errors.closeareaunit ||
                                      errors.closeareavalue}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Architect Name</label>
                                <div className="d-flex w-80">
                                  <Field
                                    type="text"
                                    name="architectname"
                                    placeholder="Enter Architect Name"
                                    value={values.architectname}
                                    onChange={handleChange}
                                  />
                                </div>
                                {touched.architectname && (
                                  <div className="error-message">
                                    {errors.architectname}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Banquet Capacity</label>
                                <div className="d-flex w-80">
                                  <Field
                                    type="number"
                                    name="banquetcapacity"
                                    placeholder="Enter Banquet Capacity"
                                    value={values.banquetcapacity}
                                    onChange={handleChange}
                                  />
                                </div>
                                {touched.banquetcapacity && (
                                  <div className="error-message">
                                    {errors.banquetcapacity}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>No. of service Lifts</label>
                                <div className="d-flex w-80">
                                  <Field
                                    type="number"
                                    name="noofservicelifts"
                                    placeholder="Enter No. of service Lifts"
                                    value={values.noofservicelifts}
                                    onChange={handleChange}
                                  />
                                </div>
                                {touched.noofservicelifts && (
                                  <div className="error-message">
                                    {errors.noofservicelifts}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>No. Car parking</label>
                                <div className="d-flex w-80">
                                  <Field
                                    type="number"
                                    name="noofcarparking"
                                    placeholder="Enter No. Car parking"
                                    value={values.noofcarparking}
                                    onChange={handleChange}
                                  />
                                </div>
                                {touched.noofcarparking && (
                                  <div className="error-message">
                                    {errors.noofcarparking}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {values.vertical === "Warehouse" ||
                        values.vertical === "Cold storage" ||
                        values.vertical === "Factory" ||
                        values.verticaltype === "Warehousing" ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={6}>
                                <h5 style={{ marginTop: "5px" }}>
                                  Warehousing Details
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={3}>
                                <label>No. of Docks</label>
                                <Field
                                  type="number"
                                  name="noofdocksvalue"
                                  placeholder="Enter value"
                                  value={values.noofdocksvalue}
                                  onChange={handleChange}
                                />
                                {touched.noofdocksvalue && (
                                  <div className="error-message">
                                    {errors.noofdocksvalue}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Dock height in ft.</label>
                                <Field
                                  type="number"
                                  name="dockheight"
                                  placeholder="Enter value"
                                  value={values.dockheight}
                                  onChange={handleChange}
                                />
                                {touched.dockheight && (
                                  <div className="error-message">
                                    {errors.dockheight}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Dock level</label>
                                <Field
                                  as="select"
                                  name="docklevel"
                                  value={values.docklevel}
                                  onChange={handleChange}
                                >
                                  <option value="">--select--</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Field>

                                {touched.firenoc && (
                                  <div className="error-message">
                                    {errors.firenoc}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>No. of Washrooms</label>
                                <Field
                                  type="number"
                                  name="noofwashroomsvalue"
                                  placeholder="Enter value"
                                  value={values.noofwashroomsvalue}
                                />
                                {touched.noofwashroomsvalue && (
                                  <div className="error-message">
                                    {errors.noofwashroomsvalue}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Open Area</label>
                                <div className="d-flex w-80">
                                  <Field
                                    as="select"
                                    name="openareaunit"
                                    placeholder="Enter Area"
                                    value={values.openareaunit}
                                    onChange={handleChange}
                                  >
                                    <option value="">--Select--</option>
                                    {/* <option value="Gaj">Gaj</option> */}
                                    <option value="Sq.feet">Sq.feet</option>
                                    <option value="Yards">Yards</option>
                                    <option value="Sq. yards">Sq. yards</option>
                                    <option value="Sq. Meter">Sq. Meter</option>
                                  </Field>
                                  <Field
                                    className="mx-1"
                                    type="text"
                                    name="openareavalue"
                                    placeholder="Enter value"
                                    value={values.openareavalue}
                                  />
                                </div>
                                {(touched.openareaunit ||
                                  touched.openareavalue) && (
                                  <div className="error-message">
                                    {errors.openareaunit ||
                                      errors.openareavalue}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label> Covered area</label>
                                <div className="d-flex w-80">
                                  <Field
                                    as="select"
                                    name="closeareaunit"
                                    placeholder="Enter close area unit"
                                    value={values.closeareaunit}
                                    onChange={handleChange}
                                  >
                                    <option value="">--Select--</option>
                                    {/* <option value="Gaj">Gaj</option> */}
                                    <option value="Sq.feet">Sq.feet</option>
                                    <option value="Yards">Yards</option>
                                    <option value="Sq. yards">Sq. yards</option>
                                    <option value="Sq. Meter">Sq. Meter</option>
                                  </Field>
                                  <Field
                                    className="mx-1"
                                    type="text"
                                    name="closeareavalue"
                                    placeholder="Enter value"
                                    value={values.closeareavalue}
                                  />
                                </div>
                                {(touched.closeareaunit ||
                                  touched.closeareavalue) && (
                                  <div className="error-message">
                                    {errors.closeareaunit ||
                                      errors.closeareavalue}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Type of Warehouse</label>
                                <Field
                                  as="select"
                                  name="typeofwarehouse"
                                  value={values.typeofwarehouse}
                                  onChange={handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="PEB">PEB</option>
                                  <option value="RCC">RCC</option>
                                  <option value="PEB+RCC">PEB+RCC</option>
                                </Field>

                                {touched.typeofwarehouse && (
                                  <div className="error-message">
                                    {errors.typeofwarehouse}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Floor</label>
                                <Field
                                  as="select"
                                  name="floor"
                                  value={values.floor}
                                  onChange={handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Ground">Ground</option>
                                  <option value="Mezzanine">Mezzanine</option>
                                </Field>

                                {touched.floor && (
                                  <div className="error-message">
                                    {errors.floor}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Covered area offered in sq. ft</label>
                                <Field
                                  type="text"
                                  name="offeredcarpetarea"
                                  placeholder="Enter Covered area offered in sq. ft"
                                  value={values.offeredcarpetarea}
                                  onChange={handleChange}
                                />

                                {touched.offeredcarpetarea && (
                                  <div className="error-message">
                                    {errors.offeredcarpetarea}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Height (ft.) Side</label>
                                <Field
                                  type="text"
                                  name="heightside"
                                  placeholder="Enter Height (ft.) Side"
                                  value={values.heightside}
                                  onChange={handleChange}
                                />

                                {touched.heightside && (
                                  <div className="error-message">
                                    {errors.heightside}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Height (ft.) Center</label>
                                <Field
                                  type="text"
                                  name="heightcenter"
                                  placeholder="Enter Height (ft.) Center"
                                  value={values.heightcenter}
                                  onChange={handleChange}
                                />

                                {touched.heightcenter && (
                                  <div className="error-message">
                                    {errors.heightcenter}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Type of Flooring</label>
                                <Field
                                  as="select"
                                  name="typeofflooring"
                                  value={values.typeofflooring}
                                  onChange={handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="FM2">FM2</option>
                                  <option value="Trimix">Trimix</option>
                                  <option value="VDF">VDF</option>
                                  <option value="VDC">VDC</option>
                                </Field>

                                {touched.typeofflooring && (
                                  <div className="error-message">
                                    {errors.typeofflooring}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Fire Hydrants</label>
                                <Field
                                  type="text"
                                  name="firehydrants"
                                  placeholder="Enter Fire Hydrants"
                                  value={values.firehydrants}
                                  onChange={handleChange}
                                />

                                {touched.firehydrants && (
                                  <div className="error-message">
                                    {errors.firehydrants}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Fire Sprinkelers</label>
                                <Field
                                  type="text"
                                  name="firesprinkelers"
                                  placeholder="Enter Fire Sprinkelers"
                                  value={values.firesprinkelers}
                                  onChange={handleChange}
                                />

                                {touched.firesprinkelers && (
                                  <div className="error-message">
                                    {errors.firesprinkelers}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Fire NOC</label>
                                <Field
                                  as="select"
                                  name="firenoc"
                                  value={values.firenoc}
                                  onChange={handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Field>

                                {touched.firenoc && (
                                  <div className="error-message">
                                    {errors.firenoc}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Security Deposit</label>
                                <Field
                                  type="number"
                                  name="securitydeposit"
                                  placeholder="Enter Security Deposit"
                                  value={values.securitydeposit}
                                  onChange={handleChange}
                                />

                                {touched.securitydeposit && (
                                  <div className="error-message">
                                    {errors.securitydeposit}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Advance </label>
                                <Field
                                  type="number"
                                  name="advance"
                                  value={values.advance}
                                  placeholder="Enter advance"
                                  onChange={handleChange}
                                />

                                {touched.advance && (
                                  <div className="error-message">
                                    {errors.advance}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Common Area Maintenance (CAM)</label>
                                <Field
                                  type="text"
                                  name="commonareamaintaince"
                                  placeholder="Enter Common Area Maintenance"
                                  value={values.commonareamaintaince}
                                  onChange={handleChange}
                                />

                                {touched.commonareamaintaince && (
                                  <div className="error-message">
                                    {errors.commonareamaintaince}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Possession Status</label>
                                <Field
                                  as="select"
                                  name="possessionstatus"
                                  value={values.possessionstatus}
                                  onChange={handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Available">Available</option>
                                  <option value="Not Available">
                                    Not Available
                                  </option>
                                </Field>
                              </Col>
                              <Col lg={3}>
                                <label>Additional Information</label>
                                <Field
                                  type="text"
                                  name="addtionalinformation"
                                  placeholder="Additional Information"
                                  value={values.addtionalinformation}
                                  onChange={handleChange}
                                />

                                {touched.addtionalinformation && (
                                  <div className="error-message">
                                    {errors.addtionalinformation}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Road width in ft.</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Road width in ft.                          "
                                  name="roadwidth"
                                  value={values.roadwidth}
                                  onChange={handleChange}
                                />

                                {touched.roadwidth && (
                                  <div className="error-message">
                                    {errors.roadwidth}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Labour Room</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Labour Room"
                                  name="labourroom"
                                  value={values.labourroom}
                                  onChange={handleChange}
                                />

                                {touched.labourroom && (
                                  <div className="error-message">
                                    {errors.labourroom}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Guard Room</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Guard Room"
                                  name="guardroom"
                                  value={values.guardroom}
                                  onChange={handleChange}
                                />

                                {touched.guardroom && (
                                  <div className="error-message">
                                    {errors.guardroom}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Power Connection (KV)</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Power Connection (KV)"
                                  name="powerconnection"
                                  value={values.powerconnection}
                                  onChange={handleChange}
                                />

                                {touched.powerconnection && (
                                  <div className="error-message">
                                    {errors.powerconnection}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Water connection (Drop Down)</label>
                                <Field
                                  as="select"
                                  placeholder="Enter Power Connection (KV)"
                                  name="waterconnection"
                                  value={values.waterconnection}
                                  onChange={handleChange}
                                >
                                  <option value=""> Select</option>
                                  <option value="Boring"> Boring</option>
                                  <option value="Supply"> Supply</option>
                                </Field>

                                {touched.waterconnection && (
                                  <div className="error-message">
                                    {errors.waterconnection}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>
                                  Flooring Loading Capacity ( in tons)
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter Flooring Loading Capacity ( in tons)"
                                  name="flooringloadingcapacity"
                                  value={values.flooringloadingcapacity}
                                  onChange={handleChange}
                                />

                                {touched.flooringloadingcapacity && (
                                  <div className="error-message">
                                    {errors.flooringloadingcapacity}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Near-by Warehouses</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Near-by Warehouses"
                                  name="nearbywarehouses"
                                  value={values.nearbywarehouses}
                                  onChange={handleChange}
                                />

                                {touched.nearbywarehouses && (
                                  <div className="error-message">
                                    {errors.nearbywarehouses}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                        <Row></Row>

                        {values.vertical === "Office" ||
                        values.vertical === "Retail" ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={6}>
                                {values.vertical === "Office" ? (
                                  <h5 style={{ marginTop: "5px" }}>
                                    Office Details
                                  </h5>
                                ) : (
                                  <h5 style={{ marginTop: "5px" }}>
                                    Retail Details
                                  </h5>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              {values.vertical === "Retail" ? (
                                <>
                                  <Col lg={3}>
                                    <label>Frontage In (ft)</label>
                                    <Field
                                      type="text"
                                      name="frontagearea"
                                      placeholder="EnterFrontage (ft) "
                                      value={values.frontagearea}
                                    />
                                    <ErrorMessage
                                      name="frontagearea"
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>
                                  <Col lg={3}>
                                    <label> Neighbourhood Brands</label>
                                    <Field
                                      type="text"
                                      name="marketbrand"
                                      placeholder="Enter Nearby Market / Brands Info "
                                      value={values.marketbrand}
                                    />
                                    <ErrorMessage
                                      name="marketbrand"
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}

                              <Col lg={3}>
                                <label>Proposed Lock in period</label>
                                <Field
                                  type="text"
                                  name="proposedlockperiod"
                                  placeholder="Enter Proposed Lock in period"
                                  value={values.proposedlockperiod}
                                  onChange={handleChange}
                                />

                                {touched.proposedlockperiod && (
                                  <div className="error-message">
                                    {errors.proposedlockperiod}
                                  </div>
                                )}
                              </Col>

                              <Col lg={3}>
                                <label>Tenure</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Tenure"
                                  name="tenure"
                                  value={values.tenure}
                                  onChange={handleChange}
                                />

                                {touched.tenure && (
                                  <div className="error-message">
                                    {errors.tenure}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Maintenance Charges </label>
                                <Field
                                  type="text"
                                  placeholder="Enter Maintenance Charges"
                                  name="maintainancecharges"
                                  value={values.maintainancecharges}
                                  onChange={handleChange}
                                />

                                {touched.maintainancecharges && (
                                  <div className="error-message">
                                    {errors.maintainancecharges}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Proposed Lease Term</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Proposed Lease Term"
                                  name="proposedleaseterm"
                                  value={values.proposedleaseterm}
                                  onChange={handleChange}
                                />

                                {touched.proposedleaseterm && (
                                  <div className="error-message">
                                    {errors.proposedleaseterm}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Rent Escalation in Percentage</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Rent Escalation"
                                  name="rentescalation"
                                  value={values.rentescalation}
                                  onChange={handleChange}
                                />

                                {touched.rentescalation && (
                                  <div className="error-message">
                                    {errors.rentescalation}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Power and Power back up</label>
                                <Field
                                  type="text"
                                  placeholder="Enter Power and Power back up"
                                  name="powerbackup"
                                  value={values.powerbackup}
                                  onChange={handleChange}
                                />

                                {touched.powerbackup && (
                                  <div className="error-message">
                                    {errors.powerbackup}
                                  </div>
                                )}
                              </Col>
                              <Col lg={3}>
                                <label>Other Tenants</label>
                                <Field
                                  type="text"
                                  name="othertenants"
                                  placeholder="Enter Other Tenants"
                                  value={values.othertenants}
                                />
                                <ErrorMessage
                                  name="othertenants"
                                  component="div"
                                  className="error-message"
                                />
                              </Col>
                              <Col lg={3}>
                                <label>Remarks</label>
                                <Field
                                  as="textarea"
                                  className="textarea"
                                  name="remark"
                                  placeholder="Enter Remarks"
                                  value={values.remark}
                                ></Field>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        <Row>
                          {values.transactiontype === "Sell" &&
                          (values.vertical === "Office" ||
                            values.vertical === "Retail" ||
                            values.vertical === "Land") ? (
                            <>
                              <Row lg={12} className="section-header mx-auto">
                                <Col lg={8}>
                                  <h5 style={{ marginTop: "5px" }}>
                                    Pricing Details
                                  </h5>
                                </Col>
                              </Row>
                              <FieldArray name="pricingorrental">
                                {({ push, remove }) => (
                                  <div>
                                    {values.pricingorrental?.map(
                                      (record, index) => (
                                        <Row key={index}>
                                          <Col lg={1}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Select <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name={`pricingorrental[${index}].area`}
                                              value={
                                                values.pricingorrental[index]
                                                  .area
                                              }
                                            >
                                              {values.vertical ===
                                                "Cold storage" ||
                                              values.vertical === "Warehouse" ||
                                              values.vertical === "Factory" ? (
                                                <>
                                                  <option value="">
                                                    select
                                                  </option>
                                                  <option value="Plot Size">
                                                    Plot Size
                                                  </option>
                                                  <option value="Covered Area">
                                                    Covered Area
                                                  </option>
                                                </>
                                              ) : (
                                                <>
                                                  <option value="">
                                                    select
                                                  </option>
                                                  <option value="Carpet">
                                                    Carpet
                                                  </option>
                                                  <option value="BUA">
                                                    BUA
                                                  </option>
                                                  <option value="SBUA">
                                                    SBUA
                                                  </option>
                                                </>
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].area`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Unit <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name={`pricingorrental[${index}].unit`}
                                              value={
                                                values.pricingorrental[index]
                                                  .unit
                                              }
                                            >
                                              <option value="">
                                                --Select--
                                              </option>
                                              <option value="Sq.m">Sq.m</option>
                                              <option value="Sq.ft">
                                                Sq.ft
                                              </option>
                                              <option value="Acre">Acre</option>
                                              <option value="Gaj">Gaj</option>
                                              <option value="Sq.yards">
                                                Sq.yards
                                              </option>
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].unit`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Floor No. <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              placeholder="Enter Flooor"
                                              name={`pricingorrental[${index}].floorno`}
                                              value={
                                                values.pricingorrental[index]
                                                  .floorno
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                --select--
                                              </option>
                                              {floornumber &&
                                                floornumber.map((e) => (
                                                  <option value={e.value}>
                                                    {e.label}
                                                  </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].floorno`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Per unit cost
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              min="0"
                                              type="number"
                                              placeholder="Enter Per unit cost"
                                              name={`pricingorrental[${index}].perunitcost`}
                                              value={
                                                values.pricingorrental[index]
                                                  .perunitcost
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `pricingorrental[${index}].quotedcost`,
                                                  e.target.value *
                                                    values.pricingorrental[
                                                      index
                                                    ].saleablearea
                                                );
                                                setFieldValue(
                                                  `pricingorrental[${index}].perunitcost`,
                                                  e.target.value
                                                );
                                                //handleChange(e)
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].perunitcost`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Saleable Area{" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              type="number"
                                              min="0"
                                              placeholder="Enter Saleable Area"
                                              name={`pricingorrental[${index}].saleablearea`}
                                              value={
                                                values.pricingorrental[index]
                                                  .saleablearea
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue(
                                                  `pricingorrental[${index}].quotedcost`,
                                                  e.target.value *
                                                    values.pricingorrental[
                                                      index
                                                    ].perunitcost
                                                );
                                                setFieldValue(
                                                  `pricingorrental[${index}].saleablearea`,
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].saleablearea`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Quoted Cost (Rs.)
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              disabled
                                              className="Field"
                                              required
                                              min="0"
                                              type="number"
                                              placeholder="Enter Quoted rent per Month (Rs.)"
                                              name={`pricingorrental[${index}].quotedcost`}
                                              value={
                                                values.pricingorrental[index]
                                                  .quotedcost
                                              }
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].quotedcost`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>

                                          <Col lg={1} className="mt-2">
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Action
                                            </label>
                                            <Button
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  values.pricingorrental[index]
                                                    .id !== undefined
                                                ) {
                                                  const id =
                                                    values.pricingorrental[
                                                      index
                                                    ].id;
                                                  setRemovePricingOrRental(
                                                    (ids) => [...ids, id]
                                                  );
                                                }
                                                remove(index);
                                                if (
                                                  values.pricingorrental
                                                    .length === 1 &&
                                                  index === 0
                                                ) {
                                                  push({
                                                    id: undefined,
                                                    area: "",
                                                    unit: "",
                                                    floorno: "",
                                                    rentalvalue: "",
                                                    perunitcost: "",
                                                    saleablearea: "",
                                                    chargeblearea: "",
                                                    quotedrentpermonth: "",
                                                    type: "pricing",
                                                  });
                                                }
                                              }}
                                            >
                                              {" "}
                                              X{" "}
                                            </Button>{" "}
                                            &nbsp;
                                            {index !==
                                            values.pricingorrental.length -
                                              1 ? (
                                              ""
                                            ) : (
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  push({
                                                    id: undefined,
                                                    area: "",
                                                    unit: "",
                                                    floorno: "",
                                                    rentalvalue: "",
                                                    perunitcost: "",
                                                    saleablearea: "",
                                                    chargeblearea: "",
                                                    quotedrentpermonth: "",
                                                    type: "pricing",
                                                  })
                                                }
                                              >
                                                {" "}
                                                +{" "}
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            </>
                          ) : values.transactiontype === "Lease" &&
                            (values.vertical === "Office" ||
                              values.vertical === "Retail" ||
                              values.vertical === "Land" ||
                              values.vertical === "Cold storage" ||
                              values.vertical === "Warehouse" ||
                              values.vertical === "Factory") ? (
                            <>
                              <Row lg={12} className="section-header mx-auto">
                                <Col lg={8}>
                                  <h5 style={{ marginTop: "5px" }}>
                                    Rental Details
                                  </h5>
                                </Col>
                              </Row>
                              <FieldArray name="pricingorrental">
                                {({ push, remove }) => (
                                  <div>
                                    {values.pricingorrental?.map(
                                      (record, index) => (
                                        <Row key={index}>
                                          <Col lg={1}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Select <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name={`pricingorrental[${index}].area`}
                                              value={
                                                values.pricingorrental[index]
                                                  .area
                                              }
                                            >
                                              {values.vertical ===
                                                "Cold storage" ||
                                              values.vertical === "Warehouse" ||
                                              values.vertical === "Factory" ? (
                                                <>
                                                  <option value="">
                                                    select
                                                  </option>
                                                  <option value="Plot Size">
                                                    Plot Size
                                                  </option>
                                                  <option value="Covered Area">
                                                    Covered Area
                                                  </option>
                                                </>
                                              ) : (
                                                <>
                                                  <option value="">
                                                    select
                                                  </option>
                                                  <option value="Carpet">
                                                    Carpet
                                                  </option>
                                                  <option value="BUA">
                                                    BUA
                                                  </option>
                                                  <option value="SBUA">
                                                    SBUA
                                                  </option>
                                                </>
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].area`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Unit <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name={`pricingorrental[${index}].unit`}
                                              value={
                                                values.pricingorrental[index]
                                                  .unit
                                              }
                                            >
                                              <option value="">
                                                --Select--
                                              </option>
                                              <option value="Sq.m">Sq.m</option>
                                              <option value="Sq.ft">
                                                Sq.ft
                                              </option>
                                              <option value="Acre">Acre</option>
                                              {/* <option value="Gaj">Gaj</option> */}
                                              <option value="Sq.yards">
                                                Sq.yards
                                              </option>
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].unit`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Floor No. <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              placeholder="Enter Flooor"
                                              name={`pricingorrental[${index}].floorno`}
                                              value={
                                                values.pricingorrental[index]
                                                  .floorno
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                --select--
                                              </option>
                                              {floornumber &&
                                                floornumber.map((e) => (
                                                  <option value={e.value}>
                                                    {e.label}
                                                  </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].floorno`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Rental Value{" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              min="0"
                                              type="number"
                                              placeholder="Enter value"
                                              name={`pricingorrental[${index}].rentalvalue`}
                                              value={
                                                values.pricingorrental[index]
                                                  .rentalvalue
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `pricingorrental[${index}].quotedrentpermonth`,
                                                  e.target.value *
                                                    values.pricingorrental[
                                                      index
                                                    ].chargeblearea
                                                );
                                                setFieldValue(
                                                  `pricingorrental[${index}].rentalvalue`,
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].rentalvalue`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Chargeable Area{" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              min="0"
                                              type="number"
                                              placeholder="Enter Chargeble Area"
                                              name={`pricingorrental[${index}].chargeblearea`}
                                              value={
                                                values.pricingorrental[index]
                                                  .chargeblearea
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue(
                                                  `pricingorrental[${index}].quotedrentpermonth`,
                                                  e.target.value *
                                                    values.pricingorrental[
                                                      index
                                                    ].rentalvalue
                                                );
                                                setFieldValue(
                                                  `pricingorrental[${index}].chargeblearea`,
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].chargeblearea`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Quoted rent per Month (Rs.){" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              disabled
                                              className="Field"
                                              required
                                              type="number"
                                              placeholder="Enter Quoted rent per Month (Rs.)"
                                              name={`pricingorrental[${index}].quotedrentpermonth`}
                                              value={
                                                values.pricingorrental[index]
                                                  .quotedrentpermonth
                                              }
                                            />
                                            <ErrorMessage
                                              name={`pricingorrental[${index}].quotedrentpermonth`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </Col>

                                          <Col lg={1} className="mt-2">
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Action
                                            </label>
                                            <Button
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  values.pricingorrental[index]
                                                    .id !== undefined
                                                ) {
                                                  const id =
                                                    values.pricingorrental[
                                                      index
                                                    ].id;
                                                  setRemovePricingOrRental(
                                                    (ids) => [...ids, id]
                                                  );
                                                }
                                                remove(index);
                                                if (
                                                  values.pricingorrental
                                                    .length === 1 &&
                                                  index === 0
                                                ) {
                                                  push({
                                                    id: undefined,
                                                    area: "",
                                                    unit: "",
                                                    floorno: "",
                                                    rentalvalue: "",
                                                    perunitcost: "",
                                                    saleablearea: "",
                                                    chargeblearea: "",
                                                    quotedrentpermonth: "",
                                                    type: "rent",
                                                  });
                                                }
                                              }}
                                            >
                                              {" "}
                                              X{" "}
                                            </Button>{" "}
                                            &nbsp;
                                            {index !==
                                            values.pricingorrental.length -
                                              1 ? (
                                              ""
                                            ) : (
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  push({
                                                    // floor: "",
                                                    area: "",
                                                    unit: "",
                                                    floorno: "",
                                                    rentalvalue: "",
                                                    perunitcost: "",
                                                    saleablearea: "",
                                                    chargeblearea: "",
                                                    quotedrentpermonth: "",
                                                    type: "rent",
                                                  })
                                                }
                                              >
                                                {" "}
                                                +{" "}
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            </>
                          ) : null}
                        </Row>

                        {!(
                          values.vertical === "Warehouse" ||
                          values.vertical === "Cold storage" ||
                          values.vertical === "Factory"
                        ) ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={8}>
                                <h5 style={{ marginTop: "5px" }}>
                                  Area Details
                                </h5>
                              </Col>
                            </Row>
                            <FieldArray name="areadetails">
                              {({ push, remove }) => (
                                <div>
                                  {values.areadetails?.map((record, index) => (
                                    <Row key={index}>
                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Area Type <b class="red-star"></b>
                                        </label>
                                        <Field
                                          className="Field"
                                          required
                                          as="select"
                                          name={`areadetails[${index}].area`}
                                          placeholder="Enter Area Type"
                                          value={values.areadetails[index].area}
                                        >
                                          {values.vertical === "Cold storage" ||
                                          values.vertical === "Warehouse" ||
                                          values.vertical === "Factory" ? (
                                            <>
                                              <option value="">
                                                {" "}
                                                --select--
                                              </option>
                                              <option value="Plot Size">
                                                Plot Size
                                              </option>
                                              <option value="Covered Area">
                                                Covered Area
                                              </option>
                                            </>
                                          ) : (
                                            <>
                                              <option value="">
                                                {" "}
                                                --select--
                                              </option>
                                              <option value="Carpet">
                                                Carpet
                                              </option>
                                              <option value="BUA">BUA</option>
                                              <option value="SBUA">SBUA</option>
                                            </>
                                          )}
                                        </Field>

                                        {errors?.areadetails?.length > 0
                                          ? (errors.areadetails?.at(index)
                                              ?.floor ||
                                              errors.areadetails?.at(index)
                                                ?.unit ||
                                              errors.areadetails?.at(index)
                                                ?.value) &&
                                            !errors.areadetails?.at(index)
                                              ?.area && (
                                              <div className="error-message">
                                                Please enter all values
                                              </div>
                                            )
                                          : ""}
                                      </Col>
                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Floor No. <b class="red-star"></b>
                                        </label>
                                        <Field
                                          className="Field"
                                          required
                                          as="select"
                                          name={`areadetails[${index}].floor`}
                                          placeholder="Enter Flooor"
                                          value={
                                            values.areadetails[index].floor
                                          }
                                        >
                                          <option value=""> --select--</option>
                                          {floornumber &&
                                            floornumber.map((e) => (
                                              <option value={e.value}>
                                                {e.label}
                                              </option>
                                            ))}
                                        </Field>
                                        {errors?.areadetails?.length > 0
                                          ? (errors.areadetails?.at(index)
                                              ?.value ||
                                              errors.areadetails?.at(index)
                                                ?.unit ||
                                              errors.areadetails?.at(index)
                                                ?.area) &&
                                            !errors.areadetails?.at(index)
                                              ?.floor && (
                                              <div className="error-message">
                                                Please enter all values
                                              </div>
                                            )
                                          : ""}
                                      </Col>

                                      <Col lg={2}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Unit <b class="red-star"></b>
                                        </label>
                                        <Field
                                          className="Field"
                                          required
                                          as="select"
                                          name={`areadetails[${index}].unit`}
                                        >
                                          <option value="">--Select--</option>
                                          <option value="Sq.m">Sq.m</option>
                                          <option value="Sq.ft">Sq.ft</option>
                                          <option value="Acre">Acre</option>
                                          {/* {values.vertical !== "Retail" && (
                                            <option value="Gaj">Gaj</option>
                                          )} */}
                                          <option value="Sq.yards">
                                            Sq.yards
                                          </option>
                                        </Field>
                                        {errors?.areadetails?.length > 0
                                          ? (errors.areadetails?.at(index)
                                              ?.floor ||
                                              errors.areadetails?.at(index)
                                                ?.value ||
                                              errors.areadetails?.at(index)
                                                ?.area) &&
                                            !errors.areadetails?.at(index)
                                              ?.unit && (
                                              <div className="error-message">
                                                Please enter all values
                                              </div>
                                            )
                                          : ""}
                                      </Col>

                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Value <b class="red-star"></b>
                                        </label>
                                        <Field
                                          className="Field"
                                          required
                                          type="text"
                                          name={`areadetails[${index}].value`}
                                          placeholder="Enter value"
                                          value={
                                            values.areadetails[index].value
                                          }
                                        />
                                        {errors?.areadetails?.length > 0
                                          ? (errors.areadetails?.at(index)
                                              ?.floor ||
                                              errors.areadetails?.at(index)
                                                ?.unit ||
                                              errors.areadetails?.at(index)
                                                ?.area) &&
                                            !errors.areadetails?.at(index)
                                              ?.value && (
                                              <div className="error-message">
                                                Please enter all values
                                              </div>
                                            )
                                          : ""}
                                      </Col>

                                      <Col lg={1} className="mt-2">
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Action
                                        </label>
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            if (
                                              values.areadetails[index].id !==
                                              undefined
                                            ) {
                                              const id =
                                                values.areadetails[index].id;
                                              setRemoveIndex((ids) => [
                                                ...ids,
                                                id,
                                              ]);
                                            }
                                            remove(index);
                                            if (
                                              values.areadetails.length === 1 &&
                                              index === 0
                                            ) {
                                              push({
                                                floor: "",
                                                unit: "",
                                                value: "",
                                                type: "height",
                                              });
                                            }
                                          }}
                                        >
                                          {" "}
                                          X{" "}
                                        </Button>{" "}
                                        &nbsp;
                                        {index !==
                                        values.areadetails.length - 1 ? (
                                          ""
                                        ) : (
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              push({
                                                area: "",
                                                floor: "",
                                                unit: "",
                                                value: "",
                                                type: "area",
                                              })
                                            }
                                          >
                                            {" "}
                                            +{" "}
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              )}
                            </FieldArray>
                          </>
                        ) : (
                          ""
                        )}

                        {!(
                          values.vertical === "Warehouse" ||
                          values.vertical === "Cold storage" ||
                          values.vertical === "Factory" ||
                          values.vertical === "Land"
                        ) ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={8}>
                                <h5 style={{ marginTop: "5px" }}>
                                  Height Details
                                </h5>
                              </Col>
                            </Row>
                            <FieldArray name="heightdetails">
                              {({ push, remove }) => (
                                <div>
                                  {values.heightdetails?.map(
                                    (record, index) => (
                                      <Row key={index}>
                                        {values.vertical === "Cold storage" ||
                                        values.vertical === "Warehouse" ||
                                        values.vertical === "Factory" ? (
                                          <Col lg={3}>
                                            <label>Height Type</label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name=""
                                            >
                                              <option>--Select--</option>
                                              <option value="Center Height">
                                                Center Height
                                              </option>
                                              <option value="Side Height">
                                                Side Height
                                              </option>
                                            </Field>
                                          </Col>
                                        ) : (
                                          <Col lg={3}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Floor No. <b class="red-star"></b>
                                            </label>
                                            <Field
                                              className="Field"
                                              required
                                              as="select"
                                              name={`heightdetails[${index}].floor`}
                                              placeholder="Enter Flooor"
                                              value={
                                                values.heightdetails[index]
                                                  .floor
                                              }
                                            >
                                              <option value="">
                                                {" "}
                                                --select--
                                              </option>
                                              {floornumber &&
                                                floornumber.map((e) => (
                                                  <option value={e.value}>
                                                    {e.label}
                                                  </option>
                                                ))}
                                            </Field>
                                            {errors?.heightdetails?.length > 0
                                              ? (errors.heightdetails?.at(index)
                                                  ?.unit ||
                                                  errors.heightdetails?.at(
                                                    index
                                                  )?.value) &&
                                                !errors.heightdetails?.at(index)
                                                  ?.floor && (
                                                  <div className="error-message">
                                                    Please enter all values
                                                  </div>
                                                )
                                              : ""}
                                          </Col>
                                        )}

                                        <Col lg={3}>
                                          <label
                                            className="form-view-label"
                                            htmlFor="formBasicName"
                                          >
                                            Unit <b class="red-star"></b>
                                          </label>
                                          <Field
                                            className="Field"
                                            required
                                            as="select"
                                            name={`heightdetails[${index}].unit`}
                                            value={
                                              values.heightdetails[index].unit
                                            }
                                          >
                                            <option value="">--Select--</option>
                                            <option value="ft">ft</option>
                                            <option value="inch">inch</option>
                                            <option value="m">m</option>
                                            <option value="Cm">Cm</option>
                                          </Field>
                                          {errors?.heightdetails?.length > 0
                                            ? (errors.heightdetails?.at(index)
                                                ?.floor ||
                                                errors.heightdetails?.at(index)
                                                  ?.value) &&
                                              !errors.heightdetails?.at(index)
                                                ?.unit && (
                                                <div className="error-message">
                                                  Please enter all values
                                                </div>
                                              )
                                            : ""}
                                        </Col>

                                        <Col lg={3}>
                                          <label
                                            className="form-view-label"
                                            htmlFor="formBasicName"
                                          >
                                            Height Value{" "}
                                            <b class="red-star"></b>
                                          </label>
                                          <Field
                                            className="Field"
                                            required
                                            type="text"
                                            name={`heightdetails[${index}].value`}
                                            placeholder="Enter value"
                                            value={
                                              values.heightdetails[index].value
                                            }
                                          />
                                          {errors?.heightdetails?.length > 0
                                            ? (errors.heightdetails?.at(index)
                                                ?.floor ||
                                                errors.heightdetails?.at(index)
                                                  ?.unit) &&
                                              !errors.heightdetails?.at(index)
                                                ?.value && (
                                                <div className="error-message">
                                                  Please enter all values
                                                </div>
                                              )
                                            : ""}
                                        </Col>

                                        <Col lg={3} className="mt-2">
                                          <label
                                            className="form-view-label"
                                            htmlFor="formBasicName"
                                          >
                                            Action
                                          </label>
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              if (
                                                values.heightdetails[index]
                                                  .id !== undefined
                                              ) {
                                                const id =
                                                  values.heightdetails[index]
                                                    .id;
                                                setRemoveIndex((ids) => [
                                                  ...ids,
                                                  id,
                                                ]);
                                              }
                                              remove(index);
                                              if (
                                                values.heightdetails.length ===
                                                  1 &&
                                                index === 0
                                              ) {
                                                push({
                                                  area: "",
                                                  floor: "",
                                                  unit: "",
                                                  value: "",
                                                  type: "height",
                                                });
                                              }
                                            }}
                                          >
                                            {" "}
                                            X{" "}
                                          </Button>{" "}
                                          &nbsp;
                                          {index !==
                                          values.heightdetails.length - 1 ? (
                                            ""
                                          ) : (
                                            <Button
                                              type="button"
                                              onClick={() =>
                                                push({
                                                  floor: "",
                                                  unit: "",
                                                  value: "",
                                                  type: "height",
                                                })
                                              }
                                            >
                                              {" "}
                                              +{" "}
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                              )}
                            </FieldArray>
                          </>
                        ) : (
                          ""
                        )}
                        {values.vertical === "Land" ? (
                          <>
                            <Row lg={12} className="section-header mx-auto">
                              <Col lg={8}>
                                <h5 style={{ marginTop: "5px" }}>Plot Size </h5>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={3}>
                                <label
                                  className="form-view-label"
                                  htmlFor="formBasicName"
                                >
                                  Unit <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name="plotunit"
                                  value={values.plotunit}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ft">ft</option>
                                  <option value="m">m</option>
                                </Field>
                              </Col>

                              <Col lg={3}>
                                <label
                                  className="form-view-label"
                                  htmlFor="formBasicName"
                                >
                                  Length <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name="plotlength"
                                  placeholder="Enter Length"
                                  value={values.plotlength}
                                />
                              </Col>
                              <Col lg={3}>
                                <label
                                  className="form-view-label"
                                  htmlFor="formBasicName"
                                >
                                  Width <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name="plotwidth"
                                  placeholder="Enter Width"
                                  value={values.plotwidth}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      </Form>
                    )}

                    {currentSection === 3 && (
                      <Form disabled="disabled" onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                          <Col lg={8}>
                            {lead.id ? (
                              <>
                                <b>Edit Lead</b>
                                <h4>{lead.firstname + " " + lead.lastname}</h4>
                              </>
                            ) : (
                              "New Lead"
                            )}
                          </Col>
                          <Col
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                          >
                            <Button
                              className="btn-sm mx-2"
                              onClick={handleBack}
                            >
                              Back
                            </Button>{" "}
                            &nbsp;
                            <Button
                              className="btn-sm mx-2"
                              variant="success"
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                            &nbsp;
                            <Button
                              className="btn-sm mx-2"
                              variant="danger"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Stepper activeStep={currentSection - 1}>
                              {steps.map((step, index) => (
                                <Step key={index}>
                                  <StepLabel
                                    onClick={() => setCurrentSection(index + 1)}
                                  >
                                    {step.title}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </Col>
                        </Row>
                        <fieldset
                          className="Field"
                          disabled="disabled"
                          title="You cannot make changes here. Please go back and make the necessary modifications."
                        >
                          <Row lg={12} className="section-header  mx-auto">
                            <Col lg={7}>
                              <h5 style={{ marginTop: "5px" }}>
                                Client Information
                              </h5>
                            </Col>
                          </Row>

                          <Row>
                            {values.salutation && values.salutation !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Salutation<b class="red-star"></b>
                                </label>
                                <Field
                                  type="text"
                                  name="salutation"
                                  value={values.salutation}
                                  onChange={handleChange}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.firstname && values.firstname !== "" ? (
                              <Col lg={3}>
                                <label>
                                  First Name <b class="red-star">*</b>
                                </label>
                                <Field
                                  type="text"
                                  name="firstname"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                  value={values.firstname}
                                  onChange={handleChange}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.lastname && values.lastname !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Last Name<b class="red-star">*</b>
                                </label>
                                <Field
                                  type="text"
                                  name="lastname"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                  value={values.lastname}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.designation && values.designation !== "" ? (
                              <Col lg={3}>
                                <label>Designation</label>
                                <Field
                                  type="text"
                                  name="designation"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                  value={values.designation}
                                  onChange={handleChange}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.phone && values.phone !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Phone
                                  <b class="red-star"></b>
                                </label>
                                <Field
                                  type="text"
                                  className="custom-input"
                                  name="phone"
                                  value={values.phone}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.email && values.email !== "" ? (
                              <Col lg={3}>
                                <label>Email </label>
                                <Field
                                  type="text"
                                  name="email"
                                  value={values.email}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.leadsource && values.leadsource !== "" ? (
                              <Col lg={3}>
                                <label>Lead Source</label>
                                <Field
                                  type="text"
                                  name="leadsource"
                                  value={values.leadsource}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.leadstage && values.leadstage !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Stage<b class="red-star"></b>
                                </label>
                                <Field
                                  type="text"
                                  name="leadstage"
                                  value={values.leadstage}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.alternatephone &&
                            values.alternatephone !== "" ? (
                              <Col lg={3}>
                                <label>Alternate Phone</label>
                                <Field
                                  type="text"
                                  name="alternatephone"
                                  value={values.alternatephone}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.clienttype && values.clienttype !== "" ? (
                              <Col lg={3}>
                                <label> Type </label>
                                <Field
                                  type="text"
                                  value={values.clienttype}
                                  name="clienttype"
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.company && values.company !== "" ? (
                              <Col lg={3}>
                                <label>Company</label>
                                <Field
                                  type="text"
                                  name="company"
                                  value={values.company}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.office && values.office !== "" ? (
                              <Col lg={3}>
                                <label>Office Address </label>
                                <Field
                                  type="text"
                                  name="office"
                                  value={values.office}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.ownerid && values.ownerid !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Assign Staff
                                  <b class="red-star"> *</b>
                                </label>
                                <Field
                                  as="select"
                                  name="ownerid"
                                  value={values.ownerid}
                                  defaultValue={defaultOwner}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    --Select Assign Staff--
                                  </option>
                                  {ownerList &&
                                    ownerList.map((ele) => (
                                      <option value={ele.id}>
                                        {ele.username}
                                      </option>
                                    ))}
                                </Field>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>

                          <Row lg={12} className="section-header mx-auto ">
                            <Col lg={7}>
                              <h5 style={{ marginTop: "5px" }}>
                                Address Information
                              </h5>
                            </Col>
                          </Row>

                          <Row>
                            {values.clientstate && values.clientstate !== "" ? (
                              <Col lg={3}>
                                <label>State</label>
                                <Field
                                  type="text"
                                  name="clientstate"
                                  value={values.clientstate}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.clientcity && values.clientcity !== "" ? (
                              <Col lg={3}>
                                <label>City</label>
                                <Field
                                  type="text"
                                  name="clientcity"
                                  value={values.clientcity}
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.clientstreet &&
                            values.clientstreet !== "" ? (
                              <Col lg={3}>
                                <label>Street</label>
                                <Field
                                  type="text"
                                  name="clientstreet"
                                  value={values.clientstreet}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {values.clientpincode &&
                            values.clientpincode !== "" ? (
                              <Col lg={3}>
                                <label> Pincode </label>
                                <Field
                                  type="text"
                                  name="clientpincode"
                                  value={values.clientpincode}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.clientcountry &&
                            values.clientcountry !== "" ? (
                              <Col lg={3}>
                                <label>Country </label>
                                <Field
                                  type="text"
                                  name="clientcountry"
                                  value={values.clientcountry}
                                />
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.zone && values.zone !== "" ? (
                              <Col lg={3}>
                                <label> Zone </label>
                                <Field
                                  type="text"
                                  value={values.zone}
                                  name="zone"
                                ></Field>
                              </Col>
                            ) : (
                              ""
                            )}

                            {values.clientcalloption &&
                            values.clientcalloption !== "" ? (
                              <Col lg={3}>
                                <label>
                                  Third Party Call
                                  <b class="red-star"></b>
                                </label>
                                <Field
                                  type="checkbox"
                                  name="clientcalloption"
                                  checked={values.clientcalloption}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>

                          <Row>
                            {values.clientcalloption &&
                            values.clientcalloption === true ? (
                              <>
                                {values.clientcalloptionname !== "" ||
                                values.clientcalloptiondate !== "" ||
                                values.clientcalloptionmobile ||
                                values.clientcalloptionemail !== "" ||
                                values.clientcalloptionremark !== "" ||
                                values.clientcalloptionratepersqfeet ||
                                values.thirdparty !== "" ||
                                values.clientcalloptionbrokerage !== "" ? (
                                  <Row
                                    lg={12}
                                    className="section-header mx-auto"
                                  >
                                    <Col lg={7}>
                                      <h5 style={{ marginTop: "5px" }}>
                                        Third Party Information
                                      </h5>
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                                {values.clientcalloptionname &&
                                values.clientcalloptionname !== "" ? (
                                  <Col lg={3}>
                                    <label> Name </label>
                                    <Field
                                      type="text"
                                      name="clientcalloptionname"
                                      value={values.clientcalloptionname}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {values.clientcalloptionmobile &&
                                values.clientcalloptionmobile !== "" ? (
                                  <Col lg={3}>
                                    <label>
                                      Mobile
                                      <b class="red-star"></b>
                                    </label>
                                    <Field
                                      type="text"
                                      name="clientcalloptionmobile"
                                      value={values.clientcalloptionmobile}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.clientcalloptionemail &&
                                values.clientcalloptionemail !== "" ? (
                                  <Col lg={3}>
                                    <label>Client Call Email </label>
                                    <Field
                                      type="text"
                                      style={{
                                        textTransform: "lowercase",
                                      }}
                                      className="custom-input"
                                      name="clientcalloptionemail"
                                      value={values.clientcalloptionemail}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {values.clientcalloptiondate &&
                                values.clientcalloptiondate !== "" ? (
                                  <Col lg={3}>
                                    <label>Date</label>
                                    <Field
                                      type="text"
                                      className="custom-input"
                                      name="clientcalloptiondate"
                                      value={values.clientcalloptiondate}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.clientcalloptionremark &&
                                values.clientcalloptionremark !== "" ? (
                                  <Col lg={3}>
                                    <label>Remarks</label>
                                    <Field
                                      type="text"
                                      name="clientcalloptionremark"
                                      value={values.clientcalloptionremark}
                                    ></Field>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.clientcalloptionratepersqfeet &&
                                values.clientcalloptionratepersqfeet !== "" ? (
                                  <Col lg={3}>
                                    <label> Rate/Sq.Ft.</label>
                                    <Field
                                      type="text"
                                      name="clientcalloptionratepersqfeet"
                                      value={
                                        values.clientcalloptionratepersqfeet
                                      }
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.clientcalloptionbrokerage &&
                                values.clientcalloptionbrokerage !== "" ? (
                                  <Col lg={3}>
                                    <label>Brokerage</label>
                                    <Field
                                      type="text"
                                      name="clientcalloptionbrokerage"
                                      value={values.clientcalloptionbrokerage}
                                    ></Field>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </Row>

                          {values.transactiontype ||
                          values.typeofclient ||
                          values.vertical ||
                          values.verticaltype ||
                          values.subverticaltype ||
                          values.retailsubvertical ||
                          values.locatedin ||
                          values.availability ||
                          values.possessiondate ||
                          values.leaseexpirationdate ||
                          values.furnishedstatus ||
                          values.otherdetails ||
                          values.otherlocations ||
                          values.areaorlocationbrief ||
                          values.completiondate ? (
                            <>
                              <Row lg={12} className="section-header  mx-auto">
                                <Col lg={8}>
                                  <h5 style={{ marginTop: "5px" }}>
                                    Lead Information
                                  </h5>
                                </Col>
                              </Row>

                              <Row>
                                {values.transactiontype &&
                                  values.transactiontype && (
                                    <Col lg={3}>
                                      <label>Transaction Type</label>
                                      <Field
                                        type="text"
                                        name="transactiontype "
                                        value={values.transactiontype}
                                      ></Field>
                                    </Col>
                                  )}

                                {values.typeofclient && values.typeofclient && (
                                  <Col lg={3}>
                                    <label>Type Of Client</label>
                                    <Field
                                      type="text"
                                      name="typeofclient"
                                      value={values.typeofclient}
                                    ></Field>
                                  </Col>
                                )}

                                {values.vertical && values.vertical && (
                                  <Col lg={3}>
                                    <label>Vertical In Property</label>
                                    <Field
                                      type="text"
                                      name="vertical"
                                      value={values.vertical}
                                    ></Field>
                                  </Col>
                                )}

                                {values.verticaltype && values.verticaltype && (
                                  <Col lg={3}>
                                    <label>Vertical Type</label>
                                    <Field
                                      type="text"
                                      name="verticaltype"
                                      value={values.verticaltype}
                                    ></Field>
                                  </Col>
                                )}
                                {values.subverticaltype &&
                                  values.subverticaltype && (
                                    <Col lg={3}>
                                      <label>Sub Vertical Type</label>
                                      <Field
                                        type="text"
                                        name="subverticaltype"
                                        value={values.subverticaltype}
                                      ></Field>
                                    </Col>
                                  )}

                                {values.vertical === "Retail" &&
                                values.subverticaltype !== "" ? (
                                  <Col lg={3}>
                                    <label>Retail Category</label>
                                    <Field
                                      type="text"
                                      name="retailsubvertical"
                                      value={values.retailsubvertical}
                                    ></Field>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {values.locatedin &&
                                (values.vertical === "Office" ||
                                  values.vertical === "Retail" ||
                                  values.vertical === "Land") ||
                                  values.vertical === "Cold storage" ||
                            values.vertical === "Warehouse" ||
                            values.vertical === "Factory" ? (
                                  <Col lg={3}>
                                    <label>Located In</label>
                                    <Field
                                      type="text"
                                      name="locatedin"
                                      value={values.locatedin}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {values.availability &&
                                (values.vertical === "Office" ||
                                  values.vertical === "Retail" ||
                                  values.vertical === "Land")||
                                  values.vertical === "Cold storage" ||
                            values.vertical === "Warehouse" ||
                            values.vertical === "Factory" ? (
                                  <Col lg={3}>
                                    <label>Availability</label>
                                    <Field
                                      type="text"
                                      name="availability"
                                      value={values.availability}
                                    ></Field>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.possessiondate &&
                                values.availability === "Available" ? (
                                  <Col lg={3}>
                                    <label> Possession Date</label>
                                    <Field
                                      type="text"
                                      name="possessiondate"
                                      value={values.possessiondate}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.leaseexpirationdate &&
                                values.availability === "Not Available" ? (
                                  <Col lg={3}>
                                    <label>Lease Expiration Date</label>
                                    <Field
                                      type="text"
                                      name="leaseexpirationdate"
                                      value={values.leaseexpirationdate}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {/* -------------Added by saideep-------------- */}

                                {values.furnishedstatus && (
                                  <Col lg={3}>
                                    <label>Furnished Status</label>
                                    <Field
                                      type="text"
                                      name="furnishedstatus"
                                      value={values.furnishedstatus}
                                    />
                                  </Col>
                                )}

                                {values.otherdetails && values.otherdetails && (
                                  <Col lg={3}>
                                    <label>Other Details</label>
                                    <Field
                                      type="text"
                                      name="otherdetails"
                                      value={values.otherdetails}
                                    ></Field>
                                  </Col>
                                )}

                                {values.otherlocations &&
                                  values.otherlocations && (
                                    <Col lg={3}>
                                      <label>Other Locations</label>
                                      <Field
                                        type="text"
                                        name="otherlocations"
                                        value={values.otherlocations}
                                      />
                                    </Col>
                                  )}
                                {values.completiondate &&
                                  values.completiondate && (
                                    <Col lg={3}>
                                      <label>Completion Date</label>
                                      <Field
                                        type="text"
                                        name="completiondate"
                                        value={values.completiondate}
                                      ></Field>
                                    </Col>
                                  )}

                                {values.areaorlocationbrief &&
                                  values.areaorlocationbrief && (
                                    <Col lg={3}>
                                      <label>Area/Location breif</label>
                                      <Field
                                        type="text"
                                        name="areaorlocationbrief"
                                        value={values.areaorlocationbrief}
                                      ></Field>
                                    </Col>
                                  )}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                          {values.vertical === "Hotels" ? (
                            <>
                              {(values.noofkeys ||
                                values.unit ||
                                values.length ||
                                values.width ||
                                values.architectname ||
                                values.banquetcapacity ||
                                values.noofservicelifts ||
                                values.noofcarparking) && (
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={6}>
                                    <h5 style={{ marginTop: "5px" }}>
                                      Hotel Details
                                    </h5>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                ``
                                {values.noofkeys && values.noofkeys && (
                                  <Col lg={3}>
                                    <label>No. of Keys</label>
                                    <div className="d-flex w-80">
                                      <Field
                                        type="number"
                                        name="noofkeys"
                                        value={values.noofkeys}
                                      />
                                    </div>
                                  </Col>
                                )}
                                {values.unit &&
                                  values.length &&
                                  values.width && (
                                    <Col lg={6}>
                                      <label> Room size</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="text"
                                          name="unit"
                                          value={values.unit}
                                        ></Field>
                                        <Field
                                          type="number"
                                          name="length"
                                          value={values.length}
                                        />
                                        <Field
                                          type="number"
                                          name="width"
                                          value={values.width}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                {values.architectname &&
                                  values.architectname && (
                                    <Col lg={3}>
                                      <label>Architect Name</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="text"
                                          name="architectname"
                                          value={values.architectname}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                {values.banquetcapacity &&
                                  values.banquetcapacity && (
                                    <Col lg={3}>
                                      <label>Banquet Capacity</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="number"
                                          name="banquetcapacity"
                                          value={values.banquetcapacity}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                {values.noofservicelifts &&
                                  values.noofservicelifts && (
                                    <Col lg={3}>
                                      <label>No. of service Lifts</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="number"
                                          name="noofservicelifts"
                                          value={values.noofservicelifts}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                {values.noofcarparking &&
                                  values.noofcarparking && (
                                    <Col lg={3}>
                                      <label>No. Car parking</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="number"
                                          name="noofcarparking"
                                          value={values.noofcarparking}
                                        />
                                      </div>
                                    </Col>
                                  )}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}

                          {values.vertical === "Warehouse" ||
                          values.vertical === "Cold storage" ||
                          values.vertical === "Factory" ||
                          values.verticaltype === "Warehousing" ? (
                            <>
                              {(values.noofdocksvalue !== "" ||
                                values.dockheight ||
                                values.docklevel ||
                                values.noofwashroomsvalue !== "" ||
                                values.openareaunit ||
                                values.openareavalue ||
                                values.closeareaunit ||
                                values.closeareavalue ||
                                values.typeofwarehouse ||
                                values.floor ||
                                values.offeredcarpetarea ||
                                values.heightside ||
                                values.heightcenter ||
                                values.typeofflooring ||
                                values.firehydrants ||
                                values.firesprinkelers ||
                                values.firenoc ||
                                values.securitydeposit ||
                                values.advance ||
                                values.commonareamaintaince ||
                                values.possessionstatus ||
                                values.addtionalinformation ||
                                values.roadwidth ||
                                values.labourroom ||
                                values.guardroom ||
                                values.powerconnection ||
                                values.waterconnection ||
                                values.flooringloadingcapacity ||
                                values.nearbywarehouses) && (
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={6}>
                                    <h5 style={{ marginTop: "5px" }}>
                                      Warehousing Details
                                    </h5>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                {values.noofdocksvalue !== "" ? (
                                  <Col lg={3}>
                                    <label>No. of Docks</label>

                                    <Field
                                      type="number"
                                      name="noofdocksvalue"
                                      value={values.noofdocksvalue}
                                    />
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {values.dockheight && values.dockheight && (
                                  <Col lg={3}>
                                    <label>Dock height in ft.</label>
                                    <Field
                                      type="number"
                                      name="dockheight"
                                      value={values.dockheight}
                                    />
                                  </Col>
                                )}
                                {values.docklevel && values.docklevel && (
                                  <Col lg={3}>
                                    <label>Dock level drop down</label>
                                    <Field
                                      type="text"
                                      name="docklevel"
                                      value={values.docklevel}
                                    ></Field>
                                  </Col>
                                )}

                                {values.noofwashroomsvalue !== "" && (
                                  <Col lg={3}>
                                    <label>No. of Washrooms</label>
                                    <Field
                                      min="0"
                                      type="number"
                                      name="noofwashroomsvalue"
                                      value={values.noofwashroomsvalue}
                                    />
                                  </Col>
                                )}

                                {values.openareaunit &&
                                  values.openareavalue && (
                                    <Col lg={3}>
                                      <label>Open Area</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="text"
                                          name="openareaunit"
                                          value={values.openareaunit}
                                        ></Field>
                                        <Field
                                          type="text"
                                          name="openareavalue"
                                          value={values.openareavalue}
                                        />
                                      </div>
                                    </Col>
                                  )}

                                {values.closeareaunit &&
                                  values.closeareavalue && (
                                    <Col lg={3}>
                                      <label> Covered area</label>
                                      <div className="d-flex w-80">
                                        <Field
                                          type="text"
                                          name="closeareaunit"
                                          value={values.closeareaunit}
                                        ></Field>
                                        <Field
                                          type="text"
                                          name="closeareavalue"
                                          value={values.closeareavalue}
                                        />
                                      </div>
                                    </Col>
                                  )}
                                {values.typeofwarehouse &&
                                  values.typeofwarehouse && (
                                    <Col lg={3}>
                                      <label>Type of Warehouse</label>
                                      <Field
                                        type="text"
                                        name="typeofwarehouse"
                                        value={values.typeofwarehouse}
                                      ></Field>
                                    </Col>
                                  )}
                                {values.floor && values.floor && (
                                  <Col lg={3}>
                                    <label>Floor</label>
                                    <Field
                                      type="text"
                                      name="floor"
                                      value={values.floor}
                                    ></Field>
                                  </Col>
                                )}

                                {values.offeredcarpetarea &&
                                  values.offeredcarpetarea && (
                                    <Col lg={3}>
                                      <label>
                                        Covered area offered in sq. ft
                                      </label>
                                      <Field
                                        type="text"
                                        name="offeredcarpetarea"
                                        value={values.offeredcarpetarea}
                                      />
                                    </Col>
                                  )}
                                {values.heightside && values.heightside && (
                                  <Col lg={3}>
                                    <label>Height (ft.) Side</label>
                                    <Field
                                      type="text"
                                      name="heightside"
                                      value={values.heightside}
                                    />
                                  </Col>
                                )}
                                {values.heightcenter && values.heightcenter && (
                                  <Col lg={3}>
                                    <label>Height (ft.) Center</label>
                                    <Field
                                      type="text"
                                      name="heightcenter"
                                      value={values.heightcenter}
                                    />
                                  </Col>
                                )}

                                {values.typeofflooring &&
                                  values.typeofflooring && (
                                    <Col lg={3}>
                                      <label>Type of Flooring</label>
                                      <Field
                                        type="text"
                                        name="typeofflooring"
                                        value={values.typeofflooring}
                                      ></Field>
                                    </Col>
                                  )}

                                {values.firehydrants && values.firehydrants && (
                                  <Col lg={3}>
                                    <label>Fire Hydrants</label>
                                    <Field
                                      type="text"
                                      name="firehydrants"
                                      value={values.firehydrants}
                                    />
                                  </Col>
                                )}
                                {values.firesprinkelers &&
                                  values.firesprinkelers && (
                                    <Col lg={3}>
                                      <label>Fire Sprinkelers</label>
                                      <Field
                                        type="text"
                                        name="firesprinkelers"
                                        value={values.firesprinkelers}
                                      />
                                    </Col>
                                  )}
                                {values.firenoc && values.firenoc && (
                                  <Col lg={3}>
                                    <label>Fire NOC</label>
                                    <Field
                                      type="text"
                                      name="firenoc"
                                      value={values.firenoc}
                                      onChange={handleChange}
                                    ></Field>
                                  </Col>
                                )}

                                {values.securitydeposit &&
                                  values.securitydeposit && (
                                    <Col lg={3}>
                                      <label>Security Deposit</label>
                                      <Field
                                        type="number"
                                        name="securitydeposit"
                                        value={values.securitydeposit}
                                      />
                                    </Col>
                                  )}
                                {values.otherdetails && values.otherdetails && (
                                  <Col lg={3}>
                                    <label>Advance </label>
                                    <Field
                                      type="number"
                                      name="advance"
                                      value={values.advance}
                                    />
                                  </Col>
                                )}
                                {values.commonareamaintaince &&
                                  values.commonareamaintaince && (
                                    <Col lg={3}>
                                      <label>
                                        Common Area Maintenance (CAM)
                                      </label>
                                      <Field
                                        type="text"
                                        name="commonareamaintaince"
                                        value={values.commonareamaintaince}
                                      />
                                    </Col>
                                  )}
                                {values.possessionstatus &&
                                  values.possessionstatus && (
                                    <Col lg={3}>
                                      <label>Possession Status</label>
                                      <Field
                                        type="text"
                                        name="possessionstatus"
                                        value={values.possessionstatus}
                                      ></Field>
                                    </Col>
                                  )}
                                {values.addtionalinformation &&
                                  values.addtionalinformation && (
                                    <Col lg={3}>
                                      <label>Additional Information</label>
                                      <Field
                                        type="text"
                                        name="addtionalinformation"
                                        value={values.addtionalinformation}
                                      />
                                    </Col>
                                  )}
                                {values.roadwidth && values.roadwidth && (
                                  <Col lg={3}>
                                    <label>Road width in ft.</label>
                                    <Field
                                      type="text"
                                      name="roadwidth"
                                      value={values.roadwidth}
                                    />
                                  </Col>
                                )}
                                {values.labourroom && values.labourroom && (
                                  <Col lg={3}>
                                    <label>Labour Room</label>
                                    <Field
                                      type="text"
                                      name="labourroom"
                                      value={values.labourroom}
                                    />
                                  </Col>
                                )}
                                {values.guardroom && values.guardroom && (
                                  <Col lg={3}>
                                    <label>Guard Room</label>
                                    <Field
                                      type="text"
                                      name="guardroom"
                                      value={values.guardroom}
                                    />
                                  </Col>
                                )}
                                {values.powerconnection &&
                                  values.powerconnection && (
                                    <Col lg={3}>
                                      <label>Power Connection (KV)</label>
                                      <Field
                                        type="text"
                                        name="powerconnection"
                                        value={values.powerconnection}
                                      />
                                    </Col>
                                  )}

                                {values.waterconnection &&
                                  values.waterconnection && (
                                    <Col lg={3}>
                                      <label>
                                        Water connection (Drop Down)
                                      </label>
                                      <Field
                                        type="text"
                                        name="waterconnection"
                                        value={values.waterconnection}
                                      ></Field>
                                    </Col>
                                  )}
                                {values.flooringloadingcapacity &&
                                  values.flooringloadingcapacity && (
                                    <Col lg={3}>
                                      <label>
                                        Flooring Loading Capacity ( in tons)
                                      </label>
                                      <Field
                                        type="text"
                                        name="flooringloadingcapacity"
                                        value={values.flooringloadingcapacity}
                                      />
                                    </Col>
                                  )}
                                {values.nearbywarehouses &&
                                  values.nearbywarehouses && (
                                    <Col lg={3}>
                                      <label>Near-by Warehouses</label>
                                      <Field
                                        type="text"
                                        name="nearbywarehouses"
                                        value={values.nearbywarehouses}
                                      />
                                    </Col>
                                  )}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}

                          <Row></Row>

                          {values.vertical === "Office" ||
                          values.vertical === "Retail" ? (
                            <>
                              {(values.clientcalloptionremark ||
                                values.frontagearea ||
                                values.marketbrand ||
                                values.proposedlockperiod ||
                                values.tenure ||
                                values.maintainancecharges ||
                                values.proposedleaseterm ||
                                values.rentescalation ||
                                values.powerbackup ||
                                values.othertenants) && (
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={6}>
                                    {values.vertical === "Office" ? (
                                      <h5 style={{ marginTop: "5px" }}>
                                        Office Details
                                      </h5>
                                    ) : (
                                      <h5 style={{ marginTop: "5px" }}>
                                        Retail Details
                                      </h5>
                                    )}
                                  </Col>
                                </Row>
                              )}

                              <Row>
                                {values.vertical === "Retail" ? (
                                  <>
                                    {values.perunitcost &&
                                    values.perunitcost ? (
                                      <Col lg={2}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Per unit cost
                                          <b className="red-star"></b>
                                        </label>
                                        <Field
                                          min="0"
                                          type="number"
                                          name="perunitcost"
                                          value={values.perunitcost}
                                        />
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {values.saleablearea &&
                                    values.saleablearea ? (
                                      <Col lg={2}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Saleable Area{" "}
                                          <b className="red-star"></b>
                                        </label>
                                        <Field
                                          min="0"
                                          type="number"
                                          name="saleablearea"
                                          value={values.saleablearea}
                                        />
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {values.quotedcost && values.quotedcost ? (
                                      <Col lg={2}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Quoted Cost (Rs.)
                                          <b className="red-star"></b>
                                        </label>
                                        <Field
                                          min="0"
                                          type="number"
                                          name="quotedcost"
                                          value={values.quotedcost}
                                        />
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {values.frontagearea && values.frontagearea && (
                                  <Col lg={3}>
                                    <label>Frontage In (ft)</label>
                                    <Field
                                      type="text"
                                      name="frontagearea"
                                      placeholder="EnterFrontage (ft) "
                                      value={values.frontagearea}
                                    />
                                  </Col>
                                )}

                                {values.marketbrand && values.marketbrand && (
                                  <Col lg={3}>
                                    <label> Neighbourhood Brands</label>
                                    <Field
                                      type="text"
                                      name="marketbrand"
                                      placeholder="Enter Nearby Market / Brands Info "
                                      value={values.marketbrand}
                                    />
                                  </Col>
                                )}

                                {values.proposedlockperiod &&
                                  values.proposedlockperiod && (
                                    <Col lg={3}>
                                      <label>Proposed Lock in period</label>
                                      <Field
                                        type="text"
                                        name="proposedlockperiod"
                                        value={values.proposedlockperiod}
                                      />
                                    </Col>
                                  )}

                                {values.tenure && values.tenure && (
                                  <Col lg={3}>
                                    <label>Tenure</label>
                                    <Field
                                      type="text"
                                      name="tenure"
                                      value={values.tenure}
                                    />
                                  </Col>
                                )}
                                {values.maintainancecharges &&
                                  values.maintainancecharges && (
                                    <Col lg={3}>
                                      <label>Maintenance Charges </label>
                                      <Field
                                        type="text"
                                        name="maintainancecharges"
                                        value={values.maintainancecharges}
                                      />
                                    </Col>
                                  )}
                                {values.proposedleaseterm &&
                                  values.proposedleaseterm && (
                                    <Col lg={3}>
                                      <label>Proposed Lease Term</label>
                                      <Field
                                        type="text"
                                        name="proposedleaseterm"
                                        value={values.proposedleaseterm}
                                      />
                                    </Col>
                                  )}
                                {values.rentescalation &&
                                  values.rentescalation && (
                                    <Col lg={3}>
                                      <label>
                                        Rent Escalation in Percentage
                                      </label>
                                      <Field
                                        type="text"
                                        name="rentescalation"
                                        value={values.rentescalation}
                                      />
                                    </Col>
                                  )}
                                {values.powerbackup && values.powerbackup && (
                                  <Col lg={3}>
                                    <label>Power and Power back up</label>
                                    <Field
                                      type="text"
                                      name="powerbackup"
                                      value={values.powerbackup}
                                    />
                                  </Col>
                                )}
                                {values.othertenants && values.othertenants && (
                                  <Col lg={3}>
                                    <label>Other Tenants</label>
                                    <Field
                                      type="text"
                                      name="othertenants"
                                      value={values.othertenants}
                                    />
                                  </Col>
                                )}
                                {values.remark && values.remark && (
                                  <Col lg={3}>
                                    <label>Remarks</label>
                                    <Field
                                      as="textarea"
                                      className="textarea"
                                      name="remark"
                                      value={values.remark}
                                    ></Field>
                                  </Col>
                                )}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}

                          <Row>
                            {values.transactiontype === "Sell" &&
                            (values.vertical === "Office" ||
                              values.vertical === "Retail" ||
                              values.vertical === "Land") ? (
                              <>
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={8}>
                                    <h5 style={{ marginTop: "5px" }}>
                                      Pricing Details
                                    </h5>
                                  </Col>
                                </Row>
                                <FieldArray name="pricingorrental">
                                  {({ push, remove }) => (
                                    <div>
                                      {values.pricingorrental?.map(
                                        (record, index) => (
                                          <Row key={index}>
                                            <Col lg={1}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Select <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                name={`pricingorrental[${index}].area`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .area
                                                }
                                              >
                                                {values.vertical ===
                                                  "Cold storage" ||
                                                values.vertical ===
                                                  "Warehouse" ||
                                                values.vertical ===
                                                  "Factory" ? (
                                                  <>
                                                    <option value="">
                                                      select
                                                    </option>
                                                    <option value="Plot Size">
                                                      Plot Size
                                                    </option>
                                                    <option value="Covered Area">
                                                      Covered Area
                                                    </option>
                                                  </>
                                                ) : (
                                                  <>
                                                    <option value="">
                                                      select
                                                    </option>
                                                    <option value="Carpet">
                                                      Carpet
                                                    </option>
                                                    <option value="BUA">
                                                      BUA
                                                    </option>
                                                    <option value="SBUA">
                                                      SBUA
                                                    </option>
                                                  </>
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].area`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Unit <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                name={`pricingorrental[${index}].unit`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .unit
                                                }
                                              >
                                                <option value="">
                                                  --Select--
                                                </option>
                                                <option value="Sq.m">
                                                  Sq.m
                                                </option>
                                                <option value="Sq.ft">
                                                  Sq.ft
                                                </option>
                                                <option value="Acre">
                                                  Acre
                                                </option>
                                                <option value="Gaj">Gaj</option>
                                                <option value="Sq.yards">
                                                  Sq.yards
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].unit`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Floor No.{" "}
                                                <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                placeholder="Enter Flooor"
                                                name={`pricingorrental[${index}].floorno`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .floorno
                                                }
                                              >
                                                <option value="">
                                                  {" "}
                                                  --select--
                                                </option>
                                                {floornumber &&
                                                  floornumber.map((e) => (
                                                    <option value={e.value}>
                                                      {e.label}
                                                    </option>
                                                  ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].floorno`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Per unit cost
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                min="0"
                                                type="number"
                                                placeholder="Enter Per unit cost"
                                                name={`pricingorrental[${index}].perunitcost`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .perunitcost
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `pricingorrental[${index}].quotedcost`,
                                                    e.target.value *
                                                      values.pricingorrental[
                                                        index
                                                      ].saleablearea
                                                  );
                                                  setFieldValue(
                                                    `pricingorrental[${index}].perunitcost`,
                                                    e.target.value
                                                  );
                                                  //handleChange(e)
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].perunitcost`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Saleable Area{" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                type="number"
                                                min="0"
                                                placeholder="Enter Saleable Area"
                                                name={`pricingorrental[${index}].saleablearea`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .saleablearea
                                                }
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setFieldValue(
                                                    `pricingorrental[${index}].quotedcost`,
                                                    e.target.value *
                                                      values.pricingorrental[
                                                        index
                                                      ].perunitcost
                                                  );
                                                  setFieldValue(
                                                    `pricingorrental[${index}].saleablearea`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].saleablearea`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Quoted Cost (Rs.)
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                disabled
                                                className="Field"
                                                required
                                                min="0"
                                                type="number"
                                                placeholder="Enter Quoted rent per Month (Rs.)"
                                                name={`pricingorrental[${index}].quotedcost`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .quotedcost
                                                }
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].quotedcost`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>

                                           
                                          </Row>
                                        )
                                      )}
                                    </div>
                                  )}
                                </FieldArray>
                              </>
                            ) : values.transactiontype === "Lease" &&
                              (values.vertical === "Office" ||
                                values.vertical === "Retail" ||
                                values.vertical === "Land" ||
                                values.vertical === "Cold storage" ||
                                values.vertical === "Warehouse" ||
                                values.vertical === "Factory") ? (
                              <>
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={8}>
                                    <h5 style={{ marginTop: "5px" }}>
                                      Rental Details
                                    </h5>
                                  </Col>
                                </Row>
                                <FieldArray name="pricingorrental">
                                  {({ push, remove }) => (
                                    <div>
                                      {values.pricingorrental?.map(
                                        (record, index) => (
                                          <Row key={index}>
                                            <Col lg={1}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Select <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                name={`pricingorrental[${index}].area`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .area
                                                }
                                              >
                                                {values.vertical ===
                                                  "Cold storage" ||
                                                values.vertical ===
                                                  "Warehouse" ||
                                                values.vertical ===
                                                  "Factory" ? (
                                                  <>
                                                    <option value="">
                                                      select
                                                    </option>
                                                    <option value="Plot Size">
                                                      Plot Size
                                                    </option>
                                                    <option value="Covered Area">
                                                      Covered Area
                                                    </option>
                                                  </>
                                                ) : (
                                                  <>
                                                    <option value="">
                                                      select
                                                    </option>
                                                    <option value="Carpet">
                                                      Carpet
                                                    </option>
                                                    <option value="BUA">
                                                      BUA
                                                    </option>
                                                    <option value="SBUA">
                                                      SBUA
                                                    </option>
                                                  </>
                                                )}
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].area`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Unit <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                name={`pricingorrental[${index}].unit`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .unit
                                                }
                                              >
                                                <option value="">
                                                  --Select--
                                                </option>
                                                <option value="Sq.m">
                                                  Sq.m
                                                </option>
                                                <option value="Sq.ft">
                                                  Sq.ft
                                                </option>
                                                <option value="Acre">
                                                  Acre
                                                </option>
                                                {/* <option value="Gaj">Gaj</option> */}
                                                <option value="Sq.yards">
                                                  Sq.yards
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].unit`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Floor No.{" "}
                                                <b class="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                placeholder="Enter Flooor"
                                                name={`pricingorrental[${index}].floorno`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .floorno
                                                }
                                              >
                                                <option value="">
                                                  {" "}
                                                  --select--
                                                </option>
                                                {floornumber &&
                                                  floornumber.map((e) => (
                                                    <option value={e.value}>
                                                      {e.label}
                                                    </option>
                                                  ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].floorno`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Rental Value{" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                min="0"
                                                type="number"
                                                placeholder="Enter value"
                                                name={`pricingorrental[${index}].rentalvalue`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .rentalvalue
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `pricingorrental[${index}].quotedrentpermonth`,
                                                    e.target.value *
                                                      values.pricingorrental[
                                                        index
                                                      ].chargeblearea
                                                  );
                                                  setFieldValue(
                                                    `pricingorrental[${index}].rentalvalue`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].rentalvalue`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Chargeable Area{" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                min="0"
                                                type="number"
                                                placeholder="Enter Chargeble Area"
                                                name={`pricingorrental[${index}].chargeblearea`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .chargeblearea
                                                }
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setFieldValue(
                                                    `pricingorrental[${index}].quotedrentpermonth`,
                                                    e.target.value *
                                                      values.pricingorrental[
                                                        index
                                                      ].rentalvalue
                                                  );
                                                  setFieldValue(
                                                    `pricingorrental[${index}].chargeblearea`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].chargeblearea`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Quoted rent per Month (Rs.){" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                disabled
                                                className="Field"
                                                required
                                                type="number"
                                                placeholder="Enter Quoted rent per Month (Rs.)"
                                                name={`pricingorrental[${index}].quotedrentpermonth`}
                                                value={
                                                  values.pricingorrental[index]
                                                    .quotedrentpermonth
                                                }
                                              />
                                              <ErrorMessage
                                                name={`pricingorrental[${index}].quotedrentpermonth`}
                                                component="div"
                                                className="error-message"
                                              />
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    </div>
                                  )}
                                </FieldArray>
                              </>
                            ) : null}
                          </Row>

                          {!(
                            values.vertical === "Warehouse" ||
                            values.vertical === "Cold storage" ||
                            values.vertical === "Factory"
                          ) ? (
                            <>
                              {values.areadetails[0]?.area !== "" && (
                                <Row lg={12} className="section-header mx-auto">
                                  <Col lg={8}>
                                    <h5 style={{ marginTop: "5px" }}>
                                      Area Details
                                    </h5>
                                  </Col>
                                </Row>
                              )}

                              {values.areadetails[0]?.area &&
                              values.areadetails[0]?.area !== "" ? (
                                <FieldArray name="areadetails">
                                  {({ push, remove }) => (
                                    <div>
                                      {values.areadetails?.map(
                                        (record, index) => (
                                          <Row key={index}>
                                            <Col lg={3}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Area Type{" "}
                                                <b class="red-star"></b>
                                              </label>
                                              <Field
                                                type="text"
                                                name={`areadetails[${index}].area`}
                                                value={
                                                  values.areadetails[index].area
                                                }
                                              ></Field>
                                            </Col>
                                            <Col lg={3}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Floor No.{" "}
                                                <b class="red-star"></b>
                                              </label>
                                              <Field
                                                type="text"
                                                name={`areadetails[${index}].floor`}
                                                value={
                                                  values.areadetails[index]
                                                    .floor
                                                }
                                              ></Field>
                                            </Col>

                                            {/* <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Chargeable Area{" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              min="0"
                                              type="number"
                                              name="chargeblearea"
                                              value={values.chargeblearea}
                                            />
                                          </Col> */}

                                            <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Unit{" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                as="select"
                                                name={`areadetails[${index}].unit`}
                                                value={
                                                  values.areadetails[index].unit
                                                }
                                              >
                                                <option value="">
                                                  --Select--
                                                </option>
                                                <option value="Sq.m">
                                                  Sq.m
                                                </option>
                                                <option value="Sq.ft">
                                                  Sq.ft
                                                </option>
                                                <option value="Acre">
                                                  Acre
                                                </option>
                                                {/* {values.vertical !== "Retail" && (
                                            <option value="Gaj">Gaj</option>
                                          )} */}
                                                <option value="Sq.yards">
                                                  Sq.yards
                                                </option>
                                              </Field>
                                            </Col>
                                            <Col lg={3}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Value{" "}
                                                <b class="red-star"></b>
                                              </label>
                                              <Field
                                                type="text"
                                                name={`areadetails[${index}].value`}
                                                value={
                                                  values.areadetails[index].value
                                                }
                                              ></Field>
                                            </Col>
                                            {/* <Col lg={2}>
                                              <label
                                                className="form-view-label"
                                                htmlFor="formBasicName"
                                              >
                                                Chargeable Area{" "}
                                                <b className="red-star"></b>
                                              </label>
                                              <Field
                                                className="Field"
                                                required
                                                type="text"
                                                name={`areadetails[${index}].value`}
                                                placeholder="Enter value"
                                                value={
                                                  values.areadetails[index]
                                                    .value
                                                }
                                              />
                                            </Col> */}
                                          </Row>
                                        )
                                      )}

                                      {values.quotedrentpermonth &&
                                        values.quotedrentpermonth && (
                                          <Col lg={2}>
                                            <label
                                              className="form-view-label"
                                              htmlFor="formBasicName"
                                            >
                                              Quoted rent per Month (Rs.){" "}
                                              <b className="red-star"></b>
                                            </label>
                                            <Field
                                              min="0"
                                              type="number"
                                              name="quotedrentpermonth"
                                              value={values.quotedrentpermonth}
                                            />
                                          </Col>
                                        )}
                                    </div>
                                  )}
                                </FieldArray>
                              ) : (
                                ""
                              )}

                              {!(
                                values.vertical === "Warehouse" ||
                                values.vertical === "Cold storage" ||
                                values.vertical === "Factory"
                              ) ? (
                                <>
                                  {/* {console.log("values.heightdetails-->",values.heightdetails)} */}
                                  {values.heightdetails[0]?.floor && (
                                    <Row
                                      lg={12}
                                      className="section-header mx-auto"
                                    >
                                      <Col lg={8}>
                                        <h5 style={{ marginTop: "5px" }}>
                                          Height Details
                                        </h5>
                                      </Col>
                                    </Row>
                                  )}
                                  {values.heightdetails[0]?.floor &&
                                  values?.heightdetails[0]?.floor !== "" ? (
                                    <FieldArray name="heightdetails">
                                      {({ push, remove }) => (
                                        <div>
                                          {values.heightdetails?.map(
                                            (record, index) => (
                                              <Row key={index}>
                                                {values.vertical ===
                                                  "Cold storage" ||
                                                values.vertical ===
                                                  "Warehouse" ||
                                                values.vertical ===
                                                  "Factory" ? (
                                                  <Col lg={3}>
                                                    <label>Height Type</label>
                                                    <Field
                                                      type="text"
                                                      name="area"
                                                    ></Field>
                                                  </Col>
                                                ) : (
                                                  <Col lg={3}>
                                                    <label
                                                      className="form-view-label"
                                                      htmlFor="formBasicName"
                                                    >
                                                      Floor No.{" "}
                                                      <b class="red-star"></b>
                                                    </label>
                                                    <Field
                                                      type="text"
                                                      name={`heightdetails[${index}].floor`}
                                                      value={
                                                        values.heightdetails[
                                                          index
                                                        ].floor
                                                      }
                                                    ></Field>
                                                  </Col>
                                                )}

                                                <Col lg={3}>
                                                  <label
                                                    className="form-view-label"
                                                    htmlFor="formBasicName"
                                                  >
                                                    Unit{" "}
                                                    <b class="red-star"></b>
                                                  </label>
                                                  <Field
                                                    type="text"
                                                    name={`heightdetails[${index}].unit`}
                                                    value={
                                                      values.heightdetails[
                                                        index
                                                      ].unit
                                                    }
                                                  ></Field>
                                                </Col>

                                                <Col lg={3}>
                                                  <label
                                                    className="form-view-label"
                                                    htmlFor="formBasicName"
                                                  >
                                                    Height Value{" "}
                                                    <b class="red-star"></b>
                                                  </label>
                                                  <Field
                                                    type="text"
                                                    name={`heightdetails[${index}].value`}
                                                    value={
                                                      values.heightdetails[
                                                        index
                                                      ].value
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </FieldArray>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {values.vertical === "Land" ||
                              values.verticaltype === "Outright" ||
                              values.verticaltype === "Joint Venture" ||
                              values.verticaltype === "Joint Development" ||
                              values.verticaltype === "Industrial" ||
                              values.verticaltype === "Commercial" ? (
                                <>
                                  {(values.plotunit ||
                                    values.plotlength ||
                                    values.plotwidth) && (
                                    <Row
                                      lg={12}
                                      className="section-header mx-auto"
                                    >
                                      <Col lg={8}>
                                        <h5 style={{ marginTop: "5px" }}>
                                          Plot Size{" "}
                                        </h5>
                                      </Col>
                                    </Row>
                                  )}

                                  <Row>
                                    {values.plotunit && values.plotunit && (
                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Unit <b class="red-star"></b>
                                        </label>
                                        <Field
                                          type="text"
                                          name="plotunit"
                                          value={values.plotunit}
                                        ></Field>
                                      </Col>
                                    )}

                                    {values.plotlength && values.plotlength && (
                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Length <b class="red-star"></b>
                                        </label>
                                        <Field
                                          type="text"
                                          name="plotlength"
                                          value={values.plotlength}
                                        />
                                      </Col>
                                    )}
                                    {values.plotwidth && values.plotwidth && (
                                      <Col lg={3}>
                                        <label
                                          className="form-view-label"
                                          htmlFor="formBasicName"
                                        >
                                          Width <b class="red-star"></b>
                                        </label>
                                        <Field
                                          type="text"
                                          name="plotwidth"
                                          value={values.plotwidth}
                                        />
                                      </Col>
                                    )}
                                  </Row>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </fieldset>
                      </Form>
                    )}
                  </>
                )}
              </Formik>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LeadEdit;
