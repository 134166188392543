import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import PubSub from 'pubsub-js';
import { ErrorMessage, Field, Formik } from "formik";
import { schemaTaskEdit } from "../common/ValidateSchemaHelper";
import { taskEditInitialValues } from "../common/InitialValuesHelper";

const TaskEdit = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [task, setTask] = useState(props.task ? props.task.row : { parentid: props.parentid, parenttype: props.table });
    useEffect(() => {
        if (props.parentid !== null && props.task !== null) {
            let current = new Date();
            task.lastmodifieddate = moment(current).format('YYYY-MM-DD');
        }
    }, []);

    //===typeahead===
    const [ownerList, setownerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchUsers();
            if (result) {
                setownerList(result);
                if (task.id) {
                    setDefaultOwner([{ 'id': task.ownerid, 'username': task.ownername }]);
                } else {
                    setDefaultOwner([{ 'id': '', 'username': '' }]);
                    //setDefaultTargetDate(new Date());
                    //task.targetdate = defaultTargetDate;
                }
            }
        }
        init();
    }, []);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // const handleChange = (e) => {
    //     setTask({ ...task, [e.target.name]: e.target.value });

    //     if (e.target.name === 'targetdate') {
    //         if (new Date(e.target.value) < currentDate) {
    //             setTask({ ...task, [e.target.name]: null });
    //             setErrorMessage("Target date should be greater than or equal to today's date");
    //         }
    //         else {
    //             setTask({ ...task, [e.target.name]: e.target.value });
    //             setErrorMessage('');
    //         }
    //     }


    // };

   
    const handleSubmitSave = async (values) => {

        if (task.id) {
            const result = await inventoryApi.saveTask(values);
            console.log('result',result);
            if (result) {
                setTask(result)
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record update successfully' });
                submitTasks();
            }
        } else {
            console.log('values',values);
            const result = await inventoryApi.createTask(values);
            setTask(result)
            console.log('result',result);
            if (result) {
                console.log('result',result);
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                submitTasks();
            }
        }
    }

    const submitTasks = () => {
        props.submitTasks();
    };

    return (


        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {task.id ? "Edit Task" : "Add Task"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form inputbox">
                    <Row>
                        <Col lg={12}>
                            <Formik
                                validationSchema={schemaTaskEdit()}
                                onSubmit={handleSubmitSave}
                                initialValues={taskEditInitialValues(task)}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <>
                                        <Row>
                                            <Col lg={6}>

                                                <label>Title<b class="red-star"> *</b></label>
                                                <Field
                                                    required
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>

                                            <Col lg={6}>
                                                <label>Type<b class="red-star"></b></label>
                                                <Field
                                                    as="select"
                                                    name="type"
                                                    value={values.type}
                                                    placeholder="Enter Payment Status"
                                                    onChange={handleChange}>

                                                    <option value=''>Select Type</option>
                                                    <option value="Call">Call </option>
                                                    <option value="Task">Task</option>
                                                    <option value="Meeting">Meeting</option>
                                                    <option value="Notes">Notes</option>

                                                    {values.type === 'Email' ? <option value="Email">Email</option> : " "}
                                                </Field>
                                            </Col>
                                            <Col lg={6}>
                                                <label>Priority<b class="red-star"> *</b></label>
                                                <Field
                                                    required
                                                    as="select"
                                                    name="priority"
                                                    value={values.priority}
                                                    placeholder="Enter Priority"
                                                    onChange={handleChange}>
                                                    <option value="">Select Priority</option>
                                                    <option value="Low">Low</option>
                                                    <option value="Normal">Normal </option>
                                                    <option value="High">High</option>
                                                </Field>
                                                <ErrorMessage
                                                    name="priority"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <label>Status<b class="red-star"> *</b></label>
                                                <Field
                                                    required
                                                    as="select"
                                                    name="status"
                                                    value={values.status}
                                                    placeholder="Enter status"
                                                    onChange={handleChange}>

                                                    <option value="">Select Status</option>
                                                    <option value="Not Started">Not Started</option>
                                                    <option value="In Progress">In Progress </option>
                                                    <option value="Completed">Completed</option>
                                                    <option value="Waiting on someone else">Waiting on someone else</option>
                                                    <option value="Deferred">Deferred</option>

                                                </Field>
                                                <ErrorMessage
                                                    name="status"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <label>Target Date<b class="red-star">*</b></label>
                                                <Field
                                                    required
                                                    type="date"
                                                    name="targetdate"
                                                    placeholder="Enter targetdate"
                                                    value={moment(values.targetdate).format('YYYY-MM-DD')}
                                                    min={moment(new Date()).format('YYYY-MM-DD')}
                                                    onChange={handleChange} />
                                                <ErrorMessage
                                                    name="targetdate"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <label>
                                                    Assign Staff
                                                    <b class="red-star"> *</b>
                                                </label>
                                                <Field
                                                    required
                                                    as="select"
                                                    name="ownerid"
                                                    value={values.ownerid}
                                                    defaultValue={defaultOwner}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Assign Staff</option>
                                                    {ownerList &&
                                                        ownerList.map((ele) => (
                                                            <option value={ele.id}>{ele.username}</option>
                                                        ))}
                                                </Field>
                                                <ErrorMessage
                                                            name="ownerid"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                            </Col>
                                            <Col >
                                                <label>Description</label>
                                                <Field
                                                    as="textarea"
                                                    className="textarea"
                                                    name="description"
                                                    placeholder="Enter Description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={handleSubmit}  >Save</Button>
                                            <Button onClick={props.onHide} variant="light">Close</Button>
                                        </Modal.Footer>
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
export default TaskEdit;
