import React, { useState, useEffect } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

// Define styles for PDF components
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    margin: "10px 0",
    backgroundColor: 'white',
    color: 'black'
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: '#A27C4B',
  },
  waretableCol: {
    width: "50%",
    borderStyle: "solid",
    //borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    borderCollapse: 'collapse',
    //backgroundColor:'#dddddd',
  },
  tableColwarehouse: {
    width: "50%",
    borderStyle: "solid",
    borderCollapse: 'collapse',
    borderColor: "#000",
    padding: 5,
    backgroundColor: '#03396c',
  },
  tableColware: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: 'black',
  },
  tableCell: {
    margin: 3,
    fontSize: 10,
    // backgroundColor:'#A27C4B'
  },
  tableCellwarehouse: {
    margin: 3,
    fontSize: 10,
  },
  header: {
    backgroundColor: "#e0e0e0",
    fontWeight: "bold",
  },
  werehouseheader: {
   // backgroundColor: 'black',
    fontWeight: "bold",
    color: 'white'
  },
  wareevenRow: {
    backgroundColor: '#FFF2D7',
  },
  wareoddRow: {
    backgroundColor: '#e5d3b3',
  },
  evenRow: {
    backgroundColor: '#dddddd',
  },
  oddRow: {
    backgroundColor: 'white',
  },
});

function TableTemplateDownload({ table, columns, data, isWareHouse }) {
  const [dataRec, setDataRec] = useState(data);
  useEffect(() => {
    console.log('dataRec',data);
    setDataRec(data);
  }, [data]);
  return (


    <View style={styles.table}>
      <View style={[styles.tableRow, styles.header]}>

        {isWareHouse && !columns ? <View style={styles.tableColwarehouse}> <Text style={styles.werehouseheader} >VALIDATION PARAMETERS :</Text></View> : (isWareHouse && columns ? <View style={styles.tableColware}><Text style={styles.werehouseheader} >COMMERCIAL :</Text></View> : <View style={styles.tableCol}><Text style={styles.tableCellwarehouse}>Parameters</Text></View>)}

        {isWareHouse && !columns ? <View style={styles.tableColwarehouse}> <Text style={styles.werehouseheader}>SPECIFICATION :</Text></View> : (isWareHouse && columns ? <View style={styles.tableColware}> <Text style={styles.werehouseheader} >SPECIFICATION :</Text></View> : <View style={styles.tableCol}> <Text style={styles.tableCellwarehouse}>Details</Text></View>)}

      </View>
      {!isWareHouse && table &&table.map((col, index) => (
        <View key={col.value} style={[styles.tableRow, index % 2 === 0 ? styles.wareevenRow : styles.wareoddRow]}>
          <View style={!isWareHouse ? styles.waretableCol : styles.tableCol}>
            <Text style={styles.tableCell}>{col.label}</Text>
          </View>
          <View style={!isWareHouse ? styles.waretableCol : styles.tableCol}>
            <Text style={styles.tableCell}>{dataRec.hasOwnProperty(col.value) ? dataRec[col.value] : col.defaultvalues}</Text>
          </View>
        </View>
      ))}
      {isWareHouse && table.map((col, index) => (
        <View key={col.value} style={[styles.tableRow, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
          <View style={isWareHouse ? styles.waretableCol : styles.tableCol}>
            <Text style={styles.tableCell}>{col.label}</Text>
          </View>
          <View style={isWareHouse ? styles.waretableCol : styles.tableCol}>
            <Text style={styles.tableCell}>{dataRec.hasOwnProperty(col.value) ? dataRec[col.value] : col.defaultvalues}</Text>
          </View>
        </View>
      ))}
    </View>

  );
}

export default TableTemplateDownload;
