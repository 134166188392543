import React, { useState } from "react";
import {
    Button,
    Tooltip,
    OverlayTrigger,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import PdfDownload from "../PdfDownload";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useLocation, useNavigate } from "react-router-dom";
import "../PdfBuilder/PdfBuilder.css";
import FormData from "form-data";
import axios from "axios";
import PubSub from "pubsub-js";
import * as constants from "../../constants/CONSTANT"
import moment from "moment";
import WarehousePdfDownload from "./WarehousePdfDownload";



const WarehousePdf = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [property, setProperty] = useState(
        location.state ? location.state : {}
    );
    const [propertyData, setpropertyData] = useState(location.state ? location.state : {});
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    function handlePDfSave(blob) {
        var myFile = new File([blob], `${propertyData.propertyData.name}.pdf`, { lastModified: 1534584790000 });
        console.log('selectedPages',selectedPages);


        const token = localStorage.getItem("token");
        var formData = new FormData();
        formData.append("image", myFile);
        formData.append(`description`, 'pdf files');
        // formData.append(`fileType`, myFile);
        formData.append(`ispdf`, true);
        //.log("formdata",JSON.stringify(formData));

        axios
            .post(
                `${constants.API_BASE_URL}/api/files/${propertyData.propertyData.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                    onUploadProgress: (data) => {
                        // Set the progress value to show the progress bar
                        setProgress(Math.round((100 * data.loaded) / data.total));
                    },

                }
            )
            .then((response) => {
                console.log("response",response)
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record saved successfully",
                });
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    console.log('propertyData line 27 :>> ', propertyData.propertyData);
    const [editedValues, setEditedValues] = useState({
        homePage: {
            imagelink: propertyData.homePage.imagelink,
        },
        aboutUsPage: {
            imagelink: propertyData.aboutUsPage.imagelink,
            imagelink2: propertyData.aboutUsPage.imagelink2,
        },
        keyDetailspage: {
            typeofwarehouse: propertyData.propertyData.typeofwarehouse,
            closeareavalue: propertyData.propertyData.closeareaunit +' '+propertyData.propertyData.closeareavalue,
            typeofwarehouse: propertyData.propertyData.typeofwarehouse,
            imagelink: propertyData.keyDetailspage.imagelink,
        },
        specificationsPage: {
            area:'',
            totalarealand:'',
            titleofland:'',
            approch:'',
            location:'',
            clearHeightattheCenter:propertyData.propertyData.heightcenter,
            clearHeight:propertyData.propertyData.heightside,
            fireNoc:propertyData.propertyData.firenoc,
            noofducks:propertyData.propertyData.noofdocksvalue,
            imagelink: propertyData.specificationsPage.imagelink,
        },
        commercialPage: {
            leaseagreement:'',
            possession:propertyData.propertyData.possessionstatus,
            area:'',
            rent:'',
            suggestedby:'Ritesh Agarwal',
            contact:'+91 9829610077',
            company:'Sthapatya Leasing & Consultant',
           
            imagelink: propertyData.commercialPage.imagelink,
        },
        innerViewPage: {
            imagelink: propertyData.innerViewPage.imagelink,
            // imagelink2: propertyData.innerViewPage.imagelink2,
            imagelink2: "/warehouse-pdf/HeaderInnerView.png",

        },
        innerViewPage2: {
            imagelink: propertyData.innerViewPage2.imagelink,
        },
        innerViewPage3: {
            imagelink: propertyData.innerViewPage3.imagelink,
        },
        outerViewPage: {
            imagelink: propertyData.outerViewPage.imagelink,
        },
        outerViewPage2: {
            imagelink: propertyData.outerViewPage2.imagelink,
        },
        layoutPage: {
            imagelink: propertyData.layoutPage.imagelink,
            imagelink2: "/warehouse-pdf/keyAreaDetailspagebg.png",
        },
        contactUsPage: {
            imagelink: propertyData.contactUsPage.imagelink,
        },
        keyDetailsfirstpage: {
            imagelink: propertyData.keyDetailsfirstpage.imagelink,
        },
        keyDetailsSecondbg: {
            imagelink: propertyData.keyDetailsSecondbg.imagelink,
        },
        logo: {
            imagelink: "/pdf_template_1/logo.jpg",
        },

    });



    const [selectedPages, setSelectedPages] = useState({
        homePage: true,
        aboutUsPage: true,
        keyDetailspage: true,
        specificationsPage: true,
        commercialPage: true,
        innerViewPage: editedValues.innerViewPage.imagelink !==''? true:false,
        innerViewPage2:editedValues.innerViewPage2.imagelink !==''? true:false,
        innerViewPage3: editedValues.innerViewPage3.imagelink !==''? true:false,
        outerViewPage: editedValues.outerViewPage.imagelink !==''? true:false,
        outerViewPage2:editedValues.outerViewPage2.imagelink !==''? true:false,
        layoutPage: editedValues.layoutPage.imagelink !==''? true:false,
        contactUsPage: true,
    });

    const handlePageCheckboxChange = (pageName) => {
        setSelectedPages((prevSelectedPages) => ({
            ...prevSelectedPages,
            [pageName]: !prevSelectedPages[pageName],
        }));
    };
    const initialEditStates = {
        editHomePage: false,
        editAboutUsPage: false,
        editKeyDetailspage: false,
        editSpecificationsPage: false,
        editSpecificationsPageFireNoc	: false,
        editSpecificationsPageClearHeight: false,
        editSpecificationsPageClearHeightattheCenter: false,
        editSpecificationsPageLocation: false,
        editSpecificationsPageApproach: false,
        editSpecificationsPageTitleofLand: false,
        editSpecificationsPageTotallandArea	: false,
        editSpecificationsPageArea: false,
        editSpecificationsPageDocks: false,
        editCommercialPageLeaseagreement: false,
        editCommercialPagePossession: false,
        editCommercialPageArea: false,
        editCommercialPageRent: false,
        editCommercialPageSuggested: false,
        editCommercialPageContact: false,
        editCommercialPageCompany: false,
        editInnerViewPage: false,
        editInnerViewPage2: false,
        editInnerViewPage3: false,
        editOuterViewPage: false,
        editOuterViewPage2: false,
        editlayoutPage: false,
        editContactUsPage: false,


       
    };

    const [editStates, setEditStates] = useState(initialEditStates);
    //.log("editStates *==>", editStates);

    // Function to toggle the state of a specific property
    const toggleEditState = (key) => {
        setEditStates((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleGoBack = () => {
        navigate(`/properties/${propertyData.propertyData.id}`, { state: propertyData.propertyData });
    };

    const handleInputChange = (event) => {
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
            const fileName = fileInput.files[0].name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage("Select Images Only");
                setError(true);
                fileInput.value = '';
            } else {
                setErrorMessage("");
                setError(false);
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const newImage = reader.result;
                        setEditedValues((prevValues) => ({
                            ...prevValues,
                            [name]: {
                                ...prevValues[name],
                                [data]: newImage,
                            },
                        }));
                    };

                    reader.readAsDataURL(file);

                    //To Display the image after editing
                    const toggleEditMode = (editMode) => {
                        setEditStates((prevEditStates) => ({
                            ...prevEditStates,
                            [editMode]: !prevEditStates[editMode],
                        }));
                    };

                    const toggleEditModeIfTrue = (keys) => {
                        for (const key of keys) {
                            if (editStates[key]) {
                                toggleEditMode(key);
                                break;
                            }
                        }
                    };

                    const modeKeys = [
                        'editHomePage',
                        'editAboutUsPage',
                        'editKeyDetailspage',
                        'editSpecificationsPage',
                        'editSpecificationsPageFireNoc',
                        'editSpecificationsPageClearHeight',
                        'editSpecificationsPageClearHeightattheCenter',
                        'editSpecificationsPageLocation',
                        'editSpecificationsPageApproach',
                        'editSpecificationsPageTitleofLand',
                        'editSpecificationsPageTotallandArea',
                        'editSpecificationsPageArea',
                        'editSpecificationsPageDocks',
                        'editCommercialPage',
                        'editInnerViewPage',
                        'editInnerViewPage2',
                        'editInnerViewPage3',
                        'editOuterViewPage',
                        'editOuterViewPage2',
                        'editlayoutPage',
                        'editContactUsPage',
                        'editCommercialPageLeaseagreement',
                        'editCommercialPagePossession',
                        'editCommercialPageArea',
                        'editCommercialPageRent',
                        'editCommercialPageSuggested',
                        'editCommercialPageContact',
                        'editCommercialPageCompany',

                    ];

                    toggleEditModeIfTrue(modeKeys);
                }
            }

            const { type } = event.target;
            const name = event.target.name;
            const data = event.target.getAttribute("data");

            if (type !== "file") {
                // Handle text input
                const value = event.target.value;

                setEditedValues((prevValues) => ({
                    ...prevValues,
                    [name]: {
                        ...prevValues[name],
                        [data]: value,
                    },
                }));
            }
        };
    }
    //second page

    const handleInputChangeSecondPage = (fieldName, value) => {
        setEditedValues((prevValues) => ({
            ...prevValues,
            aboutUsPage: {
                ...prevValues.aboutUsPage,
                [fieldName]: value,
            },
        }));
    };

    //Fourth Page
    const handleInputChangeFourthPage = (fieldName, value) => {
        setEditedValues((prevValues) => ({
            ...prevValues,
            specificationsPage: {
                ...prevValues.specificationsPage,
                [fieldName]: value,
            },
        }));
    };
    const handleInputChangeFourthPage1 = (fieldName, value) => {
        setEditedValues((prevValues) => ({
            ...prevValues,
            fourthpage1: {
                ...prevValues.fourthpage1,
                [fieldName]: value,
            },
        }));
    };
    //.log('editedValues.homePage.imagelink', editedValues.homePage.imagelink)


    return (
        <div>
            <center>
                <div
                    className="d-flex justify-content-between"
                    style={{ width: "95%" }}
                >
                    <div className="m-2">
                        <Button onClick={handleGoBack}> Back </Button>
                    </div>

                    <div className="m-2">
                       
                        <PDFDownloadLink
                            document={
                                <WarehousePdfDownload
                                    reportBuilder={{ ...propertyData, ...editedValues }}
                                    table={property}
                                    selectedPages={selectedPages}
                                />
                            }
                            fileName={`${propertyData.propertyData.name} - pdf - ${moment().format('DD-MM-YYYY:HH:MM:SS')}.pdf`}
                        >
                            {({ loading, blob }) =>
                                loading ? (
                                    <Button disabled>Loading Document...</Button>
                                ) : (
                                    <Button onClick={() => handlePDfSave(blob)}> Save</Button>
                                )
                            }
                        </PDFDownloadLink>
                        {/* </OverlayTrigger> */}
                    </div>
                </div>
            </center>

            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.homePage}
                        onChange={() => handlePageCheckboxChange("homePage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                    </div>
                    <div>
                        <div
                        // onDoubleClick={() => toggleEditState("editHomePage")}
                        >
                            {editStates.editHomePage ? (
                                <>
                                    <input
                                        name="homePage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '380px',
                                            width: '700px',
                                            position: 'relative',
                                            //  marginLeft: '-109px',
                                        }}
                                        src={editedValues.homePage.imagelink}
                                        alt="Property"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.homePage.imagelink} */}
                    </div>
                </div>
            </div>

            {/*---------------------------   aboutUsPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.aboutUsPage}
                        onChange={() => handlePageCheckboxChange("aboutUsPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >
                    </div>
                    <div>

                        <div style={{ padding: '30px' }}>
                            <div onDoubleClick={() => toggleEditState("editAboutUsPage")}>
                                {editStates.editAboutUsPage ? (
                                    <>
                                        <input
                                            name="aboutUsPage"
                                            data="imagelink"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={handleInputChange}
                                            style={{ marginTop: "155px" }}
                                        />
                                        {errorMessage && (
                                            <div className="text-danger">
                                                <b>{errorMessage}</b>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Row lg={6}>
                                            <Col lg={2}>
                                                <div style={{ width: '100px' }}>
                                                    <img
                                                        style={{
                                                            height: '320px',
                                                            width: '190px',
                                                            position: 'relative',
                                                        }}
                                                        src={editedValues.aboutUsPage.imagelink}
                                                        alt="Property"
                                                    />
                                                </div>
                                            </Col>
                                            <Col style={{ textAlign: "center" }}>
                                                <h2 style={{ color: 'White', marginLeft: '28px', fontWeight: 'bold' }}>ABOUT US</h2>
                                                <p style={{ color: 'White', marginLeft: '10px', fontWeight: 'bold' }}>Decade isn’t just a number it is a journey of Sthapatya that has gained trust of its client’s.
                                                    We, at Sthapatya have always believed into a customized, client-specific & need-based approach. We understands the existing gaps & requirements in the current situations from our clients and aspire to provide a more efficient & best suited warehousing or real estate solution to cater our clients’ growing needs from time to time.
                                                </p>
                                            </Col>
                                            <Col lg={2}>
                                                <div style={{ width: '100px', }}>
                                                    <img
                                                        style={{
                                                            height: '300px',
                                                            width: '190px',
                                                            position: 'relative',
                                                            marginLeft: '35px'
                                                        }}
                                                        src={editedValues.aboutUsPage.imagelink2}
                                                        alt="Property"
                                                    />
                                                </div>
                                                <div style={{ textAlign: "center" }} >
                                                    <h4 style={{ color: 'White', marginLeft: '28px', fontWeight: 'bold' }}>Owner</h4>
                                                    <h4 style={{ color: 'White', marginLeft: '28px', fontWeight: 'bold', marginLeft: '22px' }}>RITESH AGARWAL</h4>
                                                </div>
                                            </Col>
                                        </Row>

                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                    </div>
                </div>
            </div>
            {/*---------------------------   keyDetailspage Page----------------------------- */}
            

            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.keyDetailspage}
                        onChange={() => handlePageCheckboxChange("keyDetailspage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >
                    </div>
                    <div>
                        <img
                            style={{
                                marginTop: '0px',
                                height: '380px',
                                width: '230px',
                                position: 'relative',
                                //  marginLeft: '-109px',
                            }}
                            src={editedValues.keyDetailsfirstpage.imagelink}
                            alt="Property"
                        />

                        <h1
                            style={{
                                position: "relative",
                                textAlign: "center",
                                color: "white",
                                top: "-270px"
                            }}>Warehouse <br />Key area<br /> details</h1>
                    </div>
                    <div>
                        <img
                            style={{
                                marginTop: '0px',
                                height: '380px',
                                width: '470px',
                                position: 'relative',
                                //  marginLeft: '-109px',
                            }}
                            src={editedValues.keyDetailsSecondbg.imagelink}
                            alt="Property"
                        />

                        <div class="d-flex flex-column bd-highlight"
                            style={{
                                position: "relative",
                                bottom: "350px",
                                left: "70px"
                            }}>
                            <div class="p-3 bd-highlight">
                                <div
                                    name="box1"
                                    style={{
                                        height: "40px",
                                        width: "260px",
                                        backgroundColor: "#4c89ff",
                                        border: "none",
                                        borderRadius: "6px"
                                    }}>
                                    <h5
                                        style={{
                                            position: "absolute",
                                            right: "310px",
                                            top: "23px",
                                            color: "white"
                                        }}>Warehouse&nbsp;type :</h5>
                                        <h5
                                        style={{
                                            position: "absolute",
                                            right: "270px",
                                            top: "23px",
                                            color: "white"
                                        }}>PEB
                                        </h5>
                                </div>
                                <hr width="20%" color="#191970" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "272px",
                                        top: "30px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #4c89ff",
                                        height: "36px",
                                        position: "absolute",
                                        left: "365px",
                                        top: "30px"
                                    }}>

                                </div>
                                <hr width="81%" color="#191970" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "-15px",
                                        top: "65px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #4c89ff",
                                        height: "36px",
                                        position: "absolute",
                                        right: "485px",
                                        top: "30px"
                                    }}></div>
                                <hr width="6%" color="#4c89ff" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "-15px",
                                        top: "30px"
                                    }} />
                            </div>
                            <div class="p-3 bd-highlight">
                                <div
                                    name="box2"
                                    style={{
                                        height: "40px",
                                        width: "260px",
                                        backgroundColor: "#37D5D6",
                                        border: "none",
                                        borderRadius: "6px"
                                    }}>
                                    <h5
                                        style={{
                                            position: "absolute",
                                            right: "400px",
                                            top: "88px",
                                            color: "white"
                                        }}>Type :
                                        </h5>
                                        <h5
                                        style={{
                                            position: "absolute",
                                            right: "247px",
                                            top: "88px",
                                            color: "white"
                                        }}>Under Construction

                                        </h5>
                                </div>
                                <hr width="21%" color="#37D5D6" size="3"
                                    style={{
                                        position: "relative",
                                        left: "260px",
                                        top: "-20px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #37D5D6",
                                        height: "36px",
                                        position: "absolute",
                                        left: "365px",
                                        top: "96px"
                                    }}>

                                </div>
                                <hr width="81%" color="#37D5D6" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "-15px",
                                        top: "132px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #37D5D6",
                                        height: "36px",
                                        position: "absolute",
                                        right: "485px",
                                        top: "97px"
                                    }}></div>
                                <hr width="6%" color="#37D5D6" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "-15px",
                                        top: "97px"
                                    }} />
                            </div>
                            <div class="p-3 bd-highlight">
                                <div
                                    name="box3"
                                    style={{
                                        height: "40px",
                                        width: "260px",
                                        backgroundColor: "#22E4AC",
                                        border: "none",
                                        borderRadius: "6px"
                                    }}>
                                    <h5
                                        style={{
                                            position: "absolute",
                                            right: "340px",
                                            top: "150px",
                                            color: "white"
                                        }}>Covered&nbsp;Area :</h5>
                                         <h5
                                        style={{
                                            position: "absolute",
                                            right: "250px",
                                            top: "151px",
                                            color: "white"
                                        }}> 40,000Sqft
                                        </h5>
                                </div>
                                <hr width="21%" color="#22E4AC" size="3"
                                    style={{
                                        position: "relative",
                                        left: "260px",
                                        top: "-20px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #22E4AC",
                                        height: "36px",
                                        position: "absolute",
                                        left: "365px",
                                        top: "161px"
                                    }}>

                                </div>
                                <hr width="85%" color="#22E4AC" size="3"
                                    style={{
                                        position: "relative",
                                        left: "-26px",
                                        top: "14px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #22E4AC",
                                        height: "36px",
                                        position: "absolute",
                                        right: "484px",
                                        top: "161px"
                                    }}></div>
                                <hr width="6%" color="#22E4AC" size="3"
                                    style={{
                                        position: "absolute",
                                        left: "-15px",
                                        top: "160px"
                                    }} />
                            </div>
                            <div class="p-3 bd-highlight">
                                <div
                                    name="box4"
                                    style={{
                                        height: "40px",
                                        width: "260px",
                                        backgroundColor: "#04AF70",
                                        border: "none",
                                        borderRadius: "6px"
                                    }}>
                                    <h5
                                        style={{
                                            position: "absolute",
                                            right: "360px",
                                            top: "218px",
                                            color: "white"
                                        }}>Land&nbsp;Area :</h5>
                                        <h5
                                        style={{
                                            position: "absolute",
                                            right: "270px",
                                            top: "218px",
                                            color: "white"
                                        }}> 65,000Sqft 
                                        </h5>
                                </div>
                                <hr width="21%" color="#04AF70" size="3"
                                    style={{
                                        position: "relative",
                                        left: "260px",
                                        top: "-21px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #04AF70",
                                        height: "36px",
                                        position: "absolute",
                                        left: "365px",
                                        top: "226px"
                                    }}>

                                </div>
                                <hr width="85%" color="#04AF70" size="3"
                                    style={{
                                        position: "relative",
                                        left: "-25px",
                                        top: "14px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #04AF70",
                                        height: "36px",
                                        position: "absolute",
                                        right: "483px",
                                        top: "227px"
                                    }}></div>
                                <hr width="6%" color="#04AF70" size="3"
                                    style={{
                                        position: "relative",
                                        left: "-25px",
                                        top: "-22px"
                                    }} />
                            </div>
                            <div class="p-3 bd-highlight">
                                <div
                                    name="box5"
                                    style={{
                                        height: "40px",
                                        width: "260px",
                                        backgroundColor: "#027148",
                                        border: "none",
                                        borderRadius: "6px"
                                    }}>
                                    <h5
                                        style={{
                                            position: "absolute",
                                            right: "359px",
                                            top: "282px",
                                            color: "white"
                                        }}>Land&nbsp;Title :</h5>
                                        <h6
                                        style={{
                                            position: "absolute",
                                            right: "219px",
                                            top: "284px",
                                            color: "white"
                                        }}>Warehouse Approved
                                        </h6>
                                </div>
                                <hr width="21%" color="#027148" size="3"
                                    style={{
                                        position: "relative",
                                        left: "260px",
                                        top: "-21px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #027148",
                                        height: "36px",
                                        position: "absolute",
                                        left: "365px",
                                        top: "293px"
                                    }}>

                                </div>
                                <hr width="85%" color="#027148" size="3"
                                    style={{
                                        position: "relative",
                                        left: "-25px",
                                        top: "14px"
                                    }} />
                                <div
                                    style={{
                                        borderLeft: "1px solid #027148",
                                        height: "36px",
                                        position: "absolute",
                                        right: "483px",
                                        top: "294px"
                                    }}></div>
                                <hr width="6%" color="#027148" size="3"
                                    style={{
                                        position: "relative",
                                        left: "-25px",
                                        top: "-22px"
                                    }} />
                            </div>
                        </div>
                    </div>

                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.firstpage.proposalDate} */}
                    </div>
                </div>
            </div>

            {/*---------------------------   specificationsPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.specificationsPage}
                        onChange={() => handlePageCheckboxChange("specificationsPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >
                    </div>
                    <div>
                        <div
                        // onDoubleClick={() => toggleEditState("editSpecificationsPage")}
                        >
                            {editStates.editSpecificationsPage ? (
                                <>
                                    <input
                                        name="specificationsPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px', }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '100px',
                                            width: '700px',
                                            position: 'relative',
                                        }}
                                        src={editedValues.innerViewPage.imagelink2}
                                        alt="Property"
                                    />
                                    <div className="pagecontainer" style={{
                                        backgroundColor: 'white',
                                        height: '330px',
                                        width: '700px'
                                    }}>

                                        <table style={{ fontWeight: 'bold' }}>
                                            <tr style={{ backgroundColor: '#1560bd', color: 'white', fontSize: '7', fontWeight: 'bold' }}>
                                                <th>VALIDATION PARAMETERS :</th>
                                                <th>SPECIFICATIONS</th>
                                            </tr>
                                            <tr>
                                                <td>Area </td>
                                                {editStates.editSpecificationsPageArea ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.area}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      area: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageArea")}>{editedValues.specificationsPage.area}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Total land Area  </td>
                                                {editStates.editSpecificationsPageTotallandArea ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.totalarealand}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      totalarealand: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageTotallandArea")}>{editedValues.specificationsPage.totalarealand}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Title of Land </td>
                                                {editStates.editSpecificationsPageTitleofLand ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.titleofland}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      titleofland: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageTitleofLand")}>{editedValues.specificationsPage.titleofland}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Approach/Access Road Width  </td>
                                                {editStates.editSpecificationsPageApproach ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.approch}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      approch: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageApproach")}>{editedValues.specificationsPage.approch}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Location </td>
                                                {editStates.editSpecificationsPageLocation ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.location}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      location: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageLocation")}>{editedValues.specificationsPage.location}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Clear Height at the Center </td>
                                                {editStates.editSpecificationsPageClearHeightattheCenter ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.clearHeightattheCenter}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      clearHeightattheCenter: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageClearHeightattheCenter")}>{editedValues.specificationsPage.clearHeightattheCenter}</td>
                                                }
                                            </tr>

                                            
                                            <tr>
                                                <td>Clear Height (Side)  </td>
                                                {editStates.editSpecificationsPageClearHeight ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.clearHeight}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      clearHeight: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageClearHeight")}>{editedValues.specificationsPage.clearHeight}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Fire Noc </td>
                                                {editStates.editSpecificationsPageFireNoc ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.fireNoc}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      fireNoc: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageFireNoc")}>{editedValues.specificationsPage.fireNoc}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>No. of Docks </td>
                                                
                                                {editStates.editSpecificationsPageDocks ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.specificationsPage.noofducks}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    specificationsPage: {
                                                                      ...editedValues.specificationsPage,
                                                                      noofducks: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editSpecificationsPageDocks")}>{editedValues.specificationsPage.noofducks}</td>
                                                }
                                            </tr>
                                        </table>

                                    </div>
                                   
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.specificationsPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   commercialPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.commercialPage}
                        onChange={() => handlePageCheckboxChange("commercialPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                       
                    </div>
                    <div>
                        <div
                        // onDoubleClick={() => toggleEditState("editCommercialPage")}
                        >
                            {editStates.editCommercialPage ? (
                                <>
                                    <input
                                        name="commercialPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px', }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '100px',
                                            width: '700px',
                                            position: 'relative',
                                        }}
                                        src={editedValues.innerViewPage.imagelink2}
                                        alt="Property"
                                    />
                                    <div className="pagecontainer" style={{
                                        backgroundColor: 'white',
                                        height: '330px',
                                        width: '700px'
                                    }}>

                                        <table style={{ fontWeight: 'bold', height: '330px' }}>
                                            <tr style={{ backgroundColor: '#1560bd', color: 'white', fontSize: '7', fontWeight: 'bold' }}>
                                                <th>COMMERCIAL  :</th>
                                                <th>SPECIFICATION</th>
                                            </tr>
                                            <tr>
                                                <td>Lease agreement  </td>
                                                {editStates.editCommercialPageLeaseagreement ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.leaseagreement}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      leaseagreement: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageLeaseagreement")}>{editedValues.commercialPage.leaseagreement}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Possession</td>
                                                {editStates.editCommercialPagePossession ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.possession}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      possession: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPagePossession")}>{editedValues.commercialPage.possession}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Area ( carpet)</td>
                                                {editStates.editCommercialPageArea ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.area}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      area: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageArea")}>{editedValues.commercialPage.area}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Rent sq.ft </td>
                                                {editStates.editCommercialPageRent ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.rent}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      rent: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageRent")}>{editedValues.commercialPage.rent}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Suggested by  </td>
                                                {editStates.editCommercialPageSuggested ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.suggestedby}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      suggestedby: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageSuggested")}>{editedValues.commercialPage.suggestedby}</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>Contact  </td>
                                                {editStates.editCommercialPageContact ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.contact}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      contact: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageContact")}>{editedValues.commercialPage.contact}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Company  </td>
                                                {editStates.editCommercialPageCompany ? (
                                                        <>
                                                            <input
                                                            type="text"
                                                            value={editedValues.commercialPage.company}
                                                            onChange={(e) =>
                                                                setEditedValues({
                                                                    ...editedValues,
                                                                    commercialPage: {
                                                                      ...editedValues.commercialPage,
                                                                      company: e.target.value,
                                                                    }
                                                                  })
                                                            }
                                                        />
                                                        </>
                                                    ) :
                                                <td onDoubleClick={() => toggleEditState("editCommercialPageCompany")}>{editedValues.commercialPage.company}</td>
                                                }
                                            </tr>

                                        </table>

                                    </div>
                                   
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.commercialPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   innerViewPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.innerViewPage}
                        onChange={() => handlePageCheckboxChange("innerViewPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >
                    </div>
                    <div>
                        <img
                            style={{
                                marginTop: '0px',
                                height: '100px',
                                width: '700px',
                                position: 'relative',
                            }}
                            src={editedValues.innerViewPage.imagelink2}
                            alt="Property"
                        />
                        <h1
                            style={{
                                position: "relative",
                                textAlign: "center",
                                color: "white",
                                top: "-65px"
                            }}>Inner view
                        </h1>
                        <div onDoubleClick={() => toggleEditState("editInnerViewPage")}>
                            {editStates.editInnerViewPage ? (
                                <>
                                    <input
                                        name="innerViewPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>


                                    <div style={{ width: '100px' }} >
                                        <img
                                            style={{
                                                marginTop: '-42px',
                                                height: '280px',
                                                position: 'relative',
                                                width: '700px',
                                            }}
                                            src={editedValues.innerViewPage.imagelink}
                                            alt="Property"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.innerViewPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   innerViewPage2 Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.innerViewPage2}
                        onChange={() => handlePageCheckboxChange("innerViewPage2")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                    </div>
                    <div>
                    <img
                            style={{
                                marginTop: '0px',
                                height: '100px',
                                width: '700px',
                                position: 'relative',
                            }}
                            src={editedValues.innerViewPage.imagelink2}
                            alt="Property"
                        />
                        <h1
                            style={{
                                position: "relative",
                                textAlign: "center",
                                color: "white",
                                top: "-65px"
                            }}>Inner view
                        </h1>
                        <div onDoubleClick={() => toggleEditState("editInnerViewPage2")}>
                            {editStates.editInnerViewPage2 ? (
                                <>
                                    <input
                                        name="innerViewPage2"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '-42px',
                                                height: '280px',
                                                position: 'relative',
                                                width: '700px',
                                        }}
                                        src={editedValues.innerViewPage2.imagelink}
                                        alt="Property"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.innerViewPage2.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   innerViewPage3 Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.innerViewPage3}
                        onChange={() => handlePageCheckboxChange("innerViewPage3")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    
                    <div>
                    <img
                            style={{
                                marginTop: '0px',
                                height: '100px',
                                width: '700px',
                                position: 'relative',
                            }}
                            src={editedValues.innerViewPage.imagelink2}
                            alt="Property"
                        />
                        <h1
                            style={{
                                position: "relative",
                                textAlign: "center",
                                color: "white",
                                top: "-65px"
                            }}>Inner view
                        </h1>
                        <div onDoubleClick={() => toggleEditState("editInnerViewPage3")}>
                            {editStates.editInnerViewPage3 ? (
                                <>
                                    <input
                                        name="innerViewPage3"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '-42px',
                                            height: '280px',
                                            position: 'relative',
                                            width: '700px',
                                        }}
                                        src={editedValues.innerViewPage3.imagelink}
                                        alt="Property"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                    </div>
                </div>
            </div>
            {/*---------------------------   outerViewPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.outerViewPage}
                        onChange={() => handlePageCheckboxChange("outerViewPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                    </div>
                    <div>
                        <div onDoubleClick={() => toggleEditState("editOuterViewPage")}>
                            {editStates.editOuterViewPage ? (
                                <>
                                    <input
                                        name="outerViewPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '380px',
                                            width: '700px',
                                            position: 'relative',
                                            //  marginLeft: '-109px',
                                        }}
                                        src={editedValues.outerViewPage.imagelink}
                                        alt="Property"
                                    />
                                    <div style={{
                                        backgroundColor: 'white',
                                        height: '37px',
                                        position: "relative",
                                        textAlign: "center",
                                        top: editedValues.outerViewPage.imagelink !== '' ? '-85px' : '205px',
                                        width: '700px'

                                    }}>
                                        <h3 style={{ fontWeight: 'bold', textAlign: 'center', position: 'relative', top: '7px' }} >OUTER VIEW</h3>
                                        <img
                                            style={{
                                                marginTop: '-44px',
                                                height: '39px',
                                                marginLeft: '325px',
                                            }}
                                            src={editedValues.logo.imagelink}
                                            alt="Property"
                                        />

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.outerViewPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   outerViewPage2 Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.outerViewPage2}
                        onChange={() => handlePageCheckboxChange("outerViewPage2")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >
                       
                    </div>
                    <div>
                        <div onDoubleClick={() => toggleEditState("editOuterViewPage2")}>
                            {editStates.editOuterViewPage2 ? (
                                <>
                                    <input
                                        name="outerViewPage2"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '380px',
                                            width: '700px',
                                            position: 'relative',
                                            //  marginLeft: '-109px',
                                        }}
                                        src={editedValues.outerViewPage2.imagelink}
                                        alt="Property"
                                    />
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            position: 'relative',
                                            top: editedValues.outerViewPage2.imagelink !== '' ? '-258px' : '99px',
                                            height: '275px',
                                            width: '275px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            opacity: '0.8',
                                            marginLeft: '440px'
                                        }}
                                    >
                                        <h2 style={{ textAlign: 'center', fontWeight: '900', marginTop: '130px', color: 'black' }}>OUTER VIEW</h2>
                                        <img
                                            style={{
                                                marginTop: '-4px',
                                                height: '90px',
                                                marginLeft: '60px',
                                            }}
                                            src={editedValues.logo.imagelink}
                                            alt="Property"
                                        />
                                    </div>


                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.outerViewPage2.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   layoutPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.layoutPage}
                        onChange={() => handlePageCheckboxChange("layoutPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                        
                    </div>
                    <div>
                        <div onDoubleClick={() => toggleEditState("editlayoutPage")}>
                            {editStates.editlayoutPage ? (
                                <>
                                    <input
                                        name="layoutPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div  >
                                    <Row lg={6}>
                                        <Col lg={2}>
                                            <img
                                                style={{
                                                    marginTop: '0px',
                                                    height: '380px',
                                                    width: '260px',
                                                    // position: 'relative',
                                                }}
                                                src={editedValues.layoutPage.imagelink2}
                                                alt="Property"
                                            />
                                        </Col>
                                        <h2 style={{ fontWeight: '900', marginTop: '130px', color: 'white', marginLeft: '-77px', }}>Layout Plan</h2>

                                        <Col lg={2}>
                                            <img
                                                style={{
                                                    marginTop: '0px',
                                                    height: '380px',
                                                    width: '438px',
                                                    //position: 'relative',
                                                    marginLeft: '50px'
                                                }}
                                                src={editedValues.layoutPage.imagelink}
                                                alt="Property"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.layoutPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   contactUsPage Page----------------------------- */}
            <div>
                <label>
                    <input
                        className="check-box"
                        type="checkbox"
                        checked={selectedPages.contactUsPage}
                        onChange={() => handlePageCheckboxChange("contactUsPage")}
                    />
                </label>
            </div>

            <div className="size">
                <div className="page-container">
                    <div >


                       
                    </div>
                    <div>
                        <div
                        // onDoubleClick={() => toggleEditState("editContactUsPage")}
                        >
                            {editStates.editContactUsPage ? (
                                <>
                                    <input
                                        name="contactUsPage"
                                        data="imagelink"
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleInputChange}
                                        style={{ marginTop: "155px" }}
                                    />
                                    {errorMessage && (
                                        <div className="text-danger">
                                            <b>{errorMessage}</b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ width: '100px' }} >
                                    <img
                                        style={{
                                            marginTop: '0px',
                                            height: '380px',
                                            width: '700px',
                                            position: 'relative',
                                            //  marginLeft: '-109px',
                                        }}
                                        src={editedValues.contactUsPage.imagelink}
                                        alt="Property"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="bottom-section1"
                        style={{
                            fontSize: "12px",
                            marginBottom: "-45px",
                            color: "white",
                        }}
                    >
                        {/* {editedValues.contactUsPage.imagelink} */}
                    </div>
                </div>
            </div>
            {/*---------------------------   Thank you Page----------------------------- */}


            {/* <div className="size">
                <div className="page-container">
                    <div className="thankyou">Thank You</div>
                </div>
            </div> */}
            {/*---------------------------   warehouse keydetail Page----------------------------- */}

            <div></div>
        </div>
    );
};

export default WarehousePdf;
