import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import PubSub from "pubsub-js";
import CityState from "../../constants/CityState.json";
import jwt_decode from "jwt-decode";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import data from "../NewJson";
import { schemaPropertyEdit } from "../common/ValidateSchemaHelper";
import { propertyEditInitialValues } from "../common/InitialValuesHelper";
import ContactNewModal from "../contact/ContactNewModal";
import moment from "moment";
import { floornumber } from "../NewJson";

const PropertyEdit = (props) => {
  const location = useLocation();
  const [property, setProperty] = useState(
    location.state ? location.state : {}
  );
  const [userInfo, setUserInfo] = useState({});
  const [validated, setValidated] = useState(false);
  const [typeOfClient, setTypeOfClient] = useState([]);
  const [transcationType, setTranscationType] = useState([
    property.transactiontype,
  ]);
  const [verticals, setVertical] = useState([]);
  const [verticalType, setVerticalType] = useState([]);
  const [subVerticalType, setSubVerticalType] = useState([]);
  const [active, setActive] = useState(
    location?.state?.showonweb ? true : false
  );
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const [selectedOwner, setSelectedOwner] = useState();
  const [cityList, setCityList] = useState(false);
  const [ofcCityList, setOfcCityList] = useState(false);
  const [ofcstateList, setofcstateList] = useState(false);
  const [stateList, setStateList] = useState(false);
  const [removeIndex, setRemoveIndex] = useState([]);
  const [removePricingOrRental, setRemovePricingOrRental] = useState([]);


  useEffect(() => {
    if (!(property.id && property.id !== '')) {
      setTranscationType(data.Value["Transaction type"]);
    } else {
      setTranscationType(data.Value["Transaction type"]);
      if (property.transactiontype && Object.entries(data.Dependency[property?.transactiontype])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(
          data.Dependency[property?.transactiontype]
        )) {
          temp.push(key);
        }
        setTypeOfClient(temp);
      }
      if (property.typeofclient && Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient]
        )) {
          temp.push(key);
        }
        setVertical(temp);
      }
      if (property.vertical && Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient][property?.vertical])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient][property?.vertical]
        )) {
          temp.push(key);
        }
        setVerticalType(temp);
      }
      if (property.verticaltype && Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient][property?.vertical][property?.verticaltype])?.length > 0) {
        const temp = [];
        for (const [key, value] of Object.entries(data.Dependency[property?.transactiontype][property?.typeofclient][property?.vertical][property?.verticaltype]
        )) {
          temp.push(value);
        }
        setSubVerticalType(temp[0]);
      }
    }
  }, []);

  useEffect(() => {
    var temp = [];
    CityState.map((ele) => {
      if (!temp.includes(ele.state)) {
        temp.push(ele.state);
      }
    });
    setStateList(temp.sort());
    var tempList = CityState.filter((ele) => ele.state === property.state);
    setCityList(tempList.sort());
  }, []);

  useEffect(() => {
    var temp = [];
    CityState.map((ele) => {
      if (!temp.includes(ele.state)) {
        temp.push(ele.state);
      }
    });
    setofcstateList(temp.sort());
    var tempList = CityState.filter((ele) => ele.state === property.officestate);
    setOfcCityList(tempList.sort());
  }, []);

  const [contactList, setContactList] = useState([]);
  const [ownerList, setownerList] = useState([]);
  const [defaultOwner, setDefaultOwner] = useState([]);
  const [defaultContact, setDefaultContact] = useState([]);

  useEffect(() => {
    async function init() {
      // Fetch Contacts
      const conResult = await inventoryApi.fetchContacts();
      if (conResult && conResult.length > 0) {
        setContactList(conResult);
      } else {
        setContactList([]);
      }
      if (property.id && property.contactid) {
        setDefaultContact([
          {
            id: "",
            contactname: conResult.find((e) => e.id === property.contactid)
              ?.contactname,
          },
        ]);
      }
      // else if (defaultContact.id !== '' ) {
      //   console.log('conResult.find((e) => e.id === property.contactid)',conResult.find((e) => e.id === defaultContact.contactid));
      //   setDefaultContact([
      //     {
      //       id: "",
      //       contactname: conResult.find((e) => e.id === property.contactid)
      //         ?.contactname,
      //     },
      //   ]);
      // }
      else {
        setDefaultContact([{ id: "", contactname: "" }]);
      }
    }
    init();
  }, [modalShow]);

  useEffect(() => {
    async function init() {
      if (localStorage.getItem("token") && !location?.state) {
        let user = jwt_decode(localStorage.getItem("token"));
        setUserInfo(user);
        var obj = {};
        obj.value = user.id;
        obj.label = user.username;
        setSelectedOwner(obj);
        setProperty({ ...property, ownerid: user.id });
      }

      // Fetch Owners from user
      const result = await inventoryApi.fetchUsers();
      if (result) {
        setownerList(result);
        if (property.id) {
          setDefaultOwner([
            { id: property.ownerid, username: property.ownername },
          ]);
        } else {
          setDefaultOwner([{ id: "", username: "" }]);
        }
      }

      let owner;
      owner = await inventoryApi.fetchUsers();
      if (owner && owner?.length) {
        setownerList(owner);
      } else {
        setownerList([]);
      }
    }
    init();
  }, []);

  // useEffect(() => {
  //   async function init() {
  //     // Fetch Contacts
  //     const conResult = await inventoryApi.fetchContacts();
  //     if (conResult && conResult.length > 0) {
  //       setContactList(conResult);
  //     } else {
  //       setContactList([]);
  //     }
  //   }
  //   init();
  // }, [modalShow]);

  const handleSubmitSave = async (values) => {

    values.heightdetails = values.heightdetails.filter((item) => (item.floor !== "" || item.unit !== "" || item.value !== ""));
    values.areadetails = values.areadetails.filter((item) => item.floor !== "" || item.unit !== "" || item.value !== "" || item.area !== "");
    values.pricingorrental = values.pricingorrental.filter((item) => (item.floorno !== "" || item.unit !== "" || item.area !== "" ));
    
    for (const key in values) {
      if (values[key] === undefined || values[key] === null) {
        values[key] = "";
      }
    }

    console.log('values',values);

    property.showonweb = active;
    let result = {};
    if (property.id) {
      values.id = property.id;
      if (removeIndex.length > 0 || removePricingOrRental.length > 0) {
        let deletAarea = await inventoryApi.deletePropertyAreadetails(removeIndex)
        let deletPricingOrRental = await inventoryApi.deletePropertyPricingorRentaldetails(removePricingOrRental)
      }
      result = await inventoryApi.saveProperty(values);
      console.log('resultee',result);
      if (!result.error) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/properties/${property.id}`, { state: result });
      }
      else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Record Not Saved",
          message: result.error,
        });
      }
    }
    else {
      result = await inventoryApi.createProperty(values);
      console.log("create result", result);

      if (!result.error) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/properties/${result.id}`, { state: result });
      }
      else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Record Not Saved",
          message: result.error,
        });
      }
    }
  };
  const handleCancel = () => {
    if (property.id)
      navigate("/properties/" + property.id, { state: property });
    else navigate("/properties");
  };
  console.log('property',property);

  
  return (
    <Container className="view-form inputbox">
      {location?.state?.id ? (
        <CustomSeparator
          cmpListName="Inventory "
          cmpViewName={property.name}
          currentCmpName="Edit"
          indexLength="2"
          indexViewLength="3"
          cmpViewUrl={"/properties/" + property.id}
          url="/properties"
        ></CustomSeparator>
      ) : (
        <CustomSeparator
          cmpListName="Inventory"
          currentCmpName="Create"
          indexLength="2"
          url="/properties"
        ></CustomSeparator>
      )}

      <Row className="mt-4 mx-2 justify-content-between" >
        <Col lg={12} className="ibs-form-section" >
          <Formik
            validationSchema={schemaPropertyEdit(property)}
            onSubmit={handleSubmitSave}
            initialValues={propertyEditInitialValues(property)}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              setFieldValue,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row className="view-form-header align-items-center">
                  <Col lg={3}>
                    {property.id ? (
                      <>
                        Edit Inventory
                        <h4>{property.name}</h4>
                      </>
                    ) : (
                      "New Inventory"
                    )}
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <label>
                      Property Name<b class="red-star">*</b>
                    </label>
                    <Field
                      style={{ textTransform: "capitalize" }}
                      required
                      type="text"
                      name="name"
                      placeholder="Enter Property Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </Col>

                  <Col lg={3} style={{ marginLeft: '-18px' }}>
                    <Form.Group className="mx-3" controlId="contact">
                      <Form.Label className="form-view-label" htmlFor="contact">
                        Contact <b class="red-star"></b>
                      </Form.Label>
                      {defaultContact.length > 0 ? (
                        <InputGroup>
                          <Typeahead
                            required
                            id="basic-typeahead-single2"
                            defaultSelected={defaultContact}
                            name="contactid"
                            labelKey="contactname"
                            options={contactList}
                            onChange={(event) => {
                              if (event.length > 0) {
                                setFieldValue("contactid", event[0].id);
                              } else {
                                setFieldValue("contactid", "");
                              }
                            }}
                            placeholder="Choose a contact..."
                          />
                          <Button style={{ marginLeft: '-60px' }}
                            variant="primary"
                            onClick={() => { setModalShow(true) }}
                          >
                            <b>+</b>
                          </Button>
                          <ErrorMessage
                            name="contactid"
                            component="div"
                            className="error-message"
                          />
                        </InputGroup>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>

                  {/* <Col lg={3}>
                    <label>Super-Built Up Area</label>
                    <Field
                      required
                      type="text"
                      name="superbuiltuparea"
                      placeholder="Enter Particulars"
                      value={values.superbuiltuparea}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={3}>
                    <label>Floor</label>
                    <Field
                      type="text"
                      name="floor"
                      placeholder="Enter floor  "
                      value={values.floor}
                      onChange={handleChange}
                    />
                  </Col>

                 <Col lg={6}>
                    <label>Area / From / To</label>
                    <div className="d-flex w-80">
                      <Field
                        as="select"
                        name="arearangein"
                        placeholder="Enter Area"
                        value={values.arearangein}
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="Gaj">Gaj</option>
                        <option value="Sq.feet">Sq.feet</option>
                        <option value="Yards">Yards</option>
                        <option value="Sq. yards">Sq. yards</option>
                        <option value="Sq. Meter">Sq. Meter</option>
                      </Field>
                      <Field
                        type="number"
                        name="area"
                        placeholder="Enter from"
                        value={values.area}
                        onChange={handleChange}
                      />

                      <Field
                        type="number"
                        name="areato"
                        placeholder="Enter to"
                        value={values.areato}
                        onChange={handleChange}
                      />
                    </div>
                    {((touched.arearangein && errors.arearangein) ||
                      (errors.area && touched.area) ||
                      (errors.areato && touched.areato)) && (
                      <div className="error-message">
                        {errors.arearangein || errors.area || errors.areato}
                      </div>
                    )}
                  </Col> */}
                </Row>

                <Row>
                  <Col lg={3}>
                    <label>
                      Transaction Type<b class="red-star"></b>
                    </label>
                    <Field
                      className="Field"
                      as="select"
                      name="transactiontype "
                      value={values.transactiontype}
                      onChange={(e) => {
                        setFieldValue("transactiontype", e.target.value);
                        setFieldValue("typeofclient", '');
                        setFieldValue("vertical", "");
                        setFieldValue("verticaltype", "");
                        setFieldValue("subverticaltype", "");
                        const temp = [];
                        if (e.target.value !== "") {
                          if (
                            Object.entries(data.Dependency[e.target.value])
                              .length > 0
                          ) {
                            for (const [key, value] of Object.entries(
                              data.Dependency[e.target.value]
                            )) {
                              temp.push(key);
                            }
                            setTypeOfClient(temp);
                            const tmp = [];
                            setFieldValue("typeofclient", temp[0]);

                            if (
                              Object.entries(data.Dependency[e.target.value])
                                .length > 0
                            ) {
                              for (const [key, value] of Object.entries(
                                data.Dependency[e.target.value][temp[0]]
                              )) {
                                tmp.push(key);
                              }
                              setVertical(tmp);
                            }
                          } else {
                            setTypeOfClient(data.Value["Type of client"]);
                          }
                        } else {
                          setTypeOfClient([]);
                        }

                        if(e.target.value === 'Lease'){
                          setRemovePricingOrRental(   values?.pricingorrental?.filter(price => price?.type === 'pricing').map(item => item.id))
                         }else if(e.target.value === 'Sell'){
                           setRemovePricingOrRental( values?.pricingorrental?.filter(price => price?.type === 'rent').map(item => item.id))
                        }

                        if (property.id) {
                          if (e.target.value === 'Sell') {
                            let details = property?.pricingorrental?.filter(e => e.type === 'pricing');
                            setFieldValue("pricingorrental", details);
                            if (property?.pricingorrental === null || details?.length === 0) {
                              setFieldValue("pricingorrental", [{
                                id: undefined,
                                area: "",
                                unit: "",
                                floorno: "",
                                rentalvalue: "",
                                perunitcost: "",
                                saleablearea: "",
                                chargeblearea: "",
                                quotedrentpermonth: "",
                                type: "pricing"
                              }]);
                            }
                          } else {
                            let details = property?.pricingorrental?.filter(e => e.type === 'rent');
                            setFieldValue("pricingorrental", details);
                            if (property?.pricingorrental === null ||details?.length === 0) {
                              setFieldValue("pricingorrental", [{
                                id: undefined,
                                area: "",
                                unit: "",
                                floorno: "",
                                rentalvalue: "",
                                perunitcost: "",
                                saleablearea: "",
                                chargeblearea: "",
                                quotedrentpermonth: "",
                                type: "rent"
                              }]);
                            }
                          }
                        } else {

                          if (e.target.value === 'Sell') {
                            setFieldValue("pricingorrental", [{
                              id: undefined,
                              area: "",
                              unit: "",
                              floorno: "",
                              rentalvalue: "",
                              perunitcost: "",
                              saleablearea: "",
                              chargeblearea: "",
                              quotedrentpermonth: "",
                              type: "pricing"
                            }]);
                          } else {
                            setFieldValue("pricingorrental", [{
                              id: undefined,
                              area: "",
                              unit: "",
                              floorno: "",
                              rentalvalue: "",
                              perunitcost: "",
                              saleablearea: "",
                              chargeblearea: "",
                              quotedrentpermonth: "",
                              type: "rent"
                            }]);
                          }
                        }
                        console.log('values?.pricingorrental',values?.pricingorrental);
                      }}
                    >
                      <option value="">--Select--</option>
                      {transcationType &&
                        transcationType.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="transactiontype"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={3}>
                    <label>
                      Type Of Client<b class="red-star"></b>
                    </label>
                    <Field
                      title='Please Select Dependent'
                      className="Field"
                      disabled={!values.transactiontype}
                      as="select"
                      name="typeofclient"
                      value={values.typeofclient}
                      onChange={(e) => {
                        setFieldValue("typeofclient", e.target.value);
                        setFieldValue("vertical", "");
                        setFieldValue("verticaltype", "");
                        setFieldValue("subverticaltype", "");
                        const temp = [];
                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[values.transactiontype]
                            ).length > 0
                          ) {
                            for (const [key, value] of Object.entries(
                              data.Dependency[values.transactiontype][
                              e.target.value
                              ]
                            )) {
                              temp.push(key);
                            }
                            setVertical(temp);
                          } else {
                            setVertical(data.Value["Vertical"]);
                          }
                        } else {
                          setVertical([]);
                        }
                      }}
                    >
                      <option value="">--Select--</option>
                      {typeOfClient.length > 0 &&
                        typeOfClient.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="typeofclient"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={3}>
                    <label>
                      Vertical In Property<b class="red-star"></b>
                    </label>
                    <Field
                      title=' Please Select Dependent'
                      className="Field"
                      disabled={!values.typeofclient}
                      as="select"
                      name="vertical"
                      value={values.vertical}
                      onChange={(e) => {
                        setFieldValue("vertical", e.target.value);
                        setFieldValue("verticaltype", "");
                        setFieldValue("subverticaltype", "");

                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[values.transactiontype]
                            ).length > 0
                          ) {
                            const temp = [];
                            for (const [key, value] of Object.entries(
                              data.Dependency[values.transactiontype][
                              values.typeofclient
                              ][e.target.value]
                            )) {
                              temp.push(key);
                            }
                            setVerticalType(temp);
                          } else {
                            setVerticalType(data.Value["Vertical-type"]);
                          }
                        } else {
                          setVerticalType([]);
                        }
                      }}
                    >
                      <option value="">--Select--</option>
                      {verticals &&
                        verticals.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="vertical"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={3}>
                    <label>
                      Vertical Type<b class="red-star"></b>
                    </label>
                    <Field
                      title=' Please Select Dependent'
                      disabled={!values.vertical}
                      className="Field"
                      as="select"
                      name="verticaltype"
                      value={values.verticaltype}
                      onChange={(e) => {
                        setFieldValue("verticaltype", e.target.value);
                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[values.transactiontype]
                            ).length > 0
                          ) {
                            const temp = [];
                            for (const [key, value] of Object.entries(
                              data.Dependency[values.transactiontype][
                              values.typeofclient
                              ][values.vertical][e.target.value]
                            )) {
                              temp.push(...value);
                            }
                            setSubVerticalType(temp);
                            setFieldValue("subverticaltype", temp[0]);
                          } else {
                            setSubVerticalType(data.Value["Sub vertical type"]);
                          }
                        } else {
                          setSubVerticalType([]);
                        }
                      }}
                    >
                      <option value="">--Select--</option>
                      {verticalType &&
                        verticalType.map((value) => (
                          <>
                            <option value={value}>{value}</option>
                          </>
                        ))}

                    </Field>
                    <ErrorMessage
                      name="verticaltype"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={3}>
                    <label>
                      Sub Vertical Type<b class="red-star"></b>
                    </label>
                    <Field
                      title=' Please Select Dependent'
                      disabled={!values.verticaltype}
                      className="Field"
                      as="select"
                      name="subverticaltype"
                      value={values.subverticaltype}
                      onChange={(e) =>
                        setFieldValue("subverticaltype", e.target.value)
                      }
                    >
                      <option value="">--Select--</option>
                      {values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ?
                        <>
                          <option value="Ready to Move">Ready to Move</option>
                          <option value="BTS">BTS</option>
                          <option value="Under Construction">Under Construction</option>
                        </> : ''
                      }
                      {subVerticalType &&
                        subVerticalType.map((value) => (
                          <>
                            <option value={value}>{value}</option>
                          </>
                        ))}
                      {/* <option value="Ready to Move">Ready to Move</option>
                      <option value='BTS'>BTS</option>
                      <option value='Under Construction'>Under Construction</option> */}
                    </Field>
                    <ErrorMessage
                      name="subverticaltype"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  {values.vertical === 'Retail' ? <Col lg={3}>
                    <label>Retail Category</label>
                    <Field
                      as="select"
                      name="retailsubvertical"
                      placeholder="Enter Retail  Sub Vertical"
                      value={values.retailsubvertical}
                      onChange={handleChange}>
                      <option value="">--Select--</option>
                      <option value="Footware,Bags , Luggage & Accessories">Footware,Bags , Luggage & Accessories</option>
                      <option value="Luggage & Accessories">Luggage & Accessories</option>
                      <option value="Watches">Watches</option>
                      <option value="Fashion Accessories">Fashion Accessories</option>
                      <option value="Kid's Fashion">Kid's Fashion</option>
                      <option value="Jewellery">Jewellery</option>
                      <option value="Women's Fashion">Women's Fashion</option>
                      <option value="Cafes,Restaurants & Desserts">Cafes,Restaurants & Desserts</option>
                      <option value="Sportwear & Sportsgear">Sportwear & Sportsgear</option>
                      <option value="Books & Toys">Books & Toys</option>
                      <option value="Hypermarkets, Supermarket & Gourmet">Hypermarkets, Supermarket & Gourmet</option>
                      <option value="Electronics, Gizmos & Gadgets">Electronics, Gizmos & Gadgets</option>
                      <option value="Departmental stores">Departmental stores</option>
                      <option value="General Fashion">General Fashion</option>
                      <option value="Multiplex, Fec & Entertainment">Multiplex, Fec & Entertainment </option>
                      <option value="Cosmetics, Salon, Spa & Optics">Cosmetics, Salon, Spa & Optics</option>
                    </Field>
                  </Col> : ''}

                  {/* <Col lg={3}>
                    <label>Possession Status</label>
                    <Field
                      as="select"
                      name="possessionstatus"
                      value={values.possessionstatus}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                      
                    </Field>
                  </Col> */}
                  {!(values.vertical === 'Office' || values.vertical === 'Retail' || values.vertical === 'Land' || values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory") ? <Col lg={3}>

                    <label>Property Type</label>
                    <Field
                      as="select"
                      name="propertytype"
                      value={values.propertytype}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="Sell">Sell</option>
                      <option value="Lease">Lease</option>
                      <option value="Rent">Rent</option>
                      <option value="Investiment">Investiment</option>
                      <option value="Commercial">Commercial</option>
                      {/* <option value="Additional Rooms">Additional Rooms</option>
                      <option value="Facing">Facing</option> */}
                    </Field>
                  </Col> :
                    <Col lg={3}>
                      <label>Located In</label>
                      <Field
                        type='text'
                        placeholder='Enter Location'
                        name="locatedin"
                        value={values.locatedin}
                        onChange={handleChange}
                      />
                    </Col>
                  }
                    <Col lg={3}>
                      <label>Availability</label>
                      <Field
                        as="select"
                        name="availability"
                        value={values.availability}
                        onChange={handleChange}
                      >
                        <option value="">--Select--</option>
                        <option value="Available">Available</option>
                        <option value="Not Available">Not Available</option>
                      </Field>
                    </Col> 
                  {values.availability === 'Available' ?
                    <Col lg={3}>
                      <label> Possession Date</label>
                      <Field
                        type="date"
                        name="possessiondate"
                        value={values.possessiondate}
                        onChange={handleChange}
                      />
                    </Col> : ''
                  }
                  {values.availability === 'Not Available' ?
                    <Col lg={3}>
                      <label>
                        Lease Expiration Date
                        {/* <b class="red-star"></b> */}
                      </label>
                      <Field
                        min={moment(new Date()).format("DD/MM/YYYY")}
                        type="date"
                        name="leaseexpirationdate"
                        value={values.leaseexpirationdate}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="leaseexpirationdate"
                        component="div"
                        className="error-message"
                      />
                    </Col> : ''
                  }


                  {!(values.vertical === 'Retail' || values.vertical === 'Land' || values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory") ? <Col lg={3}>
                    <label>Furnished Status</label>
                    <Field
                      as="select"
                      name="furnishedstatus"
                      value={values.furnishedstatus}
                      onChange={handleChange}
                    >
                      {values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ? (
                        <>
                          <option value="">--Select--</option>
                          <option value="Semi Furnished">Available</option>
                          <option value="Furnished">Not Available</option>
                        </>
                      ) :
                        (
                          <>
                            <option value="">--Select--</option>
                            <option value="Semi Furnished">Semi Furnished</option>
                            <option value="Furnished">Furnished</option>
                            <option value="Unfurnished">Unfurnished</option>
                          </>
                        )
                      }
                    </Field>
                  </Col> : ''}



                  <Col lg={3}>
                    <label>Description</label>
                    <Field
                      as="textarea"
                      className="textarea"
                      name="description"
                      placeholder="Enter Description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>

                  {(values.vertical === 'Hotels') ?
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Hotel Details
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <label>No. of Keys</label>
                          <div className="d-flex w-80">
                            <Field
                              type="number"
                              name="noofkeys"
                              placeholder="Enter No. of Keys"
                              value={values.noofkeys}
                              onChange={handleChange}
                            />
                          </div>
                          {touched.noofkeys && (
                            <div className="error-message">
                              {errors.noofkeys}
                            </div>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label> Room size</label>
                          <div className="d-flex w-80">
                            <Field
                              as="select"
                              name="unit"
                              placeholder="Enter unit"
                              value={values.unit}
                              onChange={handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Ft.">Ft.</option>
                              <option value="m">m</option>

                            </Field>
                            <Field
                              className="mx-1"
                              type="number"
                              name="length"
                              placeholder="Enter Length"
                              value={values.length}
                            />
                            <Field
                              className="mx-1"
                              type="number"
                              name="width"
                              placeholder="Enter Width"
                              value={values.width}
                            />
                          </div>
                          {(touched.length ||
                            touched.length) && (
                              <div className="error-message">
                                {errors.closeareaunit ||
                                  errors.closeareavalue}
                              </div>
                            )}
                        </Col>


                        <Col lg={3}>
                          <label>Architect Name</label>
                          <div className="d-flex w-80">
                            <Field
                              type="text"
                              name="architectname"
                              placeholder="Enter Architect Name"
                              value={values.architectname}
                              onChange={handleChange}
                            />
                          </div>
                          {touched.architectname && (
                            <div className="error-message">
                              {errors.architectname}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Banquet Capacity</label>
                          <div className="d-flex w-80">
                            <Field
                              type="number"
                              name="banquetcapacity"
                              placeholder="Enter Banquet Capacity"
                              value={values.banquetcapacity}
                              onChange={handleChange}
                            />
                          </div>
                          {touched.banquetcapacity && (
                            <div className="error-message">
                              {errors.banquetcapacity}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>No. of service Lifts</label>
                          <div className="d-flex w-80">
                            <Field
                              type="number"
                              name="noofservicelifts"
                              placeholder="Enter No. of service Lifts"
                              value={values.noofservicelifts}
                              onChange={handleChange}
                            />
                          </div>
                          {touched.noofservicelifts && (
                            <div className="error-message">
                              {errors.noofservicelifts}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>No. of Car parking</label>
                          <div className="d-flex w-80">
                            <Field
                              type="number"
                              name="noofcarparking"
                              placeholder="Enter No. Car parking"
                              value={values.noofcarparking}
                              onChange={handleChange}
                            />
                          </div>
                          {touched.noofcarparking && (
                            <div className="error-message">
                              {errors.noofcarparking}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </> : ''}

                  {(values.vertical === "Warehouse" || values.vertical === 'Cold storage' || values.vertical === 'Factory' || values.verticaltype === 'Warehousing') ?
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Warehousing Details
                          </h5>
                        </Col>
                      </Row>
                      {/* <Col lg={3}>
                        <label>Rental Unit</label>
                        <Field
                          as="select"
                          name="rentalunit"
                          placeholder="Enter unit"
                          value={values.rentalunit}
                          onChange={handleChange}
                        >
                          <option value="">--Select unit--</option>
                          <option value="Sq.feet">Sq.feet</option>
                          <option value="Sq. yards">Sq. yards</option>
                          <option value="Sq. Meter">Sq. Meter</option>
                        </Field>
                      </Col>
                      <Col lg={3}>
                        <label>Rental Value</label>
                        <Field
                          className="mx-1"
                          type="text"
                          name="rentalvalue"
                          placeholder="Enter value"
                          value={values.rentalvalue}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("quotedrentonchargeablearea", e.target.value * values.chargeablearea);
                          }
                          }
                        />
                        {(touched.rentalunit ||
                          touched.rentalvalue) && (
                            <div className="error-message">
                              {errors.rentalunit || errors.rentalvalue}
                            </div>
                          )}
                      </Col>
                      <Col lg={3}>
                        <label>Approx. Chargeable Area (Sq.ft)</label>
                        <Field
                          type="text"
                          name="chargeablearea"
                          placeholder="Enter Approx. Chargeable Area (Sq.ft)"
                          value={values.chargeablearea}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("quotedrentonchargeablearea", values.rentalvalue * e.target.value);
                          }
                          } />

                        {touched.chargeablearea && (
                          <div className="error-message">
                            {errors.chargeablearea}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Quoted Rent On Chargeable Area</label>
                        <Field
                          type="text"
                          name="quotedrentonchargeablearea"
                          placeholder="Enter Quoted Rent On Chargeable Area"
                          value={values.quotedrentonchargeablearea}
                          onChange={handleChange}
                          disabled
                        >
                         
                        </Field>

                        {touched.quotedrentonchargeablearea && (
                          <div className="error-message">
                            {errors.quotedrentonchargeablearea}
                          </div>
                        )}
                      </Col> */}

                      <Col lg={3}>
                        <label>No. of Docks</label>
                        <div className="d-flex w-80">
                          <Field
                            type="number"
                            name="noofdocksvalue"
                            placeholder="Enter value"
                            value={values.noofdocksvalue}
                            onChange={handleChange}
                          />
                        </div>
                        {touched.noofdocksvalue && (
                          <div className="error-message">
                            {errors.noofdocksvalue}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Dock height in ft.</label>
                        <div className="d-flex w-80">
                          <Field
                            type="number"
                            name="dockheight"
                            placeholder="Enter value"
                            value={values.dockheight}
                            onChange={handleChange}
                          />
                        </div>
                        {touched.dockheight && (
                          <div className="error-message">
                            {errors.dockheight}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Dock level</label>
                        <Field
                          as='select'
                          name="docklevel"
                          value={values.docklevel}
                          onChange={handleChange}
                        >
                          <option value=''>--select--</option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </Field>

                        {touched.firenoc && (
                          <div className="error-message">
                            {errors.firenoc}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>No. of Washrooms</label>
                        <div className="d-flex w-80">
                          <Field
                            type="number"
                            name="noofwashroomsvalue"
                            placeholder="Enter value"
                            value={values.noofwashroomsvalue}
                          />
                        </div>
                        {touched.noofwashroomsvalue && (
                          <div className="error-message">
                            {errors.noofwashroomsvalue}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Open Area</label>
                        <div className="d-flex w-80">
                          <Field
                            as="select"
                            name="openareaunit"
                            placeholder="Enter Area"
                            value={values.openareaunit}
                            onChange={handleChange}
                          >
                            <option value="">--Select--</option>
                            {/* <option value="Gaj">Gaj</option> */}
                            <option value="Sq.feet">Sq.feet</option>
                            <option value="Yards">Yards</option>
                            <option value="Sq. yards">Sq. yards</option>
                            <option value="Sq. Meter">Sq. Meter</option>
                          </Field>
                          <Field
                            className="mx-1"
                            type="text"
                            name="openareavalue"
                            placeholder="Enter value"
                            value={values.openareavalue}
                          />
                        </div>
                        {(touched.openareaunit ||
                          touched.openareavalue) && (
                            <div className="error-message">
                              {errors.openareaunit ||
                                errors.openareavalue}
                            </div>
                          )}
                      </Col>

                      <Col lg={3}>
                        <label> Covered Area</label>
                        <div className="d-flex w-80">
                          <Field
                            as="select"
                            name="closeareaunit"
                            placeholder="Enter close area unit"
                            value={values.closeareaunit}
                            onChange={handleChange}
                          >
                            <option value="">--Select--</option>
                            {/* <option value="Gaj">Gaj</option> */}
                            <option value="Sq.feet">Sq.feet</option>
                            <option value="Yards">Yards</option>
                            <option value="Sq. yards">Sq. yards</option>
                            <option value="Sq. Meter">Sq. Meter</option>
                          </Field>
                          <Field
                            className="mx-1"
                            type="text"
                            name="closeareavalue"
                            placeholder="Enter value"
                            value={values.closeareavalue}
                          />
                        </div>
                        {(touched.closeareaunit ||
                          touched.closeareavalue) && (
                            <div className="error-message">
                              {errors.closeareaunit ||
                                errors.closeareavalue}
                            </div>
                          )}
                      </Col>


                      {/* <Col lg={3}>

                        <label>Rental Unit</label>
                        <Field
                          as="select"
                          name="rentalunit"
                          placeholder="Enter unit"
                          value={values.rentalunit}
                          onChange={handleChange}
                        >
                          <option value="">--Select unit--</option>
                          <option value="Sq.feet">Sq.feet</option>
                          <option value="Sq. yards">Sq. yards</option>
                          <option value="Sq. Meter">Sq. Meter</option>
                        </Field>
                      </Col>
                      <Col lg={3}>
                        <label>Rental Value</label>
                        <Field
                          className="mx-1"
                          type="text"
                          name="rentalvalue"
                          placeholder="Enter value"
                          value={values.rentalvalue}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("quotedrentonchargeablearea", e.target.value * values.chargeablearea);
                          }
                          }
                        />
                        {(touched.rentalunit ||
                          touched.rentalvalue) && (
                            <div className="error-message">
                              {errors.rentalunit || errors.rentalvalue}
                            </div>
                          )}
                      </Col>
                      <Col lg={3}>
                        <label>Approx. Chargeable Area (Sq.ft)</label>
                        <Field
                          type="text"
                          name="chargeablearea"
                          placeholder="Enter Chargeable Area"
                          value={values.chargeablearea}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("quotedrentonchargeablearea", values.rentalvalue * e.target.value);
                          }
                          } />

                        {touched.chargeablearea && (
                          <div className="error-message">
                            {errors.chargeablearea}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Quoted Rent On Chargeable Area</label>
                        <Field
                          type="text"
                          name="quotedrentonchargeablearea"
                          placeholder="Enter Quoted Rent On Chargeable Area"
                          value={values.quotedrentonchargeablearea}
                          onChange={handleChange}
                          disabled
                        >
                          
                        </Field>

                        {touched.quotedrentonchargeablearea && (
                          <div className="error-message">
                            {errors.quotedrentonchargeablearea}
                          </div>
                        )}
                      </Col> */}
                      <Col lg={3}>
                        <label>Type of Warehouse</label>
                        <Field
                          as="select"
                          name="typeofwarehouse"
                          value={values.typeofwarehouse}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          <option value="PEB">PEB</option>
                          <option value="RCC">RCC</option>
                          <option value="PEB+RCC">PEB+RCC</option>
                        </Field>

                        {touched.typeofwarehouse && (
                          <div className="error-message">
                            {errors.typeofwarehouse}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Floor</label>
                        <Field
                          as="select"
                          name="floor"
                          value={values.floor}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          <option value="Ground">Ground</option>
                          <option value="Mezzanine">Mezzanine</option>
                        </Field>

                        {touched.floor && (
                          <div className="error-message">
                            {errors.floor}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Covered area offered in sq. ft</label>
                        <Field
                          type="text"
                          name="offeredcarpetarea"
                          placeholder='Enter Covered area offered in sq. ft'

                          value={values.offeredcarpetarea}
                          onChange={handleChange}
                        />

                        {touched.offeredcarpetarea && (
                          <div className="error-message">
                            {errors.offeredcarpetarea}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Height (ft.) Side</label>
                        <Field
                          type="text"
                          name="heightside"
                          placeholder="Enter Height (ft.) Side"
                          value={values.heightside}
                          onChange={handleChange}
                        />

                        {touched.heightside && (
                          <div className="error-message">
                            {errors.heightside}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Height (ft.) Center</label>
                        <Field
                          type="text"
                          name="heightcenter"
                          placeholder="Enter Height (ft.) Center"
                          value={values.heightcenter}
                          onChange={handleChange}
                        />

                        {touched.heightcenter && (
                          <div className="error-message">
                            {errors.heightcenter}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Type of Flooring</label>
                        <Field
                          as="select"
                          name="typeofflooring"
                          value={values.typeofflooring}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          <option value="FM2">FM2</option>
                          <option value="Trimix">Trimix</option>
                          <option value="VDF">VDF</option>
                          <option value="VDC">VDC</option>
                          <option value="Kota Stone">Kota Stone</option>
                          <option value="Tiles">Tiles</option>
                          <option value="Marbels">Marbels</option>
                        </Field>

                        {touched.typeofflooring && (
                          <div className="error-message">
                            {errors.typeofflooring}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Fire Hydrants</label>
                        <Field
                          type="text"
                          name="firehydrants"
                          placeholder="Enter Fire Hydrants"
                          value={values.firehydrants}
                          onChange={handleChange}
                        />

                        {touched.firehydrants && (
                          <div className="error-message">
                            {errors.firehydrants}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Fire Sprinkelers</label>
                        <Field
                          type="text"
                          name="firesprinkelers"
                          placeholder="Enter Fire Sprinkelers"
                          value={values.firesprinkelers}
                          onChange={handleChange}
                        />

                        {touched.firesprinkelers && (
                          <div className="error-message">
                            {errors.firesprinkelers}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Fire NOC</label>
                        <Field
                          as='select'
                          name="firenoc"
                          value={values.firenoc}
                          onChange={handleChange}
                        >
                          <option value=''>--Select--</option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </Field>

                        {touched.firenoc && (
                          <div className="error-message">
                            {errors.firenoc}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Security Deposit</label>
                        <Field
                          type="number"
                          name="securitydeposit"
                          placeholder="Enter Security Deposit"
                          value={values.securitydeposit}
                          onChange={handleChange}
                        />

                        {touched.securitydeposit && (
                          <div className="error-message">
                            {errors.securitydeposit}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Advance </label>
                        <Field
                          type="number"
                          name="advance"
                          placeholder="Enter an advance"
                          value={values.advance}
                          onChange={handleChange}
                        />

                        {touched.advance && (
                          <div className="error-message">
                            {errors.advance}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Common Area Maintenance (CAM)</label>
                        <Field
                          type="text"
                          name="commonareamaintaince"
                          placeholder="Enter Common Area Maintenance"
                          value={values.commonareamaintaince}
                          onChange={handleChange}
                        />

                        {touched.commonareamaintaince && (
                          <div className="error-message">
                            {errors.commonareamaintaince}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Possession Status</label>
                        <Field
                          as="select"
                          name="possessionstatus"
                          value={values.possessionstatus}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          <option value="Available">Available</option>
                          <option value="Not Available">
                            Not Available
                          </option>
                        </Field>
                      </Col>
                      <Col lg={3}>
                        <label>Additional Information</label>
                        <Field
                          type="text"
                          name="addtionalinformation"
                          placeholder="Additional Information"
                          value={values.addtionalinformation}
                          onChange={handleChange}
                        />

                        {touched.addtionalinformation && (
                          <div className="error-message">
                            {errors.addtionalinformation}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Road width in ft.</label>
                        <Field
                          type="text"
                          placeholder='Enter Road width in ft.                          '
                          name="roadwidth"
                          value={values.roadwidth}
                          onChange={handleChange}
                        />

                        {touched.roadwidth && (
                          <div className="error-message">
                            {errors.roadwidth}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Labour Room</label>
                        <Field
                          type="text"
                          placeholder='Enter Labour Room'
                          name="labourroom"
                          value={values.labourroom}
                          onChange={handleChange}
                        />

                        {touched.labourroom && (
                          <div className="error-message">
                            {errors.labourroom}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Guard Room</label>
                        <Field
                          type="text"
                          placeholder='Enter Guard Room'
                          name="guardroom"
                          value={values.guardroom}
                          onChange={handleChange}
                        />

                        {touched.guardroom && (
                          <div className="error-message">
                            {errors.guardroom}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Power Connection (KV)</label>
                        <Field
                          type="text"
                          placeholder='Enter Power Connection (KV)'
                          name="powerconnection"
                          value={values.powerconnection}
                          onChange={handleChange}
                        />

                        {touched.powerconnection && (
                          <div className="error-message">
                            {errors.powerconnection}
                          </div>
                        )}
                      </Col>

                      <Col lg={3}>
                        <label>Water connection (Drop Down)</label>
                        <Field
                          as='select'
                          placeholder='Enter Power Connection (KV)'
                          name="waterconnection"
                          value={values.waterconnection}
                          onChange={handleChange}
                        >
                          <option value=''> Select</option>
                          <option value='Boring'> Boring</option>
                          <option value='Supply'> Supply</option>
                        </Field>

                        {touched.waterconnection && (
                          <div className="error-message">
                            {errors.waterconnection}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Flooring Loading Capacity ( in tons)</label>
                        <Field
                          type="text"
                          placeholder='Enter Flooring Loading Capacity ( in tons)'
                          name="flooringloadingcapacity"
                          value={values.flooringloadingcapacity}
                          onChange={handleChange}
                        />

                        {touched.flooringloadingcapacity && (
                          <div className="error-message">
                            {errors.flooringloadingcapacity}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Near-by Warehouses</label>
                        <Field
                          type="text"
                          placeholder='Enter Near-by Warehouses'
                          name="nearbywarehouses"
                          value={values.nearbywarehouses}
                          onChange={handleChange}
                        />

                        {touched.nearbywarehouses && (
                          <div className="error-message">
                            {errors.nearbywarehouses}
                          </div>
                        )}
                      </Col>
                    </>
                    : ''}
                  <Row></Row>
                  {/* {values.vertical === "Retail" && (
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Retail Details
                          </h5>
                        </Col>
                      </Row>
                      <Row>

                        <Col lg={3}>
                          <label>Project (Building Name)</label>
                          <Field
                            type="text"
                            name="project"
                            value={values.project}
                            onChange={handleChange}
                          />

                          {touched.project && (
                            <div className="error-message">
                              {errors.project}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Location</label>
                          <Field
                            type="text"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                          />

                          {touched.location && (
                            <div className="error-message">
                              {errors.location}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Total No. of Floors</label>
                          <Field
                            type="text"
                            name="totalfloors"
                            value={values.totalfloors}
                            onChange={handleChange}
                          />

                          {touched.totalfloors && (
                            <div className="error-message">
                              {errors.totalfloors}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Total Built-up of Building in Sq.ft(approx.)</label>
                          <Field
                            type="text"
                            name="totalbuilduparea"
                            value={values.totalbuilduparea}
                            onChange={handleChange}
                          />

                          {touched.totalbuilduparea && (
                            <div className="error-message">
                              {errors.totalbuilduparea}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Offered space details</label>
                          <Field
                            type="text"
                            name="offeredspacedetails"
                            value={values.offeredspacedetails}
                            onChange={handleChange}
                          />

                          {touched.offeredspacedetails && (
                            <div className="error-message">
                              {errors.offeredspacedetails}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Floor Number/s offered</label>
                          <Field
                            type="text"
                            name="flooroffered"
                            value={values.flooroffered}
                            onChange={handleChange}
                          />

                          {touched.flooroffered && (
                            <div className="error-message">
                              {errors.flooroffered}
                            </div>
                          )}
                        </Col>



                        <Col lg={3}>
                          <label>Quoted Rent (in Rs) per Sft. per month (excluding applicable taxes)</label>
                          <Field
                            type="text"
                            name="quotedrent"
                            value={values.quotedrentuotedrent}
                            onChange={handleChange}
                          />

                          {touched.quotedrent && (
                            <div className="error-message">
                              {errors.quotedrent}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Maintenance Charges per sft per month</label>
                          <Field
                            type="text"
                            name="maintainancecharges"
                            value={values.maintainancecharges}
                            onChange={handleChange}
                          />

                          {touched.maintainancecharges && (
                            <div className="error-message">
                              {errors.maintainancecharges}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Power and Power back up</label>
                          <Field
                            type="text"
                            name="powerbackup"
                            value={values.powerbackup}
                            onChange={handleChange}
                          />

                          {touched.powerbackup && (
                            <div className="error-message">
                              {errors.powerbackup}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Total Power allocation for the unit</label>
                          <Field
                            type="text"
                            name="powerallocation"
                            value={values.powerallocation}
                            onChange={handleChange}
                          />

                          {touched.powerallocation && (
                            <div className="error-message">
                              {errors.powerallocation}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Power Back - up System</label>
                          <Field
                            type="text"
                            name="powerbackupsystem"
                            value={values.powerbackupsystem}
                            onChange={handleChange}
                          />

                          {touched.powerbackupsystem && (
                            <div className="error-message">
                              {errors.powerbackupsystem}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Power Back - up Charges</label>
                          <Field
                            type="text"
                            name="powerbackupcharges"
                            value={values.powerbackupcharges}
                            onChange={handleChange}
                          />

                          {touched.powerbackupcharges && (
                            <div className="error-message">
                              {errors.powerbackupcharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Other General terms</label>
                          <Field
                            type="text"
                            name="othergeneralterms"
                            value={values.othergeneralterms}
                            onChange={handleChange}
                          />

                          {touched.othergeneralterms && (
                            <div className="error-message">
                              {errors.othergeneralterms}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Proposed Lease Term</label>
                          <Field
                            type="text"
                            name="proposedleaseterm"
                            value={values.proposedleaseterm}
                            onChange={handleChange}
                          />

                          {touched.proposedleaseterm && (
                            <div className="error-message">
                              {errors.proposedleaseterm}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Proposed Lock in period</label>
                          <Field
                            type="text"
                            name="proposedlockperiod"
                            value={values.proposedlockperiod}
                            onChange={handleChange}
                          />

                          {touched.proposedlockperiod && (
                            <div className="error-message">
                              {errors.proposedlockperiod}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Rent Escalation in Percentage</label>
                          <Field
                            type="text"
                            name="rentescalation"
                            value={values.rentescalation}
                            onChange={handleChange}
                          />

                          {touched.rentescalation && (
                            <div className="error-message">
                              {errors.rentescalation}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Interest Free Security Deposit (Refundable)*</label>
                          <Field
                            type="text"
                            name="intrestfreesecuritydeposit"
                            value={values.intrestfreesecuritydeposit}
                            onChange={handleChange}
                          />

                          {touched.intrestfreesecuritydeposit && (
                            <div className="error-message">
                              {errors.intrestfreesecuritydeposit}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Property Tax</label>
                          <Field
                            type="text"
                            name="propertytax"
                            value={values.propertytax}
                            onChange={handleChange}
                          />

                          {touched.propertytax && (
                            <div className="error-message">
                              {errors.propertytax}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Stamp Duty & Registration Charges on Lease Deed</label>
                          <Field
                            type="text"
                            name="stampdutyregistrationcharges"
                            value={values.stampdutyregistrationcharges}
                            onChange={handleChange}
                          />

                          {touched.stampdutyregistrationcharges && (
                            <div className="error-message">
                              {errors.stampdutyregistrationcharges}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label> Additional Parking Charges (INR)</label>
                          <Field
                            type="number"
                            name="parkingcharges"
                            placeholder="Enter parkingcharges"
                            value={values.parkingcharges}
                          />
                          <ErrorMessage
                            name="parkingcharges"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label> Parking Availability</label>
                          <Field
                            type="number"
                            name="availability"
                            placeholder="Enter Availability"
                            value={values.availability}
                          />
                          <ErrorMessage
                            name="availability"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label> Nearby Market / Brands Info & Immediate Neighbourhood Details</label>
                          <Field
                            type="text"
                            name="marketbrand"
                            placeholder="Enter Nearby Market / Brands Info "
                            value={values.marketbrand}
                          />
                          <ErrorMessage
                            name="marketbrand"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label> Google Coordinates</label>
                          <Field
                            type="text"
                            name="googlecoordinates"
                            placeholder="Enter Nearby Market / Brands Info "
                            value={values.googlecoordinates}
                          />
                          <ErrorMessage
                            name="googlecoordinates"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label> Offered Area in sq. ft. in SBA</label>
                          <Field
                            type="text"
                            name="offeredarea"
                            placeholder="Enter Offered Area in sq. ft. in SBA "
                            value={values.offeredarea}
                          />
                          <ErrorMessage
                            name="offeredarea"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Carpet Area Offered in Sq.ft</label>
                          <Field
                            type="text"
                            name="offeredcarpetarea"
                            placeholder="Enter Carpet Area Offered in Sq.ft "
                            value={values.offeredcarpetarea}
                          />
                          <ErrorMessage
                            name="offeredcarpetarea"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Frontage Area (ft)</label>
                          <Field
                            type="text"
                            name="frontagearea"
                            placeholder="EnterFrontage Area (ft) "
                            value={values.frontagearea}
                          />
                          <ErrorMessage
                            name="frontagearea"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Commercial Terms</label>
                          <Field
                            type="text"
                            name="commercialterms"
                            placeholder="Enter Commercial Terms  "
                            value={values.commercialterms}
                          />
                          <ErrorMessage
                            name="commercialterms"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Height Floor to Floor (ft)</label>
                          <Field
                            type="text"
                            name="heightfloor"
                            placeholder="Enter Height Floor to Floor (ft)"
                            value={values.heightfloor}
                          />
                          <ErrorMessage
                            name="heightfloor"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Remarks</label>
                          <Field
                            type="text"
                            name="remark"
                            placeholder="Enter Remarks"
                            value={values.clientcalloptionremark}
                          ></Field>
                        </Col>


                      </Row>

                    </>
                  )} */}
                  <Row></Row>

                  {/* {values.transactiontype === "Lease" &&
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Rental Details
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <label>Select</label>
                          <Field
                            type="text"
                            name="proposedlockperiod"
                            placeholder="Enter Proposed Lock in period"
                            value={values.proposedlockperiod}
                            onChange={handleChange}
                          />

                          {touched.proposedlockperiod && (
                            <div className="error-message">
                              {errors.proposedlockperiod}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Unit</label>
                          <Field
                            type="text"
                            placeholder='Enter Tenure'
                            name="proposedlockperiod"
                            value={values.Tenure}
                            onChange={handleChange}
                          />

                          {touched.Tenure && (
                            <div className="error-message">
                              {errors.Tenure}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Floor no.</label>
                          <Field
                            type="text"
                            placeholder='Enter Maintenance Charges'
                            name="maintainancecharges"
                            value={values.maintainancecharges}
                            onChange={handleChange}
                          />

                          {touched.maintainancecharges && (
                            <div className="error-message">
                              {errors.maintainancecharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Rental Value</label>
                          <Field
                            type="number"
                            placeholder='Enter Rental Value'
                            name="proposedleaseterm"
                            value={values.proposedleaseterm}
                            onChange={handleChange}
                          />

                          {touched.proposedleaseterm && (
                            <div className="error-message">
                              {errors.proposedleaseterm}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Chargeble Area</label>
                          <Field
                            type="number"
                            placeholder='Enter Chargeble Area'
                            name="rentescalation"
                            value={values.rentescalation}
                            onChange={handleChange}
                          />

                          {touched.rentescalation && (
                            <div className="error-message">
                              {errors.rentescalation}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Quoted rent per Month (Rs.)</label>
                          <Field
                            type="number"
                            placeholder='Enter Quoted rent'
                            name="powerbackup"
                            value={values.powerbackup}
                            onChange={handleChange}
                          />

                          {touched.powerbackup && (
                            <div className="error-message">
                              {errors.powerbackup}
                            </div>
                          )}
                        </Col>

                      </Row>
                    </>
                  } */}
                  {/* {values.transactiontype === "Sell" &&
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Pricing  Details
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <label>Select</label>
                          <Field
                            type="text"
                            name="proposedlockperiod"
                            placeholder="Enter Proposed Lock in period"
                            value={values.proposedlockperiod}
                            onChange={handleChange}
                          />

                          {touched.proposedlockperiod && (
                            <div className="error-message">
                              {errors.proposedlockperiod}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Unit</label>
                          <Field
                            type="text"
                            placeholder='Enter Tenure'
                            name="proposedlockperiod"
                            value={values.Tenure}
                            onChange={handleChange}
                          />

                          {touched.Tenure && (
                            <div className="error-message">
                              {errors.Tenure}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Floor no.</label>
                          <Field
                            type="text"
                            placeholder='Enter Maintenance Charges'
                            name="maintainancecharges"
                            value={values.maintainancecharges}
                            onChange={handleChange}
                          />

                          {touched.maintainancecharges && (
                            <div className="error-message">
                              {errors.maintainancecharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Per unit cost</label>
                          <Field
                            type="number"
                            placeholder='Enter Per unit cost'
                            name="proposedleaseterm"
                            value={values.proposedleaseterm}
                            onChange={handleChange}
                          />

                          {touched.proposedleaseterm && (
                            <div className="error-message">
                              {errors.proposedleaseterm}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Saleable Area</label>
                          <Field
                            type="number"
                            placeholder='Enter Saleable Area'
                            name="rentescalation"
                            value={values.rentescalation}
                            onChange={handleChange}
                          />

                          {touched.rentescalation && (
                            <div className="error-message">
                              {errors.rentescalation}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Quoted Cost (Rs.)</label>
                          <Field
                            type="number"
                            placeholder='Quoted Cost'
                            name="powerbackup"
                            value={values.powerbackup}
                            onChange={handleChange}
                          />

                          {touched.powerbackup && (
                            <div className="error-message">
                              {errors.powerbackup}
                            </div>
                          )}
                        </Col>

                      </Row>
                    </>
                  } */}
                  {/* {values.vertical === "Office" && (
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={6}>
                          <h5 style={{ marginTop: "5px" }}>
                            Office Details
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <label>Vertical Name</label>
                          <Field
                            type="text"
                            name="verticalname"
                            value={values.verticalname}
                            onChange={handleChange}
                          />

                          {touched.verticalname && (
                            <div className="error-message">
                              {errors.verticalname}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Project</label>
                          <Field
                            type="text"
                            name="project"
                            value={values.project}
                            onChange={handleChange}
                          />

                          {touched.project && (
                            <div className="error-message">
                              {errors.project}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Location</label>
                          <Field
                            type="text"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                          />

                          {touched.location && (
                            <div className="error-message">
                              {errors.location}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Total No. of Floors</label>
                          <Field
                            type="text"
                            name="totalfloors"
                            value={values.totalfloors}
                            onChange={handleChange}
                          />

                          {touched.totalfloors && (
                            <div className="error-message">
                              {errors.totalfloors}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Floor Plate Size in Sq. ft. (approx.)</label>
                          <Field
                            type="text"
                            name="floorplatesize"
                            value={values.floorplatesize}
                            onChange={handleChange}
                          />

                          {touched.floorplatesize && (
                            <div className="error-message">
                              {errors.floorplatesize}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Total Built up Area of Building in Sq.ft(approx.)</label>
                          <Field
                            type="text"
                            name="totalbuilduparea"
                            value={values.totalbuilduparea}
                            onChange={handleChange}
                          />

                          {touched.totalbuilduparea && (
                            <div className="error-message">
                              {errors.totalbuilduparea}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Offered space details</label>
                          <Field
                            type="text"
                            name="offeredspacedetails"
                            value={values.offeredspacedetails}
                            onChange={handleChange}
                          />

                          {touched.offeredspacedetails && (
                            <div className="error-message">
                              {errors.offeredspacedetails}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Floor Number/s offered</label>
                          <Field
                            type="text"
                            name="flooroffered"
                            value={values.flooroffered}
                            onChange={handleChange}
                          />

                          {touched.flooroffered && (
                            <div className="error-message">
                              {errors.flooroffered}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Super / Chargeable area Offered in sq. ft. Approx.</label>
                          <Field
                            type="text"
                            name="chargeableareaoffered"
                            value={values.chargeableareaoffered}
                            onChange={handleChange}
                          />

                          {touched.chargeableareaoffered && (
                            <div className="error-message">
                              {errors.chargeableareaoffered}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Normal Working Hours</label>
                          <Field
                            type="text"
                            name="workinghours"
                            value={values.workinghours}
                            onChange={handleChange}
                          />

                          {touched.workinghours && (
                            <div className="error-message">
                              {errors.workinghours}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Status</label>
                          <Field
                            type="text"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                          />

                          {touched.status && (
                            <div className="error-message">
                              {errors.status}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Quoted Rent (in Rs) per Sft. per month (excluding applicable taxes)</label>
                          <Field
                            type="text"
                            name="quotedrent"
                            value={values.quotedrent}
                            onChange={handleChange}
                          />

                          {touched.quotedrent && (
                            <div className="error-message">
                              {errors.quotedrent}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Fit Out Rental</label>
                          <Field
                            type="text"
                            name="fitoutrental"
                            value={values.fitoutrental}
                            onChange={handleChange}
                          />

                          {touched.fitoutrental && (
                            <div className="error-message">
                              {errors.fitoutrental}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Maintenance Charges per sft per month</label>
                          <Field
                            type="text"
                            name="maintainancecharges"
                            value={values.maintainancecharges}
                            onChange={handleChange}
                          />

                          {touched.maintainancecharges && (
                            <div className="error-message">
                              {errors.maintainancecharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Status of Property </label>
                          <Field
                            as='select'
                            name="propertystatus"
                            value={values.propertystatus}
                            onChange={handleChange}
                          >
                            <option value=''>Select</option>
                            <option value='Bare shell/Warm shell'>Bare shell/Warm shell</option>
                            <option value='Semi furnished'>Semi furnished</option>
                            <option value=' Fully fitted out'> Fully fitted out</option>
                            <option value='fit-out'>fit-out</option>
                          </Field>

                          {touched.propertystatus && (
                            <div className="error-message">
                              {errors.propertystatus}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Power and Power back up</label>
                          <Field
                            type="text"
                            name="powerbackup"
                            value={values.powerbackup}
                            onChange={handleChange}
                          />

                          {touched.powerbackup && (
                            <div className="error-message">
                              {errors.powerbackup}
                            </div>
                          )}
                        </Col>

                        <Col lg={3}>
                          <label>Total Power allocation for the unit</label>
                          <Field
                            type="text"
                            name="powerallocation"
                            value={values.powerallocation}
                            onChange={handleChange}
                          />

                          {touched.powerallocation && (
                            <div className="error-message">
                              {errors.powerallocation}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Per Unit Electricity charges</label>
                          <Field
                            type="text"
                            name="electriccharges"
                            value={values.electriccharges}
                            onChange={handleChange}
                          />

                          {touched.electriccharges && (
                            <div className="error-message">
                              {errors.electriccharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Power Back - up System</label>
                          <Field
                            type="text"
                            name="powerbackupsystem"
                            value={values.powerbackupsystem}
                            onChange={handleChange}
                          />

                          {touched.powerbackupsystem && (
                            <div className="error-message">
                              {errors.powerbackupsystem}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Power Back - up Charges</label>
                          <Field
                            type="text"
                            name="powerbackupcharges"
                            value={values.powerbackupcharges}
                            onChange={handleChange}
                          />

                          {touched.powerbackupcharges && (
                            <div className="error-message">
                              {errors.powerbackupcharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Other General terms</label>
                          <Field
                            type="text"
                            name="othergeneralterms"
                            value={values.othergeneralterms}
                            onChange={handleChange}
                          />

                          {touched.othergeneralterms && (
                            <div className="error-message">
                              {errors.othergeneralterms}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Proposed Lease Term</label>
                          <Field
                            type="text"
                            name="proposedleaseterm"
                            value={values.proposedleaseterm}
                            onChange={handleChange}
                          />

                          {touched.proposedleaseterm && (
                            <div className="error-message">
                              {errors.proposedleaseterm}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Proposed Lock in period</label>
                          <Field
                            type="text"
                            name="proposedlockperiod"
                            value={values.proposedlockperiod}
                            onChange={handleChange}
                          />

                          {touched.proposedlockperiod && (
                            <div className="error-message">
                              {errors.proposedlockperiod}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Rent Escalation in Percentage</label>
                          <Field
                            type="text"
                            name="rentescalation"
                            value={values.rentescalation}
                            onChange={handleChange}
                          />

                          {touched.rentescalation && (
                            <div className="error-message">
                              {errors.rentescalation}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Interest Free Security Deposit (Refundable)*</label>
                          <Field
                            type="text"
                            name="intrestfreesecuritydeposit"
                            value={values.intrestfreesecuritydeposit}
                            onChange={handleChange}
                          />

                          {touched.intrestfreesecuritydeposit && (
                            <div className="error-message">
                              {errors.intrestfreesecuritydeposit}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Property Tax</label>
                          <Field
                            type="text"
                            name="propertytax"
                            value={values.propertytax}
                            onChange={handleChange}
                          />

                          {touched.propertytax && (
                            <div className="error-message">
                              {errors.propertytax}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Stamp Duty & Registration Charges on Lease Deed</label>
                          <Field
                            type="text"
                            name="stampdutyregistrationcharges"
                            value={values.stampdutyregistrationcharges}
                            onChange={handleChange}
                          />

                          {touched.stampdutyregistrationcharges && (
                            <div className="error-message">
                              {errors.stampdutyregistrationcharges}
                            </div>
                          )}
                        </Col>
                        <Col lg={3}>
                          <label>Car Parking</label>
                          <Field
                            type="number"
                            name="numberofcarortruckparking"
                            placeholder="Enter # of Car/ Truck Parking"
                            value={values.numberofcarortruckparking}
                          />
                          <ErrorMessage
                            name="numberofcarortruckparking"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Car Park charges (Rs. / car park / month)</label>
                          <Field
                            type="number"
                            name="carparkcharges"
                            placeholder="Enter Car park charges"
                            value={values.carparkcharges}
                          />
                          <ErrorMessage
                            name="carparkcharges"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Availability</label>
                          <Field
                            type="number"
                            name="availability"
                            placeholder="Enter Availability"
                            value={values.availability}
                          />
                          <ErrorMessage
                            name="availability"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Contact Name</label>
                          <Field
                            type="number"
                            name="contactname"
                            placeholder="Enter contactname"
                            value={values.contactname}
                          />
                          <ErrorMessage
                            name="contactname"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Contact Number</label>
                          <Field
                            type="number"
                            name="contactnumber"
                            placeholder="Enter Contact Number"
                            value={values.contactnumber}
                          />
                          <ErrorMessage
                            name="contactname"
                            component="div"
                            className="error-message"
                          />
                        </Col>

                        <Col lg={3}>
                          <label>Other Tenants</label>
                          <Field
                            type="text"
                            name="othertenants"
                            placeholder="Enter Other Tenants"
                            value={values.othertenants}
                          />
                          <ErrorMessage
                            name="othertenants"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Designation</label>
                          <Field
                            className="custom-input"
                            type="text"
                            name="designation"
                            style={{ textTransform: "capitalize" }}
                            value={values.designation}
                            onChange={handleChange}
                            placeholder="Enter designation"
                            required
                          />
                          <ErrorMessage
                            name="designation"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                      </Row>
                      <div className="px-3">
                        <Row lg={12} className="section-header">
                          <Col lg={7}>
                            <h5 style={{ marginTop: "5px" }}>
                              Office Address Information
                            </h5>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col lg={3}>
                          <label>Office State</label>
                          <Field
                            as="select"
                            name="officestate"
                            // defaultValue="Rajasthan"
                            value={values.officestate}
                            onChange={(event) => {
                              var temp = CityState.filter(
                                (ele) => ele.state === event.target.value
                              );
                              setOfcCityList(temp.sort());
                              handleChange(event);
                            }}
                          >
                            <option value="">--Select State--</option>
                            {ofcstateList &&
                              ofcstateList.map((state) => (
                                <option value={state}>{state}</option>
                              ))}
                          </Field>
                        </Col>
                        <Col lg={3}>
                          <label>Office City</label>
                          <Field
                            as="select"
                            disabled={!values.officestate}
                            //className="Field"
                            placeholder="Enter City"
                            onChange={handleChange}
                            name="officecity"
                            value={values.officecity}
                          >
                            <option value="">--Select City--</option>

                            {ofcCityList &&
                              ofcCityList.length > 0 &&
                              ofcCityList.map((ele) => (
                                <option value={ele.name}>{ele.name}</option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="officecity"
                            component="div"
                            className="error-message"
                          />
                        </Col>

                        <Col lg={3}>
                          <label>Office Street</label>
                          <Field
                            type="text"
                            name="officestreet"
                            placeholder="Enter street"
                            value={values.officestreet}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Office Country</label>
                          <Field
                            disabled
                            className="Field"
                            type="text"
                            name="officecountry"
                            placeholder="Enter country"
                            value="India"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col lg={3}>
                          <label>Office Pincode</label>
                          <Field
                            type="text"
                            name="officepincode"
                            placeholder="Enter pincode"
                            value={values.officepincode}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="officepincode"
                            component="div"
                            className="error-message"
                          />
                        </Col>
                      </Row>
                      {/* <Row lg={12} className="section-header mx-auto ">
                              <Col lg={7}>
                                <h5 style={{ marginTop: "5px" }}>
                                  Office Information
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                          <Col lg={3}>
                            <label>State</label>
                            <Field
                              as="select"
                              name="officestate"
                              value={values.officestate}
                              onChange={(event) => {
                                var temp = CityState.filter(
                                  (ele) => ele.state === event.target.value
                                );
                                setCityList(temp.sort());
                                handleChange(event);
                              }}
                            >
                              <option value="">--Select State--</option>
                              {stateList &&
                                stateList.map((state) => (
                                  <option value={state}>{state}</option>
                                ))}
                            </Field>
                          </Col>
                          <Col lg={3}>
                            <label>City</label>
                            <Field
                              as="select"
                              name="officecity"
                              value={values.officecity}
                              onChange={handleChange}
                            >
                              <option value="">--Select City--</option>
                              {cityList &&
                                cityList.map((ele) => (
                                  <option value={ele.name}>{ele.name}</option>
                                ))}
                            </Field>
                          </Col>
                          <Col lg={3}>
                            <label>Street</label>
                            <Field
                              className=""
                              type="text"
                              name="officestreet"
                              placeholder="Enter street"
                              value={values.officestreet}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={3}>
                            <label> Pincode </label>
                            <Field
                              className=" no-arrows"
                              type="number"
                              name="officepincode"
                              placeholder="Enter pincode"
                              value={values.officepincode}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="officepincode"
                              component="div"
                              className="error-message"
                            />
                          </Col>

                          <Col lg={3}>
                            <label>Country </label>
                            <Field
                              className=""
                              type="text"
                              name="officecountry"
                              defaultValue={"India"}
                              value={values.officecountry}
                              onChange={handleChange}
                            />
                          </Col>

                          
                        </Row> 
                    </>
                  )} */}
                  <Row></Row>
                </Row>
                {/* {values.transactiontype === 'Lease' && (values.vertical === 'Office' || values.vertical === 'Retail' || values.vertical === 'Land') ?
                  <>
                    <Row lg={12} className="section-header mx-auto">
                      <Col lg={8}>
                        <h5 style={{ marginTop: "5px" }}>Rental Details</h5>
                      </Col>
                    </Row>
                    <FieldArray name="rentaldetails">
                      {({ push, remove }) => (
                        <div>
                          {values.rentaldetails?.map((record, index) => (
                            <Row key={index}>
                           
                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Unit <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`rentaldetails[${index}].unit`}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Sq.m">Sq.m</option>
                                  <option value="Sq.ft">Sq.ft</option>
                                  <option value="Acre">Acre</option>
                                  <option value="Gaj">Gaj</option>
                                  <option value="Sq.yards">Sq.yards</option>
                                </Field>
                                {errors?.rentaldetails?.length > 0
                                  ? (errors.rentaldetails?.at(index)?.floor ||
                                    errors.rentaldetails?.at(index)?.value ||
                                    errors.rentaldetails?.at(index)?.area) &&
                                  !errors.rentaldetails?.at(index)?.unit && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>
                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Floor No. <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`rentaldetails[${index}].floor`}
                                  placeholder="Enter Flooor"
                                  value={values.rentaldetails[index].floor}
                                >
                                  <option value=""> --select--</option>
                                  {floornumber && floornumber.map((e) => (
                                    <option value={e.value}>{e.label}</option>
                                  ))}
                                </Field>
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.value ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.floor && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>



                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Rental  Value <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name={`rentaldetails[${index}].value`}
                                  placeholder="Enter value"
                                  value={values.rentaldetails[index].value}
                                />
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.value && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>
                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Chargeble Area <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name={`rentaldetails[${index}].chargeblearea`}
                                  placeholder="Enter Chargeble Area"
                                  value={values.rentaldetails[index].chargeblearea}
                                />
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.value && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>
                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Quoted rent per Month (Rs.) <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name={`rentaldetails[${index}].quotedrentpermonth`}
                                  placeholder="Enter Quoted rent per Month (Rs.)"
                                  value={values.rentaldetails[index].quotedrentpermonth}
                                />
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.value && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={1} className="mt-2">
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Action
                                </label>
                                <Button
                                  type="button"
                                  onClick={() => {

                                    if (values.rentaldetails[index].id !== undefined) {
                                      const id = values.rentaldetails[index].id;
                                      setRemoveIndex((ids) => [...ids, id]);
                                    }
                                    remove(index);
                                    if (values.rentaldetails.length === 1 && index === 0) {
                                      push({
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "height",
                                      })
                                    }
                                  }}
                                >
                                  {" "}
                                  X{" "}
                                </Button>{" "}
                                &nbsp;
                                {index !== values.rentaldetails.length - 1 ? (
                                  ""
                                ) : (
                                  <Button
                                    // disabled={index !== values.records.length - 1}

                                    // style={{marginLeft:"1150px", marginTop:"-150px",height:"40px",width:"40px"}}
                                    type="button"
                                    onClick={() =>
                                      push({
                                        area: "",
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "rent",
                                      })
                                    }
                                  >
                                    {" "}
                                    +{" "}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </> : ''

                } */}

                {(values.vertical === 'Office' || values.vertical === 'Retail') ?
                  <>
                    <Row lg={12} className="section-header mx-auto">
                      <Col lg={6}>
                        {values.vertical === 'Office' ? <h5 style={{ marginTop: "5px" }}>
                          Office Details
                        </h5> : <h5 style={{ marginTop: "5px" }}>
                          Retail Details
                        </h5>}
                      </Col>
                    </Row>
                    <Row>
                      {values.vertical === 'Retail' ?
                        <>
                          <Col lg={3}>
                            <label>Frontage In (ft)</label>
                            <Field
                              type="text"
                              name="frontagearea"
                              placeholder="EnterFrontage (ft) "
                              value={values.frontagearea}
                            />
                            <ErrorMessage
                              name="frontagearea"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                          <Col lg={3}>
                            <label> Neighbourhood Brands</label>
                            <Field
                              type="text"
                              name="marketbrand"
                              placeholder="Enter Nearby Market / Brands Info "
                              value={values.marketbrand}
                            />
                            <ErrorMessage
                              name="marketbrand"
                              component="div"
                              className="error-message"
                            />
                          </Col>
                        </> : ''

                      }

                      <Col lg={3}>
                        <label>Proposed Lock in period</label>
                        <Field
                          type="text"
                          name="proposedlockperiod"
                          placeholder="Enter Proposed Lock in period"
                          value={values.proposedlockperiod}
                          onChange={handleChange}
                        />

                        {touched.proposedlockperiod && (
                          <div className="error-message">
                            {errors.proposedlockperiod}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Tenure</label>
                        <Field
                          type="text"
                          placeholder='Enter Tenure'
                          name="tenure"
                          value={values.tenure}
                          onChange={handleChange}
                        />

                        {touched.tenure && (
                          <div className="error-message">
                            {errors.tenure}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Maintenance Charges </label>
                        <Field
                          type="text"
                          placeholder='Enter Maintenance Charges'
                          name="maintainancecharges"
                          value={values.maintainancecharges}
                          onChange={handleChange}
                        />

                        {touched.maintainancecharges && (
                          <div className="error-message">
                            {errors.maintainancecharges}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Proposed Lease Term</label>
                        <Field
                          type="text"
                          placeholder='Enter Proposed Lease Term'
                          name="proposedleaseterm"
                          value={values.proposedleaseterm}
                          onChange={handleChange}
                        />

                        {touched.proposedleaseterm && (
                          <div className="error-message">
                            {errors.proposedleaseterm}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Rent Escalation in Percentage</label>
                        <Field
                          type="text"
                          placeholder='Enter Rent Escalation'
                          name="rentescalation"
                          value={values.rentescalation}
                          onChange={handleChange}
                        />

                        {touched.rentescalation && (
                          <div className="error-message">
                            {errors.rentescalation}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Power and Power back up</label>
                        <Field
                          type="text"
                          placeholder='Enter Power and Power back up'
                          name="powerbackup"
                          value={values.powerbackup}
                          onChange={handleChange}
                        />

                        {touched.powerbackup && (
                          <div className="error-message">
                            {errors.powerbackup}
                          </div>
                        )}
                      </Col>
                      <Col lg={3}>
                        <label>Other Tenants</label>
                        <Field
                          type="text"
                          name="othertenants"
                          placeholder="Enter Other Tenants"
                          value={values.othertenants}
                        />
                        <ErrorMessage
                          name="othertenants"
                          component="div"
                          className="error-message"
                        />
                      </Col>
                      <Col lg={3}>
                        <label>Remarks</label>
                        <Field
                          as="textarea"
                          className="textarea"
                          name="clientcalloptionremark"
                          placeholder="Enter Remarks"
                          value={values.clientcalloptionremark}
                        ></Field>
                      </Col>
                    </Row>
                  </> : ''}

                <Row >

                  {values.transactiontype === 'Sell' && (values.vertical === 'Office' || values.vertical === 'Retail' || values.vertical === 'Land') ? (
                    <>
                      <Row lg={12} className="section-header mx-auto">
                        <Col lg={8}>
                          <h5 style={{ marginTop: "5px" }}>Pricing Details</h5>

                        </Col>
                      </Row>
                      <FieldArray name="pricingorrental">
                        {({ push, remove }) => (
                          <div>
                            {values.pricingorrental?.map((record, index) => (
                              <Row key={index} >
                                <Col lg={1}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Select <b class="red-star"></b>
                                  </label>
                                  <Field
                                    className="Field"
                                    required
                                    as="select"
                                    name={`pricingorrental[${index}].area`}
                                    value={values.pricingorrental[index].area}
                                  >

                                    {
                                      values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ? (
                                        <>
                                          <option value="">select</option>
                                          <option value="Plot Size">Plot Size</option>
                                          <option value="Covered Area">Covered Area</option>
                                        </>
                                      ) : (
                                        <>
                                          <option value="">select</option>
                                          <option value="Carpet">Carpet</option>
                                          <option value="BUA">BUA</option>
                                          <option value="SBUA">SBUA</option></>
                                      )}

                                  </Field>
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].area`}
                                    component="div"
                                    className="error-message"
                                  />
                                </Col>
                                <Col lg={2}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Unit <b class="red-star"></b>
                                  </label>
                                  <Field
                                    className="Field"
                                    required
                                    as="select"
                                    name={`pricingorrental[${index}].unit`}
                                    value={values.pricingorrental[index].unit}                                >
                                    <option value="">--Select--</option>
                                    <option value="Sq.m">Sq.m</option>
                                    <option value="Sq.ft">Sq.ft</option>
                                    <option value="Acre">Acre</option>
                                    <option value="Gaj">Gaj</option>
                                    <option value="Sq.yards">Sq.yards</option>
                                  </Field>
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].unit`}
                                    component="div"
                                    className="error-message"
                                  />

                                </Col>
                                <Col lg={2}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Floor No. <b class="red-star"></b>
                                  </label>
                                  <Field
                                    className="Field"
                                    required
                                    as="select"
                                    placeholder="Enter Flooor"
                                    name={`pricingorrental[${index}].floorno`}
                                    value={values.pricingorrental[index].floorno}
                                  >
                                    <option value=""> --select--</option>
                                    {floornumber && floornumber.map((e) => (
                                      <option value={e.value}>{e.label}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].floorno`}
                                    component="div"
                                    className="error-message"
                                  />

                                </Col>
                                <Col lg={2}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Per unit cost<b className="red-star"></b>
                                  </label>
                                  <Field
                                    className="Field"
                                    required
                                    min='0'
                                    type="number"
                                    placeholder="Enter Per unit cost"
                                    name={`pricingorrental[${index}].perunitcost`}
                                    value={values.pricingorrental[index].perunitcost}
                                    onChange={(e) => {
                                      setFieldValue(`pricingorrental[${index}].quotedcost`, e.target.value * values.pricingorrental[index].saleablearea)
                                      setFieldValue(`pricingorrental[${index}].perunitcost`, e.target.value)
                                      //handleChange(e)
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].perunitcost`}
                                    component="div"
                                    className="error-message"
                                  />
                                </Col>
                                <Col lg={2}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Saleable Area <b className="red-star"></b>
                                  </label>
                                  <Field
                                    className="Field"
                                    required
                                    type="number"
                                    min='0'
                                    placeholder="Enter Saleable Area"
                                    name={`pricingorrental[${index}].saleablearea`}
                                    value={values.pricingorrental[index].saleablearea}
                                    onChange={(e) => {
                                      handleChange(e)
                                      setFieldValue(`pricingorrental[${index}].quotedcost`, e.target.value * values.pricingorrental[index].perunitcost)
                                      setFieldValue(`pricingorrental[${index}].saleablearea`, e.target.value)
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].saleablearea`}
                                    component="div"
                                    className="error-message"
                                  />
                                </Col>
                                <Col lg={2}>
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Quoted Cost (Rs.)<b className="red-star"></b>
                                  </label>
                                  <Field
                                    disabled
                                    className="Field"
                                    required
                                    min='0'
                                    type="number"
                                    placeholder="Enter Quoted rent per Month (Rs.)"
                                    name={`pricingorrental[${index}].quotedcost`}
                                    value={values.pricingorrental[index].quotedcost}
                                  />
                                  <ErrorMessage
                                    name={`pricingorrental[${index}].quotedcost`}
                                    component="div"
                                    className="error-message"
                                  />
                                </Col>

                                <Col lg={1} className="mt-2">
                                  <label className="form-view-label" htmlFor="formBasicName">
                                    Action
                                  </label>
                                  <Button
                                    type="button"
                                    onClick={() => {

                                      if (values.pricingorrental[index].id !== undefined) {
                                        const id = values.pricingorrental[index].id;
                                        setRemovePricingOrRental((ids) => [...ids, id]);
                                      }
                                      remove(index);
                                      if (values.pricingorrental.length === 1 && index === 0) {
                                        push({
                                          id: undefined,
                                          area: "",
                                          unit: "",
                                          floorno: "",
                                          rentalvalue: "",
                                          perunitcost: "",
                                          saleablearea: "",
                                          chargeblearea: "",
                                          quotedrentpermonth: "",
                                          type: "pricing",
                                        })
                                      }
                                    }}
                                  >
                                    {" "}
                                    X{" "}
                                  </Button>{" "}
                                  &nbsp;
                                  {index !== values.pricingorrental.length - 1 ? (
                                    ""
                                  ) : (
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        push({
                                          id: undefined,
                                          area: "",
                                          unit: "",
                                          floorno: "",
                                          rentalvalue: "",
                                          perunitcost: "",
                                          saleablearea: "",
                                          chargeblearea: "",
                                          quotedrentpermonth: "",
                                          type: "pricing",
                                        })
                                      }
                                    >
                                      {" "}
                                      +{" "}
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        )}
                      </FieldArray>

                    </>
                  ) : (
                    values.transactiontype === 'Lease' && (values.vertical === 'Office' || values.vertical === 'Retail' || values.vertical === 'Land' || values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory") ? (
                      <>
                        <Row lg={12} className="section-header mx-auto">
                          <Col lg={8}>

                            <h5 style={{ marginTop: "5px" }}>Rental Details</h5>


                          </Col>
                        </Row>
                        <FieldArray name="pricingorrental">
                          {({ push, remove }) => (
                            <div>

                              {values.pricingorrental?.map((record, index) => (
                                <Row key={index}>
                                  <Col lg={1}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Select <b class="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      as="select"
                                      name={`pricingorrental[${index}].area`}
                                      value={values.pricingorrental[index].area}
                                    >

                                      {
                                        values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ? (
                                          <>
                                            <option value="">select</option>
                                            <option value="Plot Size">Plot Size</option>
                                            <option value="Covered Area">Covered Area</option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="">select</option>
                                            <option value="Carpet">Carpet</option>
                                            <option value="BUA">BUA</option>
                                            <option value="SBUA">SBUA</option></>
                                        )}

                                    </Field>
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].area`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Unit <b class="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      as="select"
                                      name={`pricingorrental[${index}].unit`}
                                      value={values.pricingorrental[index].unit}                                >
                                      <option value="">--Select--</option>
                                      <option value="Sq.m">Sq.m</option>
                                      <option value="Sq.ft">Sq.ft</option>
                                      <option value="Acre">Acre</option>
                                      {/* <option value="Gaj">Gaj</option> */}
                                      <option value="Sq.yards">Sq.yards</option>
                                    </Field>
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].unit`}
                                      component="div"
                                      className="error-message"
                                    />

                                  </Col>
                                  <Col lg={2}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Floor No. <b class="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      as="select"
                                      placeholder="Enter Flooor"
                                      name={`pricingorrental[${index}].floorno`}
                                      value={values.pricingorrental[index].floorno}
                                    >
                                      <option value=""> --select--</option>
                                      {floornumber && floornumber.map((e) => (
                                        <option value={e.value}>{e.label}</option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].floorno`}
                                      component="div"
                                      className="error-message"
                                    />

                                  </Col>
                                  <Col lg={2}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Rental Value <b className="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      min='0'
                                      type="number"
                                      placeholder="Enter value"
                                      name={`pricingorrental[${index}].rentalvalue`}
                                      value={values.pricingorrental[index].rentalvalue}
                                      onChange={(e) => {
                                        setFieldValue(`pricingorrental[${index}].quotedrentpermonth`, e.target.value * values.pricingorrental[index].chargeblearea)
                                        setFieldValue(`pricingorrental[${index}].rentalvalue`, e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].rentalvalue`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Chargeable Area <b className="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      min='0'
                                      type="number"
                                      placeholder="Enter Chargeble Area"
                                      name={`pricingorrental[${index}].chargeblearea`}
                                      value={values.pricingorrental[index].chargeblearea}
                                      onChange={(e) => {
                                        handleChange(e)
                                        setFieldValue(`pricingorrental[${index}].quotedrentpermonth`, e.target.value * values.pricingorrental[index].rentalvalue)
                                        setFieldValue(`pricingorrental[${index}].chargeblearea`, e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].chargeblearea`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Quoted rent per Month (Rs.) <b className="red-star"></b>
                                    </label>
                                    <Field
                                      disabled
                                      className="Field"
                                      required
                                      type="number"
                                      placeholder="Enter Quoted rent per Month (Rs.)"
                                      name={`pricingorrental[${index}].quotedrentpermonth`}
                                      value={values.pricingorrental[index].quotedrentpermonth}
                                    />
                                    <ErrorMessage
                                      name={`pricingorrental[${index}].quotedrentpermonth`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </Col>

                                  <Col lg={1} className="mt-2">
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Action
                                    </label>
                                    <Button
                                      type="button"
                                      onClick={() => {

                                        if (values.pricingorrental[index].id !== undefined) {
                                          const id = values.pricingorrental[index].id;
                                          setRemovePricingOrRental((ids) => [...ids, id]);
                                        }
                                        remove(index);
                                        if (values.pricingorrental.length === 1 && index === 0) {
                                          push({
                                            id: undefined,
                                            area: "",
                                            unit: "",
                                            floorno: "",
                                            rentalvalue: "",
                                            perunitcost: "",
                                            saleablearea: "",
                                            chargeblearea: "",
                                            quotedrentpermonth: "",
                                            type: "rent",
                                          })
                                        }
                                      }}
                                    >
                                      {" "}
                                      X{" "}
                                    </Button>{" "}
                                    &nbsp;
                                    {index !== values.pricingorrental.length - 1 ? (
                                      ""
                                    ) : (
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          push({
                                            // floor: "",
                                            area: "",
                                            unit: "",
                                            floorno: "",
                                            rentalvalue: "",
                                            perunitcost: "",
                                            saleablearea: "",
                                            chargeblearea: "",
                                            quotedrentpermonth: "",
                                            type: "rent",
                                          })
                                        }
                                      >
                                        {" "}
                                        +{" "}
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </>
                    ) : null
                  )}
                </Row>





                {!(values.vertical === 'Warehouse' || values.vertical === 'Cold storage' || values.vertical === 'Factory') ?
                  <>
                    <Row lg={12} className="section-header mx-auto">
                      <Col lg={8}>
                        <h5 style={{ marginTop: "5px" }}>Area Details</h5>
                      </Col>
                    </Row>
                    <FieldArray name="areadetails">
                      {({ push, remove }) => (
                        <div>
                          {values.areadetails?.map((record, index) => (
                            <Row key={index}>
                              <Col lg={3}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Area Type <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`areadetails[${index}].area`}
                                  placeholder="Enter Area Type"
                                  value={values.areadetails[index].area}
                                >

                                  {
                                    values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ? (
                                      <>
                                        <option value=""> --select--</option>
                                        <option value="Plot Size">Plot Size</option>
                                        <option value="Covered Area">Covered Area</option>
                                      </>
                                    ) : (
                                      <>
                                        <option value=""> --select--</option>
                                        <option value="Carpet">Carpet</option>
                                        <option value="BUA">BUA</option>
                                        <option value="SBUA">SBUA</option></>

                                    )



                                  }

                                </Field>

                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.value) &&
                                  !errors.areadetails?.at(index)?.area && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>
                              <Col lg={3}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Floor No. <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`areadetails[${index}].floor`}
                                  placeholder="Enter Flooor"
                                  value={values.areadetails[index].floor}
                                >
                                  <option value=""> --select--</option>
                                  {floornumber && floornumber.map((e) => (
                                    <option value={e.value}>{e.label}</option>
                                  ))}
                                </Field>
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.value ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.floor && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={2}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Unit <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`areadetails[${index}].unit`}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Sq.m">Sq.m</option>
                                  <option value="Sq.ft">Sq.ft</option>
                                  <option value="Acre">Acre</option>
                                  {/* {values.vertical !== 'Retail' && <option value="Gaj">Gaj</option>} */}
                                  <option value="Sq.yards">Sq.yards</option>
                                </Field>
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.value ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.unit && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={3}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Value <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name={`areadetails[${index}].value`}
                                  placeholder="Enter value"
                                  value={values.areadetails[index].value}
                                />
                                {errors?.areadetails?.length > 0
                                  ? (errors.areadetails?.at(index)?.floor ||
                                    errors.areadetails?.at(index)?.unit ||
                                    errors.areadetails?.at(index)?.area) &&
                                  !errors.areadetails?.at(index)?.value && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={1} className="mt-2">
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Action
                                </label>
                                <Button
                                  type="button"
                                  onClick={() => {

                                    if (values.areadetails[index].id !== undefined) {
                                      const id = values.areadetails[index].id;
                                      setRemoveIndex((ids) => [...ids, id]);
                                    }
                                    remove(index);
                                    if (values.areadetails.length === 1 && index === 0) {
                                      push({
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "height",
                                      })
                                    }
                                  }}
                                >
                                  {" "}
                                  X{" "}
                                </Button>{" "}
                                &nbsp;
                                {index !== values.areadetails.length - 1 ? (
                                  ""
                                ) : (
                                  <Button
                                    // disabled={index !== values.records.length - 1}

                                    // style={{marginLeft:"1150px", marginTop:"-150px",height:"40px",width:"40px"}}
                                    type="button"
                                    onClick={() =>
                                      push({
                                        area: "",
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "area",
                                      })
                                    }
                                  >
                                    {" "}
                                    +{" "}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </> : ''}

                {(!(values.vertical === 'Warehouse' || values.vertical === 'Cold storage' || values.vertical === 'Factory' || values.vertical === 'Land')) ?
                  <>
                    <Row lg={12} className="section-header mx-auto">
                      <Col lg={8}>
                        <h5 style={{ marginTop: "5px" }}>Height Details</h5>
                      </Col>
                    </Row>
                    <FieldArray name="heightdetails">
                      {({ push, remove }) => (
                        <div>
                          {values.heightdetails?.map((record, index) => (
                            <Row key={index} >
                              {
                                values.vertical === "Cold storage" || values.vertical === "Warehouse" || values.vertical === "Factory" ? (
                                  <Col lg={3}>
                                    <label>Height Type</label>
                                    <Field
                                      className="Field"
                                      required
                                      as="select"
                                      name=""
                                    >
                                      <option>--Select--</option>
                                      <option value="Center Height">Center Height</option>
                                      <option value="Side Height">Side Height</option>

                                    </Field>
                                  </Col>
                                )
                                  :
                                  <Col lg={3}>
                                    <label className="form-view-label" htmlFor="formBasicName">
                                      Floor No. <b class="red-star"></b>
                                    </label>
                                    <Field
                                      className="Field"
                                      required
                                      as="select"
                                      name={`heightdetails[${index}].floor`}
                                      placeholder="Enter Flooor"
                                      value={values.heightdetails[index].floor}
                                    >
                                      <option value=""> --select--</option>
                                      {floornumber && floornumber.map((e) => (
                                        <option value={e.value}>{e.label}</option>
                                      ))}
                                    </Field>
                                    {errors?.heightdetails?.length > 0
                                      ? (errors.heightdetails?.at(index)?.unit ||
                                        errors.heightdetails?.at(index)?.value) &&
                                      !errors.heightdetails?.at(index)?.floor && (
                                        <div className="error-message">
                                          Please enter all values
                                        </div>
                                      )
                                      : ""}
                                  </Col>
                              }

                              <Col lg={3}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Unit <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  as="select"
                                  name={`heightdetails[${index}].unit`}
                                  value={values.heightdetails[index].unit}
                                >
                                  <option value="">--Select--</option>
                                  <option value="ft">ft</option>
                                  <option value="inch">inch</option>
                                  <option value="m">m</option>
                                  <option value="Cm">Cm</option>
                                </Field>
                                {errors?.heightdetails?.length > 0
                                  ? (errors.heightdetails?.at(index)?.floor ||
                                    errors.heightdetails?.at(index)?.value) &&
                                  !errors.heightdetails?.at(index)?.unit && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={3}>
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Height Value <b class="red-star"></b>
                                </label>
                                <Field
                                  className="Field"
                                  required
                                  type="text"
                                  name={`heightdetails[${index}].value`}
                                  placeholder="Enter value"
                                  value={values.heightdetails[index].value}
                                />
                                {errors?.heightdetails?.length > 0
                                  ? (errors.heightdetails?.at(index)?.floor ||
                                    errors.heightdetails?.at(index)?.unit) &&
                                  !errors.heightdetails?.at(index)?.value && (
                                    <div className="error-message">
                                      Please enter all values
                                    </div>
                                  )
                                  : ""}
                              </Col>

                              <Col lg={3} className="mt-2">
                                <label className="form-view-label" htmlFor="formBasicName">
                                  Action
                                </label>
                                <Button
                                  type="button"
                                  onClick={() => {

                                    if (values.heightdetails[index].id !== undefined) {
                                      const id = values.heightdetails[index].id;
                                      setRemoveIndex((ids) => [...ids, id]);
                                    }
                                    remove(index);
                                    if (values.heightdetails.length === 1 && index === 0) {
                                      push({
                                        area: "",
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "height",
                                      })
                                    }
                                  }}
                                >
                                  {" "}
                                  X{" "}
                                </Button>{" "}
                                &nbsp;
                                {index !== values.heightdetails.length - 1 ? (
                                  ""
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      push({
                                        floor: "",
                                        unit: "",
                                        value: "",
                                        type: "height",
                                      })
                                    }
                                  >
                                    {" "}
                                    +{" "}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </>
                  : ''}
                {(values.vertical === 'Land') ?
                  <>
                    <Row lg={12} className="section-header mx-auto">
                      <Col lg={8}>
                        <h5 style={{ marginTop: "5px" }}>Plot Size </h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>
                        <label className="form-view-label" htmlFor="formBasicName">
                          Unit <b class="red-star"></b>
                        </label>
                        <Field
                          className="Field"
                          required
                          as="select"
                          name='plotunit'
                          value={values.plotunit}
                        >
                          <option value="">--Select--</option>
                          <option value="ft">ft</option>
                          <option value="m">m</option>
                        </Field>

                      </Col>

                      <Col lg={3}>
                        <label className="form-view-label" htmlFor="formBasicName">
                          Length <b class="red-star"></b>
                        </label>
                        <Field
                          className="Field"
                          required
                          type="text"
                          name='plotlength'
                          placeholder="Enter Length"
                          value={values.plotlength}
                        />

                      </Col>
                      <Col lg={3}>
                        <label className="form-view-label" htmlFor="formBasicName">
                          Width <b class="red-star"></b>
                        </label>
                        <Field
                          className="Field"
                          required
                          type="text"
                          name='plotwidth'
                          placeholder="Enter Width"
                          value={values.plotwidth}
                        />
                      </Col>
                    </Row>
                  </>
                  : ''}




                <Row lg={12} className="section-header  mx-auto">
                  <Col lg={7}>
                    <h5 style={{ marginTop: "5px" }}>Address Information</h5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <label>State</label>
                    <Field
                      as="select"
                      name="state"
                      placeholder="State"
                      value={values.state}
                      onChange={(event) => {
                        var temp = CityState.filter(
                          (ele) => ele.state === event.target.value
                        );
                        setCityList(temp.sort());
                        handleChange(event);
                      }}
                    >
                      <option value="">--Select State--</option>
                      {stateList &&
                        stateList.map((state) => (
                          <option value={state}>{state}</option>
                        ))}
                    </Field>
                  </Col>
                  <Col lg={3}>
                    <label>City</label>
                    <Field
                      as="select"
                      disabled={!values.state}
                      className="Field"
                      placeholder="Enter City"
                      onChange={handleChange}
                      name="city"
                      value={values.city}
                    >
                      <option value="">--Select City--</option>

                      {cityList.length === 0 ? (
                        <option value="Jaipur">Jaipur</option>
                      ) : (
                        <>
                          {cityList &&
                            cityList.map((ele) => (
                              <option value={ele.name}>{ele.name}</option>
                            ))}
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error-message"
                    />
                  </Col>

                  <Col lg={3}>
                    <label>Street</label>

                    <Field
                      type="text"
                      name="street"
                      placeholder="Enter street"
                      value={values.street}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col lg={3}>
                    <label>Pincode</label>
                    <Field
                      type="number"
                      name="pincode"
                      placeholder="Enter pincode"
                      value={values.pincode}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="pincode"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={3}>
                    <label>Country</label>
                    <Field
                      disabled
                      className="Field"
                      type="text"
                      name="country"
                      placeholder="Enter country"
                      value="India"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={3}>
                    <label>Google Location</label>
                    <Field
                      type="text"
                      name="googlelocation"
                      placeholder="Enter Google location"
                      value={values.googlelocation}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                {modalShow && (
                  <ContactNewModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    submitContact={(result) => {
                      setModalShow(false);
                      setDefaultContact([{ id: result.id, contactname: result.firstname + '' + result.lastname }])
                      setFieldValue("contactid", result.id);
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Col>

      </Row>
    </Container>
  );
};

export default PropertyEdit;
