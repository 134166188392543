import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Card, Col, Container, Row,Tooltip,OverlayTrigger  } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import FilesCreate from "../FilesCreate";
import RelatedListFiles from "../RelatedListFiles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TaskEdit from "../task/TaskEdit";
import html2canvas from "html2canvas";
import EmailComposer from "../common/EmailComposer";
import RelatedLead from "../RelatedLead";
import PubSub from "pubsub-js";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailIcon,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
import jwt_decode from "jwt-decode";
import * as constants from "../../constants/CONSTANT";
import moment from "moment";
import Chat from "../common/Chat";
import Confirm from "../common/Confirm";
import RelatedListTask from "../task/RelatedListTask";
import RelatedListPDF from "../RelatedListPDF";
import {DefaultPdfValues, SectionInTemplateValues,pdfValuesDefault,DefaultWareHousePdfValues} from "../../constants/DefalutPdfValues.js"
import RelatedListArea from "../RelatedListArea.js";
import RelatedListHeight from "../RelatedLisHeight.js";
import RelatedPricingList from "../RelatedPricingList.js";
import RelatedRentList from "../RelatedRentList.js";
import RelatedListEmails from "../RelatedListEmails.js";

const PropertyView = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  const [property, setProperty] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowArea, setModalShowArea] = useState(false);
  const [modalShowHeight, setModalShowHeight] = useState(false);
  const [modalShowPrice, setModalShowPrice] = useState(false);
  const [modalShowRent, setModalShowRent] = useState(false);
  const [relatedListTasks, setRelatedListTasks] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListPdf, setRelatedListPdf] = useState(false);
  const [relatedListArea, setRelatedListArea] = useState(false);
  const [relatedListHeight, setRelatedListHeight] = useState(false);
  const [relatedListPrice, setRelatedListPrice] = useState(false);
  const [relatedListRent, setRelatedListRent] = useState(false);
  const [relatedLeads, setRelatedLeads] = useState(false);
  const [modalShowTaskfile, setModalShowFile] = useState(false);
  const [modalShowPdf, setModalShowPdf] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [refreshFileList, setRefreshFileList] = useState();
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [type, setType] = useState("Income");
  const [pdfFile, setPdfFile] = useState([]);
  const [relatedListEmails, setRelatedListEmails] = useState(false);


  const [showVideo, setShowVideo] = useState(Boolean(property.vidurl));

  const [imageURL, setImageURL] = useState();

  const [showSocial, setShowSocial] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isOverlayDeleteVisible, setIsDeleteOverlayVisible] = useState(false);
  const VerticalColors = {
    Office: "RGBA(255, 99, 2,255)",
    Land: "RGBA(255, 168, 168,255)",
    Retail: "RGBA(232, 116, 133,255)",
    Investment: "RGBA(243, 223, 223,255)",
    Warehouse: "RGBA( 241, 66, 66,255)",
    "Cold storage": "RGBA(202, 57, 57,255)",
    Factory: "RGBA(250, 154, 9,255)",
    Hotels: "RGBA(255, 2, 2,255)",
    Others: "RGBA(252, 214, 214,255)",
  };


  const redirectPDF = async () => {
   
    let pdfBuilderData  = {propertydata : JSON.parse(JSON.stringify(property))};

    let files = await inventoryApi.fetchFiles(property.id);

    if (files && files?.length > 0) {
      let documentMap = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if(file?.documenttype && file.documenttype !== '' ){
          const result = await inventoryApi.downloadFiles(file);
          let fileLink = window.URL.createObjectURL(result);
          pdfBuilderData[file.documenttype] = fileLink
            let temp = {
              id: file.id,
              image: fileLink,
              isVisible : true,
              section: file.documenttype.includes("Floor Plan")
                ? "Floor Plan"
                : file.documenttype,
              subSection: file.documenttype.includes("Floor Plan")
                ? file.documenttype.replace("Floor Plan", "")
                : "",
            };
            documentMap = [...documentMap, temp];
        }
      }
      documentMap = documentMap.sort(compare);
      pdfBuilderData.documentFile = documentMap;
    }

    navigate('/pdfbuilder/'+ property.id, { state: pdfBuilderData });
  };

  function compare(a, b) {
    if (a.section < b.section) {
      return -1;
    }
    if (a.section > b.section) {
      return 1;
    }
    return 0;
  }

  const handleMouseEnter = () => {
    setIsOverlayVisible(true);
  };

  const handleMouseLeave = () => {
    setIsOverlayVisible(false);
  };
  const handleDeleteMouseEnter = () => {
    setIsDeleteOverlayVisible(true);
  };

  const handleDeleteMouseLeave = () => {
    setIsDeleteOverlayVisible(false);
  };
  const printRef = useRef();
 
  /* Start yamini 06-09-2023 */

  async function fetchImageURL() {
    let user = jwt_decode(localStorage.getItem("token"));
    let files = await inventoryApi.fetchFiles(property.id);
    let imageFiles = files?.filter((file) => {
      return file?.documenttype === "property_image";
    });
    // if (imageFiles?.length) {
    //   let image =
    //     constants.PROJECT_BASE_URL +
    //     "/images/" +
    //     user.tenantcode +
    //     "/" +
    //     property.id +
    //     "/" +
    //     imageFiles[0].id +
    //     "." +
    //     imageFiles[0].filetype;
    //   setImageURL(image);
    // } else {
    //   setImageURL("/NoFound.svg");
    // }
    setImageURL(`${constants.BASE_URL}/viewproperty/${property.id}`);
  }

  /* End yamini 06-09-2023 */
  useEffect(() => {
    if (!window.myMap) {
      window.myMap = myMap;
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    } else {
      myMap();
    }
    fetchProperty();
    fetchImageURL();
  }, []);

  const fetchProperty = () => {
    // Coming from Email
    if (
      !property.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/")?.length >= 3
    ) {
      property.id = location.pathname.split("/")[2];
    }

    async function initProperty() {
      let result = await inventoryApi.fetchProperty(property.id);
      //.log('resultresult',result,property);
      if (result) {
        setProperty(result);
      } else {
        setProperty({});
      }
    }
    initProperty();
  };
  useEffect(() => {
    setRefreshFileList(Date.now());
  }, [property]);
 
  const deleteProperty = async () => {
    const result = await inventoryApi.deleteProperty(property.id);
    if (result)
    {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Deleted",
        message: "Record deleted successfully",
      });
     navigate(`/properties`);
    }
  };

  const editLead = () => {
    navigate(`/properties/${property.id}/e`, { state: property });
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
    setModalShowEmail(false);
  };
  const submitfiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
  };
  const submitpdf = () => {
    setModalShowPdf(false);
    setRefreshFileList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setRelatedListTasks(true);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);


    }else if (key === "emails") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedListEmails(true);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
    }
     else if (key === "files") {
      setRelatedListTasks(false);
      setRelatedListFiles(true);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);


    } else if (key === "leads") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(true);
      setRelatedListPdf(true);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);


    } else if (key === "pdf") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(true);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);

    }
    else if (key === "area") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(true);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);

    }
    else if (key === "height") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(false);
      setRelatedListHeight(true);
      setRelatedListPrice(false)
      setRelatedListRent(false)
      setRelatedListEmails(false);

    }
    else if (key === "rent") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(false)
      setRelatedListRent(true)
      setRelatedListEmails(false);

    }
    else if (key === "price") {
      setRelatedListTasks(false);
      setRelatedListFiles(false);
      setRelatedLeads(false);
      setRelatedListPdf(false);
      setRelatedListArea(false);
      setRelatedListHeight(false);
      setRelatedListPrice(true)
      setRelatedListRent(false)
      setRelatedListEmails(false);

    }
  };

  const myMap = () => {
    let contentString = `<h4>${property.name}</h4>
    <label className='maplabel'>Address:</label> <p>${property.street},${property.city},${property.state},${property.pincode},${property.country}</p>
    <label className='maplabel'>Contact Person:</label> <p> ${property.contactname} </p>
    <label className='maplabel'>Description:</label> <p> ${property.description} </p>`;
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
      ariaLabel: "Uluru",
    });

    var mapProp = {
      center: new window.google.maps.LatLng(51.508742, -0.12085),
      zoom: 14,
    };
    var map = new window.google.maps.Map(
      document.getElementById("googleMap"),
      mapProp
    );

    var geocoder = new window.google.maps.Geocoder();
    var address = `${property.street},${property.city},${property.state},${property.pincode},${property.country}`;
    geocoder.geocode({ address: address }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        var marker = new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          title: `${property.name}`,
        });

        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      }
    });
  };

  const handleSocialIcon = () => {
    setShowSocial(!showSocial);
  };
  return (
    <>
   {property && property.success !== false  ? <>
      <Container>
        <CustomSeparator
          cmpListName="Inventory"
          currentCmpName={property.name}
          indexLength="2"
          url="/properties"
        ></CustomSeparator>
      </Container>
      {property && (
        <Container className="mt-4">
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteProperty={deleteProperty}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="property"
            />
          )}
          <div ref={printRef} >
            <Row className="view-form gx-5 px-4">
              <Col lg={8} >
                <Row className="view-form-header align-items-center">
                  <Col lg={6}>
                    Inventory
                    <h4>{property.name}</h4>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                  <OverlayTrigger
                   placement="top"
                   show={isOverlayVisible}
                   overlay={<Tooltip className="my-tooltip">Send Email</Tooltip>}
                  >
                    <Button
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="btn-sm"
                        onClick={() => setModalShowEmail(true)}
                        variant="success"
                      
                      >
                        <i class="fa fa-envelope "></i>
                    </Button>
                  </OverlayTrigger> 
                  <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="my-tooltip">PDF</Tooltip>}
                >
                  <Button
                    className="btn-sm mx-2"
                    //disabled={property.vertical ==='Others' || property.vertical ===''}
                    onClick={() =>{
                      if(property.vertical ==='Others' || property.vertical ===''){
                        PubSub.publish("RECORD_ERROR_TOAST", {
                          title: "PDF Not Available",
                          message: "No PDF Template Available for this Vertical",
                        });
                      }else{
                        redirectPDF()
                       }
                      
                      }
                      }
                    variant="success"
                  >
                    <i class="fa fa-file-pdf"></i>
                  </Button>

                  </OverlayTrigger>
                  <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="my-tooltip">Edit</Tooltip>}
                >
                  <Button
                    className="btn-sm "
                    onClick={() => editLead(true)}
                  >

                    <i className="fa fa-pen-to-square"></i>
                  </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                  placement="top"
                  show={isOverlayDeleteVisible}
                  overlay={<Tooltip className="my-tooltip">Delete</Tooltip>}
                >
                  <Button
                    onMouseEnter={handleDeleteMouseEnter}
                    onMouseLeave={handleDeleteMouseLeave}
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    <i class="fa fa-trash"></i>
                  </Button>

                  </OverlayTrigger>
                  <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className="my-tooltip">Share</Tooltip>}
                >
                  <div
                    tabIndex={0}
                    onClick={handleSocialIcon}
                    // onBlur={handleSocialIcon}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    <img
                      src="/share-svgrepo-com.svg"
                      width="24px"
                      style={{ borderRadius: "5px" }}
                    />
                    {showSocial && (
                        
                      
                      <div
                        style={{
                          position: "absolute",
                          zIndex: "100",
                          borderRadius: "10px",
                          padding: ".5rem",
                          backgroundColor: "#f0f1f1",
                        }}
                      >
                        <EmailShareButton
                          url={imageURL}
                          className="mx-1 mb-1"
                        >
                          <EmailIcon size={24} round />
                        </EmailShareButton>
                        <br></br>
                        <FacebookShareButton
                          url={imageURL}
                          className="mx-1 mb-1"
                        >
                          <FacebookIcon size={24} round />
                        </FacebookShareButton>
                        <br></br>
                        <WhatsappShareButton
                          url={imageURL}
                          className="mx-1 mb-1"
                        >
                          <WhatsappIcon size={24} round />
                        </WhatsappShareButton>
                        <br></br>
                        <LinkedinShareButton
                          url={imageURL}
                          className="mx-1 mb-1"
                        >
                          <LinkedinIcon size={24} round />
                        </LinkedinShareButton>
                        <br></br>
                        <TelegramShareButton
                          url={imageURL}
                          className="mx-1 mb-1"
                        >
                          <TelegramIcon size={24} round />
                        </TelegramShareButton>
                        <br></br>
                        <TwitterShareButton
                          className="mx-1 mb-1"
                          url={imageURL}
                        >
                          <TwitterIcon size={24} round />
                        </TwitterShareButton>
                      </div>
                    )}
                  </div>
                  </OverlayTrigger>
                  </Col>
                </Row>
                <Row
                className="py-3 ibs-edit-form"
                style={{ backgroundColor: "#fff" ,
              }}>
                  <Col lg={4}>
                    <label>Property Name</label>
                    <span>{property.name}</span>
                  </Col>
                { property.contactid && <Col lg={4}>
                    <label>Contact Person</label>
                    <span>
                      <Link to={`/contacts/${property.contactid}`}>
                        {property.contactname || <br />}
                      </Link>
                    </span>
                  </Col>}
                  {/* {property.transactiontype === "Lease" && <Col lg={4}>
                    <label>Lease Expiration Date</label>
                    <span>{property.leaseexpirationdate} </span>
                  </Col> } */}
                   {property.transactiontype && 
                  <Col lg={4}>
                    <label>Transaction</label>
                    <span>{property.transactiontype}</span>
                  </Col>
                  }
                  {property.typeofclient && 
                  <Col lg={4}>
                    <label>Type Of client</label>
                    <span>{property.typeofclient} </span>
                  </Col>
                  }
                  {property.vertical && 
                  <Col lg={4}>
                    <label>Vertical</label>       
                      <Badge
                        bg={property.vertical !== null ?  property.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[property.vertical] : ""}
                        style={{
                          display: "block",
                          paddingBottom: "5px",
                          backgroundColor: property.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[property.vertical],
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                        }}
                      >
                        {property.vertical}
                      </Badge>
              
                  </Col>
                  }
                  {property.verticaltype && 
                  <Col lg={4}>
                    <label>Vertical Type</label>
                    <span>{property.verticaltype} </span>
                  </Col>
                  }
                  {property.subverticaltype && 
                  <Col lg={4}>
                    <label>Sub Vertical Type</label>
                    <span>{property.subverticaltype}</span>
                  </Col>
                  }
                  {property.retailsubvertical && property.vertical === 'Retail' &&
                  <Col lg={4}>
                    <label>Retail Category</label>
                    <span>{property.retailsubvertical}</span>
                  </Col>
                  }
                {/* {property.possessionstatus && <Col lg={4}>
                  <label>Possession Status</label>
                  <span>{property.possessionstatus}</span>
                </Col>} */}
                 {property.possessiondate && <Col lg={4}>
                    <label>Possession Date</label>
                    <span>{moment(property.possessiondate).format("DD-MM-YYYY")}</span>
                  </Col>}
                 {!(property.vertical === 'Office' || property.vertical === 'Retail' || property.vertical === 'Land' || property.vertical === "Cold storage" || property.vertical === "Warehouse" || property.vertical === "Factory")  && property?.propertytype &&  <Col>
                  <label>Property Type</label>
                  <span>{property?.propertytype}</span>
                  </Col>}
                 {!(property.vertical === 'Retail' || property.vertical === 'Land' || property.vertical === "Cold storage" || property.vertical === "Warehouse" || property.vertical === "Factory") && property.furnishedstatus &&  <Col lg={4}>
                    <label>Furnished</label>
                    <span>{property.furnishedstatus}</span>
                  </Col>
                  }
                  {property.leaseexpirationdate && <Col lg={4}>
                    <label>Lease Expiration Date</label>
                    <span>{property.leaseexpirationdate} </span>
                  </Col> 
                  }

                  {property.locatedin && <Col lg={4}>
                    <label>Located In</label>
                    <span>{property.locatedin} </span>
                  </Col> 
                  }
                  {property.availability && <Col lg={4}>
                    <label>Availability</label>
                    <span>{property.availability} </span>
                  </Col> 
                  }
                {(property.vertical === "Warehouse" || property.vertical === 'Cold storage' || property.vertical === 'Factory' || property.verticaltype === 'Warehousing') ?
                <>
                 {property.noofdocksvalue && property.noofdocksvalue !== "0" ? <Col lg={4}>
                    <label>No. of docks </label>
                    <span>{property.noofdocksvalue}</span>
                  </Col>:''}
                  {property.dockheight && <Col lg={4}>
                  <label>Dock height in ft.</label>
                    <span>{property.dockheight}</span>
                  </Col>}
                  {property.docklevel && <Col lg={4}>
                  <label>Dock level drop down</label>
                    <span>{property.docklevel}</span>
                  </Col>}
                  {property.noofwashroomsvalue && property.noofwashroomsvalue !== "0" ? <Col lg={4}>
                    <label>No.of Washrooms</label>
                    <span>{property.noofwashroomsvalue}</span>
                  </Col>:''}
                  {property.closeareaunit && property.closeareaunit !== "0" ? <Col lg={4}>
                    <label>Covered Area</label>
                    <span>{property.closeareavalue+' '+ property.closeareaunit }</span>
                  </Col>:''}
                  {property.verticaltype === "Warehousing" && property?.openareaunit  && property?.openareavalue  ? 
                    <Col lg={4}>
                      <label>Open area</label>
                      <span>
                        {property?.openareaunit + " " + property?.openareavalue} 
                      </span>
                    </Col>
                   :''}
                   {property.typeofwarehouse && property.typeofwarehouse && <Col lg={4}>
                     <label>Type of Warehouse</label>
                     <span>{property.typeofwarehouse}</span>
                   </Col>}
                   {property.floor && property.floor && <Col lg={4}>
                     <label>Floor</label>
                     <span>{property.floor}</span>
                   </Col>}
                   {property.offeredcarpetarea &&  <Col lg={4}>
                  <label>Covered Area Offered in Sq.ft</label>                   
                   <span>{property.offeredcarpetarea}</span>
                  </Col>}
                  {property.heightside &&  <Col lg={4}>
                  <label>Height (ft.) Side</label>
                    <span>{property.heightside}</span>
                  </Col>}
                  {property.heightcenter &&  <Col lg={4}>
                  <label>Height (ft.) Center</label>
                    <span>{property.heightcenter}</span>
                    </Col>}

                    {property.typeofflooring &&  <Col lg={4}>
                    <label>Type of Flooring</label>
                    <span>{property.typeofflooring}</span>
                  </Col>}

                  {property.firehydrants &&  <Col lg={4}>
                  <label>Fire Hydrants</label>
                    <span>{property.firehydrants}</span>
                  </Col>}

                  {property.firesprinkelers &&  <Col lg={4}>
                  <label>Fire Sprinkelers</label>
                    <span>{property.firesprinkelers}</span>
                  </Col>}
                  {property.firenoc &&  <Col lg={4}>
                  <label>Fire NOC</label>
                    <span>{property.firenoc}</span>
                  </Col>}
                  {property.quotedrentonchargeablearea &&  <Col lg={4}>
                  <label>Quoted Rent On Chargeable Area</label>
                    <span>{property.quotedrentonchargeablearea}</span>
                  </Col>}
                  {property.securitydeposit &&  <Col lg={4}>
                  <label>Security Deposit</label>
                    <span>{property.securitydeposit}</span>
                  </Col>}
                  {property.advance && <Col lg={4}>
                    <label>Advance</label>
                    <span>{property.advance}</span>
                  </Col>}

                  {property.commonareamaintaince &&  <Col lg={4}>
                  <label>Common Area Maintenance (CAM)</label>
                    <span>{property.commonareamaintaince}</span>
                  </Col>}
                  {property.possessionstatus && <Col lg={4}>
                  <label>Possession Status</label>
                  <span>{property.possessionstatus}</span>
                </Col>}
                
                {property.addtionalinformation && <Col lg={4}>
                <label>Additional Information</label>
                  <span>{property.addtionalinformation}</span>
                </Col>}
                {property.roadwidth && <Col lg={4}>
                    <label>Road width in ft.</label>
                    <span>{property.roadwidth}</span>
                  </Col>}
                  {property.labourroom && <Col lg={4}>
                    <label>Labour Room</label>
                    <span>{property.labourroom}</span>
                  </Col>}
                  {property.guardroom && <Col lg={4}>
                    <label>Guard Room</label>
                    <span>{property.guardroom}</span>
                  </Col>}
                  {property.powerconnection && <Col lg={4}>
                  <label>Power Connection (KV)</label>
                    <span>{property.powerconnection}</span>
                  </Col>}
                  {property.waterconnection && <Col lg={4}>
                  <label>Water connection (Drop Down)</label>
                    <span>{property.waterconnection}</span>
                  </Col>}
                  {property.flooringloadingcapacity && <Col lg={4}>
                  <label>Flooring Loading Capacity ( in tons)</label>
                    <span>{property.flooringloadingcapacity}</span>
                  </Col>}
                  {property.nearbywarehouses && <Col lg={4}>
                  <label> Near-by Warehouses</label>
                    <span>{property.nearbywarehouses}</span>
                  </Col>}
                </>
                :''}
                  {property.verticaltype === "Warehousing" && property.closeareaunit && property.closeareavalue ?
                    <Col lg={4}>
                      <label>Close area</label>
                      <span>
                        {property.closeareaunit + " "}
                        {property.closeareavalue}
                      </span>
                    </Col>
                  :''}
                  {property.verticaltype === "Warehousing" && property.rentalunit  && property.rentalvalue ?
                    <Col lg={4}>
                      <label>Rental</label>
                      <span>
                        {property.rentalunit + " "}
                        {property.rentalvalue}
                      </span>
                    </Col>
                  :''}
                {property.floor &&  <Col lg={4}>
                    <label>Floor</label>
                    <span>{property.floor}</span>
                  </Col>}
                  {property.chargeablearea &&  <Col lg={4}>
                  <label>Approx. Chargeable Area (Sq.ft)</label>
                    <span>{property.chargeablearea}</span>
                  </Col>}
                  

                {property.project && <Col lg={4}>
                <label>Project (Building Name)</label>
                  <span>{property.project}</span>
                </Col>}
                {property.location && <Col lg={4}>
                <label>Location</label>
                  <span>{property.location}</span>
                </Col>}
                {property.totalfloors && <Col lg={4}>
                <label>Total No. of Floors</label>
                  <span>{property.totalfloors}</span>
                </Col>}
                {property.totalbuilduparea && <Col lg={4}>
                <label>Total Built-up of Building in Sq.ft(approx.)</label>
                  <span>{property.totalbuilduparea}</span>
                </Col>}
                {property.offeredspacedetails && <Col lg={4}>
                <label>Offered space details</label>
                  <span>{property.offeredspacedetails}</span>
                </Col>}
                {property.flooroffered && <Col lg={4}>
                <label>Floor Number/s offered</label>
                  <span>{property.flooroffered}</span>
                </Col>}
                {property.Quotedrent && <Col lg={4}>
                <label>Quoted Rent (in Rs) per Sft. per month (excluding applicable taxes)</label>
                  <span>{property.Quotedrent}</span>
                </Col>}
               
                 
                {property.powerallocation && <Col lg={4}>
                <label>Total Power allocation for the unit</label>
                  <span>{property.powerallocation}</span>
                </Col>
                }
                {property.powerbackupsystem && <Col lg={4}>
                <label>Power Back - up System</label>
                  <span>{property.powerbackupsystem}</span>
                </Col>}

                {property.powerbackupcharges && <Col lg={4}>
                <label>Power Back - up Charges</label>
                  <span>{property.powerbackupcharges}</span>
                </Col>}
                {property.othergeneralterms && <Col lg={4}>
                <label>Other General terms</label>
                  <span>{property.othergeneralterms}</span>
                </Col>}
                
             
                {property.intrestfreesecuritydeposit && <Col lg={4}>
                <label>Interest Free Security Deposit (Refundable)*</label>
                  <span>{property.intrestfreesecuritydeposit}</span>
                </Col>}
                {property.propertytax && <Col lg={4}>
                <label>Property Tax</label>
                  <span>{property.propertytax}</span>
                </Col>}
                {property.stampdutyregistrationcharges && <Col lg={4}>
                <label>Stamp Duty & Registration Charges on Lease Deed</label>
                  <span>{property.stampdutyregistrationcharges}</span>
                </Col>}
                {property.parkingcharges && <Col lg={4}>
                <label> Additional Parking Charges (INR)</label>
                  <span>{property.parkingcharges}</span>
                </Col>}
                {/* {property.availability && <Col lg={4}>
                <label> Parking Availability</label>
                  <span>{property.availability}</span>
                </Col>} */}
               
                {property.googlecoordinates && <Col lg={4}>
                <label> Google Coordinates</label>
                  <span>{property.googlecoordinates}</span>
                </Col>}
                {property.offeredarea && <Col lg={4}>
                <label> Offered Area in sq. ft. in SBA</label>
                  <span>{property.offeredarea}</span>
                </Col>}
                {(property.vertical === 'Office' || property.vertical === 'Retail') ?
                <>
                {property.vertical === 'Retail' && property.frontagearea && <Col lg={4}>
                <label>Frontage Area (ft)</label>
                  <span>{property.frontagearea}</span>
                </Col>}
                {property.vertical === 'Retail' && property.marketbrand && <Col lg={4}>
                <label> Nevarby Market / Brands Info & Immediate Neighbourhood Details</label>
                  <span>{property.marketbrand}</span>
                </Col>}
                {property.proposedlockperiod && <Col lg={4}>
                <label>Proposed Lock in period</label>
                  <span>{property.proposedlockperiod}</span>
                </Col>}
                {property.tenure && <Col lg={4}>
                <label>Tenure</label>
                  <span>{property.tenure}</span>
                </Col>}
                {property.maintainancecharges && <Col lg={4}>
                <label>Maintenance Charges per sft per month</label>
                  <span>{property.maintainancecharges}</span>
                </Col>}
                {property.proposedleaseterm && <Col lg={4}>
                <label>Proposed Lease Term</label>
                  <span>{property.proposedleaseterm}</span>
                </Col>}
                {property.rentescalation && <Col lg={4}>
                <label>Rent Escalation in Percentage</label>
                  <span>{property.rentescalation}</span>
                </Col>}
                {property.powerbackup && <Col lg={4}>
                <label>Power and Power back up</label>
                  <span>{property.powerbackup}</span>
                </Col>} 
                {property.othertenants && <Col lg={4}>
                <label>Other Tenants</label>
                  <span>{property.othertenants}</span>
                </Col>} 
                {property.clientcalloptionremark && <Col lg={4}>
                <label>Remarks</label>
                  <span>{property.clientcalloptionremark}</span>
                </Col>}
                </>:''}
                {property.commercialterms && <Col lg={4}>
                <label>Commercial Terms</label>
                  <span>{property.commercialterms}</span>
                </Col>}
                {property.heightfloor && <Col lg={4}>
                <label>Height Floor to Floor (ft)</label>
                  <span>{property.heightfloor}</span>
                </Col>}
              
               
                {property.plotunit || property.plotlength || property.plotwidth ?  <Col lg={4}>
                  <label>Plot Size</label>
                  {property.plotunit !== null ? property.plotunit  : " "}{" "}
                  {property.plotlength !== null ? property.plotlength+'L' : " "}{" "}
                  {property.plotwidth !== null ? property.plotwidth +'W' : " "}
                </Col> : ''}


                {property.verticalname && <Col lg={4}>
                <label>Vertical Name</label>
                  <span>{property.verticalname}</span>
                </Col>}
                {property.floorplatesize && <Col lg={4}>
                <label>Floor Plate Size in Sq. ft. (approx.)</label>
                  <span>{property.floorplatesize}</span>
                </Col>}
                {property.chargeableareaoffered && <Col lg={4}>
                <label>Super / Chargeable area Offered in sq. ft. Approx.</label>
                  <span>{property.chargeableareaoffered}</span>
                </Col>}
                {property.workinghours && <Col lg={4}>
                <label>Normal Working Hours</label>
                  <span>{property.workinghours}</span>
                </Col>}
                {property.status && <Col lg={4}>
                <label>Status</label>
                  <span>{property.status}</span>
                </Col>}
                {property.fitoutrental && <Col lg={4}>
                <label>Fit Out Rental</label>
                  <span>{property.fitoutrental}</span>
                </Col>}
                {property.propertystatus && <Col lg={4}>
                <label>Status of Property </label>
                  <span>{property.propertystatus}</span>
                </Col>}
                {property.electriccharges && <Col lg={4}>
                <label>Per Unit Electricity charges</label>
                  <span>{property.electriccharges}</span>
                </Col>}
                              
                {property.numberofcarortruckparking && <Col lg={4}>
                <label>Car Parking</label>
                  <span>{property.numberofcarortruckparking}</span>
                </Col>}
                {property.carparkcharges && <Col lg={4}>
                <label>Car Park charges (Rs. / car park / month)</label>
                  <span>{property.carparkcharges}</span>
                </Col>}
               
                {property.designation && <Col lg={4}>
                <label>Designation</label>
                  <span>{property.designation}</span>
                </Col>} 

                 {property.state &&  <Col lg={4}>
                    <label>State</label>
                    <span>{property.state}</span>
                  </Col>}
                 {property.city &&  <Col lg={4}>
                    <label>City</label>
                    <span>{property.city}</span>
                  </Col>}
                {property.street &&   <Col lg={4}>
                    <label>Street</label>
                    <span>{property.street}</span>
                  </Col>}
                {property.pincode &&   <Col lg={4}>
                    <label>Pincode</label>
                    <span>{property.pincode}</span>
                  </Col>}
                  {property.country &&
                  <Col lg={4}>
                    <label>Country</label>
                    <span>{property.country}</span>
                  </Col> 
                  }
                  {property.googlelocation &&  <Col lg={4}>
                  <label>Google Location</label>
                    <a href={property.googlelocation} target="_blank">{property.googlelocation.split('/').join('\n')}</a>
                    {/* <span>{property.googlelocation.split('/').join('\n')}</span> */}
                  </Col>}
                 {property.officestreet &&  <Col lg={4}>
                    <label>Office street</label>
                    <span>{property.officestreet}</span>
                  </Col>}
                 {property.officestate &&  <Col lg={4}>
                    <label>Office state</label>
                    <span>{property.officestate}</span>
                  </Col>}
                 {property.officecity &&  <Col lg={4}>
                    <label>Office city</label>
                    <span>{property.officecity}</span>
                  </Col>}
                 {property.officepincode &&  <Col lg={4}>
                    <label>Office pincode</label>
                    <span>{property.officepincode}</span>
                  </Col>}
                  {property.description &&  <Col lg={4}>
                    <label>Description</label>
                    <span>{property.description}</span>
                  </Col>}
                   {property.vertical === 'Hotels' && property.noofkeys && <Col lg={4}>
                   <label>No. of Keys</label>
                    <span>{property.noofkeys}</span>
                  </Col>}
                   {property.vertical === 'Hotels' && property.unit && <Col lg={4}>
                   <label>Room size</label>
                    <span>{property.unit}</span>
                  </Col>}
                   {property.vertical === 'Hotels' && property.length && <Col lg={4}>
                   <label>Length</label>
                    <span>{property.unit}</span>
                  </Col>}
                   {property.vertical === 'Hotels' && property.width && <Col lg={4}>
                   <label>Width</label>
                    <span>{property.unit}</span>
                  </Col>}
                  {property.architectname && <Col lg={4}>
                   <label>Architect Name</label>
                    <span>{property.architectname}</span>
                  </Col>}
                  {property.banquetcapacity && <Col lg={4}>
                  <label>Banquet Capacity</label>
                    <span>{property.banquetcapacity}</span>
                  </Col>}
                  {property.noofservicelifts && <Col lg={4}>
                  <label>No. of service Lifts</label>
                    <span>{property.noofservicelifts}</span>
                  </Col>}
                  {property.noofcarparking && <Col lg={4}>
                  <label>No. Car parking</label>
                    <span>{property.noofcarparking}</span>
                  </Col>}
                 {/* {property.createdbyname &&  <Col lg={4}>
                  <label>Created By</label>
                  <span>{property.createdbyname}</span>
                </Col>} */}
               {property.createddate &&  <Col lg={4}>
                  <label>Created date </label>
                  <span>{moment(property.createddate).format("DD-MM-YYYY hh:mm A")}</span>
                </Col>}
               {property.lastmodifieddate &&  <Col lg={4}>
                  <label>Last Modified Date</label>
                  <span>{moment(property.lastmodifieddate).format("DD-MM-YYYY hh:mm A")}</span>
                </Col>}
                {property.lastmodifiedbyname !== " " && <Col lg={4}>
                  <label>Last Modified By</label>
                  <span>{property.lastmodifiedbyname}</span>
                </Col>}
                  <Col></Col>
                </Row>
              </Col>
            <Col lg={4} >
             <Row>
                <Col lg={12}>
                  <Chat parentid={property.id} />
                  </Col>
                  <span></span>
              <Col>
                <div
                  id="googleMap"
                  style={{ width: "100%", height: "278px" }}
                ></div>
              </Col>
              </Row>
              </Col>
            </Row>

            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  <Tab eventKey="files" title="Files"></Tab>
                  <Tab eventKey="emails" title="Emails"></Tab>
                  <Tab eventKey="tasks" title="Tasks"></Tab>
                  <Tab eventKey="pdf" title="PDF"></Tab>
                   { !(property.vertical === 'Warehouse' || property.vertical === 'Cold storage' || property.vertical === 'Factory') && property.areadetails && property.areadetails !==''? <Tab eventKey="area" title="Area"></Tab>:''}
                   {!(property.vertical === 'Warehouse' || property.vertical === 'Cold storage' || property.vertical === 'Factory' || property.vertical === 'Land') && property.heightdetails && property.heightdetails !==''? <Tab eventKey="height" title="Height"></Tab>:''}
                 {property.transactiontype === 'Lease' && property.pricingorrental && property.pricingorrental ? <Tab eventKey="rent" title="Rental"></Tab> :''}
                 {property.transactiontype === 'Sell'&& property.pricingorrental && property.pricingorrental? <Tab eventKey="price" title="Pricing"></Tab> :''}
                </Tabs>
                {relatedListTasks && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>
                )}
                {modalShowTask && (
                  <TaskEdit
                    show={modalShowTask}
                    onHide={() => setModalShowTask(false)}
                    parentid={property.id}
                    table="property"
                    submitTasks={submitTasks}
                  />
                )}
                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    Upload File
                  </Button>
                )}
                {modalShowTaskfile && (
                  <FilesCreate
                  show={modalShowTaskfile}
                  onHide={() => setModalShowFile(false)}
                  parent={property}
                  table="property"
                  submitfiles={submitfiles}
                  />
                  )}
                  {relatedListPdf && (
                    <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowPdf(true)}
                    >
                      Upload Pdf
                    </Button>
                  )}
                  {modalShowPdf && (
                    <FilesCreate
                      show={modalShowPdf}
                      onHide={() => setModalShowPdf(false)}
                      parent={property}
                      table="property"
                      file='pdf'
                      submitfiles={submitpdf}
                    />
                  )}
              </Card.Header>
              <Card.Body>
                {property && property.id && relatedLeads && (
                  <RelatedLead parent={property} />
                )}
                {relatedListTasks ? (
                  <RelatedListTask
                    refreshTaskList={refreshTaskList}
                    parent={property}
                  />
                ) : (
                  ""
                )}
                {relatedListFiles ? (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    parent={property}
                    table="property"
                  />
                ) : (
                  ""
                )}

                {relatedListEmails ? (
                    <RelatedListEmails
                      refreshEmailList={modalShowEmail}
                      parent={property}
                      table="property"
                    />
                  ) : (
                    ""
                  )}

                 {relatedListArea ? (
                <RelatedListArea
                  refreshAreaList={modalShowArea}
                  parent={property}
                  table="lead"
                />
                ) : (
                  ""
                )}
                {relatedListHeight ? (
                <RelatedListHeight
                  refreshAreaList={modalShowHeight}
                  parent={property}
                  table="lead"
                />
                ) : (
                  ""
                )}
                {relatedListPrice ? (
                <RelatedPricingList
                  refreshAreaList={modalShowRent}
                  parent={property}
                  table="lead"
                />
                ) : (
                  ""
                )}
                {relatedListRent ? (
                <RelatedRentList
                  refreshAreaList={modalShowPrice}
                  parent={property}
                  table="lead"
                />
                ) : (
                  ""
                )}
                {relatedListPdf &&
                  <RelatedListPDF
                    refreshFileList={refreshFileList}
                    parent={property}
                    table="property"
                  />
                }
              </Card.Body>
            </Card>
            <EmailComposer
              size="lg"
              show={modalShowEmail}
              onHide={() => setModalShowEmail(false)}
              parentid={property?.id}
              toEmail={property?.email}
              table="property"
              submitTasks={submitTasks}
            />
          </div>
        </Container>
      )}
    </>  :
      <h3 style={{textAlign:"center"}}>
        No Record Found
      </h3> }
    </>
  );
};

export default PropertyView;
