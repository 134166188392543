import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Container, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import Badge from "react-bootstrap/Badge";
import NewInfoPill from "../common/NewInfoPill/NewInfoPill"
import moment from "moment";
import data from "../NewJson";

const PropertyList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [properties, setProperties] = useState();
  const [filterObj, setFilterObj] = useState({});
  const [optionProject, setOptionProject] = useState([]);
  const [transcationType, setTranscationType] = useState(data.Value["Transaction type"]);
  const [typeOfClient, setTypeOfClient] = useState([]);
  const [verticals, setVertical] = useState([]);
  const [verticalType, setVerticalType] = useState([]);
  const [subVerticalType, setSubVerticalType] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [selectedTypeOfClient, setSelectedTypeOfClient] = useState();
  const [selectedVertical, setSelectedVertical] = useState();
  const [selectedVerticalType, setSelectedVerticalType] = useState();
  const [selectedSubVerticalType, setSelectedSubVerticalType] = useState();
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [locationValue, setLocationValue] = useState('');
  const [availableValue, setAvailableValue] = useState('');
  const [possessionDateValue, setPossessionDatevalue] = useState('');
  const [leaseExpirationDateValue, setLeaseExpirationvalue] = useState('');


  const VerticalColors = {
    Office: "RGBA(255, 99, 2,255)",
    Land: "RGBA(255, 168, 168,255)",
    Retail: "RGBA(232, 116, 133,255)",
    Investment: "RGBA(243, 223, 223,255)",
    Warehouse: "RGBA( 241, 66, 66,255)",
    "Cold storage": "RGBA(202, 57, 57,255)",
    Factory: "RGBA(250, 154, 9,255)",
    Hotels: "RGBA(255, 2, 2,255)",
    Others: "RGBA(252, 214, 214,255)",
  };

  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchProperties();
      if (result) {
        setBody(result);
        setProperties(result);
      } else {
        setBody([]);
        setProperties([]);
      }
      let projectList = await inventoryApi.fetchProjects();

      if (projectList && projectList.length) {
        let ar = projectList.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptionProject(ar);
      } else {
        setOptionProject([]);
      }
    }
    init();
  }, []);


  const filterData = (
    nameText,
    locationText,
    transactionTypeText,
    typeOfClientText,
    verticalText,
    verticalTypeText,
    subVerticalTypeText,
    toValue,
    fromValue,
    possessionDateTextValue,
    leaseExpirationDateValue,
    availableValue
  ) => {
    let filteredData = properties;
    console.log('locationText 163', toValue, fromValue, nameText, transactionTypeText);
    console.log('body', body);


    const lowercasedLocationText = locationText.toLowerCase();

    const lowercasedFilterName = nameText.toLowerCase();
    if (nameText !== null && nameText !== '' && nameText !== undefined) {
      filteredData = properties.filter((row) => {
        const Name = `${row?.name}`.toLowerCase().trim();
        return Name?.includes(lowercasedFilterName);
      });
    }

    if (locationText !== null && locationText !== '' && locationText !== undefined) {
      filteredData = filteredData.filter((row) => {
        const location = row?.locatedin?.toLowerCase();
        return location?.includes(lowercasedLocationText);
      });
      console.log('filteredData', filteredData);
    }

    if (transactionTypeText !== null && transactionTypeText !== '' && transactionTypeText !== undefined) {
      console.log('transactionTypeText:', transactionTypeText);
      filteredData = filteredData.filter(item => {
        return item.transactiontype && item.transactiontype.toLowerCase() === transactionTypeText.toLowerCase();
      });
      console.log('filteredData:', filteredData);
    }


    if (typeOfClientText !== null && typeOfClientText !== '' && typeOfClientText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.typeofclient && item.typeofclient.toLowerCase() === typeOfClientText.toLowerCase()
      );
    }

    if (verticalText !== null && verticalText !== '' && verticalText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.vertical && item.vertical.toLowerCase() === verticalText.toLowerCase()
      );
    }

    if (verticalTypeText !== null && verticalTypeText !== '' && verticalTypeText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.verticaltype && item.verticaltype.toLowerCase() === verticalTypeText.toLowerCase()
      );
    }

    if (subVerticalTypeText !== null && subVerticalTypeText !== '' && subVerticalTypeText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.subverticaltype && item.subverticaltype.toLowerCase() === subVerticalTypeText.toLowerCase()
      );
    }
    if (toValue !== null && toValue !== '' && toValue !== undefined) {
      console.log('filteredData 218');
      filteredData = filteredData.filter(item =>
        item.areadetails && item.areadetails.some(area => area.value && parseInt(area.value) <= toValue));
    }

    if (fromValue !== null && fromValue !== '' && fromValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.areadetails && item.areadetails.some(area => area.value && parseInt(area.value) >= fromValue && (toValue === null || toValue === '' || toValue === undefined || parseInt(area.value) <= toValue))
      );
    }
    console.log('availableValue', availableValue);



    if (possessionDateTextValue !== null && possessionDateTextValue !== '' && possessionDateTextValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.possessiondate && item.possessiondate.toLowerCase() === possessionDateTextValue.toLowerCase()
      );
      setPossessionDatevalue('');
    }

    if (leaseExpirationDateValue !== null && leaseExpirationDateValue !== '' && leaseExpirationDateValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.leaseexpirationdate && item.leaseexpirationdate.toLowerCase() === leaseExpirationDateValue.toLowerCase()
      );
      setLeaseExpirationvalue('')

    }

    if (availableValue !== null && availableValue !== '' && availableValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.availability && item.availability.toLowerCase() === availableValue.toLowerCase()
      );
    }

    return filteredData;
  };

  const handleFilterChange = (
    name,
    locationText,
    transactionTypeText,
    typeOfClientText,
    verticalText,
    verticalTypeText,
    subVerticalTypeText,
    toValue,
    fromValue,
    possessionDateTextValue,
    leaseExpirationDateValue,
    availableValue
  ) => {
    console.log('locationTextttttt', availableValue, locationText, toValue, transactionTypeText)
    try {
      console.log('if locationText', locationText)
      const filteredLeads = filterData(
        name,
        locationText,
        transactionTypeText,
        typeOfClientText,
        verticalText,
        verticalTypeText,
        subVerticalTypeText,
        fromValue,
        toValue,
        possessionDateTextValue,
        leaseExpirationDateValue,
        availableValue
      );
      console.log('if locationText 261', locationText)
      console.log('filteredLeads body', filteredLeads);
      setBody(filteredLeads);
    } catch (error) {
      console.log('catch locationText', locationText)
      console.error('Error occurred while filtering leads:', error);
    }
  };


  // Create table headers consisting of 4 columns
  const header = [
    {
      title: "Name",
      prop: "name",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <Link to={"/properties/" + row.id} state={row}>
          {row.name}
        </Link>
      ),
    },
    {
      title: "Vertical",
      prop: "vertical",
      isSortable: true,
      isFilterable: true,
      cell: (row) => {
        return (
          <>
         { row.vertical && row.vertical !=='' && <Badge
            bg={row.vertical !== null ? row.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[row.vertical] : ""}
            style={{
              display: "block",
              paddingBottom: "5px",
              backgroundColor: row.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[row.vertical],
              color: "black",
              fontWeight: 'bold',
              fontSize: '0.9rem'
            }}
          >

            {row.vertical}
          </Badge>}
          </>
        );
      },
    },
    // {
    //   title: "Contact",
    //   prop: "contactname",
    //   isSortable: true,
    //   isFilterable: true,
    // },
    {
      title: "Located in",
      prop: "locatedin",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: "Area To",
      prop: "areato",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: "Area From",
      prop: "area",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: "Created Date",
      prop: "createddate",
      isFilterable: true,
      cell: (row) => (
        <>
          {moment(row.createddate).format("DD-MM-YYYY")}
        </>
      )
    },
    {
      title: "Availability",
      prop: "availability",
      isSortable: true,
      isFilterable: true,
    },
    // {
    //   title: "Address",
    //   prop: "state",
    //   isSortable: true,
    //   isFilterable: true,
    // },
  ];

  const labels = {
    beforeSelect: " ",
  };

  const createProperty = () => {
    navigate(`/properties/e`);
  };

  return (
    <Container>
      <CustomSeparator
        currentCmpName="Inventory"
        indexLength="0"
        url="/properties"
      ></CustomSeparator>
      <Row className="g-0 ">
        <Col lg={12} className="px-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 15,
                  options: [5, 10, 15, 20],
                },
              }}
              sortProps={{
                initialState: {
                  prop: "createddate",
                  order: "desc",
                },
              }}
            >
              <Row className="mb-4 row align-items-end justify-content-between">
                <Col
                  lg={2}
                  xs={6}
                  sm={6}
                  className="mt-3"
                >
                  <Form.Group
                    controlId="formBasicTextInput"
                  >
                    <Form.Control
                      type="text"
                      value={nameValue}
                      placeholder="Enter Name"
                      //value={textInputValue}
                      onChange={(e) => {
                        setNameValue(e.target.value)
                        handleFilterChange(e.target.value, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                      }}
                    />
                  </Form.Group>

                </Col>
                {/* {secondDropdownOptions.length > 0 && (
                <Col
                lg={2}>
                  <Form.Group controlId="formBasicSecondFilter">
                    <Form.Select
                      aria-label="Select Second Filter"
                      name="secondDropdownValue"
                      onChange={handleSecondDropdownChange}
                      className="select-dropdown-2"
                    >
                      <option value="">--Select Availability--</option>
                      {secondDropdownOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                )} */}


                <Col lg={2}                  >
                  <Form.Group
                    controlId="formBasicTextInput"

                  >
                    <Form.Control
                      type="text"
                      name="locatedin"
                      placeholder="Enter Located In"
                      value={locationValue}
                      onChange={(e) => {
                        setLocationValue(e.target.value);
                        handleFilterChange(nameValue, e.target.value, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                      }
                      }
                    />
                    {/* <Button className="btn-danger"
                        onClick={() => {
                          setTextInputValue('');
                          setFilterObj({
                            ...filterObj,
                            secondDropdownValue: "",
                          });
                          setBody(leads);
                        }}>X</Button> */}
                  </Form.Group>
                </Col>

                <>
                  <Col lg={2}>
                    <Form.Select
                      id="basic-typeahead-single"
                      name="transactiontype"
                      value={selectedTransaction}
                      onChange={(e) => {
                        setSelectedTransaction(e.target.value);
                        // setTranscationType(e.target.value)
                        const temp = [];
                        if (e.target.value !== "") {
                          if (
                            Object.entries(data.Dependency[e.target.value])
                              .length > 0
                          ) {
                            for (const [key, value] of Object.entries(
                              data.Dependency[e.target.value]
                            )) {
                              temp.push(key);
                            }
                            setTypeOfClient(temp);
                          }
                        } else {
                          setTypeOfClient([]);
                        }

                        handleFilterChange(nameValue, locationValue, e.target.value, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);



                      }}
                    >
                      <option value="">Select Transaction Type </option>
                      {(transcationType) &&
                        transcationType?.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Form.Select>
                  </Col>

                  <Col lg={2}>
                    <Form.Select
                      id="basic-typeahead-single"
                      name="typeofclient"
                      disabled={!selectedTransaction}
                      value={selectedTypeOfClient}
                      onChange={(e) => {
                        setSelectedTypeOfClient(e.target.value)
                        const temp = [];
                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[selectedTransaction]
                            ).length > 0
                          ) {
                            for (const [key, value] of Object.entries(
                              data.Dependency[selectedTransaction][
                              e.target.value
                              ]
                            )) {
                              temp.push(key);
                            }
                            setVertical(temp);
                          }
                        } else {
                          setVertical([]);
                        }
                        handleFilterChange(nameValue, locationValue, selectedTransaction, e.target.value, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                      }}
                    >
                      <option value="">Select Type of Client</option>
                      {typeOfClient?.length > 0 &&
                        typeOfClient?.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg={2}>
                    <Form.Select
                      id="basic-typeahead-single"
                      name="vertical"
                      disabled={!selectedTypeOfClient}
                      value={selectedVertical}
                      onChange={(e) => {
                        setSelectedVertical(e.target.value);
                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[selectedTransaction]
                            ).length > 0
                          ) {
                            const temp = [];
                            for (const [key, value] of Object.entries(
                              data.Dependency[selectedTransaction][
                              selectedTypeOfClient
                              ][e.target.value]
                            )) {
                              temp.push(key);
                            }
                            setVerticalType(temp);
                          }
                        } else {
                          setVerticalType([]);
                        }
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, e.target.value, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                      }}
                    >
                      <option value="">Select Vertical</option>
                      {verticals?.length > 0 &&
                        verticals?.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg={2}>
                    <Form.Select
                      id="basic-typeahead-single"
                      name="verticaltype"
                      disabled={!selectedVertical}
                      value={selectedVerticalType}
                      onChange={(e) => {
                        setSelectedVerticalType(e.target.value);
                        if (e.target.value !== "") {
                          if (
                            Object.entries(
                              data.Dependency[selectedTransaction]
                            ).length > 0
                          ) {
                            const temp = [];
                            for (const [key, value] of Object.entries(
                              data.Dependency[selectedTransaction][
                              selectedTypeOfClient
                              ][selectedVertical][e.target.value]
                            )) {
                              temp.push(...value);
                            }
                            setSubVerticalType(temp);
                          }
                        } else {
                          setSubVerticalType([]);
                        }
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, e.target.value, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                      }}
                    >
                      <option value="">Select Vertical Type</option>
                      {verticalType?.length > 0 &&
                        verticalType?.map((value) => (
                          <>
                            <option value={value}>{value}</option>
                          </>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg={2}>
                    <Form.Select
                      id="basic-typeahead-single"
                      name="subverticaltype"
                      disabled={!selectedVerticalType}
                      value={selectedSubVerticalType}
                      onChange={(e) => {
                        setSelectedSubVerticalType(e.target.value);
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, e.target.value, to, from, availableValue, possessionDateValue, leaseExpirationDateValue);
                      }}
                    >
                      <option value="">Select Sub Vertical Type</option>
                      {selectedVertical === "Cold storage" || selectedVertical === "Warehouse" || selectedVertical === "Factory" ?
                        <>
                          <option value="Ready to Move">Ready to Move</option>
                          <option value="BTS">BTS</option>
                          <option value="Under Construction">Under Construction</option>
                        </> : ''
                      }
                      {subVerticalType?.length > 0 &&
                        subVerticalType?.map((value) => (
                          <>
                            <option value={value}>{value}</option>
                          </>
                        ))}
                    </Form.Select>
                  </Col>
                </>
                <>
                  <Col lg={2}>
                    <Form.Group
                      controlId="formBasicTextInput"
                    >
                      <Form.Control
                        type="number"
                        // disabled = { from !==''}
                        value={to}
                        placeholder="Enter To"
                        onChange={(e) => {
                          setTo(e.target.value)
                          console.log('to', to);
                          handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, e.target.value, from, possessionDateValue, leaseExpirationDateValue, availableValue);
                        }}
                      />
                      {/* <Button className="btn-danger"
                        onClick={() => {
                          to('');
                          setFilterObj({
                            ...filterObj,
                            secondDropdownValue: "",
                          });
                          setBody(leads);
                        }}>X</Button> */}
                    </Form.Group>
                  </Col>
                  <Col lg={2}
                    className="mt-2">
                    <Form.Group
                      controlId="formBasicTextInput"
                    >
                      <Form.Control
                        type="number"
                        value={from}
                        // disabled = { to ===''}
                        placeholder="Enter From "
                        onChange={(e) => {
                          setFrom(e.target.value)
                          handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, e.target.value, possessionDateValue, leaseExpirationDateValue, availableValue);
                        }}
                      />
                      {/* <Button className="btn-danger"
                        onClick={() => {
                          From('');
                          setFilterObj({
                            ...filterObj,
                            secondDropdownValue: "",
                          });
                          setBody(leads);
                        }}>X</Button> */}
                    </Form.Group>
                  </Col>
                </>
                <Col lg={2}
                  className="mt-2">
                  <Form.Group controlId="formBasicStatus">
                    <Form.Select
                      value={availableValue}
                      onChange={(e) => {
                        setAvailableValue(e.target.value)
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, e.target.value);

                      }}
                    >
                      <option value="">Select Availability</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col lg={2} className="mt-2">
                  {availableValue === "Available" ? (
                    <>

                      <Form.Control
                        type="date"
                        name="possessiondate"
                        //value={possessionDateValue}
                        onChange={(e) => {
                          setPossessionDatevalue(e.target.value);
                          handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, e.target.value, leaseExpirationDateValue, availableValue);

                        }}
                      />
                    </>
                  ) : (
                    availableValue === "Not Available" ? (
                      <>
                        <Form.Control
                          type="date"
                          name="leaseexpirationdate"
                          //value={leaseExpirationDateValue}
                          onChange={(e) => {
                            setLeaseExpirationvalue(e.target.value);
                            handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, e.target.value, availableValue);
                          }}
                        />
                      </>
                    ) : null
                  )}
                </Col>
                <Col lg={2}
                  className="d-flex flex-col mt-3">
                  <Button variant="danger" onClick={() => {
                    setNameValue('');
                    setLocationValue('');
                    setSelectedTransaction('');
                    setSelectedTypeOfClient('');
                    setSelectedVertical('');
                    setSelectedSubVerticalType('');
                    setSelectedVerticalType('');
                    setTo('');
                    setFrom('');
                    setAvailableValue('');
                    setLeaseExpirationvalue('');
                    setPossessionDatevalue('');
                    setBody(properties);
                  }

                  }>
                    Reset
                  </Button>
                </Col>
                <Col lg={1} xs={2} sm={1} className="">
                  <PaginationOptions labels={labels} />
                </Col>
                <Col lg={2} xs={2} sm={6} className="mt-3">
                  <div>
                    <NewInfoPill left="Total" right={body?.length} />
                  </div>
                </Col>
                <Col
                  lg={2}
                  xs={8}
                  sm={6}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    style={{}}
                    className="btn-sm outline-primary"
                    variant="outline-primary"
                    onClick={() => createProperty(true)}
                  >
                    New Inventory
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Container>
  );
};

export default PropertyList;
