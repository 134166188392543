import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import jwt_decode from "jwt-decode";
import inventoryApi from "../../api/inventoryApi";
import FileUpload from "./FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import AttachFile from "./AttachFile";
import PubSub from "pubsub-js";
import ImageResize from "quill-image-resize-module-react";
import { BASE_URL } from "../../constants/CONSTANT";

Quill.register("modules/imageResize", ImageResize);

const EmailComposer = (props) => {
  const buttonref = useRef();
  const [value, setValue] = useState("");
  const [files, setFiles] = useState();
  const [emails, setEmails] = useState([]);
  const [emailFrom, setEmailFrom] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [email, setEmail] = useState({ to: props.toEmail });
  const [user, setUser] = useState();
  const [parentid, setParentid] = useState(props.parentid);
  const [validated, setValidated] = useState(false);
  const [showAttachFile, setShowAttachFile] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons

      // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      // text direction

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];
  useEffect(() => {
    const fetchData = async () => {
      const emailData = await inventoryApi.fetchEmails();
      // console.log('email', emailData.data);
      setEmails(emailData.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let userInfo = jwt_decode(localStorage.getItem("token"));
    //.log(userInfo)

    let tempValue = {
      id: userInfo.id,
      username: userInfo.username,
      email: userInfo.email,
    };
    setUser(tempValue);

    // setEmail({...email, ['editorHtml'] : ` `});
  }, []);

  const handleChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  const handleChangeEditor = (html) => {
    console.log(html)
    setEmail({ ...email, ["editorHtml"]: html });
  };
  const checkRequredFields = () => {
    if (
      email.subject &&
      email.subject.trim() !== "" &&
      email.from &&
      email.from.trim() !== "" &&
      email.to &&
      email.to.trim() !== "" &&
      // (email.cc && email.cc.trim() !== '') &&
      email.editorHtml &&
      email.editorHtml.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    console.log(email)
    email.from = emailFrom;
    email.ownerid = user.id;
    email.parentid = parentid;
    email.attachment = selectedFiles.map((item) => item.id);
    //const ids = values.map(item => item.id);
    //e.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    //.log('ids',email)
    email.selectedfile = JSON.stringify({
      inventoryFilesSent: selectedFiles.map((item) => item.id),
    });

    createEmailRecord(email);
    const result = await inventoryApi.sendEmailTask(email);
    console.log(result);
    if (!result.errors) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Email sent",
        message: "Email sent successfully",
      });
      submitTasks();
    } else {
      PubSub.publish("RECORD_ERROR_TOAST", {
        title: "Record Not Saved",
        message: "Record not saved",
      });
    }
  };

  const submitTasks = () => {
    props.submitTasks();
  };

  const createEmailRecord = (emailData) => {
    let pdfFiles = [];
    let allAttachments = [];

    selectedFiles.forEach((file) =>
      file.hasOwnProperty("ispdf") && file.ispdf
        ? pdfFiles.push({
          id: file.id,
          parentid: parentid,
          filename: file.filetitle,
        })
        : allAttachments.push({
          id: file.id,
          parentid: parentid,
          filename: file.title,
        })
    );

    async function init() {
      let tempRecord = {
        toaddress: emailData.to,
        fromaddress: emailData.from,
        ccaddress: emailData.cc,
        subject: emailData.subject,
        body: emailData.editorHtml,
        attachments: allAttachments,
        pdf: pdfFiles,
        parentid: props.parentid,
      };

      const resultEmailRec = await inventoryApi.createEmailRecord(tempRecord);
      //.log("resultEmailRec --> ",resultEmailRec);
    }
    init();
  };

  const handleFile = async (fileset) => {
    //.log('files', fileset);
    var formData = new FormData();
    for (let i = 0; i < fileset.length; i++) {
      //.log("files", fileset[i])
      formData.append(`files${i}`, fileset[i]);
    }
    const result = await inventoryApi.createFile(props.parentid, formData);
    setSelectedFiles((prevFiles) => [...prevFiles, ...result]);
  };

  const handleRemoveFile = async (fileToRemove) => {
    if (await inventoryApi.deleteFile(fileToRemove.id)) {
      const updatedFiles = selectedFiles.filter(
        (file) => file !== fileToRemove
      );
      setSelectedFiles(updatedFiles);
    }
  };

  return (
    <>
      <Modal
        style={showAttachFile ? { filter: "blur(0.075rem)" } : ""}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Email Composer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="view-form">
            <Form
              className="mt-3"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle"
                >
                  Subject
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="subject"
                  placeholder="Enter subject"
                  value={email.subject}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle"
                >
                  From
                </Form.Label>
                <Form.Select
                  required
                  //title="pease select type "
                  name="from"
                  // placeholder="Comma separated email address"
                  value={email.from}
                  onChange={(e) => {
                    let updatedEmail = { ...email };
                    updatedEmail['from'] = e.target.value;

                    if (e.target.value === "ritesh@sthapatyaleasing.com") {
                      if (files !== true) {
                        updatedEmail.editorHtml = `
                                                  <br/><br/><b>Thanks in Anticipation</b><br/><br/>
                                                  Regards<br/><b>CA Ritesh Agarwal | Founder</b><br/>
                                                  Sthapatya Leasing And Consultant<br/>M : +9829610077<br/>O : 0141 356 6182<br/>W : www.sthapatyaleasing.com<br/>
                                                  <div style="width:6px;height:3px">
                                                      <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                      src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}"  />
                                                  </div>
                                                  </br> <p>Follow us on :
                                                  <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                                  <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                                  </a> 
                                                  &nbsp;&nbsp;&nbsp; &nbsp;
                                                  <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                                  <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                                  </a>
                                                  &nbsp;&nbsp;&nbsp; &nbsp;
                                                  <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                                  <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                                  </a>
                                                  </p>
                                                  </br>Our Associations : </br> 
                                                  <img  alt="sthapathya-logo" width="42" height="42"  src='${BASE_URL}/sthapathyafooter_243x40.png' />
                                              <br/><br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                                                  `;

                      } else {
                        updatedEmail.editorHtml = `
                                                  <br/><br/><b>Thanks & Regards</b><br/>
                                                  <br/><b>CA Ritesh Agarwal | Founder</b><br/>
                                                  Sthapatya Leasing And Consultant
                                                  <br/>M : +9829610077<br/>O : 0141 356 6182<br/>
                                                  Web.:
                                                      <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a> | 
                                                      <a href="http://www.ciril.in">www.ciril.in</a>
                                                  <br/>
                                                 
                                                  </br><p>Follow us on : 
                                                  <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                                  <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                                  </a> 
                                                  &nbsp;&nbsp;&nbsp; 
                                                  <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                                  <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                                  </a>
                                                  &nbsp;&nbsp;&nbsp; 
                                                  <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                                  <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                                  </a></p>
                                                  </br>
                                                  </br>
                                                  <div style="width:6px;height:3px">
                                                  <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                  src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}"  />
                                              </div>
                                              <br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                                                   `;

                      }
                    } else if (
                      e.target.value === "arpityagnik@sthapatyaleasing.com"
                    ) {
                      if (files !== true) {

                        updatedEmail.editorHtml = `<br/><br/><b>Thanks in anticipation</b><br/>Regards<br/><br/>Arpit Yagnik | Co-founder <br/>Sthapatya Leasing And Consultant<br/>
                        M: <a href="tel:+919636969646">+91 9636969646</a> | O: <a href="tel:+911413566182">0141 3566182</a>
                        <br/>
                        W: <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                                                   </br>

                                                  <div style="width:6px;height:3px">
                                                  <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                  src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}" 
                                                   />
                                              </div>
                                              </br<p>Follow us on :
                                              <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                              <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                              </a> 
                                              &nbsp;&nbsp;&nbsp;
                                              <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                              <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                              </a>
                                              &nbsp;&nbsp;&nbsp;
                                              <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                              <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                              </a></p>
                                              </br>Our Associations : </br> 
                                                  <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                                                  <br/><br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                                                `;
                       } else {
                        updatedEmail.editorHtml = `
                        <br/><br/><b>Thanks in anticipation</b><br/>Regards<br/><br/>Arpit Yagnik | Co-founder <br/>Sthapatya Leasing And Consultant<br/>
                        M: <a href="tel:+919636969646">+91 9636969646</a> | O: <a href="tel:+911413566182">0141 3566182</a>
                                                  Web.:
                                                      <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a> | 
                                                      <a href="http://www.ciril.in">www.ciril.in</a>
                                                  <br/>
                                                 
                                                  </br><p>Follow us on : 
                                                  <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                                  <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                                  </a>  &nbsp;&nbsp;&nbsp;
                                                  <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                                  <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                                  </a> &nbsp;&nbsp;&nbsp;
                                                  <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                                  <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                                  </a></p>
                                                  </br>
                                                  <div style="width:6px;height:3px">
                                                  <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                  src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}"  />
                                              </div>
                                              <br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                                                   `;

                      }
                    } else if (e.target.value === "bhavya@sthapatyaleasing.com" ) {
                      if (files === true) {

                        updatedEmail.editorHtml = `<br/>For any queries please contact us<br/>Our team is here to solve all your queries!<br/><br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/><br/> Bhavya Sharma | Transaction Manager Retail & Office Leasing<br/>
                        <p> M: <a href="tel:+919257024238">+91 9257024238</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a></p>
                        </br>
                        <div style="width:6px;height:3px">
                        ${files !== true
                            ? " </br>Our Associations : </br>"
                            : ""
                          }
                        
                        <img  alt="sthapathya-logo" style="width:6px;height:3px"
                        src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}" 
                        />
                    </div>
                    <br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                      } else {
                        updatedEmail.editorHtml = `<br/>For any queries please contact,<br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/>
                        Bhavya Sharma | Transaction Manager Retail & Office Leasing<br/> 
                        
                        <p> M: <a href="tel:+919257024238">+91 9257024238</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a></p>
                      </br>Our Associations : </br> 
                          <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                       
                    </div>
                    <br/><br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                      }
                    } else if (
                      e.target.value === "info@sthapatyaleasing.com"
                    ) {
                      if (files === true) {

                        updatedEmail.editorHtml = `<br/>For any queries please contact us<br/>Our team is here to solve all your queries!<br/><br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/><br/><h3>Ravi Sharma | Manager</h3><br/>
                        <p> M: <a href="tel:+91 9257024240">+91 9257024240</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a></p>
                        <h3>Industrial & Logistics Services | Advisory & Transaction Services</h3>
                        </br>
                        <div style="width:6px;height:3px">
                        ${files !== true
                            ? " </br>Our Associations : </br>"
                            : ""
                          }
                        
                         
                          
                          <img  alt="sthapathya-logo" style="width:6px;height:3px"
                        src="${files !== true ? `${BASE_URL}/stypatyalogo2.jpg` : `${BASE_URL}/ciril.jpg`}" 
                        />
                    </div>
                    <br/><br/><br/>
                    <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                      <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                      <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                      } else {
                        updatedEmail.editorHtml = `<br/>For any queries please contact,<br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/></br> </br>
                       Ravi Sharma | Manager<br/> 
                        
                       <p> M: <a href="tel:+91 9257024240">+91 9257024240</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; 
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a></p> <br/>
                        <h3>Industrial & Logistics Services | Advisory & Transaction Services</h3>
                        </br>
                      </br>Our Associations : </br> 
                          <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                       
                    </div>
                    <br/><br/><br/>
                      <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                        <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                        <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                      }
                    }

                    setEmailFrom(e.target.value)
                    setEmail({ ...updatedEmail });

                  }}
                >
                  <option value="">Select</option>
                  {emails &&
                    emails.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Row>
                <Col lg={2} className="mx-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={files === true ? false : true}
                      value={files}
                      onChange={(e) => {
                        setFiles(false);
                        let updatedEmail = { ...email };
                        if (emailFrom === "ritesh@sthapatyaleasing.com") {
                          updatedEmail.editorHtml = `
                          <br/><br/><b>Thanks in Anticipation</b><br/><br/>
                          Regards<br/><b>CA Ritesh Agarwal | Founder</b><br/>
                          Sthapatya Leasing And Consultant<br/>M : +9829610077<br/>O : 0141 356 6182<br/>W : www.sthapatyaleasing.com<br/>
                          <div style="width:6px;height:3px">
                              <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                              src=" ${BASE_URL}/stypatyalogo2.jpg"  />
                          </div>
                          </br>Follow us on : 
                          <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                          <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                          </a> 
                          <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                          <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                          </a>
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                          <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                          </a>
                          </br>
                          </br>Our Associations : </br> 
                          <img  alt="sthapathya-logo" width="42" height="42"  src='${BASE_URL}/sthapathyafooter_243x40.png' />
                      <br/><br/>
                      <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                        <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                        <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                          `;
                        } else if (
                          emailFrom === "arpityagnik@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `<br/><br/><b>Thanks in anticipation</b><br/><br/><br/>Regards<br/>Arpit Yagnik | Co-founder <br/>Sthapatya Leasing And Consultant<br/>
                          M: <a href="tel:+919636969646">+91 9636969646</a> | O: <a href="tel:+911413566182">0141 3566182</a>
                          <br/>
                          W: <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                                                     </br>
  
                                                    <div style="width:6px;height:3px">
                                                    <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                    src=" ${BASE_URL}/stypatyalogo2.jpg"  />
                                                </div>
                                                </br>Follow us on :
                                                <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                                <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                                </a> 
                                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                                <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                                </a>
                                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                                <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                                </a>
                                                </br>
                                                </br>Our Associations : </br> 
                                                    <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                                                    <br/><br/><br/>
                                                <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                  <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                  <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>
  
                                                  `;
                        } else if (
                          emailFrom === "bhavya@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `<br/>For any queries please contact,<br/><br/>
                          <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/></br> </br>
                          Bhavya Sharma | Transaction Manager Retail & Office Leasing<br/> 
                          
                          M: <a href="tel:+919257024238">+91 9257024238</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                          <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                          | Follow us on :
                                             
                          <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                          <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                          </a> 
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                          <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                          </a>
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                          <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                          </a>
                          </br>
                        </br>Our Associations : </br> 
                            <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                         
                      </div>
                      <br/><br/><br/>
                      <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                        <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                        <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                      `;
                        } else if (
                          emailFrom === "info@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `<br/>For any queries please contact,<br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/></br>
                       Ravi Sharma | Manager<br/> 
                        
                        M: <a href="tel: +91 9257024240 "> +91 9257024240 </a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a>
                        <h3>Industrial & Logistics Services | Advisory & Transaction Services</h3>
                        </br>
                      </br>Our Associations : </br> 
                          <img  alt="sthapathya-logo"  src='${BASE_URL}/sthapathyafooter_243x40.png'  />
                       
                    </div>
                    <br/><br/>
                      <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                        <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                        <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                        }

                        setEmail({ ...updatedEmail });

                      }}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Sthapatya
                    </label>
                  </div>
                </Col>
                <Col lg={1}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      // unchecked
                      onChange={(e) => {
                        setFiles(true);

                        let updatedEmail = { ...email };
                        if (emailFrom === "ritesh@sthapatyaleasing.com") {
                          updatedEmail.editorHtml = `
                          <br/><br/><b>Thanks & Regards</b><br/><br/>
                          <br/><b>CA Ritesh Agarwal | Founder</b><br/>
                          Sthapatya Leasing And Consultant
                          <br/>M : +9829610077<br/>O : 0141 356 6182<br/>
                          Web.:
                              <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a> | 
                              <a href="http://www.ciril.in">www.ciril.in</a>
                          <br/>
                         
                          </br>Follow us on : 
                          <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                          <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                          </a> 
                          <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                          <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                          </a>
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                          <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                          </a>
                          </br>
                          </br>
                          <div style="width:6px;height:3px">
                          <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                          src="${BASE_URL}/ciril.jpg"  />
                      </div>
                      <br/><br/><br/>
                      <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                        <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                        <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                           `;
                        } else if (
                          emailFrom === "arpityagnik@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `
                        <br/><br/><b>Thanks in anticipation</b><br/><br/><br/>Regards<br/>Arpit Yagnik | Co-founder <br/>Sthapatya Leasing And Consultant<br/>
                        M: <a href="tel:+919636969646">+91 9636969646</a> | O: <a href="tel:+911413566182">0141 3566182</a>
                                                  Web.:
                                                      <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a> | 
                                                      <a href="http://www.ciril.in">www.ciril.in</a>
                                                  <br/>
                                                 
                                                  </br>Follow us on : 
                                                  <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                                                  <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                                                  </a> 
                                                  <span ></span>
                                                  <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                                                  <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                                                  </a>
                                                  <span ></span>
                                                  <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                                                  <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                                                  </a>
                                                  </br>
                                                  </br>
                                                  <div style="width:6px;height:3px">
                                                  <img  alt="sthapathya-logo" style="width:6px;height:3px" 
                                                  src="${BASE_URL}/ciril.jpg"  />
                                              </div>
                                              <br/><br/>
                                              <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                                                <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                                                <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                                                   `;

                        } else if (
                          emailFrom === "bhavya@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `<br/>For any queries please contact us<br/>Our team is here to solve all your queries!<br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/><br/><h3>Bhavya Sharma | Transaction Manager Retail & Office Leasing</h3><br/>
                        M: <a href="tel:+91 9257024240">+91 9257024240</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a>
                        <h3>Industrial & Logistics Services | Advisory & Transaction Services</h3>
                        </br></br>
                        <div style="width:6px;height:3px">
                       
                          <img  alt="sthapathya-logo" style="width:6px;height:3px"
                          src="${BASE_URL}/ciril.jpg" 
                        />
                    </div>
                    <br/><br/>
                    <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                      <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                      <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                        } else if (
                          emailFrom === "info@sthapatyaleasing.com"
                        ) {
                          updatedEmail.editorHtml = `<br/>For any queries please contact us<br/>Our team is here to solve all your queries!<br/><br/><br/>
                        <b>Thanks and Regards</b><br/>Sthapatya Leasing And Consultant<br/><br/><h3>Ravi Sharma | Manager</h3>
                        M: <a href="tel:+91 9257024240">+91 9257024240</a> | O: <a href="tel:+911413566182">0141 356 6182</a> | 
                        <a href="http://www.sthapatyaleasing.com">www.sthapatyaleasing.com</a>
                        | Follow us on :
                                           
                        <a href="https://www.facebook.com/sthapatya.leasing.consultant?mibextid=ZbWKwL">
                        <img src="${BASE_URL}/facebook_31x31.png" alt="Instagfacebook_31x31ram" >
                        </a> 
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.linkedin.com/company/sthapatya-leasing-consultant/">
                        <img src="${BASE_URL}/LinkedIn_30x39.png" alt="LinkedIn_30x39">
                        </a>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        <a href="https://www.instagram.com/sthapatya_lease_and_consultant/?igshid=MzRlODBiNWFlZA%3D%3D">
                        <img src="${BASE_URL}/Instagram_30x31.jpg" alt="Instagram" style="width:48px;height:48px;">
                        </a>
                        <h3>Industrial & Logistics Services | Advisory & Transaction Services</h3>
                        </br></br>
                        <div style="width:6px;height:3px">
                        
                        
                         
                          
                          <img  alt="sthapathya-logo" style="width:6px;height:3px"
                          src="${BASE_URL}/ciril.jpg" 
                        />
                    </div>
                    <br/><br/>
                    <p>This message is intended only for the personal and confidential use of the designated recipient(s) named above. If you are not the intended recipient of this message you are hereby notified that any review, dissemination, distribution or copying of this message is strictly prohibited.</p>
                      <p>This communication is for information purposes only and should not be regarded as an official confirmation of any transaction. Email transmission cannot be guaranteed to be secure or error-free. Therefore, we do not represent that this information is complete or accurate and it should not be relied upon as such.</p>
                      <p>All information is subject to change without notice. Note: No warranty or representation, expressed or implied, is made as to the accuracy of the information contained herein, and the same is submitted subject to availability on a day-to-day basis, errors, omissions, change of price, rental or other conditions, withdrawals without notice, and to any special listings or conditions, imposed by our company.</p>

                    `;
                        }

                        setEmail({ ...updatedEmail });
                      }}
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioDefault1"
                      value={files}
                    >
                      CIRIL
                    </label>
                  </div>
                </Col>

              </Row>
              <Form.Group className="mx-3" >
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle">
                  To
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="to"
                  placeholder="Comma separated email address"
                  value={email.to}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle"
                >
                  Cc
                </Form.Label>
                <Form.Control
                  // required
                  type="text"
                  name="cc"
                  placeholder="Comma separated email address"
                  value={email.cc}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle"
                >
                  Body
                </Form.Label>
                <ReactQuill
                  onChange={handleChangeEditor}
                  required
                  value={email.editorHtml}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />
              </Form.Group>

              {/* <FileUpload  handleFile={(files) => handleFile(files)} parentid={parentid}/> */}
              <Button
                class=" fa-paperclip"
                for="files"
                style={{ margin: "14px" }}
                onClick={() => {
                  setShowAttachFile(!showAttachFile);
                  setSelectedFiles([]);
                  // buttonref.current.click()
                }}
              >
                <FontAwesomeIcon
                  icon={faPaperclip}
                  style={{ color: "#ffffff" }}
                />{" "}
                &nbsp; Attach
              </Button>
              {/* <input
            style={{ visibility: "hidden" }}
            ref={buttonref}
        type="file"
        multiple
        onChange={(e) => {handleFile(e.target.files)}} /> */}
              <br />
              <div>
                <h6>Selected Files:</h6>
                <ul>
                  {selectedFiles.length > 0
                    ? selectedFiles.map((file, index) => (
                      <li key={index}>
                        {file.title ? file.title : file.filetitle}
                        <Button
                          variant="Light"
                          style={{
                            border: "hidden",
                            backgroundColor: "white",
                          }}
                          onClick={() => handleRemoveFile(file)}
                        >
                          X
                        </Button>
                      </li>
                    ))
                    : ""}
                </ul>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleSubmit(selectedFiles)}>
            Send
          </Button>
          <Button
            onClick={() => {
              setSelectedFiles([]);
              props.onHide();
            }}
            variant="light"
          >
            Close
          </Button>
        </Modal.Footer>
        {showAttachFile && (
          <AttachFile
            parentid={props.parentid}
            show={showAttachFile}
            onHide={() => setShowAttachFile(!showAttachFile)}
            finalFiles={(data) => {
              setSelectedFiles(
                data.map((file) => {
                  return file.hasOwnProperty("id")
                    ? file
                    : { ...file, title: file.filetitle, id: file.fileid };
                })
              );
              // handleFile(data)
              setShowAttachFile(!showAttachFile);
            }}
          />
        )}
      </Modal>
    </>
  );
};
export default EmailComposer;
