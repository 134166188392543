import React, { useState } from 'react';
import { Badge, Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { TodoInitialValues } from '../common/InitialValuesHelper';
import { schemaTodoEdit } from '../common/ValidateSchemaHelper';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v1 } from "uuid";
import PubSub from "pubsub-js";
import inventoryApi from '../../api/inventoryApi';

const TodoG = () => {


    const location = useLocation();
    const [todos, setTodo] = useState(location.state ? location.state : {});
    const [editMode, setEditMode] = useState();
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const VerticalColors = {
        Low: "#F2E962",
        Normal: "#89DA3D",
        Medium: "#3DDAC7",
        High: "#F04941",
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    }

    const handleSubmitSave = async (values) => {

        values.details = values.details.filter((item) => (item.id !== undefined && item.task !== "" && item.priority !== ""));
        if (values.details && values.details.length === 0) {
            PubSub.publish("RECORD_ERROR_TOAST", {
                title: "Record Not Saved",
                message: 'please create atleast one task',
            });
            values.details = [{ id: undefined, task: values.details.task, priority: values.details.priority }];
        }
        else {
            // values.details = values.details.filter((item) => (item.task !== undefined || item.priority !== undefined || item.id !== undefined ));
            if (!values.id) {
                console.log("values: ", values);
                const result = await inventoryApi.createToDO(values);
                console.log("result submit: ", result);
                if (result) {
                    setTodo(result)
                    navigate(`/TodoView/${result.id}`, { state: result });
                }
            }
            else if (todos.id) {
                values.id = todos.id;
                console.log("values: ", values);
                const result = await inventoryApi.saveTodo(values);
                console.log('result', result);
                if (!result.errors) {
                    setTodo(result)
                    navigate(`/TodoView/${values.id}`, { state: values });
                }
                else {
                    PubSub.publish("RECORD_ERROR_TOAST", {
                        title: "Record Not Saved",
                        message: 'please create atleast one task',
                    });
                    values.details = [{ id: undefined, task: '', priority: '' }];
                }
            }
        }
    }


    return (
        <Container className="view-form inputbox">
            <Row>
                <Col></Col>
                <Col lg={10} style={{ backgroundColor: "white", margin: "40px" }}>
                    <Formik
                        validationSchema={schemaTodoEdit()}
                        onSubmit={handleSubmitSave}
                        initialValues={TodoInitialValues(todos)}
                    >
                        {({ handleSubmit, handleChange, values, setFieldValue }) => (
                            <Form
                                className="mt-3"
                                onSubmit={handleSubmit}
                            >
                                <Row
                                    lg={12}
                                    className="view-form-header align-items-center"
                                    style={{ marginTop: "-10px" }}
                                >
                                    <Col lg={10}>
                                        {todos.id ? (
                                            <>
                                                <b>Edit To-do</b>
                                                <h4>{todos.name}</h4>
                                            </>
                                        ) : (
                                            <h6 style={{ marginTop: "5px" }}>New To-Do</h6>
                                        )}
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                    {/* {console.log("values.details--->",values.details)} */}
                                        {values.details.length > 1 && <Button
                                            type="submit"
                                            className="btn-sm"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>}

                                        &nbsp;&nbsp;
                                        <Button
                                            className="btn-sm"
                                            variant="danger"
                                            onClick={() => navigate("/Todo")}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <label>
                                            Title <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <Field
                                            required
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="What is the Title?"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="error-message"
                                        />
                                    </Col>
                                </Row>
                                <FieldArray name="details">
                                    {({ push, remove }) => (
                                        <div>
                                            {values.details?.map((record, index) => (

                                                <Row lg={index}>
                                                    {values.details.length - 1 === index ?
                                                        <>
                                                            <Col lg={4}>
                                                                <label className="form-view-label" htmlFor="formBasicName">
                                                                    Task <b style={{ color: "red" }}>*</b>
                                                                </label>
                                                                <Field
                                                                    required
                                                                    name={`details[${index}].task`}
                                                                    placeholder="What is the task today?"
                                                                    type="text"
                                                                    value={values.details[index].task}
                                                                    onChange={handleChange}
                                                                />
                                                                {error && (!values.details[index].task || values.details[index].task === '') ? (
                                                                    <div className="text-danger">
                                                                        <b>{error}</b>
                                                                    </div>
                                                                ) : ''}
                                                            </Col>
                                                            <Col lg={4}>
                                                                <label>
                                                                    Priority <b style={{ color: "red" }}>*</b>
                                                                </label>
                                                                <Field
                                                                    name={`details[${index}].priority`}
                                                                    as="select"
                                                                    value={values.details[index].priority}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">--Select Priority--</option>
                                                                    <option value="Low">Low </option>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </Field>
                                                                {error && (!values.details[index].priority || values.details[index].priority === '') ? (
                                                                    <div className="text-danger">
                                                                        <b>{error}</b>
                                                                    </div>
                                                                ) : ''}
                                                            </Col>
                                                            <Col lg={4}>
                                                                <label>
                                                                    Description
                                                                </label>
                                                                <Field
                                                                    className="textarea"
                                                                    name={`details[${index}].description`}
                                                                    as="textarea"
                                                                    placeholder="Enter description"
                                                                    value={values.details[index].description}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </>
                                                        : ""}
                                                    <Col lg={2}>
                                                        {index !== values.details.length - 1 ? (
                                                            ""
                                                        ) : (
                                                            <Button
                                                                type="button"
                                                                onClick={() => {

                                                                    if (values.details[index].task && values.details[index].task?.trim() !== '' && values.details[index].priority && values.details[index].priority?.trim() !== '') {
                                                                        setFieldValue(`details[${index}].id`, v1())
                                                                        setError(false)
                                                                        push({
                                                                            id: undefined,
                                                                            task: "",
                                                                            priority: "",
                                                                            description: "",
                                                                        })
                                                                    } else {
                                                                        setError('Please Enter Requried feilds')
                                                                    }
                                                                }}
                                                            >

                                                                Add task
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    )}
                                </FieldArray>
                                <br /><br />
                                <div className="dragable mb-4 mx-3" >
                                    <DragDropContext onDragEnd={((result) => {
                                        if (!result.destination) {
                                            return;
                                        }
                                        const items = Array.from(values.details);
                                        const [reorderedItem] = items.splice(result.source.index, 1);
                                        items.splice(result.destination.index, 0, reorderedItem);
                                        setFieldValue('details', items)
                                    })}>
                                        <Droppable droppableId="drag-drop-list">
                                            {(provided) => (
                                                <ListGroup
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="drag-drop-list-container w-70"
                                                >
                                                    {values.details.map((todo, index) => (
                                                        (values.details[index].id !== undefined) ? (
                                                            <Draggable
                                                                key={todo.id}
                                                                draggableId={todo.id}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <ListGroup.Item>
                                                                            {/* <Container className="view-form">
                                                                                        <Col lg={12}>
                                                                                            <Form>
                                                                                                <Row lg={8}>
                                                                                                    <Col lg={1}>
                                                                                                        <Field
                                                                                                            type="checkbox"
                                                                                                            name={`details[${index}].checked`} defaultChecked={false}
                                                                                                            checked={values.details[index].checked}
                                                                                                            onChange={(e) => {
                                                                                                                setFieldValue(
                                                                                                                    "checked",
                                                                                                                    e.target.checked
                                                                                                                );
                                                                                                                handleChange(e);
                                                                                                            }}
                                                                                                        />
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <Row>
                                                                                                            <Col lg={10} className="d-flex ">
                                                                                                                <h4
                                                                                                                    style={{
                                                                                                                        display: "inline",
                                                                                                                        overflow: "hidden",
                                                                                                                        whiteSpace: "nowrap",
                                                                                                                        textTransform: "capitalize",
                                                                                                                        fontWeight: "bold",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {values.details[index].task}
                                                                                                                </h4>
                                                                                                                {todo.priority && (
                                                                                                                    <Badge
                                                                                                                        className="mx-2"
                                                                                                                        bg={VerticalColors[todo.priority]}
                                                                                                                        style={{
                                                                                                                            display: "block",
                                                                                                                            borderRadius: "10px", background: VerticalColors[todo.priority],
                                                                                                                            color: "black", fontSize: "0.9rem",
                                                                                                                            width: "60px",
                                                                                                                            paddingTop: "6px",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {values.details[index].priority}                                                                                                        </Badge>
                                                                                                                )}
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row className="mt-2">
                                                                                                            <Col>
                                                                                                                <p>{values.details[index].description}</p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Col>
                                                                                                    <Col lg={2} className="d-flex">
                                                                                                        <Button
                                                                                                            style={{ height: "30px", width: "30px" }}
                                                                                                            className="btn-sm mt-2 mx-2"
                                                                                                            variant="danger"
                                                                                                            onClick={() => {
                                                                                                                const removeTasks = values.details.filter((todo) => todo.id !== values.details[index].id);
                                                                                                                setFieldValue('details', removeTasks);
                                                                                                            }}
                                                                                                        >
                                                                                                            <i class="fa fa-trash"></i>
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            style={{ height: "30px", width: "30px" }}
                                                                                                            className="btn-sm mt-2 mx-2"
                                                                                                            onClick={(e) => {
                                                                                                                const fecthedData = values.details.filter((todo) => todo.id === values.details[index].id);
                                                                                                                setEditMode(fecthedData[0].id);
                                                                                                            }}
                                                                                                        >
                                                                                                            <i class="fas fa-edit"></i>
                                                                                                        </Button>
                                                                                                        &nbsp;&nbsp;

                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Form>
                                                                                        </Col>
                                                     </Container> */}
                                                                            {editMode === todo.id ? (
                                                                                <Container className="view-form inputbox">
                                                                                    <Row className="align-items-center flex-d">
                                                                                        <Col lg={12}>
                                                                                            <Form>
                                                                                                <Row lg={8}>
                                                                                                    <Col>
                                                                                                        <Row>
                                                                                                            <Col lg={10} className="d-flex ">
                                                                                                                <Field
                                                                                                                    className="w-100"
                                                                                                                    type="text"
                                                                                                                    name={`details[${index}].task`}
                                                                                                                    value={values.details[index].task}
                                                                                                                    onChange={handleChange}
                                                                                                                    placeholder="Update task"
                                                                                                                />
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                                <Field
                                                                                                                    className="w-40"
                                                                                                                    as="select"
                                                                                                                    name={`details[${index}].priority`}
                                                                                                                    value={values.details[index].priority}
                                                                                                                    onChange={handleChange}
                                                                                                                >
                                                                                                                    <option value="">--Select Priority--</option>
                                                                                                                    <option value="Low">Low </option>
                                                                                                                    <option value="Normal">Normal</option>
                                                                                                                    <option value="Medium">Medium</option>
                                                                                                                    <option value="High">High</option>
                                                                                                                </Field>


                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row className="mt-2">
                                                                                                            <Col lg={12}>
                                                                                                                <Field
                                                                                                                    className="textarea w-90"
                                                                                                                    type="text"
                                                                                                                    as="textarea"
                                                                                                                    name={`details[${index}].description`}
                                                                                                                    value={values.details[index].description}
                                                                                                                    onChange={handleChange}
                                                                                                                    placeholder="Update description"
                                                                                                                />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Col>
                                                                                                    <Col lg={2}>
                                                                                                        <Button onClick={() => {
                                                                                                            setEditMode('');
                                                                                                        }} className="btn-sm m-3 mt-4">
                                                                                                            Update
                                                                                                        </Button>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Form>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Container>
                                                                            ) : (
                                                                                <>
                                                                                    {todo.id?.trim() !== '' ?
                                                                                        <Container className="view-form">
                                                                                            <Col lg={12}>
                                                                                                <Form>
                                                                                                    <Row lg={8}>
                                                                                                        <Col lg={1}>
                                                                                                            <Field
                                                                                                                type="checkbox"
                                                                                                                name={`details[${index}].checked`} defaultChecked={false}
                                                                                                                checked={values.details[index].checked}
                                                                                                                onChange={(e) => {
                                                                                                                    setFieldValue(
                                                                                                                        "checked",
                                                                                                                        e.target.checked
                                                                                                                    );
                                                                                                                    handleChange(e);
                                                                                                                }}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Row>
                                                                                                                <Col lg={10} className="d-flex ">
                                                                                                                    <h4
                                                                                                                        style={{
                                                                                                                            display: "inline",
                                                                                                                            overflow: "hidden",
                                                                                                                            whiteSpace: "nowrap",
                                                                                                                            textTransform: "capitalize",
                                                                                                                            fontWeight: "bold",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {values.details[index].task}
                                                                                                                    </h4>
                                                                                                                    {todo.priority && (
                                                                                                                        <Badge
                                                                                                                            className="mx-2"
                                                                                                                            bg={VerticalColors[todo.priority]}
                                                                                                                            style={{
                                                                                                                                display: "block",
                                                                                                                                borderRadius: "10px", background: VerticalColors[todo.priority],
                                                                                                                                color: "black", fontSize: "0.9rem",
                                                                                                                                width: "60px",
                                                                                                                                paddingTop: "6px",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {values.details[index].priority}                                                                                                        </Badge>
                                                                                                                    )}
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <Row className="mt-2">
                                                                                                                <Col>
                                                                                                                    <p>{values.details[index].description}</p>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                        <Col lg={2} className="d-flex">
                                                                                                            <Button
                                                                                                                style={{ height: "30px", width: "30px" }}
                                                                                                                className="btn-sm mt-2 mx-2"
                                                                                                                variant="danger"
                                                                                                                onClick={() => {
                                                                                                                    const removeTasks = values.details.filter((todo) => todo.id !== values.details[index].id);
                                                                                                                    setFieldValue('details', removeTasks);
                                                                                                                }}
                                                                                                            >
                                                                                                                <i class="fa fa-trash"></i>
                                                                                                            </Button>
                                                                                                            <Button
                                                                                                                style={{ height: "30px", width: "30px" }}
                                                                                                                className="btn-sm mt-2 mx-2"
                                                                                                                onClick={(e) => {
                                                                                                                    const fecthedData = values.details.filter((todo) => todo.id === values.details[index].id);
                                                                                                                    setEditMode(fecthedData[0].id);
                                                                                                                }}
                                                                                                            >
                                                                                                                <i class="fas fa-edit"></i>
                                                                                                            </Button>
                                                                                                            &nbsp;&nbsp;

                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Form>
                                                                                            </Col>
                                                                                        </Container> : ''}
                                                                                </>
                                                                            )}
                                                                        </ListGroup.Item>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ) : null
                                                    ))}
                                                    {provided.placeholder}
                                                </ListGroup>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
export default TodoG
