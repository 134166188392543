import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import moment from "moment";
import Select from 'react-select';
import PubSub from 'pubsub-js';
import CityState from "../../constants/CityState.json";
import { ErrorMessage, Field, Formik } from "formik";
import { schemaContactEdit } from "../common/ValidateSchemaHelper";
import { contactEditInitialValues } from "../common/InitialValuesHelper";


const ContactNewModal = (props) => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [contact, setContact] = useState({});
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState(false);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        var temp = [];
        CityState.map((ele) => {
            if (!temp.includes(ele.state)) {
                temp.push(ele.state);
            }
        });
        setStateList(temp.sort());
        var tempList = CityState.filter((ele) => ele.state === contact.state);
        setCityList(tempList.sort());

    }, [accounts]);


    const location = useLocation();


    const handleSubmitSave = async (values) => {
        let result2 = await inventoryApi.createContact(values);
        if (result2) {
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
            submitContact(result2);
        }
    }

    const submitContact = (result) => {
        props.submitContact(result);
    };




    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form inputbox">
                    <Row>
                        <Col lg={12}>
                            <Formik
                                validationSchema={schemaContactEdit()}
                                onSubmit={handleSubmitSave}
                                initialValues={contactEditInitialValues(contact)}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <>
                                        <Row className="justify-content-between">
                                            <Col lg={12} className="ibs-form-section"><Form
                                                className="mt-3" onSubmit={handleSubmit} noValidate validated={validated}
                                                controlId="pricebookCreate"
                                            >
                                                <Row>

                                                  <Col lg={6}>
                                                        <label name> Contact Type </label>
                                                        <Field
                                                            as="select"
                                                            name="type"
                                                            value={values.type}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">--Select--</option>
                                                            <option value="Property Owner">Property Owner</option>
                                                            <option value="Buyer">Buyer</option>
                                                            <option value="Seller">Seller</option>
                                                            <option value="Agent">Agent</option>
                                                            <option value="Staff">Staff</option>
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>

                                                        <label>Salutation<b class="red-star"></b></label>
                                                        <Field
                                                            as="select"
                                                            name="salutation"
                                                            value={values.salutation}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">--Select--</option>
                                                            <option value="Mr">Mr.</option>
                                                            <option value="Mrs">Mrs.</option>
                                                            <option value="Ms">Ms.</option>
                                                            <option value="Dr">Dr.</option>
                                                            <option value="Prof">Prof.</option>
                                                        </Field>
                                                        </Col>
                                                        <Col lg={4}>
                                                        <label>
                                                            First Name <b class="red-star">*</b>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="firstname"
                                                            placeholder="Enter firstname"
                                                            value={values.firstname}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <ErrorMessage
                                                            name="firstname"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                        </Col>
                                                        <Col lg={4}>
                                                        <label>
                                                            {" "}
                                                            Last Name<b class="red-star">*</b>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="lastname"
                                                            value={values.lastname}
                                                            onChange={handleChange}
                                                            placeholder="Enter lastname"
                                                            required
                                                        />
                                                        <ErrorMessage
                                                            name="lastname"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>

                                                        <label>
                                                            Phone <b class="red-star">*</b>
                                                        </label>
                                                        <Field
                                                            type="phone"
                                                            name="phone"
                                                            placeholder="Enter phone number"
                                                            value={values.phone}
                                                            onChange={handleChange}
                                                            required
                                                        />

                                                        <ErrorMessage
                                                            name="phone"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Col>
                                                    <Col>

                                                        <label>Email <b class="red-star"></b> </label>
                                                        <Field
                                                            style={{ textTransform: 'lowercase' }}
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <div className="form-error">{errors.email}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>

                                                        <label>Title</label>
                                                        <Field
                                                            as="select"
                                                            name="title"
                                                            value={values.title}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">--Select--</option>
                                                            <option value="CEO">CEO</option>
                                                            <option value="Director">Director</option>
                                                            <option value="Manager">Manager</option>
                                                            <option value="Owner">Owner</option>
                                                            <option value="Partner">Partner</option>
                                                            <option value="Executive">Executive</option>
                                                            <option value="Other">Other</option>
                                                        </Field>
                                                    </Col>


                                                    <Col lg={6}>

                                                        <label>State</label>
                                                        <Field
                                                            as="select"
                                                            name="state"
                                                            value={values.state}
                                                            onChange={(event) => {
                                                                var temp = CityState.filter(
                                                                    (ele) => ele.state === event.target.value
                                                                );
                                                                setCityList(temp.sort());
                                                                handleChange(event);
                                                            }}
                                                        >
                                                            <option value="">--Select State--</option>
                                                            {stateList &&
                                                                stateList.map((state) => (
                                                                    <option value={state}>{state}</option>
                                                                ))}
                                                        </Field>
                                                    </Col>
                                                    <Col lg={6}>

                                                        <label>City</label>
                                                        <Field
                                                            as="select"
                                                            name="city"
                                                            value={values.city}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">--Select City--</option>
                                                            {cityList.length > 0 &&
                                                                cityList.map((ele) => (
                                                                    <option value={ele.name}>{ele.name}</option>
                                                                ))}
                                                            {cityList.length === 0 &&

                                                                <option value='Jaipur'>Jaipur</option>
                                                            }
                                                        </Field>
                                                    </Col>
                                                    <Col lg={6}>

                                                        <label> Pincode </label>
                                                        <Field
                                                            type="number"
                                                            className="no-arrows"
                                                            name="pincode"
                                                            placeholder="Enter pincode"
                                                            value={values.pincode}
                                                            onChange={handleChange}
                                                        />
                                                        <ErrorMessage
                                                            name="pincode"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Col>
                                                    <Col lg={6}>

                                                        <label>Country </label>
                                                        <Field
                                                            type="text"
                                                            name="country"
                                                            defaultValue={"India"}
                                                            value={values.country}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col lg={6}>

                                                        <label>Street</label>
                                                        <Field
                                                            type="text"
                                                            name="street"
                                                            placeholder="Enter street"
                                                            value={values.street}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                            </Col>
                                        </Row>
                                        <Modal.Footer>
                                            
                                            <Button variant="success" onClick={handleSubmit}>
                                                Save
                                            </Button>
                                            <Button onClick={props.onHide} variant="light">
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>

        </Modal>
    );
};
export default ContactNewModal;
