import moment from "moment";
function contactEditInitialValues(contact) {
  return {
    id: contact.id,
    salutation: contact.salutation,
    firstname: contact.firstname,
    lastname: contact.lastname,
    email: contact.email,
    phone: contact.phone,
    street: contact.street,
    title: contact.title,
    state: contact.state ? contact.state :"Rajasthan",
    city:contact.city ? contact.city :"Jaipur",
    pincode: contact.pincode,
    type: contact.type,
    country: "India",
  };
}
function userEditInitialValues(user) {
  return {
    id: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    joiningdate: user.joiningdate,
    leavingdate: user.leavingdate,
    password: user.password,
    userrole: user.userrole,
    managerid: user.managerid,
    isactive: user.isactive,
 
  };
}
function propertyEditInitialValues(property) {
  return {
    id: property.id,
    name: property.name,
    street: property.street,
    state: property.state ? property.state :"Rajasthan",
    city:property.city ? property.city :"Jaipur",
    country: "India",
    pincode: property.pincode,
    contactid: property.contactid,
    description: property.description,
    superbuiltuparea: property.superbuiltuparea,
    floor: property.floor,
    transactiontype: property.transactiontype,
    propertybase: property.propertybase,
    googlelocation: property.googlelocation,
    possessionstatus: property.possessionstatus,
    propertytype: property.propertytype,
    vertical: property.vertical,
    areatofrom: property.areatofrom,
    furnishedstatus: property.furnishedstatus,
    typeofclient: property.typeofclient,
    verticaltype: property.verticaltype,
    subverticaltype: property.subverticaltype,
    retailsubvertical: property.retailsubvertical,
    arearangein : property.arearangein,
    area : property.area,
    areato : property.areato,
    leaseexpirationdate : property.leaseexpirationdate ? property.leaseexpirationdate : '',
 
    areadetails: Array.from({  length:  property?.areadetails &&  property.areadetails?.length > 0 ?  property.areadetails?.length : 1 }, (record, index) => ({
      id: property?.areadetails ? property.areadetails[index]?.id : undefined,
      area: property?.areadetails ? property.areadetails[index]?.area : '',
      floor:property?.areadetails ?  property.areadetails[index]?.floor : '',
      unit: property?.areadetails ? property.areadetails[index]?.unit  : '',
      value:property?.areadetails ?  property.areadetails[index]?.value : '',
      type:'area'
    })),
    heightdetails: Array.from({  length:  property?.heightdetails &&  property.heightdetails?.length > 0 ?  property.heightdetails?.length : 1 }, (record, index) => ({
      id: property?.heightdetails ? property.heightdetails[index]?.id : undefined,
      floor:property?.heightdetails ?  property.heightdetails[index]?.floor : '',
      unit: property?.heightdetails ? property.heightdetails[index]?.unit  : '',
      value:property?.heightdetails ?  property.heightdetails[index]?.value : '',
      type:'height'
    })),
    pricingorrental: Array.from({  length:  property?.pricingorrental &&  property.pricingorrental?.length > 0 ?  property.pricingorrental?.length : 1 }, (record, index) => ({
      id: property?.pricingorrental ? property.pricingorrental[index]?.id : undefined,
      area:property?.pricingorrental ?  property.pricingorrental[index]?.area : '',
      unit: property?.pricingorrental ? property.pricingorrental[index]?.unit  : '',
      floorno:property?.pricingorrental ?  property.pricingorrental[index]?.floorno : '',
      rentalvalue:property?.pricingorrental ?  property.pricingorrental[index]?.rentalvalue : '',
      perunitcost:property?.pricingorrental ?  property.pricingorrental[index]?.perunitcost : '',
      saleablearea:property?.pricingorrental ?  property.pricingorrental[index]?.saleablearea : '',
      quotedcost:property?.pricingorrental ?  property.pricingorrental[index]?.quotedcost : '',
      quotedrentpermonth:property?.pricingorrental ?  property.pricingorrental[index]?.quotedrentpermonth : '',
      chargeblearea:property?.pricingorrental ?  property.pricingorrental[index]?.chargeblearea : '',
      type: (property?.pricingorrental && property.pricingorrental[index]?.type) || (property.transactiontype === 'Sell' ? 'pricing' : 'rent')
      
    })),
    openareaunit:property.openareaunit,
    openareavalue:property.openareavalue,
    closeareaunit:property.closeareaunit,
    closeareavalue:property.closeareavalue,
    noofdocksvalue:property?.noofdocksvalue ? property?.noofdocksvalue : 0,
    noofwashroomsvalue:property?.noofwashroomsvalue ? property?.noofwashroomsvalue : 0,
    rentalunit:property.rentalunit,
    rentalvalue:property.rentalvalue,
    officestate : property.officestate,
    officecity : property.officecity ,
    officestreet : property.officestreet,
    officepincode : property.officepincode,
    possessiondate : property.possessiondate,
    typeofwarehouse:property.typeofwarehouse,
    chargeablearea:property.chargeablearea,
    offeredcarpetarea:property.offeredcarpetarea,
    heightside:property.heightside,
    heightcenter:property.heightcenter,
    typeofflooring:property.typeofflooring,
    firehydrants:property.firehydrants,
    firesprinkelers:property.firesprinkelers,
    firenoc:property.firenoc,
    quotedrentonchargeablearea:property.quotedrentonchargeablearea,
    securitydeposit:property.securitydeposit,
    commonareamaintaince:property.commonareamaintaince,
    addtionalinformation:property.addtionalinformation,
    project:property.project,
    location:property.location,
    totalfloors:property.totalfloors,
    totalbuilduparea:property.totalbuilduparea,
    offeredspacedetails:property.offeredspacedetails,
    flooroffered:property.flooroffered,
    quotedrent:property.quotedrent,
    maintainancecharges:property.maintainancecharges,
    powerbackup:property.powerbackup,
    powerallocation:property.powerallocation,
    powerbackupsystem:property.powerbackupsystem,
    powerbackupcharges:property.powerbackupcharges,
    othergeneralterms:property.othergeneralterms,
    proposedleaseterm:property.proposedleaseterm,
    proposedlockperiod:property.proposedlockperiod,
    rentescalation:property.rentescalation,
    intrestfreesecuritydeposit:property.intrestfreesecuritydeposit,
    propertytax:property.propertytax,
    stampdutyregistrationcharges:property.stampdutyregistrationcharges,
    parkingcharges:property.parkingcharges,
    availability:property.availability,
    marketbrand:property.marketbrand,
    googlecoordinates:property.googlecoordinates,
    offeredarea:property.offeredarea,
    frontagearea:property.frontagearea,
    commercialterms:property.commercialterms,
    heightfloor:property.heightfloor,
    remark:property.remark,
    verticalname:property.verticalname,
    floorplatesize:property.floorplatesize,
    chargeableareaoffered:property.chargeableareaoffered,
    workinghours:property.workinghours,
    status:property.status,
    fitoutrental:property.fitoutrental,
    propertystatus:property.propertystatus,
    electriccharges:property.electriccharges,
    numberofcarortruckparking: property.numberofcarortruckparking,
    carparkcharges:property.carparkcharges,
    contactname:property.contactname,
    contactnumber:property.contactnumber,
    othertenants:property.othertenants,
    designation: property.designation,
    //===========================
    nearbywarehouses: property.nearbywarehouses,
    locatedin: property.locatedin,
    dockheight: property.dockheight,
    docklevel: property.docklevel,
    advance: property.advance,
    roadwidth: property.roadwidth,
    labourroom: property.labourroom,
    guardroom: property.guardroom,
    powerconnection: property.powerconnection,
    waterconnection: property.waterconnection,
    flooringloadingcapacity: property.flooringloadingcapacity,
    noofkeys: property.noofkeys,
    architectname: property.architectname,
    banquetcapacity: property.banquetcapacity,
    noofservicelifts: property.noofservicelifts,
    noofcarparking: property.noofcarparking,
    length: property.length,
    width: property.width,
    floorno: property.floorno,
    unit: property.unit,
    tenure: property.tenure,
    clientcalloptionremark: property.clientcalloptionremark,
    plotunit: property.plotunit,
    plotlength: property.plotlength,
    plotwidth: property.plotwidth,
    area: property.area,
    perunitcost: property.perunitcost ? property.perunitcost : 0,
    saleablearea: property.saleablearea ? property.saleablearea : 0,
    quotedcost: property.quotedcost ? property.quotedcost : 0,
    rentalvalue: property.rentalvalue ? property.rentalvalue : 0,
    chargeblearea: property.chargeblearea ? property.chargeblearea : 0,
    quotedrentpermonth: property.quotedrentpermonth ? property.quotedrentpermonth : 0,

  };
}

function leadAddressInitialValues(lead) {
  return {
    areadetails: Array.from({  length:  lead?.areadetails   &&  lead.areadetails?.length > 0 ?  lead.areadetails?.length : 1 }, (record, index) => ({
      id: lead?.areadetails ? lead.areadetails[index]?.id : undefined,
      area: lead?.areadetails ? lead.areadetails[index]?.area : '',
      floor:lead?.areadetails ?  lead.areadetails[index]?.floor : '',
      unit: lead?.areadetails ? lead.areadetails[index]?.unit  : '',
      value:lead?.areadetails ?  lead.areadetails[index]?.value : '',
      type:'area'
    })),
    heightdetails: Array.from({  length:  lead?.heightdetails &&  lead.heightdetails?.length > 0 ?  lead.heightdetails?.length : 1 }, (record, index) => ({
      id: lead?.heightdetails ? lead.heightdetails[index]?.id : undefined,
      floor:lead?.heightdetails ?  lead.heightdetails[index]?.floor : '',
      unit: lead?.heightdetails ? lead.heightdetails[index]?.unit  : '',
      value:lead?.heightdetails ?  lead.heightdetails[index]?.value : '',
      type:'height'
    })),
    pricingorrental: Array.from({  length:  lead?.pricingorrental &&  lead.pricingorrental?.length > 0 ?  lead.pricingorrental?.length : 1 }, (record, index) => ({
      id: lead?.pricingorrental ? lead.pricingorrental[index]?.id : undefined,
      area:lead?.pricingorrental ?  lead.pricingorrental[index]?.area : '',
      unit: lead?.pricingorrental ? lead.pricingorrental[index]?.unit  : '',
      floorno:lead?.pricingorrental ?  lead.pricingorrental[index]?.floorno : '',
      rentalvalue:lead?.pricingorrental ?  lead.pricingorrental[index]?.rentalvalue : '',
      perunitcost:lead?.pricingorrental ?  lead.pricingorrental[index]?.perunitcost : '',
      saleablearea:lead?.pricingorrental ?  lead.pricingorrental[index]?.saleablearea : '',
      quotedcost:lead?.pricingorrental ?  lead.pricingorrental[index]?.quotedcost : '',
      quotedrentpermonth:lead?.pricingorrental ?  lead.pricingorrental[index]?.quotedrentpermonth : '',
      chargeblearea:lead?.pricingorrental ?  lead.pricingorrental[index]?.chargeblearea : '',
      type: (lead?.pricingorrental && lead.pricingorrental[index]?.type) || (lead.transactiontype === 'Sell' ? 'pricing' : 'rent')
      
    })),
    id: lead.id,
    salutation: lead.salutation,
    firstname: lead.firstname,
    lastname: lead.lastname,
    phone: lead.phone,
    email: lead.email,
    leadsource: lead.leadsource,
    leadstage: lead.leadstage ? lead.leadstage : 'Active',
    alternatephone: lead.alternatephone,
    clienttype:lead.clienttype,
    company: lead.company,
    ownerid: lead.ownerid,
    clientstate: lead.clientstate ? lead.clientstate : "Rajasthan",
    clientcity:  lead.clientcity ? lead.clientcity : "Jaipur",
    clientstreet: lead.clientstreet,
    clientpincode: lead.clientpincode,
    zone: lead.zone,
    clientcountry: lead.clientcountry ? lead.clientcountry : "India",
    clientcalloption: lead.clientcalloption ? lead.clientcalloption : false,
    clientcalloptionname: lead.clientcalloptionname ? lead.clientcalloptionname :'',
    clientcalloptionmobile: lead.clientcalloptionmobile,
    clientcalloptionemail: lead.clientcalloptionemail,
    clientcalloptiondate: lead.clientcalloptiondate,
    clientcalloptionremark: lead.clientcalloptionremark,
    clientcalloptionratepersqfeet: lead.clientcalloptionratepersqfeet,
    clientcalloptionbrokerage: lead.clientcalloptionbrokerage,
    transactiontype: lead.transactiontype,
    typeofclient: lead.typeofclient,
    vertical: lead.vertical,
    verticaltype: lead.verticaltype,
    subverticaltype: lead.subverticaltype,
    retailsubvertical: lead.retailsubvertical,
    type: lead.type,
    otherdetails: lead.otherdetails,
    otherlocations: lead.otherlocations,
    areaorlocationbrief: lead.areaorlocationbrief,
    completiondate: lead.completiondate,
    noofdocksvalue:lead?.noofdocksvalue ? lead?.noofdocksvalue : 0,
    noofwashroomsvalue:lead?.noofwashroomsvalue ? lead?.noofwashroomsvalue : 0,
    rentalunit:lead.rentalunit,
    rentalvalue:lead.rentalvalue,
    openareaunit:lead.openareaunit,
    openareavalue:lead.openareavalue,
    closeareaunit:lead.closeareaunit,
    closeareavalue:lead.closeareavalue,
    typeofwarehouse:lead.typeofwarehouse,
    floor:lead.floor,
    chargeablearea:lead.chargeablearea,
    offeredcarpetarea:lead.offeredcarpetarea,
    heightside:lead.heightside,
    heightcenter:lead.heightcenter,
    typeofflooring:lead.typeofflooring,
    firehydrants:lead.firehydrants,
    firesprinkelers:lead.firesprinkelers,
    firenoc:lead.firenoc,
    quotedrentonchargeablearea:lead.quotedrentonchargeablearea,
    securitydeposit:lead.securitydeposit,
    commonareamaintaince:lead.commonareamaintaince,
    possessionstatus:lead.possessionstatus,
    addtionalinformation:lead.addtionalinformation,
    project:lead.project,
    location:lead.location,
    totalfloors:lead.totalfloors,
    totalbuilduparea:lead.totalbuilduparea,
    offeredspacedetails:lead.offeredspacedetails,
    flooroffered:lead.flooroffered,
    quotedrent:lead.quotedrent,
    maintainancecharges:lead.maintainancecharges,
    powerbackup:lead.powerbackup,
    powerallocation:lead.powerallocation,
    powerbackupsystem:lead.powerbackupsystem,
    powerbackupcharges:lead.powerbackupcharges,
    othergeneralterms:lead.othergeneralterms,
    proposedleaseterm:lead.proposedleaseterm,
    proposedlockperiod:lead.proposedlockperiod,
    rentescalation:lead.rentescalation,
    intrestfreesecuritydeposit:lead.intrestfreesecuritydeposit,
    propertytax:lead.propertytax,
    stampdutyregistrationcharges:lead.stampdutyregistrationcharges,
    parkingcharges:lead.parkingcharges,
    availability:lead.availability,
    marketbrand:lead.marketbrand,
    googlecoordinates:lead.googlecoordinates,
    offeredarea:lead.offeredarea,
    frontagearea:lead.frontagearea,
    commercialterms:lead.commercialterms,
    heightfloor:lead.heightfloor,
    remark:lead.remark,
    verticalname:lead.verticalname,
    floorplatesize:lead.floorplatesize,
    chargeableareaoffered:lead.chargeableareaoffered,
    workinghours:lead.workinghours,
    status:lead.status,
    fitoutrental:lead.fitoutrental,
    propertystatus:lead.propertystatus,
    electriccharges:lead.electriccharges,
    numberofcarortruckparking: lead.numberofcarortruckparking,
    carparkcharges:lead.carparkcharges,
    contactname:lead.contactname,
    contactnumber:lead.contactnumber,
    othertenants:lead.othertenants,
    designation: lead.designation,
    officestate : lead.officestate,
    officecity : lead.officecity ,
    officestreet : lead.officestreet,
    officepincode : lead.officepincode,
    officecountry : lead.officecountry,
    possessiondate : lead.possessiondate,
    leaseexpirationdate : lead.leaseexpirationdate,
    //===========
    nearbywarehouses: lead.nearbywarehouses,
    furnishedstatus: lead.furnishedstatus,
    locatedin: lead.locatedin,
    dockheight: lead.dockheight,
    docklevel: lead.docklevel,
    advance: lead.advance,
    roadwidth: lead.roadwidth,
    labourroom: lead.labourroom,
    guardroom: lead.guardroom,
    powerconnection: lead.powerconnection,
    waterconnection: lead.waterconnection,
    flooringloadingcapacity: lead.flooringloadingcapacity,
    noofkeys: lead.noofkeys,
    architectname: lead.architectname,
    banquetcapacity: lead.banquetcapacity,
    noofservicelifts: lead.noofservicelifts,
    noofcarparking: lead.noofcarparking,
    length: lead.length,
    width: lead.width,
    // floorno: lead.floorno,
    // unit: lead.unit,
    tenure: lead.tenure,
    plotunit: lead.plotunit,
    plotlength: lead.plotlength,
    plotwidth: lead.plotwidth,
    // area: lead.area,
    // perunitcost: lead.perunitcost ? lead.perunitcost : 0,
    // saleablearea: lead.saleablearea ? lead.saleablearea : 0,
    // quotedcost: lead.quotedcost ? lead.quotedcost : 0,
    // chargeblearea: lead.chargeblearea ? lead.chargeblearea : 0,
    // quotedrentpermonth: lead.quotedrentpermonth ? lead.quotedrentpermonth : 0,
    // rentalvalue: lead.rentalvalue ? lead.rentalvalue : 0,
  };
}

function projectEditInitialValues(project) {
  return {
    id: project.id,
    name: project.name,
    budget: project.budget,
    startdate: moment(project.startdate).format("YYYY-MM-DD"),
    enddate: moment(project.enddate).format("YYYY-MM-DD"),
    vidurl: project.vidurl,
    isactive: project.isactive,
    contactid: project.contactid,
    city: project.city,
    state: project.state,
    street: project.street,
    pincode: project.pincode,
    country: "India",
  };
}

function siteVisitInitialValues(siteVisit) {
  return {
    areadetails: Array.from({  length:  siteVisit?.areadetails   &&  siteVisit.areadetails?.length > 0 ?  siteVisit.areadetails?.length : 1 }, (record, index) => ({
      id: siteVisit?.areadetails ? siteVisit.areadetails[index]?.id : undefined,
      area: siteVisit?.areadetails ? siteVisit.areadetails[index]?.area : '',
      floor:siteVisit?.areadetails ?  siteVisit.areadetails[index]?.floor : '',
      unit: siteVisit?.areadetails ? siteVisit.areadetails[index]?.unit  : '',
      value:siteVisit?.areadetails ?  siteVisit.areadetails[index]?.value : '',
      type:'area'
    })),
    heightdetails: Array.from({  length:  siteVisit?.heightdetails &&  siteVisit.heightdetails?.length > 0 ?  siteVisit.heightdetails?.length : 1 }, (record, index) => ({
      id: siteVisit?.heightdetails ? siteVisit.heightdetails[index]?.id : undefined,
      floor:siteVisit?.heightdetails ?  siteVisit.heightdetails[index]?.floor : '',
      unit: siteVisit?.heightdetails ? siteVisit.heightdetails[index]?.unit  : '',
      value:siteVisit?.heightdetails ?  siteVisit.heightdetails[index]?.value : '',
      type:'height'
    })),
    id: siteVisit.id,
    fieldpersonid: siteVisit.fieldpersonid,
    siteid: siteVisit.siteid,
    sitename: siteVisit.sitename,
    status: siteVisit.status ? siteVisit.status : "Not Visited",
    // budget : project.budget,
    // startdate : moment(project.startdate).format("YYYY-MM-DD"),
    // enddate : moment(project.enddate).format("YYYY-MM-DD"),
    // vidurl : project.vidurl,
    // isactive : project.isactive,
    // contactid : project.contactid,
    // city : project.city,
    // state : project.state,
    // street : project.street,
    // pincode : project.pincode,

    description: siteVisit.description,

    ownername: siteVisit.ownername,
    owneractnumber: siteVisit.owneractnumber,
    secondcontactpersonname: siteVisit.secondcontactpersonname,
    secondcontactpersonphone: siteVisit.secondcontactpersonphone,
    email: siteVisit.email,
    propertyapprovalstatus: siteVisit.propertyapprovalstatus,
    propertytype: siteVisit.propertytype,
    floormapavailable: siteVisit.floormapavailable,
    firenocavailble: siteVisit.firenocavailble,
    nooffloor: siteVisit.nooffloor,
    propertyarea: siteVisit.propertyarea,
    eachfloorheight: siteVisit.eachfloorheight,
    frontage: siteVisit.frontage,
    noofentries: siteVisit.noofentries,
    liftavailable: siteVisit.liftavailable,
    parkingspace: siteVisit.parkingspace,
    previousbrand: siteVisit.previousbrand,
    location: siteVisit.location,
    locationarea: siteVisit.locationarea,
    expectedrent: siteVisit.expectedrent,
  };
}
function DailyTaskEditInitialValues(dailyTask) {
  return {
    id: dailyTask.id,
    title: dailyTask.title,
    priority: dailyTask.priority,
    status: dailyTask.status,
    targetdate: dailyTask.targetdate,
    ownerid: dailyTask.ownerid,
    description: dailyTask.description,
    emailsreceived:dailyTask.emailsreceived,
    emailsrepliedexceptproposalsend:dailyTask.emailsrepliedexceptproposalsend,
    underconstructionproperties: dailyTask.underconstructionproperties,
    followuptaken: dailyTask.followuptaken,
    completionofconstruction: dailyTask.completionofconstruction,
    sellerleadgenerated: dailyTask.sellerleadgenerated,
    sellerleadconfirmed: dailyTask.sellerleadconfirmed,
    followuptakenfrompotentialsellers: dailyTask.followuptakenfrompotentialsellers,
    ppt: dailyTask.ppt,
    proposalsend: dailyTask.proposalsend,
    onlineplatformupdate: dailyTask.onlineplatformupdate,
    clientdatabasedupdated: dailyTask.clientdatabasedupdated,
    crmupdate: dailyTask.crmupdate,
    otherwork1: dailyTask.otherwork1,
    otherwork2: dailyTask.otherwork2,
    otherwork3: dailyTask.otherwork3,
    otherwork4: dailyTask.otherwork4,
    details: dailyTask.details,
  };
}
function taskEditInitialValues(task) {
  return {
    id: task.id,
    title: task.title,
    type: task.type,
    priority: task.priority,
    status: task.status,
    targetdate: task.targetdate ? task.targetdate :moment(new Date()).format('YYYY-MM-DD'),
    ownerid: task.ownerid,
    description: task.description,
    parentid: task.parentid,
    parenttype : task.parenttype,
  };
}

function TodoInitialValues(todo) {
  return {
    id: todo.id,
    name: todo.name,
    details : Array.from({length:  todo?.details   &&  todo.details?.length > 0 ?  todo.details?.length + 1 : 1 }, (record, index) => ({
      id: todo?.details ? todo.details[index]?.id : undefined,
      task: todo.details? todo.details[index]?.task : '',
      checked:  todo.details? todo.details[index]?.checked : false,
      priority: todo.details? todo.details[index]?.priority : '',
      description: todo.details? todo.details[index]?.description : '',
    }))

  };
}
export {
  contactEditInitialValues,
  propertyEditInitialValues,
  projectEditInitialValues,
  leadAddressInitialValues,
  siteVisitInitialValues,
  DailyTaskEditInitialValues,
  taskEditInitialValues,
  TodoInitialValues,
  userEditInitialValues,

};