import React from 'react'

const PageNotFound = () => {
  return (
    <div><h3 style={{textAlign:"center"}}>
    Page Not Found
  </h3> </div>
  )
}

export default PageNotFound