import React, { useState, useEffect } from "react";
function TableTemplate({table,isWareHouse = false,data,handlechange,columns}) {
    const [isTextShow, setIsTextShow] = useState(false);
    const [dataRec, setDataRec] = useState(data);
    const [tableData, setData] = useState(table);
    const handleChange = (field, value) => {
        let temp = {...dataRec}; 
        temp = {...dataRec,[field]: value  };
        setDataRec((pre) => ({ ...pre, [field]: value }));
        handlechange(temp);
    }
  return (
    <table className="details-table1">
            <thead>
              <tr >
                { isWareHouse && !columns  ? <th className="tablerow">VALIDATION PARAMETERS :</th>: (isWareHouse && columns  ? <th className="bg-dark text-light">COMMERCIAL :</th>: <th>Parameters</th>)}
                { isWareHouse && !columns  ? <th className="tablerow">SPECIFICATION :</th>: (isWareHouse && columns  ? <th className="bg-dark text-light">SPECIFICATION :</th>: <th>Details</th>)}
              </tr>
            </thead>
            {
              <tbody>
                {table && table.map(col => (
                  <tr key={col.value}>
                    <td>{col.label}</td>
                    <td onDoubleClick={() => setIsTextShow(!isTextShow)}>
                      {!isTextShow && <span>{dataRec.hasOwnProperty(col.value) ? dataRec[col.value] : col.defaultvalues }</span>}
                      {isTextShow && (
                        <input
                          type="text"
                          value={dataRec.hasOwnProperty(col.value) ? dataRec[col.value] : col.defaultvalues}
                          onChange={(e) => handleChange(col.value, e.target.value)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            }
     </table>
  )
}
export default TableTemplate