import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../../api/inventoryApi";
import PubSub from "pubsub-js";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import { Field, Formik, ErrorMessage } from 'formik';
import { userEditInitialValues } from "../common/InitialValuesHelper";
import { schemaUserEdit } from "../common/ValidateSchemaHelper";


const UserEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    //const [user, setuser] = useState(location.state);
    const [user, setUser] = useState(location.state ? location.state : {});
    ////.log('user edit', user)
   // let name = user.firstname
    const [optionUsers, setOptionUsers] = useState([]);
    const [option, setoption] = useState();
    const [selectedUser, setSelectedUser] = useState('');
    const [validated, setValidated] = useState(false);
    const [show, setShow] = React.useState(false);

    useEffect(() => {

       
        if (user.id) {
            //.log('if call')
            let temp = {}
            temp.value = user.managerid;
            temp.label = user.managername;
            setoption(temp);
        }else{
            let userInfo = jwt_decode(localStorage.getItem('token'));
            //.log('userInfo', userInfo);
            let temp = {}
            temp.value = userInfo.id;
            temp.label = userInfo.username;
            setoption(temp);
        }
        async function init() {
            const result = await inventoryApi.fetchUsers();

            if (result) {
                let ar = [];
                var obj = {};
                obj.value = null;
                obj.label = '--Select--';
                ar.push(obj);
                result.map((item) => {
                    if (item.userrole !== 'USER') {
                        var obj = {};
                        obj.value = item.id;
                        obj.label = item.username;
                        ar.push(obj);
                    }

                });
                setOptionUsers(ar);
            } else {
                setOptionUsers([]);
            }
        }
        init();
    }, []);

    const checkRequredFields = () => {

        ////.log(selectedUser)
        if ((user.firstname && user.firstname.trim() !== '') && user.lastname &&
            user.email && user.userrole && user.phone) {
            return false;
        }


        return true;
    }




    const handleSubmitSave = async (values) => {
       
        let result = {};
        values.id =user.id;
        if (user.id) {
          console.log('values',values);
            result = await inventoryApi.saveUser(values);
            console.log('result save', result)
            if (result.success) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record saved successfully",
                });
                navigate(`/users/${user.id}`, { state: user });
            }

            else {
                PubSub.publish("RECORD_ERROR_TOAST", {
                    title: "Record Not Saved",
                    message: result.errors,
                });
            }
        } else {
            result = await inventoryApi.createUser(values);
            console.log('result create', result)
            if (result.success) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record saved successfully",
                });
                navigate(`/users/${result.id}`, { state: result });
            } else {
                PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });

            }
        }

    }

    const handleCancel = () => {
        if (user.id) {
            navigate(`/users/${user.id}`, { state: user })

        } else {
            navigate(`/users`)
        }

    }


    const handleChange = (e) => {

        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleActive = (e) => {
        setUser({ ...user, [e.target.name]: e.target.checked });
    };



    const handleUsers = (event) => {
        ////.log(event)
        setoption(event);
        setSelectedUser(event)
        setUser({ ...user, managerid: event.value, managername: event.label });
    }

    return (
      <Container className="view-form">
        <Row  className="mt-4" >
          <Col lg={8} className="pb-1 pt-4">
            {location?.state?.id ? (
              <CustomSeparator
                cmpListName="Users"
                cmpViewName={user.firstname + " " + user.lastname}
                currentCmpName="Edit"
                indexLength="2"
                indexViewLength="3"
                cmpViewUrl={"/users/" + user.id}
                url="/users"
              ></CustomSeparator>
            ) : (
              <CustomSeparator
                cmpListName="Users"
                currentCmpName="Create"
                indexLength="2"
                url="/users"
              ></CustomSeparator>
            )}
          </Col>
        </Row>
        <Row className="mt-4 mx-2" >
        
       
        <Col lg={12} className="ibs-form-section inputbox">
          <Formik
            validationSchema={schemaUserEdit()}
            onSubmit={handleSubmitSave}
            initialValues={userEditInitialValues(user)}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form
                className="mt-3"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
              >
                 <Row
                  className="view-form-header align-items-center"
                  style={{
                    marginTop: "-10px",
                  }}>
                  <Col lg={3}>
                    {user.id ? (
                      <>
                        Edit User
                        <h4>{user.firstname + " " + user.lastname}</h4>
                      </>
                    ) : (
                      "New User"
                    )}
                  </Col>
                  <Col lg={9} className="d-flex justify-content-end">
                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                
                <Row className="align-items inputbox">
                
                  <Col lg={6}>
                    <label>
                      First Name <b class="red-star">*</b>
                    </label>
                    <Field
                      type="text"
                      name="firstname"
                      placeholder="Enter firstname"
                      value={values.firstname}
                      onChange={handleChange}
                      required
                    />
                    <ErrorMessage
                      name="firstname"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>
                      {" "}
                      Last Name<b class="red-star">*</b>
                    </label>
                    <Field
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      placeholder="Enter lastname"
                      required
                    />
                    <ErrorMessage
                      name="lastname"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                </Row>
                <Row className="align-items">
                
                  <Col lg={6} >
                    <label>Email </label>
                    <Field
                     style={{  textTransform: 'lowercase' }}
                      type="email"
                      autocomplete="off"
                      name="email"
                      placeholder="Enter Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                     <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>
                      Phone <b class="red-star">*</b>
                    </label>
                    <Field
                      type="phone"
                      name="phone"
                      placeholder="Enter phone number"
                      value={values.phone}
                      onChange={handleChange}
                      required
                    />

                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>
                    Joining date <b class="red-star"></b>
                    </label>
                    <Field
                      type="date"
                      name="joiningdate"
                      value={values.joiningdate}
                      onChange={handleChange}
                      required
                    />

                    <ErrorMessage
                      name="joiningdate"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>
                    Leaving date <b class="red-star"></b>
                    </label>
                    <Field
                      type="date"
                      name="leavingdate"
                      value={values.leavingdate}
                      onChange={handleChange}
                      required
                    />

                    <ErrorMessage
                      name="leavingdate"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                 {!(values.id &&values.id !== '') &&
                  <Col lg={6}>
                    <label>
                   Password <b class="red-star"></b>
                    </label>
                    <Field
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}

                      required
                    />

                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </Col>}
                  <Col lg={6}>
                    <label>
                   Role <b class="red-star">*</b>
                    </label>
                    <Field
                     as='select'
                      name="userrole"
                      value={values.userrole}
                      onChange={handleChange}
                      required
                    >
                        <option value="">-- Select --</option>
                          <option value="ADMIN">Admin</option>
                          <option value="USER">User</option>
                    </Field>

                    <ErrorMessage
                      name="userrole"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>
                    Manager <b class="red-star"></b>
                    </label>
                    <Field
                     as='select'
                      name="managerid"
                      value={values.managerid}
                      onChange={handleChange}
                      required
                    >
                          {optionUsers && optionUsers.map(e=>(<option value={e.value}>{e.label}</option>))} 
                    </Field>

                    <ErrorMessage
                      name="ownerid"
                      component="div"
                      className="error-message"
                    />
                  </Col>
                  <Col lg={6}>
                      <label >
                        Active
                      </label>

                      <Field
                        inline
                        name="isactive"
                        type="checkbox"
                        value="true"
                        checked={values.isactive === true}
                        id="inline-checkbox-9"
                        onChange={handleChange}
                      />
                  </Col>
                </Row>
             
              </Form>
            )}
          </Formik>
        </Col>
          <Col></Col>
        </Row>
      </Container>
    );
}

export default UserEdit
