export const DefaultPdfValues = {
    firstpage: {
      PropertyType: "",
      PropertyName :"",
      Street: "",
      City: "",
      proposalDate: "",
      imagelink: "/pdf_template_1/firstpage.imagelink.jpg",
    },
    secondpage: {
      State: "",
      City: "",
      Population: "9,210,388",
      PopulationGrowth: "2.43%",
      PopulationDensity: "27,613/KM²",
      LiteracyRate: "83.33%",
      AnnualPerCapita: "Rs 1,56,150",
    },
  
    secondpageimage: {
      imagelink: "/pdf_template_1/secondpageimage.imagelink.jpg",
    },
  
    logo: "/pdf_template_1/logo.jpg",
  
    thirdpage: {
      imagelink: "",
    },
    fourthpage: {
      ExpectedDate: "",
      FloorPlate: "",
      Frontage: "",
      Location: "",
      Rental: "",
      Tenure: "",
      RentExcalation: "",
      MaintenanceCharges: "",
      ElectricConnection: "",
      Taxes: "",
      StampDuty: "",
      status: "",
      NeighbouringBrands: "",
    },
    fourthpage1: {
      status: "",
      Taxes: "",
      RentExcalation: "",
      StampDuty: "",
      ElectricConnection: "",
      MaintenanceCharges: "",
      NeighbouringBrands: "",
    },
    fifthpage: {
      imagelink: "/pdf_template_1/fifthpage.imagelink.jpg",
      vaastu: "Vaastu Compliant",
      ROWater: "RO Water System",
      HighSpeed: "High-Speed Internet",
      wheelChair: "Wheelchair Accessibility",
      maintenanceStaff: "Maintenance Staff",
      powerBackup: "Power Backup",
      DedicatedParking: "Dedicated Parking",
    },
    sixthpage: {
      imagelink1: "",
      imagelink2: "",
    },
    seventhpage: {
      imagelink: "",
    },
    innerpicpage: {
      imagelink: '',
    },
    innerpicpage1: {
      imagelink: '',
    },
    innerpicpage2: {
      imagelink: '',
    },
    innerpicpage3: {
      imagelink: '',
    },
    innerpicpage4: {
      imagelink: '',
    },
    innerpicpage5: {
      imagelink: '',
    },
    eightpage: {
      imagelink: "",
    },
    ninthpage: {
      imagelink1: "",
      imagelink2: "",
    },
    ninthpage1: {
      imagelink1: "",
      imagelink2: "",
    },
  
    tenthpage: {
      imagelink1: "",
      imagelink2: "",
    },
  
    eleventhPage: {
      imagelink1: "",
      imagelink2: "",
    },
  
    twelvethPage: {
      imagelink: "",
    },
  
    thirteenthPage: {
      imagelink: "",
    },
  
    fourteenthPage: {
      imagelink: "",
    },
  
    fiftenthPage: {
      imagelink: "",
    },
  
    sixteenthPage: {
      imagelink: "",
    },
    typicalPage: {
      imagelink: '',
    },
    terracePage: {
      imagelink: '',
    },
    elevationpage: {
      imagelink: '',
    },
    elevationpage1: {
      imagelink: '',
    },
    elevationpage2: {
      imagelink: '',
    },
  
    seventeenthPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    eighteenthPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    nineteenthpage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentythPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentyfirstPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentysecondPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentythirdPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentyfourthPage: {
      imagelink1: "",
      imagelink2: "",
      imagelink3: "",
    },
  
    twentyfifthpage: {
      logo: "logo.jpg",
      name: "Arpit Yagnik",
      mobile: "+91-9636969646",
      name1: "Piyush Agarwal",
      mobile1: "+91-9829610077",
      Email: "arpityagnik@sthapatyaleasing.com",
      Website: "www.sthapatyaleasing.com",
      Address:
        "Sthapatya Leasing & Consultant E-143, 1st Floor, Nirman Nagar, Opp. Hotel Kiran Palace DCM, Ajmer Road, Jaipur",
      Disclaimer:
        "Details & Pictures mentioned in this presentation is for illustration purpose only & cannot be in any way treated as a legal document. All information, specifications, plans, materials, dates, & visual representations contained are subject to change from time to time by the Land Owner / Realtor / Company / Competent Authorities and shall not form part of any contract, offer or deal. Sizes, Rates, Specifications, and  Availability of the project/property may change without any prior information and Land Owner / Realtor / Company shall not be held responsible in any manner whatsoever.",
    },
    vicinitypage: {
      imagelink: '',

    },
    actualViewpage: {
      imagelink:'',
    },
  }
export const DefaultWareHousePdfValues = {
    homePage: {
      imagelink: "/warehouse-pdf/HomePage.jpg",
    },
    
    aboutUsPage: {
      imagelink: "/warehouse-pdf/AboutUs.jpeg",
      imagelink2: "/warehouse-pdf/AboutUs2.jpeg",
    },
  
  
    keyDetailspage: {
      imagelink: "/warehouse-pdf/KeyAreaDetails.jpg",
    },
    
    keyDetailsfirstpage: {
      imagelink: "/warehouse-pdf/keyAreaDetailspagebg.png",
    },

    keyDetailsSecondbg: {
      imagelink: "/warehouse-pdf/keyDetailsSecondbg.png",
    },

    specificationsPage: {
      imagelink: "/warehouse-pdf/Specifications.jpg",
    },
    commercialPage: {
      imagelink: "/warehouse-pdf/Commercial.jpg",
    },
    innerViewPage: {
      imagelink: "",
      imagelink2: "/warehouse-pdf/HeaderInnerView.png",
    },
    innerViewPage2: {
      imagelink: "",
    },
    innerViewPage3: {
      imagelink: "",
    },
    outerViewPage: {
      imagelink: "",
    },
    outerViewPage2: {
      imagelink: "",
    },
    layoutPage: {
      imagelink: "",
    },
    contactUsPage: {
      imagelink: "/warehouse-pdf/ContactUs.jpg",
    },
    
  }
  
  export const SectionInTemplateOptions = [
    { label: "Logo", value: "logo" },
    { label: "First Page Sidebar", value: "firstpage.imagelink" },
    { label: "City Overview", value: "secondpageimage.imagelink" },
    { label: "Google View", value: "thirdpage.imagelink" },
    { label: "Modern Facilites", value: "fifthpage.imagelink" },
    { label: "Actual View 1", value: "sixthpage.imagelink1" },
    { label: "Actual View 2", value: "sixthpage.imagelink2" },
    { label: "Actual View 3", value: "seventhpage.imagelink" },
    { label: "Actual View 4", value: "eightpage.imagelink" },
    { label: "Actual View 5", value: "ninthpage.imagelink1" },
    { label: "Actual View 6", value: "ninthpage.imagelink2" },
    { label: "Actual View 7", value: "tenthpage.imagelink1" },
    { label: "Actual View 8", value: "tenthpage.imagelink2" },
    { label: "Actual View 9", value: "eleventhPage.imagelink1" },
    { label: "Actual View 10", value: "eleventhPage.imagelink2" },
    { label: "Brand Mapping", value: "twelvethPage.imagelink" },
    { label: "Floor Plan Basement", value: "thirteenthPage.imagelink" },
    { label: "Floor Plan Ground", value: "fourteenthPage.imagelink" },
    { label: "Floor Plan 1st Floor", value: "fiftenthPage.imagelink" },
    { label: "Floor Plan 2nd Floor", value: "sixteenthPage.imagelink" },
  
    { label: "Vicinity Image 1", value: "seventeenthPage.imagelink1" },
    { label: "Vicinity Image 2", value: "seventeenthPage.imagelink2" },
    { label: "Vicinity Image 3", value: "seventeenthPage.imagelink3" },
  
    { label: "Vicinity Image 4", value: "eighteenthPage.imagelink1" },
    { label: "Vicinity Image 5", value: "eighteenthPage.imagelink2" },
    { label: "Vicinity Image 6", value: "eighteenthPage.imagelink3" },
  
    { label: "Vicinity Image 7", value: "nineteenthpage.imagelink1" },
    { label: "Vicinity Image 8", value: "nineteenthpage.imagelink2" },
    { label: "Vicinity Image 9", value: "nineteenthpage.imagelink3" },
  
    { label: "Vicinity Image 10", value: "twentythPage.imagelink1" },
    { label: "Vicinity Image 11", value: "twentythPage.imagelink2" },
    { label: "Vicinity Image 12", value: "twentythPage.imagelink3" },
  
    { label: "Vicinity Image 13", value: "twentyfirstPage.imagelink1" },
    { label: "Vicinity Image 14", value: "twentyfirstPage.imagelink2" },
    { label: "Vicinity Image 15", value: "twentyfirstPage.imagelink3" },
  
    { label: "Vicinity Image 16", value: "twentysecondPage.imagelink1" },
    { label: "Vicinity Image 17", value: "twentysecondPage.imagelink2" },
    { label: "Vicinity Image 18", value: "twentysecondPage.imagelink3" },
  
    { label: "Vicinity Image 22", value: "twentythirdPage.imagelink1" },
    { label: "Vicinity Image 23", value: "twentythirdPage.imagelink2" },
    { label: "Vicinity Image 24", value: "twentythirdPage.imagelink3" },
  
    { label: "Vicinity Image 19", value: "twentyfourthPage.imagelink1" },
    { label: "Vicinity Image 20", value: "twentyfourthPage.imagelink2" },
    { label: "Vicinity Image 21", value: "twentyfourthPage.imagelink3" }
  ]
    
  export const SectionInTemplateValues = [
    "logo",
    "firstpage.imagelink",
    "secondpageimage.imagelink",
    "thirdpage.imagelink",
    "fifthpage.imagelink",
    "sixthpage.imagelink1",
    "sixthpage.imagelink2",
    "seventhpage.imagelink",
    "eightpage.imagelink",
    "ninthpage.imagelink1",
    "ninthpage.imagelink2",
    "tenthpage.imagelink1",
    "tenthpage.imagelink2",
    "eleventhPage.imagelink1",
    "eleventhPage.imagelink2",
    "twelvethPage.imagelink",
    "thirteenthPage.imagelink",
    "fourteenthPage.imagelink",
    "fiftenthPage.imagelink",
    "sixteenthPage.imagelink",
    "seventeenthPage.imagelink1",
    "seventeenthPage.imagelink2",
    "seventeenthPage.imagelink3",
    "eighteenthPage.imagelink1",
    "eighteenthPage.imagelink2",
    "eighteenthPage.imagelink3",
    "nineteenthpage.imagelink1",
    "nineteenthpage.imagelink2",
    "nineteenthpage.imagelink3",
    "twentythPage.imagelink1",
    "twentythPage.imagelink2",
    "twentythPage.imagelink3",
    "twentyfirstPage.imagelink1",
    "twentyfirstPage.imagelink2",
    "twentyfirstPage.imagelink3",
    "twentysecondPage.imagelink1",
    "twentysecondPage.imagelink2",
    "twentysecondPage.imagelink3",
    "twentythirdPage.imagelink1",
    "twentythirdPage.imagelink2",
    "twentythirdPage.imagelink3",
    "twentyfourthPage.imagelink1",
    "twentyfourthPage.imagelink2",
    "twentyfourthPage.imagelink3"
  ]
  
  export const pdfValuesDefault = [
  {label :"firstpage.PropertyType", value:"vertical"},
  {label :"firstpage.PropertyName", value:"name"},
  {label :"firstpage.Street", value:"street"},
  {label :"firstpage.City", value:"city"},
  {label :"firstpage.proposalDate", value:"proposaldate"},
  {label :"secondpage.State", value:"state"},
  {label :"secondpage.City", value:"city"},
  {label :"secondpage.Area", value:"area"},
  {label :"secondpage.Population", value:"population"},
  {label :"secondpage.PopulationGrowth", value:"populationgrowth"},
  {label :"secondpage.PopulationDensity", value:"populationdensity"},
  {label :"secondpage.LiteracyRate", value:"literacyrate"},
  {label :"secondpage.AnnualPerCapita", value:"annualpercapita"},
  {label :"fourthpage.PossessionTimeLine", value:"possessiondate"},
  {label :"fourthpage.FloorPlate", value:"floorplatesize"},
  {label :"fourthpage.Frontage", value:"frontagearea"},
  {label :"fourthpage.Location", value:"location"},
  {label :"fourthpage.Rental", value:"rental"},
  {label :"fourthpage.Tenure", value:"tenure"},
  {label :"fourthpage.status", value:"status"},
  {label :"fourthpage.Taxes", value:"propertytax"},
  {label :"fourthpage.RentExcalation", value:"rentescalation"},
  {label :"fourthpage.StampDuty", value:"stampdutyregistrationcharges"},
  {label :"fourthpage.ElectricConnection", value:"powerconnection"},
  {label :"fourthpage.MaintenanceCharges", value:"maintainancecharges"},
  {label :"fourthpage.NeighbouringBrands", value:"marketbrand"},
  ]