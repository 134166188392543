import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  
} from "@react-pdf/renderer";

// Sample images for rendering (replace with actual image paths or data URLs)
const sampleImages = [
  {
    image: "path/to/image1.jpg",
    section: "Section 1",
    subSection: "Subsection 1",
  },
  {
    image: "path/to/image2.jpg",
    section: "Section 2",
    subSection: "Subsection 2",
  },
  // Add more images as needed
];

// Styles for PDF rendering
const styles = StyleSheet.create({
  size: {
    height: 320,
    width: 600,
  },
  page: {
    //padding: 5,
    fontSize: 12,
    backgroundColor:'black'
  },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:'rgb(183, 162, 146)',
    padding:10
  },
  warehousepageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin:16
  },
  headText: {
    fontSize: 18,
    fontWeight: "bold",
    color:'white'
  },
  subHeadText: {
    fontSize: 14,
    color:'white'
  },
  appLogo: {
    width: 50,
    height: 50,
  },
  pageBody: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "45%",
    height:250,
  },
  fullWidthImage: {
    width: "100%",
    height:250,
  },
  imageware: {
    width: "45%",
    height:230,
    marginTop:20
  },
  fullWidthImageware: {
    width: "100%",
    height:230,
    marginTop:20
  },
  warehouseheaderimage:{
    position: 'absolute',
    width: 600,
    height: 70,
    zIndex: -1,
},
});

// Create Document Component
const ImageTemplateDownload = ({ imageset, isTwoLayout,isWareouse  }) => {
  const [images, imageSet] = useState(imageset);
  const chunkImages = (images) => {
    let chunks = [];
    for (let i = 0; i < images.length; i += isTwoLayout ? 2 : 1) {
      chunks.push(images.slice(i, i + (isTwoLayout ? 2 : 1)));
    }
    return chunks;
  };
  useEffect(() => {
    console.log('imageset',imageset);
    imageSet(imageset);
  }, [imageset]);
  const imageChunks = chunkImages(images.filter(e => e?.isVisible === true));
  

  return (
    <>
      {imageChunks.map((chunk, pageIndex) => (
      
        <Page key={pageIndex} style={styles.page} size={styles.size}>
          {isWareouse&&  <Image
                src="/warehouse-pdf/HeaderInnerView.png"
                style={styles.warehouseheaderimage}
                alt="Property"
              />}
          <View style={isWareouse ? styles.warehousepageHeader : styles.pageHeader}>
            <View>
            <Text style={styles.headText}>{chunk[0]?.section}</Text>
              <Text style={styles.subHeadText}>{chunk[0]?.subSection}</Text>
            </View>
            {!isWareouse && <Image style={styles.appLogo} src={"/pdf_template_1/logo.jpg"} /> }
          </View>
          <View style={styles.pageBody}>
            {chunk.map((img, index) => (
              <Image
                key={index}
                style={chunk.length > 1 ?  (isWareouse ?  styles.imageware : styles.image)  : (isWareouse ? styles.fullWidthImageware : styles.fullWidthImage)}
                src={img.image}
              />
            ))}
          </View>
        </Page>
      ))}
    </>
  );
};

export default ImageTemplateDownload;
