import React from "react";
import {
  Document,
  Text,
  Link,
  View,
  Page,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
//import Data from "./Data";

const styles = StyleSheet.create({
  body: {
    // backgroundColor: '#07575B',
    backgroundColor: "#132925",
    display: "none",
  },
  size: {
    height: 320,
    width: 620,
  },

  // title: {
  //   fontSize: 24,
  //   textAlign: "center",
  // },
  // text: {
  //   margin: 12,
  //   fontSize: 14,
  //   textAlign: "justify",
  //   fontFamily: "Times-Roman",
  //   display: "flex",
  //   flexDirection: "column",
  // },
  image: {
    // marginVertical:15,
    // marginHorizontal:100,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    textAlign: "center",
    color: "grey",
  },
  centeredText: {
    color: "white",
    fontSize: 28,
    lineHeight: 1.5,
    display: "flex",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    // paddingHorizontal: 1,
    // paddingVertical: 1,
  },

  column: {
    // backgroundColor:'#FFF59D',
    backgroundColor: "#B7A292",
    // backgroundColor:'white',
    //marginVertical: 5,
  },

  columnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#A27C4B",
    borderBottomWidth: 1,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },

  columnRowText: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "black",
    fontSize: 10,
  },

  details: {
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
    marginLeft: 90,
  },
  cityOverview: {
    fontSize: 28,
    lineHeight: 1.5,
    display: "flex",
    color: "#132925",
    marginLeft: 5,
    marginTop: 5,
  },
  cityOverviewImage: {
    height: '300px',
    marginTop: 0,
    //marginBottom: 20,
    width: '355px',
    marginLeft: 0,
    //marginRight: 5,
  },

  dataBox: {
    border: "0.6pt solid #A27C4B",
    borderRadius: 0.5,
    // marginVertical: 5,
  },
  googleView: {
    color: "black",
    fontSize: 34,
    lineHeight: 1.5,
    display: "flex",
    marginLeft: 180,
  },
  logoImage: {
    height: 42,
    width: 110,
    marginRight: 10,
    marginBottom: 5,
    marginLeft: 220,
  },
  googleImage: {
    height: 230,
    width: 550,
    marginTop: 10,
    marginBottom: 10,
    //   marginLeft: 20,
    //   marginRight: 20
  },

  detailsProperty: {
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
    // marginLeft:90
  },

  propertyColumnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "white",
    borderBottomWidth: 1,
    paddingVertical: 9,
    paddingHorizontal: 20,
  },

  propertyColumnRowText: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "black",
    fontSize: 8,
  },
  propertyRow1Colour: {
    backgroundColor: "#C8CABE",
  },
  propertyRow2Colour: {
    backgroundColor: "#F7F7F7",
  },
  facilityPoint: {
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 4,
    paddingHorizontal: 40,
    marginLeft: 45,
    marginTop: 6,
    fontSize: 16,
  },
  logoActualImage: {
    height: 42,
    width: 80,
    marginTop: -43,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 537,
  },

  actualViewImage: {
    marginTop: 20,
    marginLeft: 40,
    marginRight: 20,
    marginBottom: 10,
    height: 240,
  },
  actualView3Images: {
    marginTop: 27,
    marginLeft: 10,
    marginBottom: 10,
    height: 235,
    width: 295,
  },
  logoActualView3Image: {
    height: 42,
    width: 80,
    marginTop: -37,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 537,
  },
  vicinityColour: {
    backgroundColor: "#FBE9E7",
    display: "none",
  },
  vicinityView3Images: {
    marginTop: 27,
    marginLeft: 30,
    marginBottom: 10,
    height: 180,
    width: 150,
  },
  logoVicinityView3Image: {
    height: 42,
    width: 80,
    marginTop: -37,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 515,
  },
  brandMappingImages: {
    marginTop: 18,
    marginLeft: 60,
    marginBottom: 10,
    height: 240,
    width: 490,
  },
  logoBrandMappingImages: {
    height: 42,
    width: 80,
    marginTop: -54,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 534,
  },
  floorPlanBackgroundColour: {
    backgroundColor: "#C6B397",
    display: "none",
  },
  floorPlanImages: {
    marginTop: -45,
    marginLeft: 190,
    marginBottom: 5,
    height: 308,
    width: 260,
  },
  logoFloorPlanImages: {
    height: 42,
    width: 80,
    marginTop: -53,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 534,
  },
  logoElevation: {
    height: 42,
    width: 80,
    marginTop: -40,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 534,
  },
  floorPlanColour: {
    color: "#0C231F",
    fontSize: 34,
    marginTop: 5,
    marginLeft: 5,
    display: "flex",
  },
  floorColour: {
    color: "#0C231F",
    fontSize: 16,
    marginLeft: 20,
    display: "flex",
  },
  logoContactDetailsmages: {
    height: 50,
    width: 85,
    marginTop: -72,
    marginRight: 10,
    //  marginBottom:5,
    marginLeft: 530,
  },
});

////.log('Background color:', styles.body.backgroundColor);
const PdfDownload = ({ reportBuilder, table, selectedPages }) => {
  console.log("selectedPages*==>", reportBuilder);
  //   //.log("data PdfDownload*==>", reportBuilder,table);

  //.log("reportBuilder *==>", reportBuilder);
  // //.log('reportBuilder.firstpage *==>',reportBuilder.firstpage)
  // //.log('reportBuilder.firstpage.PropertyType *==>',reportBuilder.firstpage.PropertyType)
  // //.log('reportBuilder.firstpage.imagelink *==>',reportBuilder.firstpage.imagelink)

  return (
    <Document>
      {/*--------------------------- Main Page----------------------------- */}
      {selectedPages.firstpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "row" }}
        >
          <View style={{ flex: 7, alignItems: "center", paddingTop: 10 }}>
            <Text style={styles.centeredText}>Proposed Option</Text>
            <Text style={styles.centeredText}>For</Text>
            <Text style={styles.centeredText}>
              {reportBuilder.firstpage.PropertyType}
            </Text>
            <Text style={{ ...styles.centeredText, height: 10 }}></Text>

            <Text style={{ ...styles.centeredText, fontSize: 14 }}>
              {reportBuilder.firstpage.Street}, {reportBuilder.firstpage.City}
            </Text>
            <Text style={{ ...styles.centeredText, fontSize: 14 }}>
              {reportBuilder.firstpage.PropertyName}
            </Text>
          </View>
          <View style={{ flex: 3 }}>
            <Image
              style={{ ...styles.image }}
              src={reportBuilder.firstpage.imagelink}
            />
          </View>

          <View
            style={{
              position: "absolute",
              fontSize: 12,
              color: "white",
              bottom: 1,
              left: 1,
              right: 265,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text>By Sthapatya Leasing & Consultant</Text>
            <Text>{reportBuilder.firstpage.proposalDate}</Text>
          </View>
        </Page>
      )}

      {/*--------------------------- City  Overview Page----------------------------- */}

      {selectedPages.secondpage && (
        <Page size={styles.size} style={styles.body}>
          <View style={styles.twoColumnContainer}>
            <View style={{ ...styles.column }}>
              <Text style={styles.cityOverview}>City Overview</Text>
              <View style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                <View style={styles.dataBox}>
                  <View
                    style={{ ...styles.columnRow, backgroundColor: "#A27C4B" }}
                  >
                    <Text style={{ ...styles.details }}>Details</Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>State</Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.State}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>City</Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.City}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Area</Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.Area}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>
                      Population (2023) -{" "} City
                    </Text>

                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.Population}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Population Growth</Text>
                    <Text style={styles.columnRowText}
                    >
                      {reportBuilder.secondpage.PopulationGrowth}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>
                      Population Density{" "}  (2023)
                    </Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.PopulationDensity}
                    </Text>

                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>
                      Average literacy rate
                    </Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.LiteracyRate}
                    </Text>
                  </View>
                  <View style={styles.columnRow}>
                    <Text style={styles.columnRowText}>Annual Per Capita </Text>
                    <Text style={styles.columnRowText}> Income(District) {' '}</Text>
                    <Text style={styles.columnRowText}>
                      {reportBuilder.secondpage.AnnualPerCapita}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ flex: 6 }}>
              <Image
                style={styles.cityOverviewImage}
                src={reportBuilder.secondpageimage.imagelink}
              />
            </View>
          </View>
        </Page>
      )}


      {/*--------------------------- Google  View Page----------------------------- */}
      {selectedPages.thirdpage && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Google View</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View style={{ flex: 5, alignItems: "center" }}>
            <Image
              style={{ ...styles.size, ...styles.googleImage }}
              src={reportBuilder.thirdpage.imagelink}
            />
          </View>
        </Page>
      )}

      {/*---------------------------  Property Details Page----------------------------- */}
      {(selectedPages.fourthpage1 || selectedPages.fourthpage) && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
            {console.log('reportBuilder',reportBuilder)}
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Floor Plate
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.FloorPlate}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                    Possession Timeline
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Possession_Timeline}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                    Rent
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Rental}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                    Location
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Location}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Tenure
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Tenure}
                  </Text>
                </View>
              </View>
          </View>
        </Page>
      )}
      
      {(selectedPages.officeFourthPage) && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
            {console.log('reportBuilder',reportBuilder)}
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Status
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.Status}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Taxes
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.Taxes}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  RentEscalation
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.RentEscalation}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  StampDuty
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.StampDuty}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  ElectricConnection
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.ElectricConnection}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  MaintenanceCharges
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.MaintenanceCharges}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  NeighbouringBrands
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage3.NeighbouringBrands}
                  </Text>
                </View>
              </View>
          </View>
        </Page>
      )}

        {/*---------------------------  Property Details 2 Page----------------------------- */}
        {selectedPages.fourthpage2 && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
            {console.log('reportBuilder',reportBuilder)}
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                    Land Area
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.FloorPlate}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Frontage
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Possession_Timeline}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Road Width	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Rental}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Near by Railway station	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Frontage}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Near by International Airport	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage.Frontage}
                  </Text>
                </View>
              </View>
          </View>
        </Page>
      )}
        {/*---------------------------  Property Details 2 Page----------------------------- */}
        {selectedPages.hoteldetailpage && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Floors
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage4['Floors']}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Total Keys	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage4['Total Keys']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Banquet Capacity		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage4['Banquet Capacity']}
                  </Text>
                </View>
                
              </View>
          </View>
        </Page>
      )}

        {selectedPages.hoteldetailpage1 && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Location
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Location']}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Lifts	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Lifts']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Tenure		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Tenure']}
                  </Text>
                </View>
                
              </View>
          </View>
        </Page>
      )}

        {selectedPages.hoteldetailpage2 && (
        <Page size={styles.size} style={styles.body}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              backgroundColor: "#B7A292",
            }}
          >
            <Text style={styles.googleView}>Property Details</Text>
            <Image
              style={{ ...styles.size, ...styles.logoImage }}
              src={reportBuilder.logo.logo}
            />
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View
              style={{
                flex: 2,
                marginTop: 10,
                marginLeft: 80,
                marginRight: 80,
                flexDirection: "column",
                justifyContent: "space-between",
                border: "0.7pt solid white"
              }}
            >
              
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    backgroundColor: "#A27C4B",
                  }}
                >
                  <Text style={{ ...styles.detailsProperty, marginLeft: 15 }}>
                    Parameters
                  </Text>
                  <Text style={{ ...styles.detailsProperty, marginRight: 55 }}>
                    Details
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Rent Escalation	
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Rent Escalation	']}
                  </Text>

                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow2Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Maintenance Charges		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Maintenance Charges	']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Electric Connection			
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Electric Connection	']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Taxes		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Taxes']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Stamp Duty and Registration Cos		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Stamp Duty and Registration Cos']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Status		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Status']}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.propertyColumnRow,
                    ...styles.propertyRow1Colour,
                    backgroundColor: '#B7A292'
                  }}
                >
                  <Text style={styles.columnRowText}>
                  Neighbouring Hotels		
                  </Text>
                  <Text style={styles.columnRowText}>
                    {reportBuilder.fourthpage5['Neighbouring Hotels']}
                  </Text>
                </View>
                
              </View>
          </View>
        </Page>
      )}

      

      {/*--------------------------- Modern  Facilities Page----------------------------- */}
      {selectedPages.fifthpage && (
        <Page size={styles.size} style={styles.body}>
          <View style={styles.twoColumnContainer}>
            <View style={{ ...styles.column, flex: 4 }}>
              <Text style={styles.cityOverview}>Modern Facilities</Text>
              <Image
                style={{ height: 248, width: 248, marginTop: 10 }}
                src={reportBuilder.fifthpage.imagelink}
              />
            </View>
            <View style={{ flex: 6, marginTop: 40 }}>
              <Text style={styles.facilityPoint}>
                • {reportBuilder.fifthpage.vaastu}
              </Text>
              <Text style={styles.facilityPoint}>
                • {reportBuilder.fifthpage.ROWater}
              </Text>
              <Text style={styles.facilityPoint}>
                • {reportBuilder.fifthpage.HighSpeed}
              </Text>
              <Text style={styles.facilityPoint}>
                • {reportBuilder.fifthpage.wheelChair}
              </Text>
              <Text style={styles.facilityPoint}>
                • {reportBuilder.fifthpage.maintenanceStaff}
              </Text>
              <Text style={styles.facilityPoint}>
                •{reportBuilder.fifthpage.powerBackup}
              </Text>
              <Text style={styles.facilityPoint}>
                •{reportBuilder.fifthpage.DedicatedParking}
              </Text>
            </View>
          </View>
        </Page>
      )}

      {/*--------------------------- Actual  View Page----------------------------- */}
      {selectedPages.sixthpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              Actual View
              {console.log('Actual View1')}
            </Text>
            <Image
              style={{ ...styles.logoActualImage }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            { reportBuilder.sixthpage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualViewImage, width: 260 }}
                src={reportBuilder.sixthpage.imagelink1}
              />
            </View>}
           {reportBuilder.sixthpage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualViewImage, width: 200 }}
                src={reportBuilder.sixthpage.imagelink2}
              />
            </View>}
          </View>
        </Page>
      )}

      {selectedPages.seventhpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              {reportBuilder.firstpage.PropertyType === "Land" ? 'Site Plan' : 'Actual View' }
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.seventhpage.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.seventhpage.imagelink}
            />
          </View>}
        </Page>
      )}

      {selectedPages.eightpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              {reportBuilder.firstpage.PropertyType === "Land" ? 'Site Plan' : 'Actual View' }

            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
        { reportBuilder.eightpage.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.eightpage.imagelink}
            />
          </View>}
        </Page>
      )}

      {selectedPages.ninthpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Actual View
              {console.log('Actual View4')}

            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
          {reportBuilder.ninthpage.imagelink1 &&  <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images }}
                src={reportBuilder.ninthpage.imagelink1}
              />
            </View>}
            {reportBuilder.ninthpage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images, marginLeft: 0 }}
                src={reportBuilder.ninthpage.imagelink2}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.ninthpage1 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Actual View
              {console.log('Actual View4')}

            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
          {reportBuilder.ninthpage1.imagelink1 &&  <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images }}
                src={reportBuilder.ninthpage1.imagelink1}
              />
            </View>}
            {reportBuilder.ninthpage1.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images, marginLeft: 0 }}
                src={reportBuilder.ninthpage1.imagelink2}
              />
            </View>}
          </View>
        </Page>
      )}

      {selectedPages.tenthpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Actual View
              {console.log('Actual View5')}

            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           {reportBuilder.tenthpage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images }}
                src={reportBuilder.tenthpage.imagelink1}
              />
            </View>}
           {reportBuilder.tenthpage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images, marginLeft: 0 }}
                src={reportBuilder.tenthpage.imagelink2}
              />
            </View>}
          </View>
        </Page>
      )}

      {selectedPages.eleventhPage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Actual View
              {console.log('Actual View6')}

            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            { reportBuilder.eleventhPage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images }}
                src={reportBuilder.eleventhPage.imagelink1}
              />
            </View>}
           {reportBuilder.eleventhPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.actualView3Images, marginLeft: 0 }}
                src={reportBuilder.eleventhPage.imagelink2}
              />
            </View>}
          </View>
        </Page>
      )}
      {/*--------------------------- Inner pics Page----------------------------- */}
      {selectedPages.innerpicpage && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.innerpicpage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.innerpicpage1 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.innerpicpage1.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage1.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.innerpicpage2 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.innerpicpage2.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage2.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.innerpicpage3 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.innerpicpage3.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage3.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.innerpicpage4 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.innerpicpage4.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage4.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.innerpicpage5 && (
        <Page
          size={styles.size}
          style={{ ...styles.body, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "white",
                fontSize: 34,
                marginTop: 5,
                marginLeft: 20,
              }}
            >
              Inner Pics
            </Text>
            <Image
              style={{ ...styles.logoActualView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.innerpicpage5.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{
                ...styles.actualView3Images,
                width: 450,
                marginLeft: 85,
                height: 225,
              }}
              src={reportBuilder.innerpicpage5.imagelink}
            />
          </View>}
        </Page>
      )}

      {/*--------------------------- Brand  Mapping Page----------------------------- */}

      {selectedPages.twelvethPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: 40,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Brand Mapping
            </Text>
            <Image
              style={{ ...styles.logoBrandMappingImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           {reportBuilder.twelvethPage.imagelink &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.brandMappingImages }}
                src={reportBuilder.twelvethPage.imagelink}
              />
            </View>}
          </View>
        </Page>
      )}

      {/*--------------------------- Floor Plan Pages----------------------------- */}
      {selectedPages.thirteenthPage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text>
            <Text style={{ ...styles.floorColour }}>Basement Floor</Text>
            1
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.thirteenthPage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.thirteenthPage.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.fourteenthPage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text>
            <Text style={{ ...styles.floorColour }}>Ground Floor</Text>
            1
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.fourteenthPage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.fourteenthPage.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.fiftenthPage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text>
            <Text style={{ ...styles.floorColour }}>
              1
              <Text
                style={{ fontSize: 12, verticalAlign: "super", marginLeft: 2 }}
              >
                st
              </Text>{" "}
              Floor
            </Text>
            1
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.fiftenthPage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.fiftenthPage.imagelink}
            />
          </View>}
        </Page>
      )}
      {selectedPages.sixteenthPage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
           <View>
          {/* <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text> */}
            <Text style={{ ...styles.floorColour }}>Section Height</Text>
            
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.sixteenthPage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.sixteenthPage.imagelink}
            />
          </View>}
        </Page>
      )}

       {selectedPages.typicalpage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text>
            <Text style={{ ...styles.floorColour }}>Typical Floor</Text>
            
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
         {reportBuilder.typicalPage.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.typicalPage.imagelink}
            />
          </View>}
        </Page>
      )}
       {selectedPages.terracepage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Floor Plan</Text>
            <Text style={{ ...styles.floorColour }}>Terrace</Text>

            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.terracePage.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.terracePage.imagelink}
            />
          </View>}
        </Page>
      )}

      
      {/*---------------------------Elevations Pages----------------------------- */}
       {selectedPages.elevationpage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Elevation</Text>
            
            <Image
              style={{ ...styles.logoElevation }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.elevationpage.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.elevationpage.imagelink}
            />
          </View>}
        </Page>
      )}
       {selectedPages.elevationpage1 && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Elevation</Text>
            
            <Image
              style={{ ...styles.logoElevation }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.elevationpage1.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.elevationpage1.imagelink}
            />
          </View>}
        </Page>
      )}
       {selectedPages.elevationpage2 && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ ...styles.floorPlanColour }}>Elevation</Text>
            
            <Image
              style={{ ...styles.logoElevation }}
              src={reportBuilder.logo.logo}
            />
          </View>
          { reportBuilder.elevationpage2.imagelink && <View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.elevationpage2.imagelink}
            />
          </View>}
        </Page>
      )}
      {/*--------------------------- Vicinity Images Pages----------------------------- */}

      {selectedPages.seventeenthPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           { reportBuilder.seventeenthPage.imagelink1 !== '' && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.seventeenthPage.imagelink1}
              />
            </View>}
            { reportBuilder.seventeenthPage.imagelink2 !== '' && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.seventeenthPage.imagelink2}
              />
            </View>}
            { reportBuilder.seventeenthPage.imagelink3 !== '' && <View style={{ flex: 4 }}>

              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.seventeenthPage.imagelink3}
              />
            </View> }
          </View>
        </Page>
      )}
      {selectedPages.eighteenthPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {reportBuilder.eighteenthPage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.eighteenthPage.imagelink1}
              />
            </View>}
           {reportBuilder.eighteenthPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.eighteenthPage.imagelink2}
              />
            </View>}
            {reportBuilder.eighteenthPage.imagelink && <View style={{ flex: 4 }}>
               <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.eighteenthPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.nineteenthpage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           {reportBuilder.nineteenthpage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.nineteenthpage.imagelink1}
              />
            </View>}
           {reportBuilder.nineteenthpage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.nineteenthpage.imagelink2}
              />
            </View>}
            {reportBuilder.nineteenthpage.imagelink3 &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.nineteenthpage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.twentythPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           { reportBuilder.twentythPage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythPage.imagelink1}
              />
            </View>}
           {reportBuilder.twentythPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythPage.imagelink2}
              />
            </View>}
            {reportBuilder.twentythPage.imagelink3 &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.twentyfirstPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {reportBuilder.twentyfirstPage.imagelink1 &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfirstPage.imagelink1}
              />
            </View>}
           {reportBuilder.twentyfirstPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfirstPage.imagelink2}
              />
            </View>}
           {reportBuilder.twentyfirstPage.imagelink3 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfirstPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.twentysecondPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
           {reportBuilder.twentysecondPage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentysecondPage.imagelink1}
              />
            </View>}
           {reportBuilder.twentysecondPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentysecondPage.imagelink2}
              />
            </View>}
            {reportBuilder.twentysecondPage.imagelink3 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentysecondPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      {selectedPages.twentythirdPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {reportBuilder.twentythirdPage.imagelink1 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythirdPage.imagelink1}
              />
            </View>}
           {reportBuilder.twentythirdPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythirdPage.imagelink2}
              />
            </View>}
            {reportBuilder.twentythirdPage.imagelink3 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentythirdPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}


      {selectedPages.twentyfourthPage && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {reportBuilder.twentyfourthPage.imagelink1 &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfourthPage.imagelink1}
              />
            </View>}
           {reportBuilder.twentyfourthPage.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfourthPage.imagelink2}
              />
            </View>}
            {reportBuilder.twentyfourthPage.imagelink3 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.twentyfourthPage.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}

      {selectedPages.vicinitypage9 && (
        <Page
          size={styles.size}
          style={{ ...styles.vicinityColour, flexDirection: "column" }}
        >
          <View>
            <Text
              style={{
                color: "#5D4037",
                fontSize: 34,
                marginTop: 15,
                marginLeft: 180,
                display: "flex",
              }}
            >
              Vicinity Images
            </Text>
            <Image
              style={{ ...styles.logoVicinityView3Image }}
              src={reportBuilder.logo.logo}
            />
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {reportBuilder.vicinitypage9.imagelink1 &&<View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.vicinitypage9.imagelink1}
              />
            </View>}
           {reportBuilder.vicinitypage9.imagelink2 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.vicinitypage9.imagelink2}
              />
            </View>}
            {reportBuilder.vicinitypage9.imagelink3 && <View style={{ flex: 4 }}>
              <Image
                style={{ ...styles.vicinityView3Images }}
                src={reportBuilder.vicinitypage9.imagelink3}
              />
            </View>}
          </View>
        </Page>
      )}
      
       {selectedPages.vicinitypage && (
        <Page
          size={styles.size}
          style={{
            ...styles.floorPlanBackgroundColour,
            flexDirection: "column",
          }}
        >
          <View>
          <Text style={{ ...styles.floorPlanColour }}>Vicinity</Text>
            <Text style={{ ...styles.floorColour }}>Images</Text>
            <Image
              style={{ ...styles.logoFloorPlanImages }}
              src={reportBuilder.logo.logo}
            />
          </View>
          {reportBuilder.vicinitypage.imagelink &&<View style={{ flex: 4 }}>
            <Image
              style={{ ...styles.floorPlanImages }}
              src={reportBuilder.vicinitypage.imagelink}
            />
          </View>}
        </Page>
      )}
      
      {/*--------------------------- Contact Details Page----------------------------- */}

      {selectedPages.twentyfifthpage && (
        <Page size={styles.size} style={styles.body}>
          <View>
            <Text
              style={{
                color: "#D0CCBE",
                fontSize: 40,
                lineHeight: 1.5,
                display: "flex",
                marginLeft: 5,
                marginTop: 15,
              }}
            >
              Contact Details
            </Text>
            <Image
              style={{ ...styles.logoContactDetailsmages }}
              src={reportBuilder.logo.logo}
            />
            <View style={{ marginTop: 35 }}>
              <Text style={{ marginLeft: 15, fontSize: 14, color: "#D0CCBE" }}>
                {reportBuilder.twentyfifthpage.name}
              </Text>
              <Text
                style={{
                  marginLeft: 15,
                  fontSize: 12,
                  color: "#D0CCBE",
                  marginTop: 4,
                }}
              >
                Mobile : {reportBuilder.twentyfifthpage.mobile}
              </Text>
              <Text
                style={{
                  marginLeft: 15,
                  fontSize: 14,
                  color: "#D0CCBE",
                  marginTop: 14,
                }}
              >
                {reportBuilder.twentyfifthpage.name1}
              </Text>
              <Text
                style={{
                  marginLeft: 15,
                  fontSize: 12,
                  color: "#D0CCBE",
                  marginTop: 4,
                }}
              >
                Mobile : {reportBuilder.twentyfifthpage.mobile1}
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 10,
                  color: "#D0CCBE",
                  marginTop: 14,
                }}
              >
                Email : {reportBuilder.twentyfifthpage.Email}
              </Text>
              {/* <Text style={{ marginLeft: 15, fontSize: 14, color: "#D0CCBE" }}>
              {table.contactname}
            </Text>
            <Text
              style={{
                marginLeft: 15,
                fontSize: 12,
                color: "#D0CCBE",
                marginTop: 4,
              }}
            >
              Mobile : {table.phone}
            </Text>
            <Text
              style={{
                marginLeft: 15,
                fontSize: 14,
                color: "#D0CCBE",
                marginTop: 14,
              }}
            >
              {}
            </Text>
            <Text
              style={{
                marginLeft: 15,
                fontSize: 12,
                color: "#D0CCBE",
                marginTop: 4,
              }}
            >
              Mobile : {}
            </Text>
            <Text
              style={{
                marginLeft: 12,
                fontSize: 10,
                color: "#D0CCBE",
                marginTop: 14,
              }}
            >
              Email : {table.email}
            </Text> */}
              Website :
              <Link src={reportBuilder.twentyfifthpage.Website}>
                <Text
                  style={{
                    marginLeft: 12,
                    fontSize: 10,
                    color: "#D0CCBE",
                    marginTop: 4,
                  }}
                >
                  Website :{reportBuilder.twentyfifthpage.Website}
                </Text>
              </Link>
              <Text
                style={{
                  marginLeft: 2,
                  color: "#D0CCBE",
                  fontSize: 10,
                  marginTop: 40,
                }}
              >
                <Text
                  style={{
                    marginLeft: 2,
                    color: "#D0CCBE",
                    fontSize: 12,
                    marginTop: 30,
                  }}
                >
                  Our Address -
                </Text>{" "}
                {reportBuilder.twentyfifthpage.Address}
              </Text>
            </View>

            <View style={{ marginTop: 2, backgroundColor: "#FFFDE7" }}>
              <Text
                style={{
                  marginLeft: 2,
                  color: "#1E2224",
                  fontSize: 8,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    marginLeft: 2,
                    color: "#9A6237",
                    fontSize: 12,
                    marginTop: 30,
                  }}
                >
                  Disclaimer:
                </Text>{" "}
                {reportBuilder.twentyfifthpage.Disclaimer}
              </Text>
            </View>
          </View>
        </Page>
      )}
      {selectedPages.twentysixthpage && (
        <Page size={styles.size} style={styles.body}>
          <Text
            style={{
              textAlign: "center",
              display: "flex",
              marginTop: "120px",
              alignItems: "center",
              color: "white",
              fontSize: 54,
              justifyContent: "center",
            }}
          >
            Thank you
          </Text>
        </Page>
      )}
    </Document>
  );
};

export default PdfDownload;
