import React, { useState, useEffect } from "react";
import TableTemplate from "./TableTemplate";
import moment from "moment";

function DynamicPdfGeneratePage({
  imageFile1,
  imageFile2,
  section,
  subSection,
  changeImage,
  tableData,
  page,
  data,
  handlechange,
  mapimage,
  columns
}) {
  console.log("pages--->", page);
  const [isUpload, setIsUpload] = useState(false);
  const [dataRec, setDataRec] = useState(data);
  const [image1, setImage1] = useState(imageFile1);
  const [image2, setImage2] = useState(imageFile2);
  const [tableDataForm, setTableDataForm] = useState(tableData);
  const colourCodes = ["#4c89ff", "#37D5D6","#22E4AC","#04AF70","#027148"]
  useEffect(() => {
    setImage1(imageFile1);
  }, [imageFile1]);
  useEffect(() => {
    setImage2(imageFile2);
  }, [imageFile2]);

  useEffect(() => {
    setTableDataForm(tableDataForm);
  }, [tableDataForm]);

  const handleChange = (event, image, setImage, isImage1) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImage = { ...image, image: reader.result };
        setImage(newImage);
        changeImage(newImage, isImage1);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleTableChange = (data) => {
    setDataRec(data);
    handlechange(data);
  };
  const myStyleColor = (index) => {
    return {background: colourCodes[index]};
  };
  const myStyle = {
    backgroundImage: page === "Key Details" ? 'url("/warehouse-pdf/keyAreaDetailspagebg.png")' : 'url("/Image20240524154030.png")',
  };

  if (page === "Home Page") {
    return (
      <div className="page-with-body">
        <div className="two-page-header">
          <br/>
          <center>
            <h1 className="textcolor">
              Proposed Option <br /> For <br /> {data.vertical} Space
            </h1>
          </center>
          <center  className="textcolor">
            {data.street}, {data.city} <br /> {data.name}{" "}
          </center>
          <p className="textcolor companyname">

          <p  >
            By Sthapatya Leasing & Consultant  
          </p>
          <p  className=" companydate">
          {moment(data.lastmodifieddate).format("DD-MM-YYYY")}
          </p>
          </p>

        </div>
        <div >
          {
            <img className="two-page-side-body"
              src={image1}
              alt="Property"
              onDoubleClick={() => setIsUpload(true)}
            />
          }
        </div>
          <img  className="first-logo"
             src={"/pdf_template_1/logo.jpg"}
              alt="Property"
             // onDoubleClick={() => setIsUpload(true)}
            />
      </div>
    );
  } else if (page === "Over View") {
    return (
      <div className="page-with-body">
        <div className="two-page-header-city">
          <div className="two-page-side-header">
            <div className="details-head-text">{section}</div>
          </div>
          <div className="two-page-image city-table">
            {tableDataForm && (
              <TableTemplate
                table={tableDataForm}
                data={data}
                handlechange={(data) => handleTableChange(data)}
              />
            )}
          </div>
        </div>
        <div className="two-page-side-body">
          {
            <img
              src={image1}
              alt="Property"
              onDoubleClick={() => setIsUpload(true)}
            />
          }
        </div>
      </div>
    );
  } else if (page === "Property Details" || page === "Google View") {
    return (
      <div className="page">
        <div className="details-page-header ">
          <div>
            <div className="propertydetails-head-text">{section}</div>
            <div className="subhead-text">{subSection}</div>
          </div>
          <div>
            <img
              className="app-logo"
              src={"/pdf_template_1/logo.jpg"}
              alt="Property"
            />
          </div>
        </div>
        <div className="page-body city-table">
          {tableDataForm && (
            <TableTemplate
              table={tableDataForm}
              data={data}
              handlechange={(data) => handleTableChange(data)}
            />
          )}
          {
            <div>
              {!tableDataForm && (
                <img
                  src={mapimage}
                  alt="Property"
                  onDoubleClick={() => setIsUpload(true)}
                />
              )}
            </div>
          }
        </div>
      </div>
    );
  } else if (page === "Modern Facilites") {
    return (
      <div className="page-with-body">
        <div className="two-page-header">
          <div className="two-page-side-header">
            <div className="details-head-text-city">{section}</div>
          </div>
          <div className="two-page-image">
            <img
              src={image1}
              alt="Property"
              onDoubleClick={() => setIsUpload(true)}
            />
          </div>
        </div>
        <div className="two-page-side-body">
          <div>
            <ul className="textcolor">
              {tableData &&
                Object.entries(tableData).map(([key, value]) => (
                <>
                <li key={key}>{value}</li> <br />
                </>  
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  } else if (page === "Contact Page") {
    return (
      <div className="page-with-body">
        <div className="page-container">
          <div>
            <div class="contact-details">Contact Details</div>
            <div>
              <img
                class="contact-logo"
                 src='/pdf_template_1/logo.jpg'
                alt="Property"
              />
            </div>

            <div class="contact-names">
              <div class="contact">
                <p class="font-size-15">
                  Arpit Yagnik
                  <br />
                  Mobile : +91-9636969646
                </p>
                <p class="font-size-15">
                  Piyush Agarwal
                  <br />
                  Mobile : +91-9829610077
                </p>
                <span class="font-size-12">
                  Email : arpityagnik@sthapatyaleasing.com
                </span>
                <br />
                <span class="font-size-12">
                  Website :{" "}
                  <a href="http://www.sthapatyaleasing.com" target="_blank" >
                    www.sthapatyaleasing.com
                  </a>
                </span>
              </div>
              <p class="font-size-10 mt-neg-15">
                <span class="font-size-12 fw-bold">Our Address -</span>
                Sthapatya Leasing & Consultant E-143, 1st Floor, Nirman Nagar,
                Opp. Hotel Kiran Palace DCM, Ajmer Road, Jaipur
              </p>
            </div>
              <p className="font-size-10 bg-white text-black ">
                <span className="font-size-12 fw-bold text-danger-emphasis">
                  {" "}
                  Disclaimer:
                </span>
                Details & Pictures mentioned in this presentation is for
                illustration purpose only & cannot be in any way treated as a
                legal document. All information, specifications, plans,
                materials, dates, & visual representations contained are subject
                to change from time to time by the Land Owner / Realtor /
                Company / Competent Authorities and shall not form part of any
                contract, offer or deal. Sizes, Rates, Specifications, and
                Availability of the project/property may change without any
                prior information and Land Owner / Realtor / Company shall not
                be held responsible in any manner whatsoever.
              </p>
          </div>
        </div>
      </div>
    );
  } else if (page === "Thanks Page") {
    return (
      <>
        <div className="page-with-body">
          <center>
            <div className="page-container">
              <div className="thankyou">Thank You</div>
            </div>
          </center>
        </div>
      </>
    );
  } else if (page === "Warehouse Home") {
    return (
      <>
      <div className="pagebody">
         <img className="warehouseimg" src="/Image20240529154115.png" />
        <h2 className="text-dark vertical">{data.vertical} {'\n'} <h5 className="fw-bold text-break"> - {data.name} , {data.street} </h5> </h2>
        
      </div>
      {/* <div className="page-with-body-ware" style={myStyle}>
        <div
          className="hexa"
          style={{ backgroundImage: 'url("/Image20240524152942.png")' }}
        >
          <div className="d-flex">
            <div>
              <h2>STHAPATYA</h2>
              <h3>LEASING CONSULTANT</h3>
            </div>
            <div>
              <h4>
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginLeft: "2.8rem",
                    fontSize: "1.8rem",
                  }}
                >
                  Pro
                </span>{" "}
                posal for {data.vertical}
              </h4>
              <h5> - {data.name} , {data.street} </h5>
            </div>
          </div>
        </div>
        <div className="small-hex">
          <img src="/image_2024_05_24T10_40_01_594Z.png " />
          <div className="hex-img">
            <img src={"/pdf_template_1/logo.jpg"} alt="Property" />
          </div>
        </div>
      </div> */}
      </>
    );
  } else if (page === "About Us") {
    return (
      <div className="abotus">
         <div>
              <img
                class="logo-aboutus"
                 src='/pdf_template_1/logo.jpg'
                alt="Property"
              />
            </div>
      <div className="page-with-body ownername">
        <div className="three-layout ">
          <img
            src={image1}
            alt="Property"
            onDoubleClick={() => setIsUpload(true)}
          />
        </div>
        <div className="three-layout pt-4">
          <center>
          <u><h2 className="aboutus"> ABOUT US </h2></u>
            <p className="about-text">
              Decade isn’t just a number it is a journey of Sthapatya that has
              gained trust of its client’s.
            </p>
            <p className="about-text">  We, at Sthapatya have always
              believed into a customized, client-specific & need-based approach.
              We understands the existing gaps & requirements in the current
              situations from our clients and aspire to provide a more efficient
              & best suited warehousing or real estate solution to cater our
              clients’ growing needs from time to time.</p>
          </center>
        </div>
        <div className="three-layout">
          <img
            src={image2}
            alt="Property"
            onDoubleClick={() => setIsUpload(true)}
          />
          <h3 className="text-white bg-dark text-center ">OWNER</h3>
          <h3 className="text-white bg-dark text-center">RITESH AGARWAL</h3>
        </div>
      </div>
      </div>
    );
  } else if (page === "Key Details") {
    return (
      <div className="page-warehouse">
        <div className="three-layout d-grid" style={myStyle}>
          <center style={{marginTop:"10rem"}}>
            <h1>{data.vertical} </h1>
            <h1>Key Area </h1>
            <h1> Details</h1>
          </center>
        </div>
        <div className="two-page-side-warehouse">
        {tableData.map((e,index)=>
          <div className="key-detail-border">
            <div className="key-detail" style={myStyleColor(index)}>{e.label} :  {data[e.value]}</div>
          </div>
        )}
          
        </div>
      </div>
    );
  } else if (page === 'Warehouse Table') {
    return (
      <div className="pagebody">
        <img className="w-100 h-25 d-inline-block"
          src='/warehouse-pdf/HeaderInnerView.png'
          alt="Property"
        />
        <div className="warehousetable pagecontainer1" >
          {console.log('columns',columns)}
          <TableTemplate table={tableDataForm} data={data} isWareHouse={true} columns={columns} handlechange={(data) => handleTableChange(data)} />
        </div> 
      </div> 
    )
  } else if (page === 'Warehouse Contact') {
    return (
      <div className="page-with-body-ware" style={{backgroundImage:'url("/warehouse-pdf/ContactUs.jpg")'}}>
      </div>
    )

  }
}

export default DynamicPdfGeneratePage;
