import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import inventoryApi from '../../api/inventoryApi';
import * as constants from "../../constants/CONSTANT"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Form from "react-bootstrap/Form";
import data from "../NewJson";

import {
  DatatableWrapper,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Button, Container, Modal, Col, Row, Table } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import moment from 'moment';
import { light } from '@mui/material/styles/createPalette';

const SelectInventoryFiles = ({parentid, show, onHide, confirmFiles,prevSelectedFiles }) => {

  const [allInventories, setAllInventories] = useState([]);
  const [inventSelectedFiles, setInventSelectedFiles] = useState([])
  const [body, setBody] = useState()
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedVerticalType, setSelectedVerticalType] = useState('');
  

  const [property, setProperty] = useState( {});
  useEffect(() => {
 
    async function initProperty() {
      let result = await inventoryApi.fetchProperty(parentid);
      if (result) {
        setProperty(result);
      }
    }
    initProperty();
  },[parentid]);

  useEffect(() => {
    filesList();
  }, [])

  const filesList = async () => {
    let files = await inventoryApi.fetchLeadEmailFiles();
    console.log("files-->",files)
    if (files.length > 0) {
      const updatedFiles = files.map((inventory) => {
        let updatedPdfFiles = [];
        if (inventory.PdfFiles.length > 0) {
          inventory.PdfFiles.forEach((file) => {
            updatedPdfFiles.push({ ...file, isSelected: false });
          });
        }
        return { ...inventory, PdfFiles: updatedPdfFiles };
      });
      setAllInventories(updatedFiles);
      setInventSelectedFiles(updatedFiles);
    }
  };

  const handleSelectFile = (row) => {

    //.log("selected file --> ",row);

    setInventSelectedFiles((prev) =>
      prev.map((inventory) => {

        let updatedPdfFiles = []

        if (inventory.PdfFiles.length > 0) {
           (inventory.PdfFiles.forEach((file) => {
            
            file.id === row.id ? updatedPdfFiles.push({ ...file, isSelected: !file.isSelected }) 
            : updatedPdfFiles.push(file)
          }))}
        return {...inventory, PdfFiles: updatedPdfFiles}
      }))
  }

const handleNext = () => {

  let localSelectedFiles = []

  inventSelectedFiles.forEach((inventory) => {
    if (inventory.PdfFiles.length > 0) {
      (inventory.PdfFiles.forEach((file) => {
       
       if(file.isSelected) localSelectedFiles.push(file) 
     }))}
  })

  //.log("All selected files --> ",localSelectedFiles);
  
  confirmFiles([...prevSelectedFiles, ...localSelectedFiles]);
}

const fileSize = (bytes) => {
  var exp = bytes / 1024 / 1024;
  return exp.toFixed(2) + " MB";
};

const renderPreview = (type, body) => {
  ////.log(type);
  switch (type) {
    case constants.PDF:
      return <i className="fa-solid fa-file-pdf fa-2xl file-thumbnail"></i>;
    case constants.DOC:
    case constants.DOCX:
      return <i className="fa-solid fa-file-word fa-2xl file-thumbnail"></i>;
    case constants.XLS:
    case constants.XLSX:
      return <i className="fa-solid fa-file-excel fa-2xl file-thumbnail"></i>;
    case constants.PNG:
    case constants.JPG:
    case constants.JPEG:
      return <Image src={body} thumbnail width="200px" />;
    default:
      return <i className="fa-solid fa-file-file fa-2xl file-thumbnail"></i>;
  }
};

const inputHandler = (event) => {
  const searchText = event.target.value.toLowerCase();
  setSearchText(searchText);
  applyFilters(searchText, selectedVerticalType);
};

const handleVerticalChange = (event) => {
  const selectedVertical = event.target.value;
  setSelectedVerticalType(selectedVertical);
  applyFilters(searchText, selectedVertical);
};

const applyFilters = (searchText, selectedVertical) => {
  let filtered = allInventories;
  if (searchText !== "") {
    filtered = filtered.filter((inventory) =>
      inventory.inventoryname.toLowerCase().includes(searchText)
    );
  }
  if (selectedVertical !== "") {
    filtered = filtered.filter((inventory) =>
      inventory.Vertical.toLowerCase().includes(selectedVertical.toLowerCase())
    );
  }
  setInventSelectedFiles(filtered);
};


const header = [
  {
    title: "Title",
    prop: "filetitle",
    cell: (row) => (
      <>
          {row.filetitle}{"-"}{row.verticaltype}
      </>
    ),
  },
  // {
  //   title: "",
  //   prop: "body",
  //   cell: (row) => (
  //     <>
  //       <center>{renderPreview(row.filetype, row.body)}</center>
  //     </>
  //   ),
  // }, 
  { title: "File Type", prop: "filetype", cell: (row) => row.filetype },
  {
    title: "File Size",
    prop: "filesize",
    cell: (row) => fileSize(row.filesize),
  },
  {
    title: "Created Date",
    prop: "createddate",
    cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
  },
  {
    title: "Actions",
    prop: "id",
    cell: (row) => (
      <>
        <Button
          class=" fa-paperclip"
          for="files"
          onClick={()=> handleSelectFile(row)}
        > 
          {row.isSelected ?
            <FontAwesomeIcon icon={faMinus} style={{ color: "#ffffff" }} />
            :
            <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
          }
        </Button>
      </>
    ),
  },
];

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => onHide()}
      size="xl"
      // scrollable
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Files from Inventories
        </Modal.Title>
      </Modal.Header>

      <Row lg={8}>
      <Col lg={4}>
        <Form.Group className="mx-4 mb-3 mt-3" controlId="formBasicTitle">
          <Form.Label className="form-view-label w-40" htmlFor="formBasicTitle">
            Search Inventory :
          </Form.Label>
          <Form.Control
            className="w-50"
            placeholder="Search here"
            id="outlined-basic"
            onChange={inputHandler}
            variant="outlined"
            fullWidt
            label="Search"
          />
        </Form.Group>
      </Col>
      <Col lg={4}>
        <Form.Group className="mb-3 mt-3" controlId="formBasicTitle">
          <Form.Label className="form-view-label " htmlFor="formBasicTitle">
            Search by Vertical type :
          </Form.Label>
          <Form.Select
                    id="basic-typeahead-single"
                    name="vertical"
                    onChange={handleVerticalChange}
                    //value={selectedVertical}
                    //onChange={inputHandler}
                    placeholder="Choose a state..."
                  >
                    <option value="">Select Type</option>
                    <option value="Office">Office</option>
                    <option value="Land">Land</option>
                    <option value="Retail">Retail</option>
                    <option value="Investment">Investment</option>
                    <option value="Warehouse">Warehouse</option>
                    <option value="Cold storage">Cold storage</option>
                    <option value="Factory">Factory</option>
                    <option value="Hotels">Hotels</option>
                  </Form.Select>
        </Form.Group>
      </Col>
      </Row>

      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Accordion alwaysOpen defaultActiveKey="0">
              {inventSelectedFiles.length > 0 ? (
                <>
                  {inventSelectedFiles
                    .filter(
                      (inventory) => property.name === inventory.inventoryname
                    )
                    .map((inventory, index) => (
                      <Accordion.Item
                        eventKey={0}
                        key={inventory.inventoryname}
                      >
                        <Accordion.Header>
                         <strong> <p style={{ color: "red",fontSize:'15px' }}>
                            {inventory.inventoryname}{"  ("}{inventory.Vertical}{")"}
                          </p></strong>
                        </Accordion.Header>
                        <Accordion.Body>
                          {inventory.PdfFiles.length > 0 ? (
                            <DatatableWrapper
                              body={inventory.PdfFiles}
                              headers={header}
                            >
                              <Row className="mb-4">
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-start align-items-start"
                                ></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-start align-items-start"
                                ></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-end align-items-end"
                                ></Col>
                              </Row>
                              <Table striped className="related-list-table">
                                <TableHeader />
                                <TableBody />
                              </Table>
                              {/* <Pagination /> */}
                            </DatatableWrapper>
                          ) : (
                            "No Files Available"
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}

                  {inventSelectedFiles.filter(
                      (inventory) => property.name !== inventory.inventoryname
                    ).map((inventory, index) => (
                      <Accordion.Item
                        eventKey={index + 1}
                        key={inventory.inventoryname}
                      >
                        <Accordion.Header>
                        {inventory.inventoryname}{"  ("}{inventory.Vertical}{")"}
                        </Accordion.Header>
                        <Accordion.Body>
                          {inventory.PdfFiles.length > 0 ? (
                            <DatatableWrapper
                              body={inventory.PdfFiles}
                              headers={header}
                            >
                              <Row className="mb-4">
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-start align-items-start"
                                ></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-start align-items-start"
                                ></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className="d-flex flex-col justify-content-end align-items-end"
                                ></Col>
                              </Row>
                              <Table striped className="related-list-table">
                                <TableHeader />
                                <TableBody />
                              </Table>
                              {/* <Pagination /> */}
                            </DatatableWrapper>
                          ) : (
                            "No Files Available"
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </>
              ) : (
                "No Files in Inventories"
              )}
            </Accordion>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleNext}>Next</Button>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectInventoryFiles