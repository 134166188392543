import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, InputGroup, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../../api/inventoryApi";
import Form from "react-bootstrap/Form";
import { ShimmerTable } from "react-shimmer-effects";
import Badge from "react-bootstrap/Badge";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import CustomSeparator from "../Breadcrumbs/CustomSeparator";
import moment from "moment/moment";
import NewInfoPill from "../common/NewInfoPill/NewInfoPill";
import { Typeahead } from "react-bootstrap-typeahead";
import data from "../NewJson";
import { BASE_URL } from "../../constants/CONSTANT";

const LeadList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leads, setLeads] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [filterLeads, setFilterLeads] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [sortFilter, setSortFilter] = useState()
  const [transcationType, setTranscationType] = useState(data.Value["Transaction type"]);
  const [possessionDateValue, setPossessionDatevalue] = useState('');
  const [leaseExpirationDateValue, setLeaseExpirationvalue] = useState('');
  const [typeOfClient, setTypeOfClient] = useState([]);
  const [verticals, setVertical] = useState([]);
  const [verticalType, setVerticalType] = useState([]);
  const [subVerticalType, setSubVerticalType] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [selectedTypeOfClient, setSelectedTypeOfClient] = useState();
  const [selectedVertical, setSelectedVertical] = useState();
  const [selectedVerticalType, setSelectedVerticalType] = useState();
  const [selectedSubVerticalType, setSelectedSubVerticalType] = useState();
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [locationValue, setLocationValue] = useState('');
  const [availableValue, setAvailableValue] = useState('');
  const [staffValue, setStaffValue] = useState('');
  const [picbody, setpicBody] = useState(JSON.parse(localStorage.getItem("usersimage")));
console.log('picbody',JSON.stringify(picbody))

  // useEffect(()=>{
  //   var dataImage = localStorage.getItem('myimage');
  //   let bannerImg = document.getElementById('profile-image');
  //   console.log("bannerImg",bannerImg)
  //   if(localStorage.getItem('myimage') !== '/img_avatar.png'){ 
  //     bannerImg.src = "data:image/png;base64," + dataImage;
  //   }else{
  //     setBody('/img_avatar.png')
  //     bannerImg.src = localStorage.getItem('myimage');
  //   }
   

  // }, [localStorage.getItem('myimage')])

  useEffect(() => {
    async function init() {
     
      const result = await inventoryApi.fetchLeads();
      //.log('hello',result);
      if (result) {
        if (result.length >= 0) {
          let sortedLeads = [...result];
          sortedLeads.sort((a, b) =>
            moment(b.lastmodifieddate).diff(moment(a.lastmodifieddate))
          );
          setBody([...sortedLeads]);

          setLeads(result);
          setFilterLeads(result)
        }
      }
    }
    init();
  }, []);





  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const handleSortFilter = (sort) => {

    if (sort === "lastly") {

      setBody((prev) => prev.sort(
        (objA, objB) => Number(new Date(objA.createddate)) - Number(new Date(objB.createddate)),
      ));
    } else if (sort === "newly") {
      setBody((prev) => prev.sort(
        (objA, objB) => Number(new Date(objB.createddate)) - Number(new Date(objA.createddate)),
      ));
    }
  }
  const VerticalColors = {
    Office: "RGBA(255, 99, 2,255)",
    Land: "RGBA(255, 168, 168,255)",
    Retail: "RGBA(232, 116, 133,255)",
    Investment: "RGBA(243, 223, 223,255)",
    Warehouse: "RGBA( 241, 66, 66,255)",
    "Cold storage": "RGBA(202, 57, 57,255)",
    Factory: "RGBA(250, 154, 9,255)",
    Hotels: "RGBA(255, 2, 2,255)",
    Others: "RGBA(252, 214, 214,255)",
  };

  //==============================================
  const filterData = (
    nameText,
    locationText,
    transactionTypeText,
    typeOfClientText,
    verticalText,
    verticalTypeText,
    subVerticalTypeText,
    toValue,
    fromValue,
    possessionDateTextValue,
    leaseExpirationDateValue,
    availableValue,
    staffValue  ) => {
    let filteredData = leads;
    console.log('locationText 163', toValue, fromValue, nameText, transactionTypeText);
    console.log('body', body);


    const lowercasedLocationText = locationText.toLowerCase();

    const lowercasedFilterName = nameText.toLowerCase();
    if (nameText !== null && nameText !== '' && nameText !== undefined) {
      filteredData = leads.filter((row) => {
        const fullName = `${row?.firstname} ${row?.lastname}`.toLowerCase().trim();
        return fullName?.includes(lowercasedFilterName);
      });
    }

    if (locationText !== null && locationText !== '' && locationText !== undefined) {
      filteredData = filteredData.filter((row) => {
        const location = row?.locatedin?.toLowerCase();
        return location?.includes(lowercasedLocationText);
      });
      console.log('filteredData', filteredData);
    }

    if (transactionTypeText !== null && transactionTypeText !== '' && transactionTypeText !== undefined) {
      console.log('transactionTypeText:', transactionTypeText);
      filteredData = filteredData.filter(item => {
        return item.transactiontype && item.transactiontype.toLowerCase() === transactionTypeText.toLowerCase();
      });
      console.log('filteredData:', filteredData);
    }


    if (typeOfClientText !== null && typeOfClientText !== '' && typeOfClientText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.typeofclient && item.typeofclient.toLowerCase() === typeOfClientText.toLowerCase()
      );
    }

    if (verticalText !== null && verticalText !== '' && verticalText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.vertical && item.vertical.toLowerCase() === verticalText.toLowerCase()
      );
    }

    if (verticalTypeText !== null && verticalTypeText !== '' && verticalTypeText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.verticaltype && item.verticaltype.toLowerCase() === verticalTypeText.toLowerCase()
      );
    }

    if (subVerticalTypeText !== null && subVerticalTypeText !== '' && subVerticalTypeText !== undefined) {
      filteredData = filteredData.filter(item =>
        item.subverticaltype && item.subverticaltype.toLowerCase() === subVerticalTypeText.toLowerCase()
      );
    }
    console.log('toValue', toValue, leads);
    if (toValue !== null && toValue !== '' && toValue !== undefined) {
      console.log('filteredData 218');
      filteredData = filteredData.filter(item =>
        item.areadetails && item.areadetails.some(area => area.value && parseInt(area.value) <= toValue));
    }

    if (fromValue !== null && fromValue !== '' && fromValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.areadetails && item.areadetails.some(area => area.value && parseInt(area.value) >= fromValue && (toValue === null || toValue === '' || toValue === undefined || parseInt(area.value) <= toValue))
      );
    }
    console.log('availableValue', availableValue);



    if (possessionDateTextValue !== null && possessionDateTextValue !== '' && possessionDateTextValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.possessiondate && item.possessiondate.toLowerCase() === possessionDateTextValue.toLowerCase()
      );
      setPossessionDatevalue('');
    }

    if (leaseExpirationDateValue !== null && leaseExpirationDateValue !== '' && leaseExpirationDateValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.leaseexpirationdate && item.leaseexpirationdate.toLowerCase() === leaseExpirationDateValue.toLowerCase()
      );
      setLeaseExpirationvalue('')

    }
    console.log('availableValue',availableValue);

    if (availableValue !== null && availableValue !== '' && availableValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.availability && item.availability.toLowerCase() === availableValue.toLowerCase()
      );
    }
    if (staffValue !== null && staffValue !== '' && staffValue !== undefined) {
      filteredData = filteredData.filter(item =>
        item.ownerid && item.ownerid.toLowerCase() === staffValue.toLowerCase()
      );
    }

    return filteredData;
  };

  const handleFilterChange = (
    name,
    locationText,
    transactionTypeText,
    typeOfClientText,
    verticalText,
    verticalTypeText,
    subVerticalTypeText,
    toValue,
    fromValue,
    possessionDateTextValue,
    leaseExpirationDateValue,
    availableValue,
    staffValue
  ) => {
    console.log('locationTextttttt', availableValue, locationText, toValue, transactionTypeText)
    try {
      console.log('if locationText', locationText)
      const filteredLeads = filterData(
        name,
        locationText,
        transactionTypeText,
        typeOfClientText,
        verticalText,
        verticalTypeText,
        subVerticalTypeText,
        fromValue,
        toValue,
        possessionDateTextValue,
        leaseExpirationDateValue,
        availableValue,
        staffValue
      );
      console.log('if locationText 261', locationText)
      console.log('filteredLeads body', filteredLeads);
      setBody(filteredLeads);
    } catch (error) {
      console.log('catch locationText', locationText)
      console.error('Error occurred while filtering leads:', error);
    }
  };


  //==============================================
  const StageColors = {
    'Active': "#8E969C",
    'Close': "#C5E7E2",
    'Hold': "#EDF0A7",
    'Pending': "#A9D1D7",
    'Option Shared': "#A1DAD7",
    'Site Visit Done': "#8BD0D0",
    'Negotiation Stage': "#6BAAAE",
    'Due diligence Stage': "#B4D3C4",

  };
  console.log('body',body);

  const header = [
    {
      title: "Name",
      prop: "customername",
      isFilterable: true,
      cell: (row) => (
        <div>
          <Link to={"/leads/" + row.id} state={row} className="name">
            {row.firstname + ' ' + row.lastname}
          </Link>
        </div>
      ),
    },

    {
      title: "Vertical",
      prop: "vertical",
      isFilterable: true,
      cell: (row) => {
        return (
          <>
         { row.vertical && row.vertical !=='' && <Badge
            bg={row.vertical !== null ? row.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[row.vertical] : ""}
            style={{
              display: "block",
              paddingBottom: "5px",
              backgroundColor: row.vertical === "Logistic and Warehouse" ? "#E8EB96" : VerticalColors[row.vertical],
              color: "black",
              fontWeight: 'bold',
              fontSize: '0.9rem'
            }}
          >

            {row.vertical}
          </Badge>}
          </>
        );
      },
    },

    {
      title: "Stage",
      prop: "leadstage",
      isFilterable: true,
      cell: (row) => {
        return (
          <Badge
            bg={StageColors[row.leadstage]}
            style={{
              display: "block",
              borderRadius: "15px",
              padding: "5px 5px",
              backgroundColor: StageColors[row.leadstage],
              color: "black",
              fontWeight: 'bold',
              fontSize: '0.9rem',
              width: '150px'
            }}
          >
            {row.leadstage}
          </Badge>
        );
      },
    },

    // { title: "Email", prop: "email", isFilterable: true },
    // {
    //   title: "Phone",
    //   prop: "phone",
    //   isFilterable: true,
    // },
    {
      title: "Assigned Staff",
      prop: "ownername",
      isFilterable: true,
      cell: (row) => (
        <>
       
            <Image variant="top"
                src={`${BASE_URL}/static_files/ibs_sthapatya/users/${row.ownerid}.jpg`}
                style={{width:"35px"}}
                className="rounded-circle "
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/img_avatar.png';
                }}
               />
                 <Link to={"/users/" + row.ownerid} className="name">
            {' '+row.ownername}
          </Link>      
      </>
      )
    },
    // {
    //   title: "Area From To",
    //   prop: "areaaddress",
    //   isSortable: true,
    //   isFilterable: true,
    //   cell: (row) => (
    //     <span>
    //      {row.areavaluein &&  row.areafrom && row.areato &&
    //      <div className="areaaddress">{`${ row.areafrom}-${ row.areato} ${ row.areavaluein }  `}</div>
    //      }
    //      </span>
    //   ),
    // },
    {
      title: "Lead Source",
      prop: "leadsource",
      isFilterable: true,
    },
    {
      title: "Created Date",
      prop: "createddate",
      isFilterable: true,
      cell: (row) => (
        <>
          {moment(row.createddate).format("DD-MM-YYYY")}
        </>
      )
    },
    // {
    //   title: "Address",
    //   prop: "address",
    //   isFilterable: true,
    //   cell: (row) => (
    //     <span>
    //      <div className="address">{`${(row.clientstreet != null && row.clientstreet !== '') ? row.clientstreet + ',' : ''} ${(row.clientcity != null && row.clientcity !== '' )? row.clientcity + ',' : ''} ${(row.clientpincode != null && row.clientpincode !== '' )? row.clientpincode + ',' : ''} ${row.clientstate != null ? row.clientstate : ''}`}</div>
    //     </span>
    //   ),
    // },
    {
      title: "Located in",
      prop: "locatedin",
      isFilterable: true,
    },

  ];
  const labels = {
    beforeSelect: " ",
  };

  const createLead = () => {
    navigate(`/leads/e`);
  };

  const [ownerList, setownerList] = useState([]);


  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchUsers();
      if (result) {
        setownerList(result);
       
      }
    }
    init();
  }, []);

  return (
    <Container>
      <CustomSeparator
        currentCmpName="Leads"
        indexLength="0"
        url="/leads"
      ></CustomSeparator>
      <Row className="g-0">
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },

            }}

          >
            <Row className="mb-4 align-items-end justify-content-between">
              <Col
                lg={2}
                xs={6}
                sm={6}
                className="mt-3"
              >
                {/* <Form.Group controlId="formBasicFilterType">
                  <Form.Select
                    aria-label="Select Filter Type"
                    name="type"
                    onChange={handleChange}
                    className="select-dropdown-1"
                  >
                    <option value="">--Select Type--</option>
                    {firstDropdownOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group> */}
                <Form.Group
                  controlId="formBasicTextInput"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={nameValue}
                    onChange={(e) => {
                      setNameValue(e.target.value)
                      handleFilterChange(e.target.value, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);
                    }}
                  />
                </Form.Group>

              </Col>


              <Col lg={2}                  >
                <Form.Group
                  controlId="formBasicTextInput"

                >
                  <Form.Control
                    type="text"
                    name="locatedin"
                    placeholder="Enter Located In"
                    value={locationValue}
                    onChange={(e) => {
                      setLocationValue(e.target.value);
                      handleFilterChange(nameValue, e.target.value, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);
                    }
                    }
                  />
                  {/* <Button className="btn-danger"
                        onClick={() => {
                          setTextInputValue('');
                          setFilterObj({
                            ...filterObj,
                            secondDropdownValue: "",
                          });
                          setBody(leads);
                        }}>X</Button> */}
                </Form.Group>
              </Col>

              <>
                <Col lg={2}>
                  <Form.Select
                    id="basic-typeahead-single"
                    name="transactiontype"
                    value={selectedTransaction}
                    onChange={(e) => {
                      setSelectedTransaction(e.target.value);
                      // setTranscationType(e.target.value)
                      const temp = [];
                      if (e.target.value !== "") {
                        if (
                          Object.entries(data.Dependency[e.target.value])
                            .length > 0
                        ) {
                          for (const [key, value] of Object.entries(
                            data.Dependency[e.target.value]
                          )) {
                            temp.push(key);
                          }
                          setTypeOfClient(temp);
                        }
                      } else {
                        setTypeOfClient([]);
                      }

                      handleFilterChange(nameValue, locationValue, e.target.value, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);



                    }}
                    placeholder="Choose a state..."
                  >
                    <option value="">Select Transaction Type </option>
                    {(transcationType) &&
                      transcationType?.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>

                <Col lg={2}>
                  <Form.Select
                    id="basic-typeahead-single"
                    name="typeofclient"
                    disabled={!selectedTransaction}
                    value={selectedTypeOfClient}
                    onChange={(e) => {
                      setSelectedTypeOfClient(e.target.value)
                      const temp = [];
                      if (e.target.value !== "") {
                        if (
                          Object.entries(
                            data.Dependency[selectedTransaction]
                          ).length > 0
                        ) {
                          for (const [key, value] of Object.entries(
                            data.Dependency[selectedTransaction][
                            e.target.value
                            ]
                          )) {
                            temp.push(key);
                          }
                          setVertical(temp);
                        }
                      } else {
                        setVertical([]);
                      }
                      handleFilterChange(nameValue, locationValue, selectedTransaction, e.target.value, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue,staffValue);
                    }}
                  >
                    <option value="">Select Type of Client</option>
                    {typeOfClient?.length > 0 &&
                      typeOfClient?.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Select
                    id="basic-typeahead-single"
                    name="vertical"
                    disabled={!selectedTypeOfClient}
                    value={selectedVertical}
                    onChange={(e) => {
                      setSelectedVertical(e.target.value);
                      if (e.target.value !== "") {
                        if (
                          Object.entries(
                            data.Dependency[selectedTransaction]
                          ).length > 0
                        ) {
                          const temp = [];
                          for (const [key, value] of Object.entries(
                            data.Dependency[selectedTransaction][
                            selectedTypeOfClient
                            ][e.target.value]
                          )) {
                            temp.push(key);
                          }
                          setVerticalType(temp);
                        }
                      } else {
                        setVerticalType([]);
                      }
                      handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, e.target.value, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);
                    }}
                    placeholder="Choose a state..."
                  >
                    <option value="">Select Vertical</option>
                    {verticals?.length > 0 &&
                      verticals?.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Select
                    id="basic-typeahead-single"
                    name="verticaltype"
                    disabled={!selectedVertical}
                    value={selectedVerticalType}
                    onChange={(e) => {
                      setSelectedVerticalType(e.target.value);
                      if (e.target.value !== "") {
                        if (
                          Object.entries(
                            data.Dependency[selectedTransaction]
                          ).length > 0
                        ) {
                          const temp = [];
                          for (const [key, value] of Object.entries(
                            data.Dependency[selectedTransaction][
                            selectedTypeOfClient
                            ][selectedVertical][e.target.value]
                          )) {
                            temp.push(...value);
                          }
                          setSubVerticalType(temp);
                        }
                      } else {
                        setSubVerticalType([]);
                      }
                      handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, e.target.value, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);
                    }}
                    placeholder="Choose a state..."
                  >
                    <option value="">Select Vertical Type</option>
                    {verticalType?.length > 0 &&
                      verticalType?.map((value) => (
                        <>
                          <option value={value}>{value}</option>
                        </>
                      ))}
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Select
                    id="basic-typeahead-single"
                    name="subverticaltype"
                    disabled={!selectedVerticalType}
                    value={selectedSubVerticalType}
                    onChange={(e) => {
                      setSelectedSubVerticalType(e.target.value);
                      handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, e.target.value, to, from, availableValue, possessionDateValue, leaseExpirationDateValue,staffValue);
                    }}
                    placeholder="Choose a state..."
                  >
                    <option value="">Select Sub Vertical Type</option>
                    {selectedVertical === "Cold storage" || selectedVertical === "Warehouse" || selectedVertical === "Factory" ?
                      <>
                        <option value="Ready to Move">Ready to Move</option>
                        <option value="BTS">BTS</option>
                        <option value="Under Construction">Under Construction</option>
                      </> : ''
                    }
                    {subVerticalType?.length > 0 &&
                      subVerticalType?.map((value) => (
                        <>
                          <option value={value}>{value}</option>
                        </>
                      ))}
                  </Form.Select>
                </Col>
              </>
              <>
                <Col lg={2}>
                  <Form.Group
                    controlId="formBasicTextInput"
                  >
                    <Form.Control
                      type="number"
                      // disabled = { from !==''}
                      value={to}
                      placeholder="Enter To"
                      onChange={(e) => {

                        setTo(e.target.value)
                        console.log('to', to);
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, e.target.value, from, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);
                      }}
                    />
                    {/* <Button className="btn-danger"
                        onClick={() => {
                          to('');
                          setFilterObj({
                            ...filterObj,
                            secondDropdownValue: "",
                          });
                          setBody(leads);
                        }}>X</Button> */}
                  </Form.Group>
                </Col>
                <Col lg={2}
                  className="mt-2">
                  <Form.Group
                    controlId="formBasicTextInput"
                  >
                    <Form.Control
                      type="number"
                      value={from}
                      // disabled = { to ===''}
                      placeholder="Enter From "
                      onChange={(e) => {
                        setFrom(e.target.value)

                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, e.target.value, possessionDateValue, leaseExpirationDateValue, availableValue,staffValue);


                      }}
                    />
                  </Form.Group>
                </Col>
              </>
              <Col lg={2}
                className="mt-2">
                <Form.Group controlId="formBasicStatus">
                  <Form.Select
                    value={staffValue}
                    onChange={(e) => {
                      setStaffValue(e.target.value)
                      handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, availableValue,e.target.value);

                    }}
                  >
                     <option value="">Select Assign Staff</option>
                              {ownerList &&
                                ownerList.map((ele) => (
                                  <option value={ele.id}>{ele.username}</option>
                                ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={2}
                className="mt-2">
                <Form.Group controlId="formBasicStatus">
                  <Form.Select
                    value={availableValue}
                    onChange={(e) => {
                      setAvailableValue(e.target.value)
                      handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, leaseExpirationDateValue, e.target.value,staffValue);

                    }}
                  >
                    <option value="">Select Availability</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={2} className="mt-2">
                {availableValue === "Available" ? (
                  <>

                    <Form.Control
                      type="date"
                      name="possessiondate"
                      //placeholder="Enter Possession Date"
                     // value={possessionDateValue !==''?possessionDateValue:'Enter Possession Date'}
                      onChange={(e) => {
                        setPossessionDatevalue(e.target.value);
                        handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, e.target.value, leaseExpirationDateValue, availableValue,staffValue);

                      }}
                    />
                  </>
                ) : (
                  availableValue === "Not Available" ? (
                    <>
                      <Form.Control
                        type="date"
                        name="leaseexpirationdate"
                        //value={leaseExpirationDateValue}
                        onChange={(e) => {
                          setLeaseExpirationvalue(e.target.value);
                          handleFilterChange(nameValue, locationValue, selectedTransaction, selectedTypeOfClient, selectedVertical, selectedVerticalType, selectedSubVerticalType, to, from, possessionDateValue, e.target.value, availableValue,staffValue);
                        }}
                      />
                    </>
                  ) : null
                )}
              </Col>

             

              <Col lg={2}
                className="d-flex flex-col mt-3">
                <Button variant="danger" onClick={() => {
                  setNameValue('');
                  setLocationValue('');
                  setSelectedTransaction('');
                  setSelectedTypeOfClient('');
                  setSelectedVertical('');
                  setSelectedSubVerticalType('');
                  setSelectedVerticalType('');
                  setTo('');
                  setFrom('');
                  setAvailableValue('');
                  setLeaseExpirationvalue('');
                  setPossessionDatevalue('');
                  setStaffValue('');
                  setBody(leads);
                }

                }>
                  Reset
                </Button>
              </Col>

              <Col
                lg={1}
                xs={2}
                sm={2}
                className="mt-2">
                <PaginationOptions labels={labels} />
              </Col>

              <Col
                lg={2}
                xs={3}
                sm={3}
                className="mt-2">
                <Form.Group controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Select option"
                    onChange={(e) => {

                      setSortFilter(e.target.value)

                      handleSortFilter(e.target.value)

                    }}
                  >
                    <option value="no filter">--Sort Lead--</option>
                    <option value="newly">Newest</option>
                    <option value="lastly">Oldest</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col
                lg={2}
                xs={4}
                sm={4}
                className="mt-3 d-flex flex-col justify-content-start align-items-end"
              >
                <NewInfoPill left="Total" right={body?.length} />
              </Col>

              <Col
                lg={2}
                xs={3}
                sm={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm outline-primary"
                  variant="outline-primary"
                  onClick={() => createLead(true)}
                >
                  New Lead
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {body ? (
                  <Table responsive striped className="data-table">
                    <TableHeader />

                    <TableBody />
                  </Table>
                ) : (
                  <ShimmerTable row={10} col={8} />
                )}
              </Col>
            </Row>
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Container>
  );
};

export default LeadList;
